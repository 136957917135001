import React, { FC } from 'react';

// @ts-ignore
import Arrow from '../../../../images/arrow-back.svg';

import './ExchangeButton.scss';


interface ExchangeButtonProps {
    onClick: () => void;
    text: string;
}

const ExchangeButton: FC<ExchangeButtonProps> = ({ onClick, text }) => (
    <button onClick={onClick} type="button" className="exchange-button">
        {text}
        <div className="exchange-button__arrow">
            <img alt="arrow-back" src={Arrow} />
        </div>
    </button>
)

export default ExchangeButton;
