import {
    addHours,
    addSeconds,
    areIntervalsOverlapping,
    compareAsc,
    formatDate,
    startOfDayInHours,
} from '../../helpers/date';
import trans from '../../helpers/trans';
import { DateFormat } from '../../types/dateFormatTypes';
import { PayrollPeriod } from './PayrollPeriod';

export const doesDateOverlapWithPayrollPeriod = (date: Date, payrollPeriod: PayrollPeriod): boolean => areIntervalsOverlapping({
    start: date,
    end: addSeconds(date, 1),
}, {
    start: payrollPeriod.period.start,
    end: payrollPeriod.period.end,
});

export const findPayrollPeriodIndexFromDate = (date: Date, payrollPeriods: PayrollPeriod[]): number => payrollPeriods
    .findIndex(payrollPeriod => doesDateOverlapWithPayrollPeriod(date, payrollPeriod));

export const getPayrollPeriodFromDate = (date: Date, payrollPeriods: PayrollPeriod[]): PayrollPeriod | undefined => {
    if (!payrollPeriods.length) {
        return undefined;
    }

    const match = payrollPeriods.find(payrollPeriod => doesDateOverlapWithPayrollPeriod(date, payrollPeriod));

    if (match) {
        return match;
    }

    if (compareAsc(payrollPeriods[0].period.start, date) === -1) {
        return payrollPeriods[payrollPeriods.length - 1];
    }

    return payrollPeriods[0];
};
