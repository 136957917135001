import {
    put, takeLatest, call, select,
} from 'redux-saga/effects';
import {
    AUTH_SET_TENANT,
    AUTH_SET_API_TOKEN,
    AUTH_SET_REFRESH_TOKEN,
    AUTH_SAGA_SET_API_TOKEN_AND_GET_USER,
    AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST,
    AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE,
    AUTH_SET_API_TOKEN_AND_GET_USER_SUCCESS,
    SET_LOADING,
    AUTH_SAGA_AUTHENTICATE_USER,
    AUTH_SAGA_UPDATE_API_TOKEN,
    AUTH_SAGA_UPDATE_REFRESH_TOKEN,
    AUTH_SAGA_UPDATE_TENANT,
    AUTH_SAGA_UNSET_TOKENS,
    APP_SAGA_LOGOUT,
    AUTH_UNSET_TOKENS,
    AUTH_SAGA_SUBMIT_PASSWORD,
    AUTH_SUBMIT_PASSWORD_REQUEST,
    AUTH_SUBMIT_PASSWORD_SUCCESS,
    AUTH_SUBMIT_PASSWORD_FAILURE,
    AUTH_FORGOT_PASSWORD_REQUEST,
    AUTH_FORGOT_PASSWORD_SUCCESS,
    AUTH_FORGOT_PASSWORD_FAILURE,
    AUTH_SAGA_FORGOT_PASSWORD,
    AUTH_AUTHENTICATE_USER_REQUEST,
    AUTH_AUTHENTICATE_USER_SUCCESS,
    AUTH_AUTHENTICATE_USER_FAILURE,
} from '../actionTypes';
import { getObjProperty } from '../../helpers/tools';
import authenticateUserRequest, { patchUserPassword, postForgotPassword } from '../../helpers/api/authenticationApi';
// eslint-disable-next-line import/no-cycle
import { getCurrentUser } from '../user/userSaga';
import { translate } from '../../helpers/translations/translator';
import { ToastType } from '../../@paco/types/toastTypes';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';

function* authenticateUser(action) {
    yield put({ type: AUTH_AUTHENTICATE_USER_REQUEST });
    yield put({ type: SET_LOADING, loading: true });

    try {
        const response = yield call(authenticateUserRequest, action.credentials);
        yield put(
            {
                type: AUTH_AUTHENTICATE_USER_SUCCESS,
                apiToken: response.data.token,
                refreshToken: response.data.refresh_token,
            },
        );
        yield getCurrentUser();
    } catch (error) {
        yield put({ type: AUTH_AUTHENTICATE_USER_FAILURE });
    }

    yield put({ type: SET_LOADING, loading: false });
}

function* setTenant(action) {
    yield put({ type: AUTH_SET_TENANT, tenant: action.tenant });
}

function* setApiToken(action) {
    yield put({ type: AUTH_SET_API_TOKEN, apiToken: action.apiToken });
}

function* setRefreshToken(action) {
    yield put({ type: AUTH_SET_REFRESH_TOKEN, refreshToken: action.refreshToken });
}

export function* unsetTokens() {
    yield put({ type: AUTH_UNSET_TOKENS });
}

function* setApiTokenAndGetUser() {
    yield put({ type: AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST });

    try {
        yield put({ type: 'GET_CURRENT_USER' }); // TODO: this neccessary?
        yield put({ type: AUTH_SET_API_TOKEN_AND_GET_USER_SUCCESS });
    } catch (error) {
        yield put({ type: AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE });
    }
}

function* setUserPasswordPincode(action) {
    yield put({ type: AUTH_SUBMIT_PASSWORD_REQUEST });

    try {
        const state = yield select();
        const userId = getObjProperty(state, 'userReducer.currentUser.id');
        yield call(patchUserPassword, userId, action.password, action.pincode);
        // If user was logged in by query, then we need to clear tokens
        if (action.loginByQuery) {
            yield unsetTokens();
        } else {
            action.history.push(`/${translate('nav.profile.link')}`);
        }
        yield put(setToast({ text: translate('pages.changePasswordPincode.changeSuccess'), type: ToastType.pass }));
        yield put({ type: AUTH_SUBMIT_PASSWORD_SUCCESS });
    } catch (error) {
        yield put({ type: AUTH_SUBMIT_PASSWORD_FAILURE });
    }
}

export function* forgotPassword({ email }) {
    yield put({ type: AUTH_FORGOT_PASSWORD_REQUEST });
    try {
        yield call(() => postForgotPassword(email));
        yield put({ type: AUTH_FORGOT_PASSWORD_SUCCESS });
    } catch (error) {
        yield put({ type: AUTH_FORGOT_PASSWORD_FAILURE });
    }
}

export default function* authenticationWatcher() {
    yield takeLatest(AUTH_SAGA_SET_API_TOKEN_AND_GET_USER, setApiTokenAndGetUser);
    yield takeLatest(AUTH_SAGA_AUTHENTICATE_USER, authenticateUser);
    yield takeLatest(AUTH_SAGA_UPDATE_API_TOKEN, setApiToken);
    yield takeLatest(AUTH_SAGA_UPDATE_REFRESH_TOKEN, setRefreshToken);
    yield takeLatest(AUTH_SAGA_UPDATE_TENANT, setTenant);
    yield takeLatest(AUTH_SAGA_UNSET_TOKENS, unsetTokens);
    yield takeLatest(AUTH_SAGA_SUBMIT_PASSWORD, setUserPasswordPincode);
    yield takeLatest(AUTH_SAGA_FORGOT_PASSWORD, forgotPassword);
    yield takeLatest(APP_SAGA_LOGOUT, unsetTokens);
}
