import {
    put,
    takeLatest,
} from 'redux-saga/effects';
import {
    SET_LOADING,
    SET_APP_ERROR,
    APP_SAGA_SET_LOADING,
    APP_SAGA_SET_APP_ERROR,
    APP_SAGA_LOGOUT,
    APP_LOGOUT,
} from '../actionTypes';
import { clearSentrySessionUser } from '../../helpers/sentry';

export function* setLoading({ loading }) {
    yield put({ type: SET_LOADING, loading });
}

export function* setAppError({ error }) {
    yield put({ type: SET_APP_ERROR, error });
}

export function* logout() {
    clearSentrySessionUser();
    yield put({ type: APP_LOGOUT });
}

export default function* appWatcher() {
    yield takeLatest(APP_SAGA_SET_LOADING, setLoading);
    yield takeLatest(APP_SAGA_SET_APP_ERROR, setAppError);
    yield takeLatest(APP_SAGA_LOGOUT, logout);
}
