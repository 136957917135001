import React, { FC, useEffect, useState } from 'react';

import { Toast } from '../../compositions';
import { Toast as ToastInterface } from '../../types/toastTypes';
import { toastsWithFadingBehaviour } from './helpers';

import './ToastsContainer.scss';

interface ToastProps {
    toasts: ToastInterface[];
    className?: string;
}

const ToastsContainer: FC<ToastProps> = ({ toasts, className = '' }) => {
    const [toastsState, setToastsState] = useState<ToastInterface[]>([]);

    useEffect(() => {
        setToastsState(toastsWithFadingBehaviour(toasts));
    }, [toasts]);

    return (
        <div className={`toasts-container ${className}`}>
            {toastsState.map(toast => (
                <Toast
                    key={`${toast.createdOn}-${toast.text}`}
                    toast={toast}
                    className="toasts-container__toast"
                />
            ))}
        </div>
    );
};

export default ToastsContainer;
