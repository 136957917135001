import {
    put, takeLatest, call, all, delay, select,
} from 'redux-saga/effects';
import {
    getProfileOfCurrentUser,
    patchEmergencyContact,
    patchPersonGender,
    patchUserPhoneAndEmail,
    postUserAddress,
} from '../../helpers/api/userApi';
import { getSettings } from '../../helpers/api/settingsApi';
import {
    CURRENT_USER_REQUEST,
    CURRENT_USER_SUCCESS,
    CURRENT_USER_FAILURE,
    CURRENT_USER_SAGA_GET_CURRENT_USER,
    CURRENT_USER_EDIT_REQUEST,
    CURRENT_USER_EDIT_FAILURE,
    CURRENT_USER_EDIT_SUCCESS,
    CURRENT_USER_SAGA_EDIT_PHONE,
    CURRENT_USER_SAGA_EDIT_EMAIL,
    CURRENT_USER_SAGA_EDIT_ADDRESS,
    CURRENT_USER_SAGA_EDIT_EMERGENCY_CONTACT,
    CURRENT_USER_SAGA_EDIT_GENDER,
} from '../actionTypes';
// eslint-disable-next-line import/no-cycle
import { unsetTokens } from '../authentication/authenticationSaga';
import { checkPermission } from '../../helpers/tools';
import { setAppError } from '../app/appSaga';
import { translate } from '../../helpers/translations/translator';
import { setSentrySessionUser } from '../../helpers/sentry';
import { ToastType } from '../../@paco/types/toastTypes';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';

export function* getCurrentUser() {
    yield put({ type: CURRENT_USER_REQUEST });

    try {
        const [userData, settingsData] = yield all([
            call(() => getProfileOfCurrentUser()),
            call(() => getSettings()),
        ]);

        const user = userData.data;
        const { roles } = user;
        setSentrySessionUser(user);

        const permissions = roles
            .reduce((total, role) => [...total, ...role.permissions], [])
            .map(permission => ({ name: permission.slug, id: permission.id }));
        const { pathname } = document.location;
        // Viewer does not have use-employee-app permission, but still needs to be able to reset
        // password or complete registration
        const forgotPassword = pathname.indexOf(translate('nav.forgotPassword.link')) !== -1;
        const completeRegistration = pathname.indexOf(translate('nav.completeRegistration.link')) !== -1;
        if (!forgotPassword && !completeRegistration && !checkPermission(permissions, 'use-employee-app')) {
            yield setAppError({ error: { code: 403 } });
        }

        yield put({
            type: CURRENT_USER_SUCCESS,
            user: userData.data,
            settings: settingsData.data,
            permissions,
        });
    } catch (error) {
        yield put({ type: CURRENT_USER_FAILURE, error: error.response || { status: 401 } });
        yield unsetTokens();
    }
}

function* completeEditAndRoute(title, history) {
    yield history.push(`/${translate('nav.profile.link')}`);
    const toast = translate('pages.profile.updateItemSuccess', { item: title });
    yield put(setToast({ type: ToastType.pass, text: toast }));
}

function* editUserPhone(action) {
    const { phone, userId, history } = action;
    yield put({ type: CURRENT_USER_EDIT_REQUEST });

    try {
        const response = yield call(() => patchUserPhoneAndEmail(userId, phone));
        yield put({ type: CURRENT_USER_EDIT_SUCCESS, user: response.data });
        yield completeEditAndRoute(translate('pages.profile.phone'), history);
    } catch (error) {
        yield put({ type: CURRENT_USER_EDIT_FAILURE, error: error.response });
    }
}

function* editUserEmail(action) {
    const { email, userId, history } = action;
    yield put({ type: CURRENT_USER_EDIT_REQUEST });

    try {
        const response = yield call(() => patchUserPhoneAndEmail(userId, null, email));
        yield put({ type: CURRENT_USER_EDIT_SUCCESS, user: response.data });
        history.push(`/${translate('nav.profile.link')}`);
        const toast = translate('pages.profile.updateEmailSuccess');
        yield put(setToast({ type: ToastType.pass, text: toast }));
        yield delay(3500);
        yield put(setToast({ type: ToastType.pass, text: translate('pages.profile.logout') }));
        yield delay(1500);
        yield unsetTokens();
    } catch (error) {
        yield put({ type: CURRENT_USER_EDIT_FAILURE, error: error.response });
    }
}

function* editUserAddress(action) {
    const {
        street,
        houseNumber,
        houseNumberAddition,
        postalCode,
        city,
        personId,
        history,
    } = action;
    yield put({ type: CURRENT_USER_EDIT_REQUEST });

    try {
        const response = yield call(
            () => postUserAddress(
                personId,
                street,
                houseNumber,
                houseNumberAddition,
                postalCode,
                city,
            ),
        );
        const state = yield select();
        const { currentUser } = state.userReducer;
        currentUser.person.addresses.push(response.data);
        yield put({ type: CURRENT_USER_EDIT_SUCCESS, user: currentUser });
        yield completeEditAndRoute(translate('pages.profile.address'), history);
    } catch (error) {
        yield put({ type: CURRENT_USER_EDIT_FAILURE, error: error.response });
    }
}

function* editEmergencyContact(action) {
    const {
        personId,
        emergencyContactId,
        fullName,
        phone,
        relationType,
        history,
    } = action;
    yield put({ type: CURRENT_USER_EDIT_REQUEST });

    try {
        const response = yield call(
            () => patchEmergencyContact(
                personId, emergencyContactId, fullName, phone, relationType,
            ),
        );
        const state = yield select();
        const { currentUser } = state.userReducer;
        currentUser.person.emergencyContact = response.data;

        yield put({ type: CURRENT_USER_EDIT_SUCCESS, user: currentUser });
        yield completeEditAndRoute(translate('pages.profile.emergencyContact'), history);
    } catch (error) {
        yield put({ type: CURRENT_USER_EDIT_FAILURE, error: error.response });
    }
}

function* editUserGender(action) {
    const { personId, gender, history } = action;
    yield put({ type: CURRENT_USER_EDIT_REQUEST });

    try {
        const response = yield call(() => patchPersonGender(personId, gender));
        const state = yield select();
        const { currentUser } = state.userReducer;
        currentUser.person.gender = response.data.gender;
        yield put({ type: CURRENT_USER_EDIT_SUCCESS, user: currentUser });
        yield completeEditAndRoute(translate('common.gender'), history);
    } catch (error) {
        yield put({ type: CURRENT_USER_EDIT_FAILURE, error: error.response });
    }
}

export default function* userWatcher() {
    yield takeLatest(CURRENT_USER_SAGA_GET_CURRENT_USER, getCurrentUser);
    yield takeLatest(CURRENT_USER_SAGA_EDIT_PHONE, editUserPhone);
    yield takeLatest(CURRENT_USER_SAGA_EDIT_EMAIL, editUserEmail);
    yield takeLatest(CURRENT_USER_SAGA_EDIT_ADDRESS, editUserAddress);
    yield takeLatest(CURRENT_USER_SAGA_EDIT_EMERGENCY_CONTACT, editEmergencyContact);
    yield takeLatest(CURRENT_USER_SAGA_EDIT_GENDER, editUserGender);
}
