import React, { FC, ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';

import { translate } from '../../../helpers/translations/translator';
import Documents from '../../../pages/Documents/Documents';
import LoketHoursPerPeriodPage from '../../../pages/Absences/LoketHoursPerPeriod';
import { InactiveUserTopBar } from './subcomponents';
import { InactiveUserPage } from "../../pages";

const InactiveUserRouteGroup: FC = (): ReactElement => {
    const location = useLocation();

    return (
        <div className="inactive-user-route-group">
            <InactiveUserTopBar showBackButton={location.pathname !== '/'} />

            <Switch>
                <Route
                    exact
                    path={`/${translate('nav.documents.link')}`}
                    component={Documents}
                />

                <Route
                    exact
                    path={`/${translate('nav.absences.link')}/${translate('nav.absences.loketHoursPerPeriod.link')}`}
                    component={LoketHoursPerPeriodPage}
                />

                <Route
                    exact
                    path="/"
                    component={InactiveUserPage}
                />

                <Redirect to="/" />
            </Switch>
        </div>
    );
};

export default InactiveUserRouteGroup;
