import { ShiftPlanningViewModel, ShiftViewModel } from '../../models';
import { convertDateToTime } from '../../helpers/DateTimeParser';

export default function getShiftTime(shift: ShiftViewModel | ShiftPlanningViewModel, showEndTime?: boolean): string {
    const startTime = convertDateToTime(shift.start);
    const endTime = convertDateToTime(shift.end.transformToUpToButExcludingDate().date);

    if (showEndTime) {
        return `${startTime} - ${endTime}`;
    }

    return startTime;
}
