import {
    SHIFT_SAGA_CANCEL_OFFER_SHIFT,
    SHIFT_SAGA_GET_SHIFT,
    SHIFT_SAGA_OFFER_SHIFT,
    SHIFT_SAGA_REGISTER_OFFERED_SHIFT,
    SHIFT_SAGA_REGISTER_SHIFT,
    SHIFT_SAGA_SET_SHIFT,
    SHIFT_SAGA_UNREGISTER_OFFERED_SHIFT,
    SHIFT_SAGA_UNREGISTER_SHIFT,
} from '../actionTypes';

export default shiftUuid => ({ type: SHIFT_SAGA_GET_SHIFT, shiftUuid });
export const registerShift = (shiftId, comment, history) => ({
    type: SHIFT_SAGA_REGISTER_SHIFT,
    shiftId,
    comment,
    history,
});
export const unregisterShift = (shift, history) => ({ type: SHIFT_SAGA_UNREGISTER_SHIFT, shift, history });
export const offerShift = (shiftId, history) => ({ type: SHIFT_SAGA_OFFER_SHIFT, shiftId, history });
export const cancelShiftOffer = (shift, history) => ({ type: SHIFT_SAGA_CANCEL_OFFER_SHIFT, shift, history });
export const registerOfferedShift = (exchange, history) => ({ type: SHIFT_SAGA_REGISTER_OFFERED_SHIFT, exchange, history });
export const unregisterOfferedShift = (exchange, history) => ({ type: SHIFT_SAGA_UNREGISTER_OFFERED_SHIFT, exchange, history });
export const setShift = shift => ({ type: SHIFT_SAGA_SET_SHIFT, shift });
