import axios from 'axios';
import moment from 'moment';
import { ApiResponse, FileResource, UserDocumentViewModel } from '../../models';
import transformFileResourceToUserDocument from '../../services/UserDocumentService/transformFileResourceToUserDocument';

export const getFilesForMe = async (
    year = moment().get('year'),
    includes: string[] = [],
): Promise<UserDocumentViewModel[]> => {
    const startDate = `${year}-01-01 04:00`;
    const endDate = `${year + 1}-01-01 04:00`;

    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/files`,
        {
            params: {
                include: includes.join(','),
                startDate,
                endDate,
            },
        },
    ) as unknown as ApiResponse<FileResource[]>;

    return response
        .data.map(file => transformFileResourceToUserDocument(
            file,
            file.documentType,
        ));
}
