import React, { FC, useEffect, useState } from 'react';

import { ColleagueViewModel, ShiftViewModel } from '../../models';
import { Button, InfoListItem, LoadingSpinner } from '../../@paco/components';
import { translate } from '../../helpers/translations/translator';
import FormUserSelect from '../../components/FormUserSelect/FormUserSelect';
import FormShiftSelect from '../../components/FormShiftSelect/FormShiftSelect';
import { DateInput } from '../../@paco/compositions';
import { SettingResource } from '../../models/Setting';

import './ExchangeShiftForm.scss';


interface ExchangeShiftFormProps {
    loading: boolean;
    employees: ColleagueViewModel[];
    shifts: ShiftViewModel[];
    settings: SettingResource[];
    onSubmitShiftPlanning: (shiftPlanningId: string) => void;
    onSelectUserAndDate: (userId: string, date: Date) => void;
}

const ExchangeShiftForm: FC<ExchangeShiftFormProps> = ({
    loading,
    employees,
    shifts,
    settings,
    onSelectUserAndDate,
    onSubmitShiftPlanning,
}) => {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [selectedShift, setSelectedShift] = useState<string | null>(null);

    useEffect(() => {
        if (selectedUser && selectedDate) {
            onSelectUserAndDate(selectedUser, selectedDate);
        }
    }, [selectedUser, selectedDate]);

    useEffect(() => {
        if (employees.length) {
            setSelectedUser(employees[0].id);
        }
    }, [employees]);

    const onSelectedDateChange = (date: Date) => {
        setSelectedDate(date);
    }

    const onSelectedUserChange = (userId: string) => {
        setSelectedUser(userId);
    }

    const onSelectShiftChange = (shift: ShiftViewModel) => {
        setSelectedShift(shift.id);
    }

    const onSubmitButtonClick = () => {
        const userShift = shifts.find(shift => shift.id === selectedShift);

        if (!userShift) {
            return;
        }

        const userShiftPlanning = userShift.shiftPlannings.find(shiftPlanning => shiftPlanning?.user?.id === selectedUser);

        if (userShiftPlanning) {
            onSubmitShiftPlanning(userShiftPlanning.id);
        }
    }

    return (
        <form className="exchange-shift-form">
            <ul>
                <InfoListItem label={translate('pages.exchanges.withWhomDoYouWantToChangeYourShift')} />
                <InfoListItem>
                    <FormUserSelect
                        users={employees}
                        selectedUser={selectedUser}
                        onSelectUser={onSelectedUserChange}
                    />
                </InfoListItem>
                <InfoListItem label={translate('common.date')}>
                    <DateInput
                        hideLabel
                        label={translate('common.date')}
                        value={selectedDate}
                        onChange={onSelectedDateChange}
                    />
                </InfoListItem>
                {(selectedDate && selectedUser) && (
                    <>
                        <InfoListItem label={translate('pages.exchanges.whichShiftDoYouWantToTakeOver')} />
                        <InfoListItem>
                            {(shifts.length || loading) ? (
                                <FormShiftSelect
                                    shifts={shifts}
                                    settings={settings}
                                    userId={selectedUser}
                                    selectedShift={selectedShift}
                                    onSelectShift={onSelectShiftChange}
                                />
                            ) : (
                                translate('pages.exchanges.noShiftsFoundForUserOnThisDate')
                            )
                            }
                        </InfoListItem>
                    </>
                )}
            </ul>
            {loading && (
                <LoadingSpinner />
            )}
            {selectedShift && (
                <Button
                    text={translate('common.subscribe')}
                    onClick={onSubmitButtonClick}
                    className="exchange-shift-form__submit-button"
                />
            )}
        </form>
    );
};

export default ExchangeShiftForm;
