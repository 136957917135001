export const LEAVE_OF_ABSENCES = 'LEAVE_OF_ABSENCES';
export const ABSENCES = 'ABSENCES';
export const MY_SHIFTS = 'MY_SHIFTS';
export const OPEN_SHIFTS = 'OPEN_SHIFTS';
export const MY_EXCHANGES = 'MY_EXCHANGES';
export const OFFERED_EXCHANGES = 'OFFERED_EXCHANGES';
export const ABSENCE_STATUS_DENIED = 'denied';
export const ABSENCE_STATUS_OPEN = 'open';
export const MONTH = 'month';
export const WEEK = 'week';
export const GENDER = 'gender';
export const GENDERS = [
    {
        label: 'male',
        value: 'male',
    },
    {
        label: 'female',
        value: 'female',
    },
    {
        label: 'other',
        value: 'other',
    },
    {
        label: 'unknown',
        value: 'null',
    },
];
