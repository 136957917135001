import {
    put, takeLatest, call,
} from 'redux-saga/effects';
import {
    SET_LOADING,
    LEAVE_OF_ABSENCES_FAILURE,
    LEAVE_OF_ABSENCES_REQUEST,
    LEAVE_OF_ABSENCES_SUCCESS,
    ABSENCES_FAILURE,
    ABSENCES_REQUEST,
    ABSENCES_SUCCESS,
    ABSENCES_SET_TAB,
    ABSENCES_SAGA_SET_TAB,
    ABSENCES_SAGA_GET_LEAVE_OF_ABSENCES_ME,
    ABSENCES_SAGA_GET_ABSENCES_ME,
} from '../actionTypes';
import { getAbsencesForMe } from '../../helpers/api/absencesApi';
import { getObjProperty } from '../../helpers/tools';
import { translate } from '../../helpers/translations/translator';
import { ABSENCES } from '../../constants';
import { getLeaveOfAbsencesForMe } from '../../helpers/api-ts/absencesApi';
import { compareAsc } from '../../@paco/helpers/date';

export function* fetchAbsences({ year }) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: ABSENCES_REQUEST });

    try {
        const includes = ['comments'];

        const response = yield call(() => getAbsencesForMe(includes, year));
        const absences = response.data.sort((a, b) => compareAsc(a.start, b.start));
        yield put({
            type: ABSENCES_SUCCESS,
            absences,
        });
    } catch (error) {
        yield put(
            { type: ABSENCES_FAILURE, errors: [error.response] },
        );
    }

    yield put({ type: SET_LOADING, loading: false });
}

export function* fetchLeaveOfAbsences(action) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: LEAVE_OF_ABSENCES_REQUEST });

    try {
        const year = getObjProperty(action, 'year') || undefined;
        const includes = ['comments'];
        const startDate = new Date(`${year}-01-01`);
        const endDate = new Date(`${year}-12-31`);

        const leaveOfAbsences = yield call(() => getLeaveOfAbsencesForMe(includes, startDate, endDate));
        const sortedLeaveOfAbsences = leaveOfAbsences.sort((a, b) => compareAsc(a.start, b.start));

        yield put({
            type: LEAVE_OF_ABSENCES_SUCCESS,
            leaveOfAbsences: sortedLeaveOfAbsences,
        });
    } catch (error) {
        yield put(
            { type: LEAVE_OF_ABSENCES_FAILURE, errors: [error.response] },
        );
    }

    yield put({ type: SET_LOADING, loading: false });
}

export function* setAbsencesTab({ tab, history }) {
    const tabLink = tab === ABSENCES ? translate('nav.absences.absences.link') : translate('nav.absences.leaveOfAbsences.link');
    const link = `/${translate('nav.absences.link')}/${tabLink}`;
    history && history.push(link);
    yield put({ type: ABSENCES_SET_TAB, tab });
}

export default function* leaveOfAbsencesWatcher() {
    yield takeLatest(ABSENCES_SAGA_GET_LEAVE_OF_ABSENCES_ME, fetchLeaveOfAbsences);
    yield takeLatest(ABSENCES_SAGA_GET_ABSENCES_ME, fetchAbsences);
    yield takeLatest(ABSENCES_SAGA_SET_TAB, setAbsencesTab);
}
