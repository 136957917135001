import { ShiftExchangeViewModel, ShiftPlanningViewModel } from '../../../models';

export default function getColleagueShiftPlanning(
    exchange: ShiftExchangeViewModel,
    userId: string,
): ShiftPlanningViewModel | undefined {
    if (exchange.shiftPlanning1 && exchange.shiftPlanning1.user && exchange.shiftPlanning1.user.id !== userId) {
        return exchange.shiftPlanning1;
    }

    if (exchange.shiftPlanning2 && exchange.shiftPlanning2.user && exchange.shiftPlanning2.user.id !== userId) {
        return exchange.shiftPlanning2;
    }

    return undefined;
}
