import React, { FC } from 'react';
import Icon from '../style/Icon/Icon';

import './FormSelect.scss';


interface FormSelectProps {
    options: JSX.Element[];
    selected: string | null;
    title?: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const FormSelect: FC<FormSelectProps> = ({
    options,
    selected,
    title,
    onChange,
}) => (
    <div className="form-select">
        { title && (
            <div className="form-select__title">
                {title}
            </div>
        )}
        <div className="form-select__select-container">
            <select
                value={selected || ''}
                className="form-select__select"
                onChange={onChange}
            >
                {options}
            </select>
            <Icon kind="keyboard_arrow_down" className="form-select__arrow-down" fontSize={24} />
        </div>
    </div>
)

export default FormSelect;


