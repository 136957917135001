import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import connect from 'react-redux/es/connect/connect';
import Navigation from './Header/Navigation';
import ToolTip from '../ToolTip/ToolTip';
import { LoadingSpinner } from '../../@paco/components';

function Layout(props) {
    return (
        <>
            <Navigation {...props} />
            {props.loading && <LoadingSpinner />}
            {props.children}
            {!props.isEmbedded && <ToolTip {...props} />}
        </>
    );
}

Layout.propTypes = {
    isEmbedded: PropTypes.bool.isRequired,
    children: PropTypes.object,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    shiftsActiveTab: PropTypes.string,
    exchangesActiveTab: PropTypes.string,
    absencesActiveTab: PropTypes.string,
};
Layout.defaultProps = {
    children: null,
    shiftsActiveTab: null,
    exchangesActiveTab: null,
    absencesActiveTab: null,
};

const mapStateToProps = state => ({
    isEmbedded: state.pacoAppReducer.isEmbedded,
    loading: state.appReducer.loading,
    shiftsActiveTab: state.shiftsReducer.activeTab,
    exchangesActiveTab: state.exchangesReducer.activeTab,
    absencesActiveTab: state.absencesReducer.activeTab,
});

export default withRouter(connect(mapStateToProps)(Layout));
