/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconWarningTriangle: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 14 14" className={`icon ${className}`}>
        <g transform="translate(-521.000000, -390.000000)">
            <path d="M522.47,403.22h11.06c1.13,0,1.84-1.22,1.27-2.2l-5.53-9.52c-0.57-0.97-1.98-0.97-2.54,0l-5.53,9.52 C520.63,402,521.34,403.22,522.47,403.22z M528,398.1c-0.4,0-0.73-0.33-0.73-0.73v-1.46c0-0.4,0.33-0.73,0.73-0.73 s0.73,0.33,0.73,0.73v1.46C528.73,397.77,528.4,398.1,528,398.1z M528.73,401.03h-1.47v-1.46h1.47V401.03z" />
        </g>
    </svg>
);

export default IconWarningTriangle;
/* eslint-enable max-len */
