import { PayrollPeriod as LegacyPayrollPeriod } from '../../../models';
import { PayrollPeriod } from './PayrollPeriod';
import { transformToPeriod } from '../Period/PeriodTransformers';

export const transformLegacyPayrollPeriodToPayrollPeriod = (legacy: LegacyPayrollPeriod): PayrollPeriod => ({
    id: legacy.id,
    year: legacy.year,
    periodNumber: legacy.periodNumber,
    canEdit: legacy.canEdit,
    period: transformToPeriod(legacy.start, legacy.end.transformToUpToButExcludingDate().date),
});
