import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCrossClose: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 14 14" className={`icon ${className}`}>
        <polygon points="14,1.4 12.6,0 7,5.6 1.4,0 0,1.4 5.6,7 0,12.6 1.4,14 7,8.4 12.6,14 14,12.6 8.4,7 " />
    </svg>
);

export default IconCrossClose;
