import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { Button, LanguageIcon, Page } from '../../components';
import trans, {
    getLanguage,
    languages,
    Locale,
    setLanguage,
} from '../../helpers/trans';

import './LanguagePage.scss';

const LanguagePage: FC = (): ReactElement => {
    const handleButtonClick = (locale: Locale): void => {
        setLanguage(locale);
        window.location.href = '/';
    }

    return (
        <Page className="language-page">
            <h1 className="language-page__title">
                {trans('common.appLanguage')}
            </h1>
            <div className="language-page__buttons-wrapper">
                {languages.map(language => {
                    const isActive = getLanguage().code === language.code;
                    const buttonClassName = classnames('language-page__button', {
                        'language-page__button--is-active': isActive,
                    });

                    return (
                        <Button
                            key={language.code}
                            text={trans(`common.language.${language.code}`)}
                            onClick={() => handleButtonClick(language.code)}
                            className={buttonClassName}
                        >
                            <LanguageIcon
                                locale={language.code}
                                className="language-page__button-icon"
                            />
                            <div className="language-page__button-text">
                                {trans(`common.language.${language.code}`)}
                            </div>
                        </Button>
                    )
                })}
            </div>
        </Page>
    );
}

export default LanguagePage;
