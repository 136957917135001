import React, { ChangeEvent, FC, ReactElement } from 'react';
import { ErrorLabel, Input } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';

import './TextInput.scss';

export interface TextInputProps extends Omit<HTMLInputProps, 'onChange'> {
    error?: string;
    inputClassName?: string;
    type: HTMLInputProps['type'];
    onChange?: (value: string) => void;
}

const TextInput: FC<TextInputProps> = ({
    error,
    type = 'text',
    className = '',
    inputClassName,
    onChange,
    ...inputProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (onChange) {
            onChange(event.currentTarget.value);
        }
    };

    return (
        <div className={`text-input ${className}`}>
            <Input
                {...inputProps}
                type={type}
                className={`text-input__input ${inputClassName}`}
                onChange={handleChange}
            />
            {error && <ErrorLabel text={error} className="text-input__error-label" />}
        </div>
    );
};

export default TextInput;
