import React from 'react';
import PropTypes from 'prop-types';

import LeaveOfAbsence from '../../models/LeaveOfAbsence';
import { getLeaveOfAbsenceDateString } from '../../helpers/date/DateTimeParser';
import { Badge, ListGroupItem } from '../../@paco/components';

function LeaveOfAbsenceListItem({ leaveOfAbsence, onClick, className }) {
    const leaveOfAbsenceObject = new LeaveOfAbsence(leaveOfAbsence);

    return (
        <ListGroupItem onClick={onClick} className={className}>
            <div className="list-group-item__header text">
                <div className="d-flex">
                    <div className="font-weight-bold">{leaveOfAbsence.reason}</div>
                </div>
                <div className="d-flex">
                    {getLeaveOfAbsenceDateString(leaveOfAbsence)}
                </div>
            </div>
            { leaveOfAbsence.status && (
                <div className="align-self-center d-flex flex-column">
                    <Badge className="ml-auto" color={leaveOfAbsenceObject.getColor()}>
                        {leaveOfAbsenceObject.getLabel()}
                    </Badge>
                </div>
            ) }
        </ListGroupItem>
    );
}

LeaveOfAbsenceListItem.propTypes = {
    leaveOfAbsence: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

LeaveOfAbsenceListItem.defaultProps = {
    onClick: () => {},
    className: '',
};

export default LeaveOfAbsenceListItem;
