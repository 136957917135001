import { UserResource } from '../../models';
import { User } from '../../@paco/entities/User/User';
import { UserStatus } from '../../@paco/types/userStatus';
import { getEnumKeyByEnumValue } from '../../@paco/helpers/enum';
import { transformLegacyPersonResourceToPacoPerson } from '../PersonService/transformLegacyPersonToPacoPerson';
import { transformLegacyDepartmentToDepartment } from '../DepartmentService/transformLegacyDepartmentToDepartment';
import { transformLegacyRoleToRole } from '../RoleService/transformLegacyRoleToRole';

export const transformLegacyUserResourceToPacoUser = (user: UserResource): User => {
    const status = getEnumKeyByEnumValue(UserStatus, user.status);

    if (!status) {
        console.error(`[transformToUser]: Unknown status enum found named "${user.status}".`);
    }

    return {
        id: user.id,
        isEligibleForTimeForTime: user.isEligibleForTimeForTime,
        isLoketAuthorized: user.isLoketAuthorized,
        isPaidBasedOnTrackedTime: user.isPaidBasedOnTrackedTime,
        shouldTrackTime: user.shouldTrackTime,
        birthday: new Date(user.birthday),
        contractHours: user.contractHours,
        departments: user.departments.map(transformLegacyDepartmentToDepartment),
        email: user.email,
        employeeNumber: user.employeeNumber,
        nationality: user.nationality,
        person: transformLegacyPersonResourceToPacoPerson(user.person),
        phone: user.phone,
        registrationComplete: user.registrationComplete,
        roles: user.roles.map(transformLegacyRoleToRole),
        status: status ? UserStatus[status] : UserStatus.active,
    };
}
