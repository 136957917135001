import { put } from 'redux-saga/effects';
import {
    EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_FAILURE,
    EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_FAILURE,
    EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_FAILURE,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_FAILURE,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_REQUEST,
    EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_SUCCESS,
    EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_FAILURE,
    EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_REQUEST,
    EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_SUCCESS,
} from '../actionTypes';
import {
    declineShiftExchange,
    deleteShiftExchange,
    getShiftExchange,
    getShiftExchangesInDateRange,
    patchApproveShiftExchange,
    postShiftExchange,
} from '../../helpers/api-ts/shiftExchanges';
import { translate } from '../../helpers/translations/translator';
import { AcceptShiftExchangeAction, CancelShiftExchangeAction, SubmitShiftExchangeAction } from './exchangesModels';
import { ToastType } from '../../@paco/types/toastTypes';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';

export function* fetchShiftExchanges(): any {
    yield put({ type: EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_REQUEST });

    try {
        const shiftExchanges = yield getShiftExchangesInDateRange(
            [
                'shiftPlanning1',
                'shiftPlanning2',
                'shiftPlanning1.user',
                'shiftPlanning2.user',
                'shiftPlanning1.shift',
                'shiftPlanning2.shift',
                'shiftPlanning1.shift.shiftPlannings',
                'shiftPlanning2.shift.shiftPlannings',
                'shiftPlanning1.shift.department',
                'shiftPlanning2.shift.department',
                'shiftPlanning1.shift.department.group',
                'shiftPlanning2.shift.department.group',
            ],
        );

        yield put({ type: EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_SUCCESS, shiftExchanges });
    } catch (error) {
        console.error('[fetchShiftExchanges]', error);
        yield put({ type: EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGES_FAILURE });
    }
}

export function* fetchShiftExchange(action: { exchangeId: string }): any {
    yield put({ type: EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_REQUEST });

    try {
        const shiftExchange = yield getShiftExchange(
            action.exchangeId,
            [
                'shiftPlanning1',
                'shiftPlanning2',
                'shiftPlanning1.user',
                'shiftPlanning2.user',
                'shiftPlanning1.shift',
                'shiftPlanning2.shift',
                'shiftPlanning1.shift.shiftPlannings',
                'shiftPlanning2.shift.shiftPlannings',
                'shiftPlanning1.shift.shiftPlannings.user',
                'shiftPlanning2.shift.shiftPlannings.user',
                'shiftPlanning1.shift.department',
                'shiftPlanning2.shift.department',
                'shiftPlanning1.shift.department.group',
                'shiftPlanning2.shift.department.group',
            ],
        );
        yield put({ type: EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_SUCCESS, shiftExchange });
    } catch (error) {
        console.error('[fetchShiftExchange]', error);
        yield put({ type: EXCHANGE_SHIFTS_GET_SHIFT_EXCHANGE_FAILURE });
    }
}

export function* cancelShiftExchange(action: CancelShiftExchangeAction): any {
    yield put({ type: EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_REQUEST });
    const { forMe, exchangeId, history } = action;

    try {
        yield forMe ? declineShiftExchange(exchangeId) : deleteShiftExchange(exchangeId);
        const translation = translate(forMe ? 'pages.exchanges.denyShiftExchangeSuccess' : 'pages.exchanges.cancelShiftExchangeSuccess');

        yield put(setToast({ text: translation, type: ToastType.pass }));
        yield put({ type: EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_SUCCESS });
        history.push(`/${translate('nav.exchanges.link')}`);
    } catch (error) {
        console.error('[cancelShiftExchange]', error);
        yield put({ type: EXCHANGE_SHIFTS_CANCEL_SHIFT_EXCHANGE_FAILURE });
    }
}

export function* acceptShiftExchange(action: AcceptShiftExchangeAction): any {
    yield put({ type: EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_REQUEST });

    try {
        const shiftExchange = yield patchApproveShiftExchange(action.exchangeId);
        yield put(setToast({ text: translate('pages.exchanges.acceptShiftExchangeSuccess'), type: ToastType.pass }));
        yield put({ type: EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_SUCCESS, shiftExchange });
        action.history.push(`/${translate('nav.exchanges.link')}`);
    } catch (error) {
        console.error('[acceptShiftExchange]', error);
        yield put({ type: EXCHANGE_SHIFTS_ACCEPT_SHIFT_EXCHANGE_FAILURE });
    }
}

export function* submitShiftExchange(action: SubmitShiftExchangeAction): any {
    yield put({ type: EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_REQUEST });

    try {
        yield postShiftExchange(action.shiftPlanningId, action.shiftPlanning2Id);
        yield put(setToast({ text: translate('pages.exchanges.submitShiftExchangeSuccess'), type: ToastType.pass }));
        yield put({ type: EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_SUCCESS });
        action.history.push(`/${translate('nav.exchanges.link')}`);
    } catch (error) {
        console.error('[submitShiftExchange]', error);
        yield put({ type: EXCHANGE_SHIFTS_SUBMIT_SHIFT_EXCHANGE_FAILURE });
    }
}
