import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import getTrack from '../../redux/track/trackActions';
import { translate } from '../../helpers/translations/translator';
import { convertDateToDateString, convertDateToDay, convertDateToTime } from '../../helpers/DateTimeParser';
import { getObjProperty, shouldShowEndTime } from '../../helpers/tools';
import { Card, InfoListItem } from '../../@paco/components';
import TimeTicker from '../../components/TimeTicker';
import TrackLogs from '../../components/TrackLogs/TrackLogs';

import './TracksDetail.scss';

class TracksDetail extends Component {
    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
        const { match, dispatch } = this.props;

        if (match.params.trackUuid) {
            dispatch(getTrack(match.params.trackUuid));
        }
    }

    getTrackInformation = () => {
        const { track } = this.props;
        let content = null;

        if (!track.billableEnd) {
            content = <TimeTicker from={track.checkIn} />;
        } else {
            content = `${convertDateToTime(track.billableStart)} - ${convertDateToTime(track.billableEnd)}`;
        }
        return content;
    };

    getShiftTime = (track, settings) => {
        if (!track.shift) {
            return null;
        }

        const showEndTime = shouldShowEndTime(settings, getObjProperty(track, 'shift.department.id'));
        if (track.shift && track.shift.end && showEndTime) {
            return `${convertDateToTime(track.shift.start)} - ${convertDateToTime(track.shift.end)}`;
        }

        return convertDateToTime(track.shift.start);
    }

    render() {
        const { track, settings } = this.props;

        if (!track) {
            return null;
        }

        const feedback = getObjProperty(track, 'feedback.rating');
        const feedbackRemark = getObjProperty(track, 'feedback.remark');
        const shiftTime = this.getShiftTime(track, settings);

        return (
            <>
                <Card className="tracks-detail__header">
                    <h1 className="tracks-detail__title">
                        {this.getTrackInformation()}
                    </h1>
                    <span className="tracks-detail__date">
                        {`${convertDateToDay(track.checkIn)} ${convertDateToDateString(track.checkIn)}`}
                    </span>
                </Card>

                <ul className="mb-4 border-top">
                    {track.shift && (
                        <InfoListItem label={translate('common.department')}>
                            {track.shift.department.name}
                        </InfoListItem>
                    )}
                    {(track.shift) && (
                        <InfoListItem label={translate('common.shiftTime')}>
                            {shiftTime}
                        </InfoListItem>
                    )}
                    <InfoListItem label={translate('pages.shifts.clockedTime')}>
                        <span className={track.billableEnd ? '' : 'text-danger'}>{this.getTrackInformation()}</span>
                    </InfoListItem>
                </ul>

                {track.billableEnd && (
                    <>
                        <ul className="mb-4 border-top">
                            <InfoListItem label={translate('pages.shifts.workedTime')}>
                                {track.totalBillableTime}
                            </InfoListItem>
                        </ul>

                        <ul className="mb-4 border-top">
                            <InfoListItem label={translate('common.break')}>
                                {`${track.pause} ${translate('common.minutes')}`}
                            </InfoListItem>
                            <InfoListItem label={translate('common.meal')}>
                                {track.meal ? translate(`pages.tracks.${track.meal.toLowerCase()}`) : ''}
                            </InfoListItem>
                            { feedback && (
                                <InfoListItem label={translate('common.satisfaction')}>
                                    {translate(`common.${feedback.toLowerCase()}`)}
                                </InfoListItem>
                            )}
                            { feedbackRemark && (
                                <InfoListItem label={`${translate('common.reason')} ${translate('common.dissatisfaction').toLowerCase()}`}>
                                    {feedbackRemark}
                                </InfoListItem>
                            )}
                        </ul>
                    </>
                )}

                {track.logs && <TrackLogs logs={track.logs} />}
            </>
        );
    }
}

TracksDetail.propTypes = {
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    track: PropTypes.object,
    settings: PropTypes.array,
};

TracksDetail.defaultProps = {
    track: null,
    settings: [],
};

function mapStateToProps(state) {
    return {
        track: state.trackReducer.track,
        settings: state.userReducer.settings,
    };
}

export default withRouter(connect(mapStateToProps)(TracksDetail));
