import { setTranslations } from '../translations/translator';
import dutch from '../../translations/dutch.json';
import english from '../../translations/english.json';
import german from '../../translations/german.json';
// eslint-disable-next-line import/no-cycle
import { Locale, localStorageLanguageKey } from '../../@paco/helpers/trans';

export const setLegacyLanguage = (locale: Locale) => {
    localStorage.setItem(localStorageLanguageKey, locale);

    switch (locale) {
    case 'en':
        return setTranslations(english);
    case 'de':
        return setTranslations(german);
    default:
        return setTranslations(dutch);
    }
}
