import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import './ListGroupItem.scss';

interface ListGroupItemProps {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

const ListGroupItem: FC<ListGroupItemProps> = ({
    disabled,
    onClick,
    className = '',
    children,
}): ReactElement => {
    const listItemClassName = classnames('list-group-item', {
        'list-group-item--has-on-click': onClick,
        'list-group-item--is-disabled': disabled,
    }, className);

    return (
        <li
            onClick={onClick}
            className={listItemClassName}
        >
            {children}
        </li>
    );
}

export default ListGroupItem;
