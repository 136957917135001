import {
    AUTH_SET_TENANT,
    AUTH_SET_API_TOKEN,
    AUTH_SET_REFRESH_TOKEN,
    AUTH_UNSET_TOKENS,
    AUTH_SUBMIT_PASSWORD_REQUEST,
    AUTH_SUBMIT_PASSWORD_FAILURE,
    AUTH_SUBMIT_PASSWORD_SUCCESS,
    AUTH_FORGOT_PASSWORD_SUCCESS,
    AUTH_FORGOT_PASSWORD_FAILURE,
    AUTH_FORGOT_PASSWORD_REQUEST,
    AUTH_AUTHENTICATE_USER_REQUEST,
    AUTH_AUTHENTICATE_USER_SUCCESS,
    AUTH_AUTHENTICATE_USER_FAILURE,
} from '../actionTypes';
import {
    localStorageRefreshTokenKey,
    localStorageTenantKey,
    localStorageTokenKey,
} from '../../@paco/helpers/authorizedFetch';

const initialState = {
    loading: false,
    authenticateFailure: false,
    newPasswordSuccess: false,
    isAuthenticated: !!localStorage.getItem(localStorageTokenKey),
    tenant: localStorage.getItem(localStorageTenantKey),
    apiToken: localStorage.getItem(localStorageTokenKey),
    apiRefreshToken: localStorage.getItem(localStorageRefreshTokenKey),
    errors: [],
};

const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
    case AUTH_AUTHENTICATE_USER_REQUEST:
        return {
            ...state, loading: true, authenticateFailure: false, errors: [],
        };
    case AUTH_AUTHENTICATE_USER_SUCCESS:
        localStorage.setItem(localStorageTokenKey, action.apiToken);
        localStorage.setItem(localStorageRefreshTokenKey, action.refreshToken);
        localStorage.setItem('authenticated', 'true');

        return {
            ...state, isAuthenticated: true, loading: false, apiToken: action.apiToken, errors: [],
        };
    case AUTH_AUTHENTICATE_USER_FAILURE:
        return {
            ...state,
            isAuthenticated: false,
            authenticateFailure: true,
            loading: false,
        };
    case AUTH_SET_TENANT:
        localStorage.setItem(localStorageTenantKey, action.tenant);
        return {
            ...state, tenant: action.tenant,
        };
    case AUTH_SET_API_TOKEN:
        localStorage.setItem(localStorageTokenKey, action.apiToken);
        return {
            ...state, apiToken: action.apiToken,
        };
    case AUTH_SET_REFRESH_TOKEN:
        localStorage.setItem(localStorageRefreshTokenKey, action.refreshToken);

        return {
            ...state, apiRefreshToken: action.refreshToken,
        };
    case AUTH_UNSET_TOKENS:
        localStorage.removeItem(localStorageRefreshTokenKey);
        localStorage.removeItem(localStorageTokenKey);
        localStorage.removeItem(localStorageTenantKey);

        return {
            ...state,
            isAuthenticated: false,
            apiToken: null,
            apiRefreshToken: null,
            tenant: null,
        };
    case AUTH_FORGOT_PASSWORD_REQUEST:
    case AUTH_SUBMIT_PASSWORD_REQUEST:
        return { ...state, loading: true };
    case AUTH_SUBMIT_PASSWORD_FAILURE:
    case AUTH_SUBMIT_PASSWORD_SUCCESS:
        return { ...state, loading: false, newPasswordSuccess: true };
    case AUTH_FORGOT_PASSWORD_SUCCESS:
    case AUTH_FORGOT_PASSWORD_FAILURE:
        return { ...state, loading: false };
    default:
        return state;
    }
};

export default authenticationReducer;
