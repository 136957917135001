import React, { FC, FormEvent, useState } from 'react';

import trans from '../../../helpers/trans';
import { DateInput, FormActionButtons } from '../../../compositions';
import { InfoListItem } from '../../../components';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../../helpers/date';
import { Period } from '../../../entities/Period/Period';
import { transformToPeriod } from '../../../entities/Period/PeriodTransformers';

import './DownloadShiftsIcalForm.scss';

interface DownloadShiftsIcalFormProps {
    isLoading: boolean;
    startDate: Date;
    endDate: Date;
    onCancelClick: () => void;
    onSubmit: (period: Period) => void;
    className?: string;
}

const DownloadShiftsIcalForm: FC<DownloadShiftsIcalFormProps> = ({
    isLoading,
    startDate: formStartDate,
    endDate: formEndDate,
    onCancelClick,
    onSubmit,
    className = '',
}) => {
    const [startDate, setStartDate] = useState<Date>(formStartDate);
    const [endDate, setEndDate] = useState<Date>(formEndDate);

    const handleSubmitForm = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        onSubmit(transformToPeriod(
            getStartOfWorkDay(startDate),
            getEndOfWorkDay(endDate),
        ));
    }

    return (
        <form onSubmit={handleSubmitForm} className={`download-shifts-ical-form ${className}`}>
            <div className="download-shifts-ical-form__row">
                <span className="download-shifts-ical-form__warning">{trans('common.caution')}:</span>
                {trans('containers.forms.downloadShiftsIcalForm.intro')}

                <div className="download-shifts-ical-form__confirm">
                    {trans('containers.forms.downloadShiftsIcalForm.doYouWantToExport')}
                </div>
            </div>

            <ul className="download-shifts-ical-form__inputs">
                <InfoListItem
                    label={trans('common.from')}
                    childrenWrapperClassName="download-shifts-ical-form__date-input-wrapper"
                    className="download-shifts-ical-form__info-list-item"
                >
                    <DateInput
                        hideLabel
                        label={trans('common.from')}
                        type="date"
                        value={startDate}
                        onChange={setStartDate}
                    />
                </InfoListItem>

                <InfoListItem
                    label={trans('common.until')}
                    childrenWrapperClassName="download-shifts-ical-form__date-input-wrapper"
                    className="download-shifts-ical-form__info-list-item"
                >
                    <DateInput
                        hideLabel
                        label={trans('common.until')}
                        type="date"
                        value={endDate}
                        onChange={setEndDate}
                    />
                </InfoListItem>
            </ul>

            <FormActionButtons
                isLoading={isLoading}
                onCancelClick={onCancelClick}
            />
        </form>
    );
};

export default DownloadShiftsIcalForm;
