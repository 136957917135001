import React, {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { Reducers } from '../../../redux/reducers';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriodTransformers';
import { DateNavigator } from '../../compositions';
import { TimeModeType } from '../../types/timeModeTypes';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import {
    setIncrementedSelectedDays,
    setTimePeriodAndSelectedDays,
} from '../../redux/@interface/calendar/calendarActions';
import { getPayrollPeriods } from '../../../redux/app-ts/appActions';

interface ConnectedDateNavigatorProps {
  timeModeButtons: TimeModeType[];
  onAfterDateChange: () => void;
  className?: string;
}

const ConnectedDateNavigator: FC<ConnectedDateNavigatorProps> = ({ timeModeButtons, onAfterDateChange, className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const { payrollPeriods: legacyPayrollPeriods } = useSelector((state: Reducers) => state.appReducer);
    const { selectedDays, timeMode } = useTypedSelector(state => state.calendarReducer);

    const payrollPeriods = useMemo(() => (
        legacyPayrollPeriods.map(transformLegacyPayrollPeriodToPayrollPeriod)
    ), [legacyPayrollPeriods]);

    const handleDayPickerClick = (day: Date): void => {
        dispatch(setTimePeriodAndSelectedDays(timeMode, day, payrollPeriods));

        onAfterDateChange();
    };

    const handleTimeModeChange = (newTimeMode: TimeModeType): void => {
        dispatch(setTimePeriodAndSelectedDays(newTimeMode, selectedDays[0], payrollPeriods));

        onAfterDateChange();
    };

    const handleIncrementButtonClick = (goForward: boolean): void => {
        dispatch(setIncrementedSelectedDays(timeMode, selectedDays, goForward, payrollPeriods));

        onAfterDateChange();
    };

    useEffect((): void => {
        if (!payrollPeriods.length) {
            dispatch(getPayrollPeriods());
        }
    }, []);

    return (
        <DateNavigator
            activeTimeMode={timeMode}
            payrollPeriods={payrollPeriods}
            selectedDays={selectedDays}
            timeModeButtons={timeModeButtons}
            onDayPickerClick={handleDayPickerClick}
            onIncrementButtonClick={handleIncrementButtonClick}
            onTimeModeChange={handleTimeModeChange}
            className={className}
        />
    );
};

export default ConnectedDateNavigator;
