import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../helpers/translations/translator';
import {
    Button,
    ErrorLabel,
    InfoListItem,
    LoadingSpinner,
} from '../../@paco/components';
import { TextInput } from '../../@paco/compositions';

import './ProfileDetailItem.scss';


const emailRegEx = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
const EMAIL = 'email';
const PHONE = 'tel';

const ProfileDetail = ({
    title,
    fields,
    onSave,
    intro,
    loading,
}) => {
    const [emailCheck, setEmailCheck] = useState(true);
    const [phoneCheck, setPhoneCheck] = useState(true);
    const [formCompleted, setFormCompleted] = useState(true);
    const [formModified, setFormModified] = useState(false);
    const canSubmit = emailCheck && emailCheck && formCompleted;
    const formRef = useRef(null);

    const invalidFields = (inputs) => inputs.find((input) => {
        if (input.name === translate('pages.profile.houseNumberAddition')) {
            return false;
        }

        return input.value.length === 0;
    })

    const onFormChange = (e) => {
        if (e.target.type === EMAIL) {
            setEmailCheck(e.target.value.toLowerCase().search(emailRegEx) === 0);
        }

        if (e.target.type === PHONE) {
            setPhoneCheck(e.target.value.length >= 10);
        }

        const inputs = Array.from(formRef.current.getElementsByTagName('input'));

        setFormCompleted(!invalidFields(inputs));
        setFormModified(true);
    };

    const onFormSubmit = (e) => {
        e.preventDefault();

        const inputs = Array.from(formRef.current.getElementsByTagName('input'));
        const values = inputs.map(input => input.value);
        onSave(values);
    };

    const getInvalidCheck = (type) => {
        if (type === EMAIL) {
            return !emailCheck;
        }

        if (type === PHONE) {
            return !phoneCheck;
        }

        return false;
    };

    const getFeedback = (type) => {
        if (type === EMAIL) {
            return translate('pages.profile.emailFeedback');
        }

        if (type === PHONE) {
            return translate('pages.profile.phoneFeedback');
        }

        return null;
    };

    return (
        <form
            className="profile-page-detail"
            ref={formRef}
            onSubmit={onFormSubmit}
            onChange={onFormChange}
        >
            <div className="profile-page-detail__header">
                {title}
            </div>
            { intro && (
                <div className="profile-page-detail__intro">
                    {intro}
                </div>
            )}
            <ul className="profile-page-detail__list-group">
                { fields.map(field => (
                    <InfoListItem
                        key={field.propName}
                        label={field.propName}
                        className="profile-page-detail__list-item"
                        childrenWrapperClassName="profile-page-detail__list-item-children"
                        labelClassName="profile-page-detail__list-item-label"
                    >
                        <div className="profile-page-detail__form-group">
                            <TextInput
                                error={getInvalidCheck(field.type) ? getFeedback(field.type) : undefined}
                                id={field.propName}
                                name={field.propName}
                                type={field.type || 'text'}
                                defaultValue={field.value}
                                placeholder={field.propName}
                            />
                            <ErrorLabel className="profile-page-detail__form-requirements">
                                {getFeedback(field.type)}
                            </ErrorLabel>
                        </div>
                    </InfoListItem>
                ))}
            </ul>
            <div className="profile-page-detail__save-button-wrapper">
                <Button
                    color="orange"
                    disabled={!canSubmit || !formModified}
                    type="submit"
                    className="profile-page-detail__save-button"
                >
                    {translate('common.save')}
                </Button>
            </div>
            {loading && <LoadingSpinner />}
        </form>
    );
};

ProfileDetail.propTypes = {
    title: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    intro: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

ProfileDetail.defaultProps = {
    intro: null,
};

export default ProfileDetail;
