import React, {FC, ReactElement} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ListGroupItem from '../ListGroupItem/ListGroupItem';

import './InfoListItem.scss';

interface InfoListItemProps {
    disabled?: boolean;
    label?: string;
    className?: string;
    childrenWrapperClassName?: string;
    labelClassName?: string;
}

const InfoListItem: FC<InfoListItemProps> = ({
    disabled = false,
    label,
    children,
    className = '',
    childrenWrapperClassName = '',
    labelClassName = '',
}): ReactElement => {
    const classNames = classnames('info-list-item', {
        'info-list-item--no-children': !children,
        'info-list-item--no-label': !label,
        'info-list-item--is-disabled': disabled,
    }, className);

    return (
        <ListGroupItem
            disabled={disabled}
            className={classNames}
        >
            {label && (
                <div className={`info-list-item__label ${labelClassName}`}>
                    {label}
                </div>
            )}
            <div className={`info-list-item__children-wrapper ${childrenWrapperClassName}`}>
                {children}
            </div>
        </ListGroupItem>
    );
}

export default InfoListItem;
