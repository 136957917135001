import React from 'react';
import PropTypes from 'prop-types';
import { convertDateToDateString, convertDateToDay, convertDateToTime } from '../../helpers/DateTimeParser';
import { getObjProperty, shouldShowEndTime } from '../../helpers/tools';
import Shift from '../../models/Shift';
import Icon from '../style/Icon/Icon';
import trans from '../../@paco/helpers/trans';
import { Badge, ListGroupItem } from '../../@paco/components';

function generateReplacementUserComments(users, byMe) {
    if (!users.length) {
        return null;
    }

    if (byMe && users.length > 1) {
        return trans('containers.exchanges.numberOfPeopleOfferedToTakeOverYourShift', { number: users.length });
    }

    if (byMe) {
        return trans('containers.exchanges.onePersonOfferedToTakeOverYourShift');
    }

    if (users.length > 1) {
        return trans('containers.exchanges.numberOfPeopleOfferedToTakeOverThisShift', { number: users.length });
    }

    return trans('containers.exchanges.onePersonOfferedToTakeOverThisShift');
}

function generateShiftTime(exchange, showEndTime) {
    const ownerShiftPlanning = exchange.shift.shiftPlannings
        .find(shiftPlanning => shiftPlanning.user.id === exchange.owner.id);

    if (ownerShiftPlanning && showEndTime) {
        return `${convertDateToTime(ownerShiftPlanning.start)} - ${convertDateToTime(ownerShiftPlanning.end)}`;
    }

    if (showEndTime) {
        return `${convertDateToTime(exchange.shift.start)} - ${convertDateToTime(exchange.shift.end)}`;
    }

    return convertDateToTime(ownerShiftPlanning?.start || exchange.shift.start);
}

function ExchangeListItem({
    exchange,
    onClick,
    byMe,
    settings,
}) {
    const exchangeObject = new Shift(exchange.shift);
    const department = getObjProperty(exchange, 'shift.department.name');
    const group = getObjProperty(exchange, 'shift.department.group.name');
    const comments = generateReplacementUserComments(exchange.replacementUsers, byMe);
    const showEndTime = shouldShowEndTime(settings, getObjProperty(exchange, 'shift.department.id'));
    const shiftTime = generateShiftTime(exchange, showEndTime);

    return (
        <ListGroupItem onClick={onClick}>
            <div className="list-group-item__header text">
                <div className="d-flex flex-row">
                    <div className="font-weight-bold">{convertDateToDay(exchange.shift.start)}</div>
                    <div className="text text-nowrap ml-1"> {`- ${convertDateToDateString(exchange.shift.start)}`}</div>
                </div>
                <div>{shiftTime}</div>
                {comments && <small>{comments}</small>}
            </div>
            <div className="align-self-center d-flex flex-column">
                <Badge className="ml-auto" color={exchangeObject.getColor()}>
                    {exchangeObject.getLabel()}
                    {exchangeObject.getIcon() && (
                        <span className="ml-1">
                            <Icon fontSize={18} kind={exchangeObject.getIcon()} color="white" />
                        </span>
                    )}
                </Badge>
                <div className="small mt-1 text-right">
                    {`${group ? `${group} - ` : ''}${department}`}
                </div>
            </div>
        </ListGroupItem>
    );
}

ExchangeListItem.propTypes = {
    byMe: PropTypes.bool.isRequired,
    exchange: PropTypes.object.isRequired,
    settings: PropTypes.array,
    onClick: PropTypes.func,
};

ExchangeListItem.defaultProps = {
    onClick: () => {},
    settings: [],
};

export default ExchangeListItem;
