import React, { FC, useEffect, useMemo } from 'react';
import { ShiftViewModel } from '../../models';
import getShiftSelectOptions from './helpers/getShiftSelectOptions'
import FormSelect from '../FormSelect/FormSelect';
import { SettingResource } from '../../models/Setting';


interface FormUserSelectProps {
    shifts: ShiftViewModel[];
    userId?: string;
    settings: SettingResource[];
    selectedShift: string | null;
    onSelectShift: (shift: ShiftViewModel) => void;
}

const FormShiftSelect: FC<FormUserSelectProps> = ({
    selectedShift,
    shifts,
    settings,
    userId,
    ...props
}) => {
    const options = useMemo(() => getShiftSelectOptions(shifts, userId, settings), [shifts]);

    useEffect(() => {
        if (shifts.length) {
            props.onSelectShift((shifts[0]));
        }
    }, [shifts]);

    const onSelectShift = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = shifts.find(shift => shift.id === event.target.value);

        if (value) {
            props.onSelectShift(value);
        }
    }

    return (
        <FormSelect
            options={options}
            selected={selectedShift}
            onChange={onSelectShift}
        />
    );
}

export default FormShiftSelect;
