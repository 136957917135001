import { FetchResult, FetchResultType } from '../FetchResult';
import { generateApiV3Url } from '../../helpers/url';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { Period } from '../Period/Period';
import { generateDownloadURI } from '../../helpers/browser';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';

export const downloadShiftsIcalApiCall = async (
    userUuid: string,
    period: Period,
): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiV3Url({
            endpoint: `/shifts/ical-feed/${userUuid}`,
            queryParams: {
                start: formatDate(period.start, DateFormat.apiDate),
                end: formatDate(period.end, DateFormat.apiDate),
            },
        });

        const response = await authorizedFetch(url);
        const blob = await response.blob();

        if (!response.ok || !blob) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const file = window.URL.createObjectURL(blob);
        generateDownloadURI(file, trans('common.valkSchedule'));

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[downloadShiftsIcalApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
