import axios from 'axios';
import { TrackResource, TrackViewModel, ApiResponse } from '../../models';
import transformTrackResource from '../../services/TrackService/transformTrackResource';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

export const getTracksForMe = async (
    includes: string[] = [],
    startDate: Date,
    endDate: Date,
): Promise<TrackViewModel[]> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/tracks`,
        {
            params: {
                include: includes.join(','),
                startDate: `${formatDate(startDate, DateFormat.apiDate)} 04:00`,
                endDate: `${formatDate(endDate, DateFormat.apiDate)} 04:00`,
            },
        },
    ) as unknown as ApiResponse<TrackResource[]>;

    return response.data.map(resource => transformTrackResource(resource));
}
