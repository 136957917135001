import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Reducers } from '../../../redux/reducers';
import { ToastsContainer } from '../../containers';
import { Toast } from '../../types/toastTypes';

const ConnectedToastsContainer: FC = () => {
    const toasts = useSelector<Reducers, Toast[]>(state => state.toastsReducer.toasts);

    return (
        <ToastsContainer toasts={toasts} />
    );
};

export default ConnectedToastsContainer;
