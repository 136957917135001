import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import moment from 'moment';
import { convertDateToTime } from '../../helpers/DateTimeParser';
import {
    cancelShiftOffer,
    registerOfferedShift,
    unregisterOfferedShift,
} from '../../redux/shift/shiftActions';
import { Button, InfoListItem } from '../../@paco/components';
import TimeTicker from '../../components/TimeTicker';
import { EmployeesList } from '../../@paco/compositions';
import Shift from '../../models/Shift';
import { getDeviatedTimeShift, getObjProperty, shouldShowEndTime } from '../../helpers/tools';
import { translate } from '../../helpers/translations/translator';
import { getExchange, setExchange } from '../../redux/exchanges/exchangesActions';
import { getUserFullName } from '../../helpers/getUserFullName';
import transformShiftResource from '../../services/ShiftService/transformShiftResource';
import ExchangeHeader from '../../containers/ExchangeHeader/ExchangeHeader';
import { transformLegacyBasicUserToBasicUser } from '../../services/UserService/transformLegacyBasicUserToBasicUser';

import './ExchangeDetail.scss';

const EXCHANGE_STATUS_REGISTERED_TO_OFFER = 'registeredToOffer';
const EXCHANGE_STATUS_OFFERED_BY_ME = 'offeredByMe';
const EXCHANGE_STATUS_OFFERED = 'offered';

class ExchangeDetail extends Component {
    state = {
        legacyShiftObject: null,
    }

    componentWillUnmount() {
        this.props.dispatch(setExchange(null));
    }

    componentDidMount() {
        const { match, dispatch } = this.props;

        if (match.params.exchangeUuid) {
            dispatch(getExchange(match.params.exchangeUuid));
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        const { exchange } = props;

        if (!exchange) {
            return state;
        }

        const legacyShiftObject = new Shift(exchange.shift);
        const shiftViewModel = transformShiftResource(
            exchange.shift,
            exchange.shift.shiftPlannings,
            exchange.shift.tracks,
            exchange.shift.department,
        );

        return {
            ...state,
            legacyShiftObject,
            shiftViewModel,
        }
    }

    handleCancelShiftOffer = (shift) => {
        const { dispatch, history } = this.props;

        dispatch(cancelShiftOffer(shift, history));
    }

    handleRegisterOfferedShift = (exchange) => {
        const { dispatch, history } = this.props;

        dispatch(registerOfferedShift(exchange, history));
    }

    handleUnregisterOfferedShift = (exchange) => {
        const { dispatch, history } = this.props;

        dispatch(unregisterOfferedShift(exchange, history));
    }

    getContent = () => {
        const { exchange } = this.props;
        const { legacyShiftObject } = this.state;

        if (!moment(exchange.shift.start).isAfter(moment())) {
            return this.getTrackInfo();
        }

        switch (legacyShiftObject.getStatus()) {
        case EXCHANGE_STATUS_REGISTERED_TO_OFFER: {
            return (
                <div className="d-flex justify-content-center">
                    <Button
                        text={translate('common.unsubscribe')}
                        onClick={() => this.handleUnregisterOfferedShift(exchange)}
                        className="exchange-detail__action-button"
                    />
                </div>
            );
        }
        case EXCHANGE_STATUS_OFFERED_BY_ME: {
            return (
                <div className="d-flex justify-content-center">
                    <Button
                        text={translate('pages.absences.revokeRequest')}
                        onClick={() => this.handleCancelShiftOffer(exchange.shift)}
                        className="exchange-detail__action-button"
                    />
                </div>
            );
        }
        case EXCHANGE_STATUS_OFFERED: {
            return (
                <div className="d-flex justify-content-center">
                    <Button
                        text={translate('pages.exchanges.registerExchange')}
                        onClick={() => this.handleRegisterOfferedShift(exchange)}
                        className="exchange-detail__action-button"
                    />
                </div>
            );
        }
        default: {
            return null;
        }
        }
    };

    getTrackedTime = (shift) => {
        const content = null;

        if (!shift.tracks[0]) {
            return content;
        }

        if (!shift.tracks[0].checkOut) {
            return (
                <InfoListItem label={translate('pages.exchanges.clockedTime')}>
                    <TimeTicker from={shift.tracks[0].checkIn} />
                </InfoListItem>
            );
        }

        return (
            <InfoListItem label={translate('pages.exchanges.clockedTime')}>
                {`${convertDateToTime(shift.tracks[0].checkIn)} -
                ${convertDateToTime(shift.tracks[0].checkOut)}`}
            </InfoListItem>
        );
    }

    getTrackInfo = () => {
        const { exchange } = this.props;

        const track = exchange.shift.tracks[0];

        return (
            <>
                {this.getTrackedTime(exchange.shift)}
                {track && (
                    <>
                        <InfoListItem label={translate('pages.exchanges.workedTime')}>
                            {track.totalBillableTime}
                        </InfoListItem>
                        <InfoListItem label={translate('common.break')}>
                            {track.pause} {translate('common.minutes')}
                        </InfoListItem>
                        <InfoListItem label={translate('common.meal')}>
                            {track.meal ? translate(`pages.tracks.${track.meal.toLowerCase()}`) : ''}
                        </InfoListItem>
                        <InfoListItem label={translate('common.satisfaction')}>
                            {track.happiness}
                        </InfoListItem>
                    </>
                )}
            </>
        );
    };

    generateShiftTime = (exchange, showEndtime) => {
        if (showEndtime) {
            return `${convertDateToTime(exchange.shift.start)} - ${convertDateToTime(exchange.shift.end)}`;
        }

        return convertDateToTime(exchange.shift.start);
    }

    render() {
        const { exchange, settings } = this.props;
        const { legacyShiftObject } = this.state;

        if (!exchange || !legacyShiftObject) {
            return null;
        }

        const department = getObjProperty(exchange.shift, 'department.name');
        const group = getObjProperty(exchange.shift, 'department.group.name');
        const plannedUsers = getObjProperty(exchange.shift, 'plannedUsers');
        const ownerId = getObjProperty(exchange, 'owner.id');
        const coworkers = plannedUsers.filter(user => user.id !== ownerId);
        const showEndTime = shouldShowEndTime(settings, getObjProperty(exchange, 'shift.department.id'));
        const shiftTime = this.generateShiftTime(exchange, showEndTime);
        const deviatedTime = getDeviatedTimeShift(exchange.shift, ownerId, showEndTime);

        return exchange && (
            <>
                <ExchangeHeader modifier={legacyShiftObject.getColor()} date={exchange.shift.start} />

                <ul className="border-top mb-4">
                    <InfoListItem label={translate('common.department')}>
                        {`${group ? `${group} - ` : ''}${department}`}
                    </InfoListItem>
                    {deviatedTime
                      && (
                          <InfoListItem label={translate('pages.shifts.deviatedShiftTime')}>
                              {deviatedTime}
                          </InfoListItem>
                      )}
                    <InfoListItem label={`${deviatedTime ? translate('pages.shifts.originalShiftTime') : translate('common.shiftTime')}`}>
                        {shiftTime}
                    </InfoListItem>
                    <InfoListItem label={translate('pages.exchanges.exchangeRequestBy')}>
                        {getUserFullName(exchange.owner)}
                    </InfoListItem>
                </ul>
                <EmployeesList employees={coworkers.map(transformLegacyBasicUserToBasicUser)} />
                {this.getContent()}
            </>
        );
    }
}

ExchangeDetail.propTypes = {
    exchange: PropTypes.object,
    match: PropTypes.object.isRequired,
    settings: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
};

ExchangeDetail.defaultProps = {
    exchange: null,
    settings: [],
};

const mapStateToProps = state => ({
    exchange: state.exchangesReducer.exchange,
    settings: state.userReducer.settings,
});

export default withRouter(connect(mapStateToProps)(ExchangeDetail));
