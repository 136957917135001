import { PayrollPeriod } from '../../models';
import UpToButExcludingDate from '../../helpers/UpToButExcludingDate';


export interface PayrollPeriodResponseApiParams {
    type: 'payrollPeriods';
    id: string;
    year: number;
    periodNumber: number;
    start: string;
    end: string;
    canEdit: boolean;
}

export function switchToNextOrPreviousPayrollPeriod(
    currentPayrollPeriod: PayrollPeriod,
    payrollPeriods: PayrollPeriod[],
    nextPeriod: boolean,
): PayrollPeriod {
    const index = payrollPeriods.findIndex(payrollPeriod => (payrollPeriod.id === currentPayrollPeriod.id));
    const newIndex = nextPeriod ? index + 1 : index - 1;

    return payrollPeriods[newIndex] || payrollPeriods[index];
}

export const transformApiParamsToPayrollPeriod = (
    params: PayrollPeriodResponseApiParams,
): PayrollPeriod => ({
    type: 'payrollPeriods',
    id: params.id,
    year: params.year,
    periodNumber: params.periodNumber,
    start: new Date(params.start),
    end: new UpToButExcludingDate(params.end).transformToUpToAndIncludingDate(),
    canEdit: params.canEdit,
});
