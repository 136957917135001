import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import {
    CustomInput,
    FormGroup,
    FormFeedback,
} from 'reactstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import connect from 'react-redux/es/connect/connect';
import { translate } from '../../helpers/translations/translator';
import passwordRegEx from '../../helpers/passwordRegEx';
import { getObjProperty } from '../../helpers/tools';
import { submitUserPasswordPincode } from '../../redux/authentication/authenticationActions';
import { Button, InfoListItem, Logo } from '../../@paco/components';
import { ConnectedToastsContainer } from '../../@paco/connectors';
import { Toast } from '../../@paco/compositions';
import { ToastType } from '../../@paco/types/toastTypes';

import './ChangePasswordPincode.scss';

class changePasswordPincode extends Component {
    formRef = React.createRef();

    state = {
        passwordCheck: true,
        passwordRepeatCheck: true,
        pincodeCheck: true,
        pincodeRepeatCheck: true,
        loginByQuery: false,
        data: {},
    };

    componentDidMount() {
        const { search } = this.props.location;
        const params = queryString.parse(search);
        this.setState({
            loginByQuery: !!params.api_token,
        });
    }

    onFormChange = (e) => {
        e && e.preventDefault();
        const data = serialize(this.formRef.current, { hash: true });
        this.setState({ data });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        const { history } = this.props;
        const { data, loginByQuery } = this.state;
        const {
            password, passwordRepeat, pincode, pincodeRepeat,
        } = data;

        const passwordCheck = !password || (password.search(passwordRegEx) === 0);
        const passwordRepeatCheck = password === passwordRepeat;
        const pincodeCheck = !pincode || (pincode.match(/^[0-9]+$/) != null && pincode.length === 4);
        const pincodeRepeatCheck = pincode === pincodeRepeat;

        this.setState({
            passwordCheck,
            passwordRepeatCheck,
            pincodeCheck,
            pincodeRepeatCheck,
        });

        if (passwordCheck && passwordRepeatCheck && pincodeCheck && pincodeRepeatCheck
            && (password || pincode)) {
            this.props.dispatch(submitUserPasswordPincode(
                password, pincode, loginByQuery, history,
            ));
        }
    };

    render() {
        const {
            passwordCheck, passwordRepeatCheck, pincodeCheck, pincodeRepeatCheck, loginByQuery,
        } = this.state;
        const { loading, newPasswordSuccess, userError } = this.props;
        const invalidToken = getObjProperty(userError, 'status') === 401;

        return (
            <div className={`reset-password ${loginByQuery ? 'reset-password--standalone' : ''} ${invalidToken ? 'reset-password--invalid-token' : ''}`}>
                { invalidToken && (
                    <>
                        <div className="registration-toast-error">
                            <Toast
                                toast={{
                                    type: ToastType.fail,
                                    text: translate('pages.registration.expiredLink'),
                                }}
                            />
                        </div>
                        <div className="reset-password-cover" />
                    </>
                ) }
                {loginByQuery && (
                    <div className="reset-password__logo-wrapper">
                        <Logo className="reset-password__logo" />
                    </div>
                )}
                <p className="reset-password-intro">
                    {translate('pages.changePasswordPincode.intro')}
                </p>
                <form className="reset-password-form" ref={this.formRef} onSubmit={this.onFormSubmit} onChange={this.onFormChange}>
                    <ul>
                        <InfoListItem label={translate('common.password')} className="reset-password-form-group">
                            <FormGroup className="reset-password-form-group">
                                <CustomInput
                                    invalid={!passwordCheck}
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder={translate('common.password')}
                                />
                                <FormFeedback className="reset-password-form-requirements">
                                    {translate('pages.registration.passwordRequirements')}
                                </FormFeedback>
                            </FormGroup>
                        </InfoListItem>
                        <InfoListItem label={translate('pages.changePasswordPincode.confirmPassword')} className="reset-password-form-group">
                            <FormGroup className="reset-password-form-group">
                                <CustomInput
                                    invalid={!passwordRepeatCheck}
                                    type="password"
                                    className="form-control"
                                    id="passwordRepeat"
                                    name="passwordRepeat"
                                    placeholder={translate('pages.changePasswordPincode.confirmPassword')}
                                />
                                <FormFeedback>
                                    {translate('pages.registration.passwordNotSame')}
                                </FormFeedback>
                            </FormGroup>
                        </InfoListItem>
                        <InfoListItem label={translate('common.pincode')} className="reset-password-form-group">
                            <FormGroup className="reset-password-form-group">
                                <CustomInput
                                    invalid={!pincodeCheck}
                                    type="password"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="form-control"
                                    id="pincode"
                                    name="pincode"
                                    placeholder={translate('common.pincode')}
                                />
                                <FormFeedback className="reset-password-form-requirements">
                                    {translate('pages.registration.pincodeRequirements')}
                                </FormFeedback>
                            </FormGroup>
                        </InfoListItem>
                        <InfoListItem label={translate('pages.changePasswordPincode.confirmPincode')} className="reset-password-form-group">
                            <FormGroup className="reset-password-form-group">
                                <CustomInput
                                    invalid={!pincodeRepeatCheck}
                                    type="password"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="form-control"
                                    id="pincodeRepeat"
                                    name="pincodeRepeat"
                                    placeholder={translate('pages.changePasswordPincode.confirmPincode')}
                                />
                                <FormFeedback>
                                    {translate('pages.registration.pincodeNotSame')}
                                </FormFeedback>
                            </FormGroup>
                        </InfoListItem>
                    </ul>
                    <div className="reset-password-bottom">
                        { (!newPasswordSuccess || !loginByQuery)
                        && (
                            <Button
                                disabled={loading}
                                type="submit"
                                className="reset-password__save-button"
                            >
                                {translate('common.save')}
                            </Button>
                        ) }
                        { (newPasswordSuccess && loginByQuery)
                        && (
                            <NavLink
                                to={{
                                    pathname: '/login',
                                }}
                                className="reset-password__back-to-login-button"
                            >
                                {translate('pages.login.backToLogin')}
                            </NavLink>
                        ) }
                    </div>
                </form>
                {loginByQuery && <ConnectedToastsContainer />}
            </div>
        );
    }
}

changePasswordPincode.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    newPasswordSuccess: PropTypes.bool.isRequired,
    userError: PropTypes.object,
};

changePasswordPincode.defaultProps = {
    userError: {},
};

const mapStateToProps = state => ({
    loading: state.authenticationReducer.loading,
    newPasswordSuccess: state.authenticationReducer.newPasswordSuccess,
    userError: state.userReducer.error,
});

export default withRouter(connect(mapStateToProps)(changePasswordPincode));
