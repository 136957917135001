import axios from 'axios';

// Server caps page sizes to 100 for get calls, this interceptor fixes that problem.
axios.interceptors.request.use(
    (requestConfig) => {
        if (requestConfig.method !== 'get') {
            return requestConfig;
        }

        const params = requestConfig.params || {};

        // Calls that already have page[size] don't have to be modified.
        if (params['page[size]']) {
            return requestConfig;
        }

        params['page[size]'] = 9999;
        // eslint-disable-next-line no-param-reassign
        requestConfig.params = params;

        return requestConfig;
    },
);
