import {
    ABSENCES_SAGA_GET_USER_WITH_EMPLOYEE_BALANCES,
    ABSENCES_SAGA_GET_CURRENT_PAYROLL_PERIOD,
    ABSENCES_SAGA_GET_EMPLOYEE_LOKET_BALANCES,
} from '../actionTypes';
import { GetEmployeeLoketBalances, GetUserWithEmployeeBalancesAction } from './absencesModel';
import { PayrollPeriod } from '../../models';

export const getUserWithEmployeeBalances = (payrollPeriod: PayrollPeriod): GetUserWithEmployeeBalancesAction => ({
    type: ABSENCES_SAGA_GET_USER_WITH_EMPLOYEE_BALANCES,
    payrollPeriod,
});

export const getCurrentPayrollPeriod = () => ({ type: ABSENCES_SAGA_GET_CURRENT_PAYROLL_PERIOD });

export const getEmployeeLoketBalances = (loketEmployeeId: string): GetEmployeeLoketBalances => ({
    type: ABSENCES_SAGA_GET_EMPLOYEE_LOKET_BALANCES,
    loketEmployeeId,
});
