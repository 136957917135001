import {
    put,
    takeLatest,
    call,
    select,
} from 'redux-saga/effects';
import {
    SET_LOADING,
    PREFERENCE_SAGA_GET_PLANNING_PREFERENCES,
    PREFERENCE_GET_PLANNING_PREFERENCES_FAILURE,
    PREFERENCE_GET_PLANNING_PREFERENCES_REQUEST,
    PREFERENCE_GET_PLANNING_PREFERENCES_SUCCESS,
    PREFERENCE_SAGA_GET_PLANNING_PREFERENCE,
    PREFERENCE_GET_PLANNING_PREFERENCE_FAILURE,
    PREFERENCE_GET_PLANNING_PREFERENCE_REQUEST,
    PREFERENCE_GET_PLANNING_PREFERENCE_SUCCESS,
    PREFERENCE_SAGA_CREATE_PLANNING_PREFERENCE,
    PREFERENCE_CREATE_PLANNING_PREFERENCE_REQUEST,
    PREFERENCE_CREATE_PLANNING_PREFERENCE_SUCCESS,
    PREFERENCE_SAGA_DEL_PLANNING_PREFERENCE,
    PREFERENCE_CREATE_PLANNING_PREFERENCE_FAILURE,
    PREFERENCE_DEL_PLANNING_PREFERENCE_REQUEST,
    PREFERENCE_DEL_PLANNING_PREFERENCE_FAILURE,
    PREFERENCE_DEL_PLANNING_PREFERENCE_SUCCESS,
} from '../actionTypes';
import {
    getPreferencesForMe,
    getPreference,
    postPlanningPreference,
    delPlanningPreference,
} from '../../helpers/api/preferencesApi';
import { translate } from '../../helpers/translations/translator';
import { ToastType } from '../../@paco/types/toastTypes';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../@paco/helpers/date';

function* fetchPreferenceDays() {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: PREFERENCE_GET_PLANNING_PREFERENCES_REQUEST });
    try {
        const state = yield select();
        const { selectedDays } = state.calendarReducer;
        const startDate = selectedDays[0];
        const endDate = selectedDays[selectedDays.length - 1];
        const response = yield call(
            getPreferencesForMe,
            getStartOfWorkDay(startDate),
            getEndOfWorkDay(endDate),
        );

        yield put({
            type: PREFERENCE_GET_PLANNING_PREFERENCES_SUCCESS,
            planningPreferences: response.data,
        });
    } catch (error) {
        console.error('[fetchPreferenceDays]', error);
        yield put({ type: PREFERENCE_GET_PLANNING_PREFERENCES_FAILURE });
    }
    yield put({ type: SET_LOADING, loading: false });
}

function* fetchPreferenceDay(action) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: PREFERENCE_GET_PLANNING_PREFERENCE_REQUEST });
    try {
        const response = yield call(getPreference, action.id);

        yield put({
            type: PREFERENCE_GET_PLANNING_PREFERENCE_SUCCESS,
            planningPreference: response.data,
        });
    } catch (error) {
        console.error('[fetchPreferenceDay]', error);
        yield put({ type: PREFERENCE_GET_PLANNING_PREFERENCE_FAILURE });
    }
    yield put({ type: SET_LOADING, loading: false });
}

function* createPlanningPreference({
    startDate, endDate, reason, history,
}) {
    yield put({ type: PREFERENCE_CREATE_PLANNING_PREFERENCE_REQUEST });
    try {
        const state = yield select();
        const { currentUser } = state.userReducer;
        yield call(postPlanningPreference, startDate, endDate, reason, currentUser.id);
        yield put({ type: PREFERENCE_CREATE_PLANNING_PREFERENCE_SUCCESS });
        history.push(`/${translate('nav.planningPreferences.link')}`);
        const toast = translate('pages.planningPreferences.createPlanningPreferenceSuccess');
        yield put(setToast({ type: ToastType.pass, text: toast }));
    } catch (error) {
        console.error('[createPlanningPreference]', error);
        yield put({ type: PREFERENCE_CREATE_PLANNING_PREFERENCE_FAILURE });
    }
}

function* deletePlanningPreference({ id, history }) {
    yield put({ type: PREFERENCE_DEL_PLANNING_PREFERENCE_REQUEST });
    try {
        yield call(delPlanningPreference, id);
        yield put({ type: PREFERENCE_DEL_PLANNING_PREFERENCE_SUCCESS });
        history.push(`/${translate('nav.planningPreferences.link')}`);
        const toast = translate('pages.planningPreferences.deletePlanningPreferenceSuccess');
        yield put(setToast({ type: ToastType.pass, text: toast }));
    } catch (error) {
        console.error('[deletePlanningPreference]', error);
        yield put({ type: PREFERENCE_DEL_PLANNING_PREFERENCE_FAILURE });
    }
}

export default function* preferencesWatcher() {
    yield takeLatest(PREFERENCE_SAGA_GET_PLANNING_PREFERENCES, fetchPreferenceDays);
    yield takeLatest(PREFERENCE_SAGA_GET_PLANNING_PREFERENCE, fetchPreferenceDay);
    yield takeLatest(PREFERENCE_SAGA_CREATE_PLANNING_PREFERENCE, createPlanningPreference);
    yield takeLatest(PREFERENCE_SAGA_DEL_PLANNING_PREFERENCE, deletePlanningPreference);
}
