import React, { FC, ReactElement } from 'react';

import {
    IconArrow,
    IconCalendarExport,
    IconCheck,
    IconCheckCircle,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconCrossClose,
    IconHamburger,
    IconInfoCircle,
    IconPlus,
    IconWarningTriangle,
} from './set';

import './Icon.scss';

export interface SvgIconProps {
    className?: string;
}

const icons = {
    arrow: IconArrow,
    'calendar-export': IconCalendarExport,
    check: IconCheck,
    'check-circle': IconCheckCircle,
    'chevron-down': IconChevronDown,
    'chevron-left': IconChevronLeft,
    'chevron-right': IconChevronRight,
    'cross-close': IconCrossClose,
    hamburger: IconHamburger,
    'info-circle': IconInfoCircle,
    plus: IconPlus,
    'warning-triangle': IconWarningTriangle,
};

export type IconName = keyof typeof icons;

export interface IconProps extends SvgIconProps {
    name: IconName;
}

const Icon: FC<IconProps> = ({ name, className = '' }): ReactElement | null => {
    const IconComponent = icons[name] ? icons[name] : null;

    return IconComponent
        ? <IconComponent className={className} />
        : null;
};

export default Icon;
