import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';
import { YearEndStatement } from '../../entities/YearEndStatement/YearEndStatement';

export type YearEndStatementsState = AsyncReduxState<{
    yearEndStatements: YearEndStatement[];
}>;

const initialState: YearEndStatementsState = {
    ...initialAsyncReduxState,
    yearEndStatements: [],
};

export const YearEndStatementsSlice = createSlice({
    name: 'yearEndStatementsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): YearEndStatementsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): YearEndStatementsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): YearEndStatementsState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setYearEndStatements(state, action: PayloadAction<YearEndStatement[]>): YearEndStatementsState {
            return {
                ...state,
                yearEndStatements: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setYearEndStatements,
} = YearEndStatementsSlice.actions;

export default YearEndStatementsSlice.reducer;
