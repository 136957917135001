import React, { FC, ReactElement } from 'react';

import { InfoListItem } from '../../components';
import { translate } from '../../../helpers/translations/translator';
import { BasicUser } from '../../entities/User/User';
import { getPersonFullName } from '../../entities/Person/PersonHelpers';

interface EmployeesListProps {
    employees: BasicUser[];
    className?: string;
}

const EmployeesList: FC<EmployeesListProps> = ({ employees, className = '' }): ReactElement => (
    <ul className={className}>
        <InfoListItem
            label={translate('common.coworkers')}
        >
            {employees.map(employee => (
                <div key={employee.id}>
                    {getPersonFullName(employee.person)}
                </div>
            ))}
        </InfoListItem>
    </ul>
);

export default EmployeesList;
