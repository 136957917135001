import React, { useMemo } from 'react';
import { ShiftViewModel } from '../../../models';
import getDepartmentName from '../../../services/DepartmentService/getDepartmentName';
import getShiftTime from '../../../services/ShiftService/getShiftTime';
import { SettingResource } from '../../../models/Setting';
import { shouldShowEndTime } from '../../../helpers/tools';

function getShiftLabel(shift: ShiftViewModel, userId?: string, settings: SettingResource[] = []) {
    const shiftPlanning = shift.shiftPlannings
        .find((planning) => (planning.user && planning.user.id === userId));
    const canViewShiftEndTime = shouldShowEndTime(settings, shift?.department?.id);
    const shiftTime = getShiftTime(shiftPlanning || shift, canViewShiftEndTime);
    const departmentName = shift.department ? getDepartmentName(shift.department) : shift.department;

    return `${departmentName} - ${shiftTime}`;
}

export default function getShiftSelectOptions(
    shifts: ShiftViewModel[],
    userId?: string,
    settings: SettingResource[] = [],
): JSX.Element[] {
    return shifts.map(shift => (
        <option
            key={shift.id}
            value={shift.id}
        >
            {getShiftLabel(shift, userId, settings)}
        </option>
    ))
}
