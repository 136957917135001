import React, { FC, ReactElement } from 'react';

import './PacoButtonLoadingSpinner.scss';

interface PacoButtonLoadingSpinnerProps {
    className?: string;
}

const PacoButtonLoadingSpinner: FC<PacoButtonLoadingSpinnerProps> = ({ className = '' }): ReactElement => (
    <div className={`paco-button-loading-spinner ${className}`} />
);

export default PacoButtonLoadingSpinner;
