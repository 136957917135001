import React from 'react';

// Import must be direct for the order of styling
import IconButton from '../../@paco/compositions/@buttons/IconButton/IconButton';
import { translate } from '../../helpers/translations/translator';

import './PreviousAndNextButton.scss';


export interface PreviousAndNextButtonProps {
    onPreviousStep: () => void;
    onNextStep: () => void;
}

export const PreviousAndNextButton = ({ onPreviousStep, onNextStep }: PreviousAndNextButtonProps) => (
    <div className="previous-and-next-button">
        <IconButton
            icon="chevron-left"
            text={translate('common.back')}
            onClick={onPreviousStep}
            className="previous-and-next-button__back-button"
        />
        <IconButton
            icon="chevron-right"
            iconPos="right"
            text={translate('common.next')}
            onClick={onNextStep}
            className="previous-and-next-button__next-button"
        />
    </div>
)
