import React, { FC, ReactElement } from 'react';

import { IconButton } from '..';
import trans from '../../helpers/trans';

import './YearNavigator.scss';

interface YearNavigatorProps {
    year: number;
    onIncrementButtonClick: (year: number) => void;
    className?: string;
}

const YearNavigator: FC<YearNavigatorProps> = ({
    year,
    onIncrementButtonClick,
    className = '',
}): ReactElement => {
    const handleNextButtonClick = (): void => {
        onIncrementButtonClick(year + 1);
    };

    const handlePreviousButtonClick = (): void => {
        onIncrementButtonClick(year - 1);
    };

    return (
        <div className={`year-controls ${className}`}>
            <IconButton
                hideLabel
                icon="chevron-left"
                text={trans('common.previous')}
                onClick={handlePreviousButtonClick}
                className="year-controls__prev-button"
            />

            <div className="year-controls__year">
                {year}
            </div>

            <IconButton
                hideLabel
                icon="chevron-right"
                text={trans('common.next')}
                onClick={handleNextButtonClick}
                className="year-controls__prev-button"
            />
        </div>
    );
};

export default YearNavigator;
