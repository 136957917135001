import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';

export type ShiftsState = AsyncReduxState<{}>;

const initialState: ShiftsState = {
    ...initialAsyncReduxState,
};

export const ShiftSlice = createSlice({
    name: 'shiftsReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ShiftsState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ShiftsState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ShiftsState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
} = ShiftSlice.actions;

export default ShiftSlice.reducer;
