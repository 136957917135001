import { Resource } from './Resource';
import { PayrollPeriod } from './PayrollPeriod';

export enum LeaveType {
    normal = 'normal',
    special = 'special',
    tvt = 'time-for-time',
}

export interface LeaveOfAbsenceHoursResource extends Resource {
    type: 'absenceHours';
    hours: number;
    weekNumber: number;
    leaveType: 'normal' | 'special' | 'time-for-time';
    payrollPeriod: PayrollPeriod;
}

export interface LeaveOfAbsenceHoursViewModel {
    hours: number;
    leaveType: LeaveType;
    payrollPeriod: PayrollPeriod;
}

export interface LeaveOfAbsenceHours extends Resource {
    type: 'leaveOfAbsenceHours';
    hours: number;
    weekNumber: number;
    leaveType: LeaveType;
    payrollPeriod: PayrollPeriod;
}
