import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';
import { AccessToken } from '../../entities/AccessToken';

export type RegistrationState = AsyncReduxState<{
    accessToken?: AccessToken;
    isGetAccessTokensSuccessful: boolean;
}>;

const initialState: RegistrationState = {
    ...initialAsyncReduxState,
    isGetAccessTokensSuccessful: false,
};

export const RegistrationSlice = createSlice({
    name: 'pacoRegistrationReducer',
    initialState,
    reducers: {
        reset(): RegistrationState {
            return {
                ...initialState,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): RegistrationState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): RegistrationState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setIsGetAccessTokensSuccessful(state, action: PayloadAction<boolean>): RegistrationState {
            return {
                ...state,
                isGetAccessTokensSuccessful: action.payload,
            };
        },
        setAccessToken(state, action: PayloadAction<AccessToken>): RegistrationState {
            return {
                ...state,
                accessToken: action.payload,
            }
        },
        setError(state, action: PayloadAction<string>): RegistrationState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    reset,
    setIsLoading,
    setIsSuccessful,
    setIsGetAccessTokensSuccessful,
    setAccessToken,
    setError,
} = RegistrationSlice.actions;

export default RegistrationSlice.reducer;
