import React, { FC, ReactElement } from 'react';

import trans from '../../helpers/trans';
import { translate } from '../../../helpers/translations/translator';
import { Button } from '../../components';
import { IconNavLink } from '../../compositions';

import './InactiveUser.scss';

interface InactiveUserProps {
    onLogoutButtonClick: () => void;
    className?: string;
}

const InactiveUserInfo: FC<InactiveUserProps> = ({
    onLogoutButtonClick,
    className = '',
}): ReactElement => (
    <div className={`inactive-user ${className}`}>
        <div className="inactive-user__content">
            <h2 className="inactive-user__title">
                {trans('containers.inactiveUser.title')}
            </h2>

            <p className="inactive-user__intro">
                {trans('containers.inactiveUser.intro')}
            </p>

            <p className="inactive-user__contact">
                {trans('containers.inactiveUser.contact')}
            </p>
        </div>

        <div className="inactive-user__buttons-wrapper">
            <IconNavLink
                icon="chevron-right"
                iconPos="right"
                text={translate('nav.absences.loketHoursPerPeriod.label')}
                to={`/${translate('nav.absences.link')}/${translate('nav.absences.loketHoursPerPeriod.link')}`}
                className="inactive-user__button"
            />

            <IconNavLink
                icon="chevron-right"
                iconPos="right"
                text={translate('nav.documents.label')}
                to={`/${translate('nav.documents.link')}`}
                className="inactive-user__button"
            />

            <Button
                text={trans('common.logout')}
                onClick={onLogoutButtonClick}
                className="inactive-user__button"
            />
        </div>
    </div>
);

export default InactiveUserInfo;
