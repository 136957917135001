import {
    DepartmentResource,
    ShiftPlanningResource,
    ShiftResource,
    ShiftViewModel,
    TrackResource,
} from '../../models';
import transformDepartmentResource from '../DepartmentService/transformDepartmentResource';
import UpToButExcludingDate from '../../helpers/UpToButExcludingDate';
// eslint-disable-next-line import/no-cycle
import transformShiftPlanningResource from '../ShiftPlanningService/transformShiftPlanningResource';
import transformTrackResource from '../TrackService/transformTrackResource';

export default function transformShiftResource(
    shift: ShiftResource,
    shiftPlannings: ShiftPlanningResource[] = [],
    tracks: TrackResource[] = [],
    department?: DepartmentResource,
): ShiftViewModel {
    return {
        department: department ? transformDepartmentResource(shift.department, shift.department.group) : undefined,
        end: new UpToButExcludingDate(shift.end).transformToUpToAndIncludingDate(),
        id: shift.id,
        peopleLimit: shift.peopleLimit,
        shiftPlannings: shiftPlannings
            .map((shiftPlanning) => transformShiftPlanningResource(shiftPlanning, shiftPlanning.user)),
        start: new Date(shift.start),
        statusForCurrentUser: shift.statusForCurrentUser,
        tracks: tracks
            .map((track) => transformTrackResource(track)),
    }
}
