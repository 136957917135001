/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCheckCircle: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 18 18" className={`icon ${className}`}>
        <g transform="translate(-453.000000, -390.000000)">
            <path d="M462,390 C457.032,390 453,394.032 453,399 C453,403.968 457.032,408 462,408 C466.968,408 471,403.968 471,399 C471,394.032 466.968,390 462,390 Z M459.561,402.861 L456.33,399.63 C455.979,399.279 455.979,398.712 456.33,398.361 C456.681,398.01 457.248,398.01 457.599,398.361 L460.2,400.953 L466.392,394.761 C466.743,394.41 467.31,394.41 467.661,394.761 C468.012,395.112 468.012,395.679 467.661,396.03 L460.83,402.861 C460.488,403.212 459.912,403.212 459.561,402.861 Z" />
        </g>
    </svg>
);

export default IconCheckCircle;
/* eslint-enable max-len */
