import {
    ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_CREATE_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_CANCEL_LEAVE_OF_ABSENCE,
} from '../actionTypes';

export const getLeaveOfAbsence = uuid => ({ type: ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE, uuid });

export const createLeaveOfAbsence = (startDate, endDate, reason, history) => ({
    type: ABSENCES_SAGA_CREATE_LEAVE_OF_ABSENCE, startDate, endDate, reason, history,
});

export const cancelLeaveOfAbsence = (leaveOfAbsence, history) => ({
    type: ABSENCES_SAGA_CANCEL_LEAVE_OF_ABSENCE, leaveOfAbsence, history,
});
