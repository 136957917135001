import React, {
    CSSProperties,
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { createPortal } from 'react-dom';

import './PacoModal.scss';

export interface ModalProps {
    transitionDuration?: number;
    onModalClick?: () => void;
    className?: string;
    cardClassName?: string;
}

const PacoModal: FC<PropsWithChildren<ModalProps>> = ({
    transitionDuration = 200,
    onModalClick,
    className = '',
    cardClassName = '',
    children,
}): ReactElement => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const [rootElement] = useState<Element | null>(document.getElementById('app-root'));

    useEffect((): void => {
        setIsOpened(true);
    }, []);

    useEffect((): () => void => {
        if (isOpened) document.body.classList.add('has-active-modal');

        if (isOpened && closeButtonRef.current) {
            closeButtonRef.current.focus();
            closeButtonRef.current.blur();
        }

        return (): void => document.body.classList.remove('has-active-modal');
    }, [isOpened]);

    const cssVariables = {
        '--modal-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    const modalClassNames = classNames('paco-modal', {
        'paco-modal--is-opened': isOpened,
    }, className);

    const modalContent = (
        <section
            role="none"
            style={cssVariables}
            onClick={onModalClick}
            className={modalClassNames}
        >
            <div className={`paco-modal__card ${cardClassName}`}>
                {children}
            </div>
        </section>
    );

    if (rootElement) {
        return createPortal(
            modalContent,
            rootElement,
        );
    }

    return modalContent;
};

export default PacoModal;
