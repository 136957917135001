import { LeaveOfAbsenceViewModel } from '../../models/ts/LeaveOfAbsence';
import { LeaveOfAbsenceHoursViewModel, LeaveType } from '../../models/LeaveOfAbsenceHours';
import { AbsenceHoursViewModel } from '../../models/AbsenceHours';

export default function getHoursFromLeaveOfAbsences(
    loAbsences: LeaveOfAbsenceViewModel[],
    payrollPeriodId: string,
    leaveType: LeaveType[] = [LeaveType.normal, LeaveType.special, LeaveType.tvt],
): number {
    const loaHours = loAbsences.reduce((total: LeaveOfAbsenceHoursViewModel[], absence) => {
        const filteredHours = absence.leaveOfAbsenceHours
            .filter(hours => leaveType.find(type => type === hours.leaveType));

        return [
            ...total,
            ...filteredHours,
        ];
    }, []).filter((absenceHour: AbsenceHoursViewModel) => absenceHour.payrollPeriod.id === payrollPeriodId);

    return loaHours.reduce(
        (
            total: number,
            leaveOfAbsencesViewModel,
        ) => (total + leaveOfAbsencesViewModel.hours), 0,
    );
}
