import { TypedDispatch } from '../store';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { downloadShiftsIcalApiCall } from '../../entities/Shift/ShiftService';
import { setError, setIsLoading, setIsSuccessful } from './shiftsReducer';
import { Period } from '../../entities/Period/Period';

export const downloadShiftsIcal = (userUuid: string, period: Period) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await downloadShiftsIcalApiCall(userUuid, period);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[downloadShiftsIcal]', error);
    }

    dispatch(setIsLoading(false));
};
