import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import { withRouter } from 'react-router';
import connect from 'react-redux/es/connect/connect';
import { registerShift } from '../../../redux/shift/shiftActions';
import { translate } from '../../../helpers/translations/translator';
import { Button } from '../../../@paco/components';

import './ShiftRegister.scss';

class ShiftRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
        };
    }

    submit = () => {
        const { match, dispatch, history } = this.props;
        const { comment } = this.state;
        dispatch(registerShift(match.params.shiftUuid, comment, history));
    };

    updateComment = (e) => {
        this.setState({
            comment: e.target.value,
        });
    };


    render() {
        return (
            <>
                <div className="border-bottom">
                    <FormGroup className="pr-4 pl-4 pt-4">
                        <div className="font-weight-semi-bold mb-2">{translate('common.notes')}</div>
                        <Input className="border-0" value={this.state.comment} onChange={this.updateComment} placeholder={translate('pages.shifts.addComment')} type="textarea" />
                    </FormGroup>
                </div>
                <Button
                    onClick={() => this.submit()}
                    className="shift-register__submit-button"
                >
                    {translate('common.subscribe')}
                </Button>
            </>
        );
    }
}

ShiftRegister.propTypes = {
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(null)(ShiftRegister));
