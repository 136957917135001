import React from 'react';
import PropTypes from 'prop-types';
import { convertDateToDateString, convertDateToDay, convertDateToTime } from '../../helpers/DateTimeParser';
import { getDeviatedTimeShift, getObjProperty, shouldShowEndTime } from '../../helpers/tools';
import Shift from '../../models/Shift';
import Icon from '../style/Icon/Icon';
import UserColor from '../UserColor/UserColor';
import { Badge, ListGroupItem } from '../../@paco/components';

function ShiftsListItem({
    shift,
    onClick,
    currentUser,
    settings,
    className,
}) {
    const shiftObject = new Shift(shift);
    const department = getObjProperty(shift, 'department.name');
    const departmentId = getObjProperty(shift, 'department.id');
    const group = getObjProperty(shift, 'department.group.name');
    const userId = getObjProperty(currentUser, 'id');
    const showEndTime = shouldShowEndTime(settings, departmentId);
    const deviatedTime = getDeviatedTimeShift(shift, userId, showEndTime);
    const time = showEndTime ? `${convertDateToTime(shift.start)} - ${convertDateToTime(shift.end)}` : convertDateToTime(shift.start);
    const shiftPlanning = shift.shiftPlannings ? shift.shiftPlannings.find(planning => getObjProperty(planning, 'user.id') === userId) : undefined;
    const color = shiftPlanning ? shiftPlanning.color : undefined;

    return (
        <ListGroupItem
            onClick={onClick}
            className={className}
        >
            <div className="list-group-item__header text">
                <div className="d-flex flex-row align-items-center">
                    { color && <UserColor color={color} className="mr-1" /> }
                    <div className="font-weight-bold">{convertDateToDay(shift.start)}</div>
                    <div className="text text-nowrap ml-1"> {`- ${convertDateToDateString(shift.start)}`}</div>
                </div>
                <div>
                    { time && deviatedTime ? deviatedTime : time }
                </div>
            </div>
            <div className={`${shift.end ? 'text' : 'text-danger'} align-self-center d-flex flex-column`}>
                <Badge className="ml-auto" color={shiftObject.getColor()}>
                    {shiftObject.getLabel()}
                    {shiftObject.getIcon() && (
                        <span className="ml-1">
                            <Icon fontSize={18} kind={shiftObject.getIcon()} color="white" />
                        </span>
                    )}
                </Badge>
                <div className="small mt-1 text-right">
                    {`${group ? `${group} - ` : ''}${department}`}
                </div>
            </div>
        </ListGroupItem>
    );
}

ShiftsListItem.propTypes = {
    currentUser: PropTypes.object,
    shift: PropTypes.object.isRequired,
    settings: PropTypes.array,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

ShiftsListItem.defaultProps = {
    onClick: () => {},
    currentUser: null,
    className: '',
    settings: [],
};

export default ShiftsListItem;
