import { isFetchCollectionResultSuccessful, isFetchResultSuccessful } from '../../entities/FetchResult';
import { downloadYearEndStatementApiCall, getYearEndStatementsApiCall } from '../../entities/YearEndStatement/YearEndStatementService';
import { TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setYearEndStatements,
} from './yearEndStatementReducer';

export const getYearEndStatements = (employeeId: string) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await getYearEndStatementsApiCall(employeeId);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsSuccessful(true));
        dispatch(setYearEndStatements(response.data));
    } catch (error) {
        console.error('[getYearEndStatements]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const downloadYearEndStatement = (employmentId: string, year: number) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const response = await downloadYearEndStatementApiCall(employmentId, year);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[downloadYearEndStatement]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
