import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconHamburger: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 18 12" className={`icon-component ${className}`}>
        <path d="M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z" />
    </svg>
);

export default IconHamburger;
