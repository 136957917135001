import React from 'react';
import PropTypes from 'prop-types';
import TrackLog from './subcomponents/TrackLog';
import { translate } from '../../helpers/translations/translator';
import { getObjProperty } from '../../helpers/tools';
import './TrackLogs.scss';

const TrackLogs = ({ logs }) => {
    if (!logs || logs.length <= 1) {
        return null;
    }

    return (
        <>
            <div className="track-logs__title">
                {translate('pages.logs.changes')}
            </div>
            <ul className="mb-4 border-top list-group logs">
                {logs.map((log) => {
                    const actor = getObjProperty(log, 'actor.fullName') || translate('common.unknown');
                    return (
                        <TrackLog
                            {...log.changes}
                            key={log.id}
                            actor={actor}
                            loggedAt={log.loggedAt}
                        />
                    );
                })}
            </ul>
        </>
    );
};

TrackLogs.propTypes = {
    logs: PropTypes.array.isRequired,
};

export default TrackLogs;
