import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Button } from '../../components';
import { translate } from '../../../helpers/translations/translator';
import { version } from '../../../../package.json';

import './CollapsibleNavigation.scss';

interface NavItem {
    label: string;
    link: string;
    children: NavItem[];
}

interface CollapsibleNavigationProps {
    isActive: boolean;
    navItems: Record<string, NavItem>;
    navItemsHeaders: string[];
    onLogoutClick: () => void;
    onNavLinkClick: (header: string) => void;
    className?: string;
}

const CollapsibleNavigation: FC<CollapsibleNavigationProps> = ({
    isActive,
    navItems,
    navItemsHeaders,
    onLogoutClick,
    onNavLinkClick,
    className = '',
}): ReactElement => {
    const wrapperClassName = classnames('collapsible-navigation', {
        'collapsible-navigation--is-active': isActive,
    }, className);

    return (
        <div className={wrapperClassName}>
            <div className="collapsible-navigation__container">
                <ul className="collapsible-navigation__inner">
                    {navItemsHeaders && navItemsHeaders.map((header) => {
                        const parent = navItems[header];
                        const { children } = parent;

                        return (
                            <li
                                key={header}
                                className="collapsible-navigation__list-item"
                            >
                                <NavLink
                                    className="collapsible-navigation__link-parent"
                                    onClick={() => onNavLinkClick(header)}
                                    to={navItems[header].link}
                                >
                                    {navItems[header].label}
                                </NavLink>

                                <ul className="collapsible-navigation__list-children">
                                    {children.map(child => (
                                        <li key={child.label}>
                                            <NavLink
                                                to={parent.link + child.link}
                                                onClick={() => onNavLinkClick(header)}
                                                className="collapsible-navigation__link collapsible-navigation__link-child"
                                            >
                                                {child.label}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        );
                    })}

                    <li className="collapsible-navigation__list-item">
                        <Button
                            text={translate('common.logout')}
                            onClick={onLogoutClick}
                            className="collapsible-navigation__link-parent"
                        />
                    </li>

                    <li className="collapsible-navigation__list-item">
                        <div className="collapsible-navigation__version-number">
                            {version}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CollapsibleNavigation;
