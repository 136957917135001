import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { version } from '../../../package.json';
import { isVersionGreaterThan } from './helpers/isVersionGreaterThan';
import { translate } from '../../helpers/translations/translator';
import { ToastType } from '../../@paco/types/toastTypes';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';

const COOLDOWN_TIME = 120000;

const CacheBuster: FC = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [cooldownIsActive, setCooldownIsActive] = useState(false);

    const resetCooldown = () => {
        setCooldownIsActive(false);
    }

    async function checkForUpdate() {
        if (cooldownIsActive) {
            return;
        }

        setTimeout(resetCooldown, COOLDOWN_TIME);

        fetch(`/meta.json?${new Date().getTime()}`,
            {
                cache: 'no-cache',
            })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = version;

                if (isVersionGreaterThan(latestVersion, currentVersion)) {
                    dispatch(setToast({
                        text: translate('pages.errors.versionUpdate', { version: latestVersion }),
                        type: ToastType.updateApp,
                    }));
                }
            })
            .catch((e) => {
                Sentry.captureException(e);
            });

        setCooldownIsActive(true);
    }

    useEffect(() => {
        checkForUpdate();
    }, [location]);

    return (
        <>
            {children}
        </>
    );
}

export default CacheBuster;
