import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCheck: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 13 11" className={`icon-component ${className}`}>
        <path d="M4.41119 8.1627L1.42652 5.17803L0.410156 6.18723L4.41119 10.1883L13.0002 1.59929L11.991 0.590088L4.41119 8.1627Z" />
    </svg>
);

export default IconCheck;
