import axios from 'axios';

export default function authenticateUserRequest(credentials) {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, credentials);
}

export function refreshAccessToken(refreshToken) {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh`, {
        refresh_token: refreshToken,
    });
}

export function patchUserPassword(userId, password, pincode) {
    const body = {
        type: 'users',
        id: userId,
        attributes: {
            ...(password && { password }),
            ...(pincode && { pincode }),
        },
    };

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/users/${userId}`,
        { data: body },
    );
}

export function postForgotPassword(email) {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password/${email}`);
}
