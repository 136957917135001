import {
    put,
    takeLatest,
    call,
} from 'redux-saga/effects';

import {
    SET_LOADING,
    EXCHANGE_SHIFTS_FAILURE,
    EXCHANGE_SHIFTS_REQUEST,
    EXCHANGE_SHIFTS_PLANNED_SUCCESS,
    EXCHANGE_SHIFTS_OPEN_SUCCESS,
    EXCHANGE_SUCCESS,
    EXCHANGE_SHIFTS_SET_TAB,
    EXCHANGE_SHIFTS_SAGA_SET_TAB,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_BY_ME,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_FOR_ME,
    EXCHANGE_SHIFTS_SAGA_GET_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_SET_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGES,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_CANCEL_SHIFT_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_SUBMIT_SHIFT_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_ACCEPT_SHIFT_EXCHANGE,
} from '../actionTypes';

import {
    fetchShiftExchange,
    fetchShiftExchanges,
    cancelShiftExchange,
    submitShiftExchange,
    acceptShiftExchange,
} from '../exchanges-ts/exchangesSaga';
import { getExchange, getExchangesOfferedByMe, getExchangesOfferedForMe } from '../../helpers/api/exchangesApi';
import { translate } from '../../helpers/translations/translator';
import { MY_EXCHANGES } from '../../constants';

export function* fetchExchangesOfferedByMe() {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: EXCHANGE_SHIFTS_REQUEST });

    try {
        const includes = [
            'shift',
            'shift.shiftPlannings',
            'shift.shiftPlannings.user',
            'shift.department',
            'shift.department.group',
            'shift.tracks',
            'replacementUsers',
            'owner',
        ];

        const response = yield call(() => getExchangesOfferedByMe(includes));
        const shiftsOfferedByMe = yield call(() => response.data);

        yield put({
            type: EXCHANGE_SHIFTS_PLANNED_SUCCESS,
            shiftsOfferedByMe: shiftsOfferedByMe || [],
        });
    } catch (error) {
        yield put({ type: EXCHANGE_SHIFTS_FAILURE, errors: [error.response] });
    }
    yield put({ type: SET_LOADING, loading: false });
}

function* fetchExchangesOfferedForMe() {
    yield put({ type: EXCHANGE_SHIFTS_REQUEST });
    yield put({ type: SET_LOADING, loading: true });

    try {
        const includes = [
            'shift',
            'shift.shiftPlannings',
            'shift.shiftPlannings.user',
            'shift.department',
            'shift.department.group',
            'shift.tracks',
            'replacementUsers',
            'owner',
        ];

        const response = yield call(() => getExchangesOfferedForMe(includes));
        const openShifts = yield call(() => response.data);

        yield put({
            type: EXCHANGE_SHIFTS_OPEN_SUCCESS,
            shiftsOfferedForMe: openShifts || [],
        });
    } catch (error) {
        yield put({ type: EXCHANGE_SHIFTS_FAILURE, errors: [error.response] });
    }
    yield put({ type: SET_LOADING, loading: false });
}

function* setExchange(action) {
    yield put({
        type: EXCHANGE_SUCCESS,
        exchange: action.exchange,
    });
}

function* fetchExchange(action) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: EXCHANGE_SHIFTS_REQUEST });

    try {
        const includes = [
            'shift',
            'shift.department',
            'shift.department.group',
            'shift.tracks',
            'shift.shiftPlannings',
            'shift.shiftPlannings.user',
            'shift.plannedUsers',
            'replacementUsers',
            'owner',
        ];

        const response = yield call(() => getExchange(includes, action.exchange));
        const exchange = yield call(() => response.data);

        yield put({
            type: EXCHANGE_SUCCESS,
            exchange,
        });
    } catch (error) {
        yield put({ type: EXCHANGE_SHIFTS_FAILURE, errors: [error.response] });
    }
    yield put({ type: SET_LOADING, loading: false });
}


export function* setAbsencesTab({ tab, history }) {
    const tabLink = tab === MY_EXCHANGES ? translate('nav.exchanges.myExchanges.link') : translate('nav.exchanges.offeredExchanges.link');
    const link = `/${translate('nav.exchanges.link')}/${tabLink}`;
    history && history.push(link);
    yield put({ type: EXCHANGE_SHIFTS_SET_TAB, tab });
}

export default function* exchangesWatcher() {
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_BY_ME, fetchExchangesOfferedByMe);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_FOR_ME, fetchExchangesOfferedForMe);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGES, fetchShiftExchanges);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGE, fetchShiftExchange);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_SUBMIT_SHIFT_EXCHANGE, submitShiftExchange);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_CANCEL_SHIFT_EXCHANGE, cancelShiftExchange);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_ACCEPT_SHIFT_EXCHANGE, acceptShiftExchange);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_SET_EXCHANGE, setExchange);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_GET_EXCHANGE, fetchExchange);
    yield takeLatest(EXCHANGE_SHIFTS_SAGA_SET_TAB, setAbsencesTab);
}
