import React from 'react';
import PropTypes from 'prop-types';
import { convertDateToDateString, convertDateToDay, convertDateToTime } from '../../helpers/DateTimeParser';
import { ListGroupItem } from '../../@paco/components';

function PlanningPreferenceItem({ preference, onClick, className }) {
    const time = `${convertDateToTime(preference.start)} - ${convertDateToTime(preference.end)}`;

    return (
        <ListGroupItem onClick={onClick} className={className}>
            <div className="list-group-item__header text">
                <div className="d-flex flex-row">
                    <div className="font-weight-bold">{convertDateToDay(preference.start)}</div>
                    <div className="text text-nowrap ml-1"> {`- ${convertDateToDateString(preference.start)}`}</div>
                </div>
                <div>{time}</div>
                <div>
                    { preference.comment }
                </div>
            </div>
        </ListGroupItem>
    );
}

PlanningPreferenceItem.propTypes = {
    preference: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

PlanningPreferenceItem.defaultProps = {
    onClick: () => {},
    className: '',
};

export default PlanningPreferenceItem;
