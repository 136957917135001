import React, { ReactElement } from 'react';

import SaldoItem from '../../components/SaldoItem/SaldoItem';
import { translate } from '../../helpers/translations/translator';
import SaldoItemGroup from '../../components/SaldoItemGroup/SaldoItemGroup';
import { EmployeeBalanceType } from '../../models/EmployeeBalances';
import { roundNumberDecimals } from '../../helpers/tools';


export default function EmployeeSaldoGroup({
    absenceHours,
    specialLoaHours,
    tvtLoaHours,
    tvtMutationHours,
    loaHours,
    vacancyLoaHours,
    workedHours,
}: EmployeeBalanceType): ReactElement {
    return (
        <div className="loket-hours-per-period__saldo-items">
            <SaldoItemGroup>
                <SaldoItem
                    isTitle
                    title={translate('pages.absences.loketBalancesPerPeriod.usedLeaveAbsenceHours')}
                    hours={roundNumberDecimals(loaHours)}
                />
                <SaldoItem
                    title={translate('pages.absences.loketBalancesPerPeriod.vacancyLeaveOfAbsenceHours')}
                    hours={roundNumberDecimals(vacancyLoaHours)}
                />
                <SaldoItem
                    title={translate('pages.absences.loketBalancesPerPeriod.specialLeaveOfAbsenceHours')}
                    hours={roundNumberDecimals(specialLoaHours)}
                />
                <SaldoItem
                    title={translate('pages.absences.loketBalancesPerPeriod.tvtLeaveOfAbsenceHours')}
                    hours={roundNumberDecimals(tvtLoaHours)}
                />
            </SaldoItemGroup>
            <SaldoItem
                isTitle
                title={translate('pages.absences.loketBalancesPerPeriod.tvtMutationHours')}
                hours={roundNumberDecimals(tvtMutationHours)}
            />
            <SaldoItem
                isTitle
                title={translate('pages.absences.loketBalancesPerPeriod.absenceHours')}
                hours={roundNumberDecimals(absenceHours)}
            />
            <SaldoItem
                isTitle
                title={translate('pages.absences.loketBalancesPerPeriod.workedHours')}
                hours={roundNumberDecimals(workedHours)}
            />
        </div>
    );
}
