import { DocumentTypeResource, UserDocumentViewModel, FileResource } from '../../models';

export default function transformFileResourceToUserDocument(
    file: FileResource,
    documentType?: DocumentTypeResource,
): UserDocumentViewModel {
    return {
        id: file.id,
        isLoketFile: false,
        isDataUrl: true,
        displayName: file.displayName,
        createdAt: new Date(file.createdAt),
        documentType,
        fileName: file.fileName,
        downloadUrl: `data:${file.mimeType};base64,${file.content}`,
    }
}
