import {
    call, put, takeLatest,
} from 'redux-saga/effects';
import {
    APP_GET_PAYROLL_PERIODS_FAILURE,
    APP_GET_PAYROLL_PERIODS_REQUEST,
    APP_GET_PAYROLL_PERIODS_SUCCESS,
    APP_SAGA_GET_PAYROLL_PERIODS,
} from '../actionTypes';
import { getPayrollPeriods } from '../../helpers/api-ts/payrollPeriod';
import { PayrollPeriod } from '../../models';


export function* fetchPayrollPeriods(): any {
    yield put({ type: APP_GET_PAYROLL_PERIODS_REQUEST });

    try {
        const payrollPeriods = yield call(() => getPayrollPeriods());

        const sortedPayrollPeriods = payrollPeriods.sort(
            (payrollPeriodA: PayrollPeriod, payrollPeriodB: PayrollPeriod) => payrollPeriodA.year - payrollPeriodB.year
                || payrollPeriodA.periodNumber - payrollPeriodB.periodNumber,
        );

        yield put({
            type: APP_GET_PAYROLL_PERIODS_SUCCESS,
            payrollPeriods: sortedPayrollPeriods,
        });
    } catch (error) {
        yield put({ type: APP_GET_PAYROLL_PERIODS_FAILURE });
    }
}

export default function* appWatcher() {
    yield takeLatest(APP_SAGA_GET_PAYROLL_PERIODS, fetchPayrollPeriods);
}
