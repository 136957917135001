import React, { FC, ReactElement, SelectHTMLAttributes } from 'react';

import { FormOption } from '../../../types/formTypes';

import './Select.scss';

type HTMLSelectProps = SelectHTMLAttributes<HTMLSelectElement>;

interface SelectProps extends Omit<HTMLSelectProps, 'onChange'> {
    label: string;
    options: FormOption[];
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

const Select: FC<SelectProps> = ({
    label,
    options,
    value,
    onChange,
    className = '',
    ...props
}): ReactElement => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange(e.target.value);
    }

    return (
        <select
            {...props}
            aria-label={label}
            value={value}
            onChange={handleChange}
            className={`paco-select ${className}`}
        >
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Select;
