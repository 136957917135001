import { AbsenceHoursViewModel } from '../../models/AbsenceHours';
import { AbsenceViewModel } from '../../models/Absence';

export default function getHoursFromAbsences(
    absences: AbsenceViewModel[],
    payrollPeriodId: string,
): number {
    const absenceHours = absences.reduce((total: AbsenceHoursViewModel[], absence) => [
        ...total,
        ...(absence.absenceHours || []),
    ], []).filter((absenceHour: AbsenceHoursViewModel) => absenceHour.payrollPeriod.id === payrollPeriodId);

    return absenceHours.reduce((total: number, absenceHoursViewModel) => (total + absenceHoursViewModel.hours), 0);
}
