import { Role as LegacyRole } from '../../models/Role';
import { Role } from '../../@paco/entities/Role/Role';
import { getEnumKeyByEnumValue } from '../../@paco/helpers/enum';
import { RoleType } from '../../@paco/types/roleTypes';

export const transformLegacyRoleToRole = (resource: LegacyRole): Role => {
    const roleTypeKey = getEnumKeyByEnumValue(RoleType, resource.slug);

    if (!roleTypeKey) {
        console.error(`[transformToRole]: Unknown slug found named "${resource.slug}".`);
    }

    return {
        id: resource.id,
        name: resource.name,
        slug: roleTypeKey ? RoleType[roleTypeKey] : RoleType.employee,
        public: resource.public,
        assignable: resource.assignable,
        priority: resource.priority,
    };
}
