import React from 'react';
import { UserViewModel } from '../../../models/User';
import { getUserFullName } from '../../../helpers/getUserFullName';
import { ColleagueViewModel } from '../../../models';

export default function getUserSelectOptions(users: (UserViewModel | ColleagueViewModel)[]): JSX.Element[] {
    return users
        .sort((a, b) => a.firstname.localeCompare(b.firstname))
        .map(user => (
            <option
                key={user.id}
                value={user.id}
            >
                {getUserFullName(user)}
            </option>
        ))
}
