import {
    put,
    takeLatest,
    call,
} from 'redux-saga/effects';

import {
    FEEDBACK_SUCCESS,
    FEEDBACK_FAILURE,
    FEEDBACK_CATEGORIES_SUCCESS,
    FEEDBACK_CATEGORIES_FAILURE,
    FEEDBACK_SUBMIT,
    FEEDBACK_SUBMIT_SUCCESS,
    FEEDBACK_SUBMIT_FAILURE,
    AUTH_UNSET_TOKENS,
    SET_LOADING, FEEDBACK_SAGA_GET_FEEDBACK,
    FEEDBACK_SAGA_GET_FEEDBACK_CATEGORIES,
    FEEDBACK_SAGA_SUBMIT_FEEDBACK,
} from '../actionTypes';
import { getFeedback, getFeedbackCategories, patchFeedback } from '../../helpers/api/feedbackApi';
import { getUserFullName } from '../../helpers/getUserFullName';

function* fetchFeedback(action) {
    yield put({ type: SET_LOADING, loading: true });
    try {
        const response = yield call(() => getFeedback(action.feedbackUuid));
        const { data } = response;
        const { owner, checkOut } = data.tracks;
        const name = getUserFullName(owner);

        yield put({ type: FEEDBACK_SUCCESS, name, date: checkOut });
    } catch (error) {
        yield put({ type: FEEDBACK_FAILURE, errors: error });
    }
    yield put({ type: SET_LOADING, loading: false });
}

function* fetchFeedbackCategories(action) {
    yield put({ type: SET_LOADING, loading: true });
    try {
        const response = yield call(() => getFeedbackCategories(action.feedbackUuid));
        const { data } = response;

        yield put({ type: FEEDBACK_CATEGORIES_SUCCESS, data });
    } catch (error) {
        yield put({ type: FEEDBACK_CATEGORIES_FAILURE, errors: error });
    }
    yield put({ type: SET_LOADING, loading: false });
}

function* submitFeedback(action) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: FEEDBACK_SUBMIT });

    try {
        yield call(() => patchFeedback(action.id, action.remark, action.category));
        yield put({ type: FEEDBACK_SUBMIT_SUCCESS });
        yield put({ type: AUTH_UNSET_TOKENS });
    } catch (error) {
        yield put({ type: FEEDBACK_FAILURE, errors: error });
        yield put({ type: FEEDBACK_SUBMIT_FAILURE, errors: error });
    }
    yield put({ type: SET_LOADING, loading: false });
}

export default function* feedbackWatcher() {
    yield takeLatest(FEEDBACK_SAGA_GET_FEEDBACK, fetchFeedback);
    yield takeLatest(FEEDBACK_SAGA_GET_FEEDBACK_CATEGORIES, fetchFeedbackCategories);
    yield takeLatest(FEEDBACK_SAGA_SUBMIT_FEEDBACK, submitFeedback);
}
