import React, { FC, ReactElement } from 'react';

import { User, UserFormData } from '../../entities/User/User';
import { RegistrationFlow } from '../../../compositions/RegistrationFlow/RegistrationFlow';
import { ConnectedToastsContainer } from '../../connectors';

interface RegistrationContainerProps {
    isLoading: boolean;
    currentUser?: User;
    onSubmitRegistration: (formData: UserFormData) => void;
    className?: string;
}

const RegistrationContainer: FC<RegistrationContainerProps> = ({
    isLoading,
    currentUser,
    onSubmitRegistration,
    className = '',
}): ReactElement => (
    <div className={`registration-container ${className}`}>
        <RegistrationFlow
            isLoading={isLoading}
            currentUser={currentUser}
            onSubmit={onSubmitRegistration}
        />
        <ConnectedToastsContainer />
    </div>
)

export default RegistrationContainer;
