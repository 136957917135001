import {
    LoketBalancesViewModel,
    LoketLeaveBalanceResource,
    LoketLeaveBalanceResourceKeys as LeaveTypeKeys,
} from '../../models';
import { roundNumberDecimals } from '../../helpers/tools';

function doesAffectBalance(type: LeaveTypeKeys): boolean {
    return type === LeaveTypeKeys.VERLOF_BOVENWETTELIJK
        || type === LeaveTypeKeys.VERLOF_WETTELIJK;
}

export default function transformLoketBalancesResource(
    resources: LoketLeaveBalanceResource[] = [],
    loketEmployeeId: string,
    date: Date = new Date(),
): LoketBalancesViewModel {
    const initialValue: LoketBalancesViewModel = {
        loketEmployeeId,
        nonStatutoryLeaveHoursTotal: 0,
        statutoryLeaveHoursTotal: 0,
        leaveBalanceTotal: 0,
        reservedLeaveHoursTotal: 0,
        reservedLeaveHours: [],
        timeForTimeTotal: 0,
        usedLeaveHoursTotal: 0,
        usedLeaveHours: [],
    };

    if (!resources.length) {
        return initialValue;
    }

    const currentRecentYear = date.getFullYear();

    const values = resources
        .filter((resource) => resource.year === currentRecentYear)
        .reduce((
            viewModel: LoketBalancesViewModel,
            leaveBalance: LoketLeaveBalanceResource,
        ) => ({
            ...viewModel,
            leaveBalanceTotal:
                doesAffectBalance(leaveBalance.leaveType.key)
                    ? viewModel.leaveBalanceTotal + leaveBalance.balance
                    : viewModel.leaveBalanceTotal,
            nonStatutoryLeaveHoursTotal:
                leaveBalance.leaveType.key === LeaveTypeKeys.VERLOF_BOVENWETTELIJK
                    ? viewModel.nonStatutoryLeaveHoursTotal + leaveBalance.balance
                    : viewModel.nonStatutoryLeaveHoursTotal,
            statutoryLeaveHoursTotal:
                leaveBalance.leaveType.key === LeaveTypeKeys.VERLOF_WETTELIJK
                    ? viewModel.statutoryLeaveHoursTotal + leaveBalance.balance
                    : viewModel.statutoryLeaveHoursTotal,
            reservedLeaveHoursTotal:
                doesAffectBalance(leaveBalance.leaveType.key)
                    ? viewModel.reservedLeaveHoursTotal + leaveBalance.usageAfterToday
                    : viewModel.reservedLeaveHoursTotal,
            reservedLeaveHours: [
                ...viewModel.reservedLeaveHours,
                ...(doesAffectBalance(leaveBalance.leaveType.key) ? [{
                    label: leaveBalance.leaveType.value,
                    value: leaveBalance.usageAfterToday,
                }] : []),
            ],
            timeForTimeTotal:
                leaveBalance.leaveType.key === LeaveTypeKeys.TIME_FOR_TIME
                    ? viewModel.timeForTimeTotal + leaveBalance.balance
                    : viewModel.timeForTimeTotal,
            usedLeaveHoursTotal: viewModel.usedLeaveHoursTotal + leaveBalance.usageAfterToday,
            usedLeaveHours: [
                ...viewModel.usedLeaveHours,
                {
                    label: leaveBalance.leaveType.value,
                    value: leaveBalance.usageTotal,
                },
            ],
        }), initialValue);

    return {
        ...values,
        reservedLeaveHours: values.reservedLeaveHours
            .map(leaveHours => ({
                label: leaveHours.label,
                value: roundNumberDecimals(leaveHours.value),
            })),
        usedLeaveHours: values.usedLeaveHours
            .map(leaveHours => ({
                label: leaveHours.label,
                value: roundNumberDecimals(leaveHours.value),
            })),
        nonStatutoryLeaveHoursTotal: roundNumberDecimals(values.nonStatutoryLeaveHoursTotal),
        statutoryLeaveHoursTotal: roundNumberDecimals(values.statutoryLeaveHoursTotal),
        leaveBalanceTotal: roundNumberDecimals(values.leaveBalanceTotal),
        reservedLeaveHoursTotal: roundNumberDecimals(values.reservedLeaveHoursTotal),
        timeForTimeTotal: roundNumberDecimals(values.timeForTimeTotal),
        usedLeaveHoursTotal: roundNumberDecimals(values.usedLeaveHoursTotal),
    };
}
