import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTime } from '../../helpers/date';
import { Toast } from '../../types/toastTypes';

export type ToastsState = {
    toasts: Toast[];
};

const initialState: ToastsState = {
    toasts: [],
};

export const ToastsSlice = createSlice({
    name: 'toastsReducer',
    initialState,
    reducers: {
        clearToasts(state): ToastsState {
            return {
                ...state,
                toasts: [],
            };
        },
        setToast(state, action: PayloadAction<Toast>): ToastsState {
            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        createdOn: getTime(new Date()),
                        ...action.payload,
                    },
                ],
            };
        },
    },
});

export const {
    clearToasts,
    setToast,
} = ToastsSlice.actions;

export default ToastsSlice.reducer;
