import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    FormGroup,
    Input,
    Label,
    FormFeedback,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import authenticateUser, { forgotUserPassword } from '../../redux/authentication/authenticationActions';
import { translate } from '../../helpers/translations/translator';
import { Button, Card, Logo } from '../../@paco/components';

import './Login.scss';

const emailRegEx = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

class Login extends Component {
    state = {
        credentials: {
            username: '',
            password: '',
        },
        emailCheck: true,
        forgotPassword: false,
        forgotPasswordRequested: false,
    };

    onChange = (e) => {
        const { credentials } = this.state;

        credentials[e.currentTarget.name] = e.currentTarget.value;

        this.setState({
            credentials,
        });
    };

    authenticateUser = () => {
        const { credentials } = this.state;

        this.props.dispatch(authenticateUser(credentials));
    };

    onForgotPasswordChange = (e) => {
        this.setState({
            email: e.currentTarget.value,
        });
    }

    onForgotPasswordClick = (e) => {
        e.preventDefault();
        const { email } = this.state;
        const emailCheck = email && (email.search(emailRegEx) === 0);

        this.setState({
            emailCheck,
            forgotPasswordRequested: emailCheck,
        });

        if (emailCheck) {
            this.props.dispatch(forgotUserPassword(email));
        }
    }

    renderLoginForm() {
        const { loading, authenticateFailure } = this.props;
        return (
            <Form className="login__form">
                <FormGroup>
                    <Label className="text-primary" for="username">{translate('pages.login.username')}</Label>
                    <Input
                        onChange={this.onChange}
                        type="text"
                        name="username"
                        id="username"
                        autoComplete="username"
                        placeholder={translate('pages.login.username')}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="text-primary" for="password">{translate('pages.login.password')}</Label>
                    <Input
                        onChange={this.onChange}
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="current-password"
                        placeholder={translate('pages.login.password')}
                    />
                </FormGroup>
                { authenticateFailure
                && <p className="text-danger">{translate('pages.login.loginError')}</p>
                }
                <Button
                    disabled={loading}
                    type="submit"
                    onClick={(e) => { e.preventDefault(); this.authenticateUser(); }}
                    className="login__submit-button"
                >
                    {translate('common.login')}
                </Button>
                <Button
                    type="button"
                    disabled={loading}
                    className="login__forgot-password-button"
                    onClick={() => this.setState({ forgotPassword: true, forgotPasswordRequested: false })}
                >
                    {translate('pages.login.forgotPassword')}
                </Button>
            </Form>
        );
    }

    renderForgotPasswordForm() {
        const { forgotPasswordRequested, emailCheck } = this.state;

        return (
            <Form className="login__form">
                <h4 className="login__title font-weight-semi-bold">
                    {translate('pages.login.forgotPassword')}
                </h4>
                <p className="login__intro">
                    {translate('pages.login.forgotPasswordIntro')}
                </p>
                <FormGroup className="forgot-password-form-group">
                    <Input
                        onChange={this.onForgotPasswordChange}
                        disabled={forgotPasswordRequested}
                        invalid={!emailCheck}
                        type="text"
                        name="email"
                        id="email"
                        placeholder={translate('common.email')}
                        className="login__forgot-password-input"
                    />
                    <FormFeedback>
                        {translate('pages.registration.emailNotValid')}
                    </FormFeedback>
                    {forgotPasswordRequested && (
                        <p>
                            {translate('pages.login.forgotPasswordRequested')}
                        </p>
                    )}
                    <Button
                        type="submit"
                        className="login__request-new-password-button"
                        onClick={this.onForgotPasswordClick}
                        disabled={forgotPasswordRequested}
                    >
                        {translate('common.send')}
                    </Button>
                    <Button
                        type="button"
                        color="link"
                        className="login__back-button"
                        onClick={() => this.setState({ forgotPassword: false })}
                    >
                        {translate('common.back')}
                    </Button>
                </FormGroup>
            </Form>
        );
    }

    render() {
        const { forgotPassword } = this.state;
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            return <Redirect to={`/${translate('nav.shifts.link')}`} />;
        }

        return (
            <div className="bg-primary vh-100 d-flex">
                <div className="login">
                    <Logo className="login__logo" />
                    <Card className="login__card">
                        {!forgotPassword ? this.renderLoginForm() : this.renderForgotPasswordForm()}
                    </Card>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    authenticateFailure: PropTypes.bool.isRequired,
};

Login.defaultProps = {};

function mapStateToProps(state) {
    return {
        loading: state.authenticationReducer.loading,
        errors: state.authenticationReducer.errors,
        isAuthenticated: state.authenticationReducer.isAuthenticated,
        authenticateFailure: state.authenticationReducer.authenticateFailure,
    };
}

export default connect(mapStateToProps)(Login);
