import React, { FC, PropsWithChildren, ReactElement } from 'react';

import './ModalContent.scss';

interface ModalContentProps {
    title: string;
}

const ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({
    title,
    children,
}): ReactElement => (
    <div className="modal-content">
        <header className="modal-content__header">
            <h2 className="modal-content__title">{title}</h2>
        </header>

        <div className="modal-content__content-wrapper">
            {children}
        </div>
    </div>
);

export default ModalContent;
