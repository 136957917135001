import axios from 'axios';
import {
    ApiResponse,
    AbsenceResource,
    AbsenceViewModel,
    LeaveOfAbsenceResource,
    LeaveOfAbsenceViewModel,
} from '../../models';
import transformAbsenceResource from '../../services/AbsenceService/transformAbsenceResource';
import { transformLeaveOfAbsenceResource } from '../../services/LeaveOfAbsenceService/transformLeaveOfAbsenceResource';

export const getAbsencesForMe = async (
    includes: string[] = [],
    startDate: Date,
    endDate: Date,
): Promise<AbsenceViewModel[]> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/absences`,
        {
            params: {
                include: includes.join(','),
                startDate,
                endDate,
            },
        },
    ) as unknown as ApiResponse<AbsenceResource[]>

    return response.data.map(resource => transformAbsenceResource(
        resource,
        resource.absenceHours,
    ));
}

export const getLeaveOfAbsencesForMe = async (
    includes: string[] = [],
    startDate: Date,
    endDate: Date,
): Promise<LeaveOfAbsenceViewModel[]> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/leave-of-absences`,
        {
            params: {
                include: includes.join(','),
                startDate,
                endDate,
            },
        },
    ) as unknown as ApiResponse<LeaveOfAbsenceResource[]>

    return response.data.map(resource => transformLeaveOfAbsenceResource(
        resource,
        resource.leaveOfAbsenceHours,
    ));
}
