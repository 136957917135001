import React, {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import ReactDayPicker, { Modifiers } from 'react-day-picker';
import { getDaysFromTimeMode } from '../../redux/@interface/calendar/calendarHelpers';
import { TimeModeType } from '../../types/timeModeTypes';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';

interface DayPickerProps {
    payrollPeriods: PayrollPeriod[];
    selectedDays: Date[];
    timeMode: TimeModeType;
    onDayClick: (day: Date) => void;
    className?: string;
}

const DayPicker: FC<DayPickerProps> = ({
    payrollPeriods,
    selectedDays,
    timeMode,
    onDayClick,
    className = '',
}): ReactElement => {
    const [hoverDate, setHoverDate] = useState<Date>();

    const hoverRange = useMemo(() => {
        if (!hoverDate) {
            return [];
        }

        return getDaysFromTimeMode(timeMode, hoverDate, payrollPeriods);
    }, [hoverDate, payrollPeriods, timeMode]);

    const modifiers: Partial<Modifiers> = {
        hoverRange,
        hoverRangeStart: hoverRange[0],
        hoverRangeEnd: hoverRange[hoverRange.length - 1],
    };

    const handleDayMouseEnter = (day: Date): void => {
        setHoverDate(day);
    };

    const handleDayMouseLeave = (): void => {
        setHoverDate(undefined);
    };

    return (
        <ReactDayPicker
            locale="nl"
            firstDayOfWeek={1}
            month={selectedDays[0]}
            selectedDays={selectedDays}
            showWeekNumbers
            showOutsideDays
            modifiers={modifiers}
            onDayClick={onDayClick}
            onDayMouseEnter={handleDayMouseEnter}
            onDayMouseLeave={handleDayMouseLeave}
            className={`day-picker ${className}`}
        />
    );
};

export default DayPicker;
