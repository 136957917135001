import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
    const cb = useRef();

    useEffect(() => {
        cb.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay !== null) {
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            const id = setInterval(cb.current, delay);
            return () => clearInterval(id);
        }
        return false;
    }, [delay]);
};

export default useInterval;
