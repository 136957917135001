import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';

// eslint-disable-next-line import/no-cycle
import { ConnectedDateNavigator, ConnectedDownloadShiftsIcalForm } from '../../@paco/connectors';
import ShiftsListItem from '../../components/Shift/ShiftsListItem';
import { getOpenShifts, getPlannedShifts, setShiftsTab } from '../../redux/shifts/shiftsActions';
import ZeroState from '../../components/Helpers/ZeroState';
import { MY_SHIFTS, OPEN_SHIFTS } from '../../constants';
import { translate } from '../../helpers/translations/translator';
import { isTabDifferentThanActiveTab } from '../../helpers/tools';
import { getExchangesOfferedForMe, getShiftExchanges } from '../../redux/exchanges/exchangesActions';
import { LoadingSpinner } from '../../@paco/components';
// eslint-disable-next-line import/no-cycle
import {
    IconButton,
    ModalContent,
    PacoModal,
    TabButtons,
} from '../../@paco/compositions';
import trans from '../../@paco/helpers/trans';

import './Shifts.scss';

class Shifts extends Component {
    state = {
        downloadShiftsIcalModalIsOpen: false,
    }

    componentDidMount() {
        const { activeTab, tab } = this.props;
        if (!activeTab) {
            this.toggle(tab);
        }

        this.getShifts();
    }

    componentDidUpdate(prevProps) {
        const { activeTab, location } = this.props;
        isTabDifferentThanActiveTab(
            location, prevProps.location, activeTab, translate('nav.shifts.myShifts.link'),
            MY_SHIFTS, OPEN_SHIFTS, this.toggle,
        );
    }

    getShifts = (tab) => {
        if ((tab || this.props.tab) === MY_SHIFTS) {
            this.props.dispatch(getPlannedShifts());
        } else {
            this.props.dispatch(getOpenShifts());
            this.props.dispatch(getShiftExchanges());
            this.props.dispatch(getExchangesOfferedForMe());
        }
    }

    toggle = (tab, pushHistory) => {
        const { history } = this.props;

        if (this.props.activeTab !== tab) {
            this.props.dispatch(setShiftsTab(tab, pushHistory && history));
            this.getShifts(tab);
        }
    }

    handleTabButtonClick = (newIndex) => {
        if (newIndex === 0) {
            this.toggle(MY_SHIFTS, true);

            return;
        }

        this.toggle(OPEN_SHIFTS, true);
    }

    renderPlannedShifts = () => {
        const { plannedShifts, currentUser, settings } = this.props;

        return plannedShifts.length > 0 ? plannedShifts.map(shift => (
            <ShiftsListItem
                key={shift.id}
                currentUser={currentUser}
                settings={settings}
                shift={shift}
                onClick={() => this.props.history.push(`/${translate('nav.shifts.link')}/${shift.id}`)}
                className="shifts__list-item"
            />
        )) : <ZeroState text={translate('pages.shifts.emptyPlannedShifts')} />;
    };

    renderOpenShifts = () => {
        const {
            openShifts,
            currentUser,
            settings,
            shiftsOfferedForMe,
        } = this.props;

        return openShifts.length > 0 ? openShifts.map(shift => {
            const exchange = shiftsOfferedForMe.find(shiftExchange => shiftExchange.shift.id === shift.id);
            const link = exchange ? `/${translate('nav.shifts.exchange.link')}/${exchange.id}` : `/${translate('nav.shifts.link')}/${shift.id}`;

            return (
                <ShiftsListItem
                    currentUser={currentUser}
                    settings={settings}
                    key={shift.id}
                    shift={shift}
                    exchangeId={exchange?.id}
                    onClick={() => this.props.history.push(link)}
                />
            )
        }) : <ZeroState text={translate('pages.shifts.emptyList')} />;
    };

    handleShiftsIcalDownload = () => {
        this.setState({
            downloadShiftsIcalModalIsOpen: true,
        })
    }

    render() {
        const { downloadShiftsIcalModalIsOpen } = this.state;
        const { isExchangesLoading, isShiftsLoading, currentUser } = this.props;

        const isLoading = isExchangesLoading || isShiftsLoading;

        return (
            <>
                <div className="shifts__calendar-export-wrapper">
                    <IconButton
                        hideLabel
                        disabled={!currentUser}
                        text={trans('common.export')}
                        icon={'calendar-export'}
                        className="shifts__calendar-export"
                        iconClassName="shifts__calendar-export-icon"
                        onClick={this.handleShiftsIcalDownload}
                    />
                </div>
                <TabButtons
                    activeIndex={this.props.activeTab === MY_SHIFTS ? 0 : 1}
                    buttonLabels={[
                        translate('pages.shifts.myShifts'),
                        translate('pages.shifts.open'),
                    ]}
                    onButtonClick={this.handleTabButtonClick}
                />
                <ConnectedDateNavigator
                    onAfterDateChange={() => {
                        this.getShifts();
                        this.forceUpdate();
                    }}
                />
                <TabContent activeTab={this.props.activeTab}>
                    {isLoading
                        ? <LoadingSpinner />
                        : (
                            <>
                                <TabPane tabId={MY_SHIFTS}>
                                    <div className="shifts__row">
                                        <div className="shifts__col">
                                            <ul>
                                                {this.renderPlannedShifts()}
                                            </ul>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId={OPEN_SHIFTS}>
                                    <div className="shifts__row">
                                        <div className="shifts__col">
                                            <ul>
                                                {this.renderOpenShifts()}
                                            </ul>
                                        </div>
                                    </div>
                                </TabPane>
                            </>
                        )
                    }
                </TabContent>
                {(currentUser && downloadShiftsIcalModalIsOpen) && (
                    <PacoModal>
                        <ModalContent title={trans('containers.forms.downloadShiftsIcalForm.exportShifts')}>
                            <ConnectedDownloadShiftsIcalForm
                                userId={currentUser.id}
                                onClose={() => this.setState({ downloadShiftsIcalModalIsOpen: false })}
                            />
                        </ModalContent>
                    </PacoModal>
                )}
            </>
        );
    }
}

Shifts.propTypes = {
    isExchangesLoading: PropTypes.bool.isRequired,
    isShiftsLoading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    tab: PropTypes.string.isRequired,
    plannedShifts: PropTypes.array.isRequired,
    openShifts: PropTypes.array.isRequired,
    shiftsOfferedForMe: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    currentUser: PropTypes.object,
    settings: PropTypes.array,
};

Shifts.defaultProps = {
    currentUser: null,
    activeTab: null,
    settings: [],
};

const mapStateToProps = state => ({
    plannedShifts: state.shiftsReducer.plannedShifts,
    activeTab: state.shiftsReducer.activeTab,
    openShifts: state.shiftsReducer.openShifts,
    shiftsOfferedForMe: state.exchangesReducer.shiftsOfferedForMe,
    errors: state.tracksReducer.errors,
    currentUser: state.userReducer.currentUser,
    settings: state.userReducer.settings,
    isExchangesLoading: state.exchangesReducer.loading,
    isShiftsLoading: state.shiftsReducer.loading,
});

export default withRouter(connect(mapStateToProps)(Shifts));
