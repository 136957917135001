import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import PlanningPreferenceItem from '../../components/PlanningPreferenceItem/PlanningPreferenceItem';
import ZeroState from '../../components/Helpers/ZeroState';
import { translate } from '../../helpers/translations/translator';
import { getPreferenceDays } from '../../redux/preferences/preferenceActions';
import Icon from '../../components/style/Icon/Icon';
import { LoadingSpinner } from '../../@paco/components';
import { TimeModeType } from '../../@paco/types/timeModeTypes';
import { setTimePeriodAndSelectedDays } from '../../@paco/redux/@interface/calendar/calendarActions';
import { ConnectedDateNavigator } from '../../@paco/connectors';
import { transformLegacyPayrollPeriodToPayrollPeriod } from '../../@paco/entities/PayrollPeriod/PayrollPeriodTransformers';

import './PlanningPreferences.scss';


function calculateRightEmptySpace() {
    return (window.innerWidth > 1024 ? window.innerWidth - 1024 : 0) / 2;
}

class PlanningPreferences extends Component {
    state = {
        addButtonTop: window.innerHeight - 75,
        addButtonRight: 20 + calculateRightEmptySpace(),
    };

    componentDidMount() {
        const { payrollPeriods, selectedDays, timeMode } = this.props;

        if (timeMode !== TimeModeType.week) {
            this.props.dispatch(setTimePeriodAndSelectedDays(
                TimeModeType.week,
                selectedDays[0],
                payrollPeriods.map(transformLegacyPayrollPeriodToPayrollPeriod),
            ));
        }
        this.getPreferenceDays();
        window.addEventListener('resize', this.onResize);
    }

    getPreferenceDays = () => {
        this.props.dispatch(getPreferenceDays());
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        const right = calculateRightEmptySpace();
        this.setState({
            addButtonTop: window.innerHeight - 75,
            addButtonRight: 20 + right,
        });
    }

    onPlanningPreferenceClick = (id) => {
        this.props.history.push(`/${translate('nav.planningPreferences.link')}/${id}`);
    }

    renderPreferenceDays = () => {
        const { planningPreferences } = this.props;

        return planningPreferences.length > 0 ? planningPreferences.map(preference => (
            <PlanningPreferenceItem
                key={preference.id}
                preference={preference}
                onClick={() => this.onPlanningPreferenceClick(preference.id)}
                className="preferences__list-item"
            />
        )) : <ZeroState text={translate('pages.planningPreferences.emptyList')} />;
    };

    render() {
        const { loading } = this.props;
        const { addButtonTop, addButtonRight } = this.state;

        return (
            <>
                <ConnectedDateNavigator
                    timeModeButtons={[TimeModeType.week]}
                    onAfterDateChange={this.getPreferenceDays}
                />
                <div className="preferences__row">
                    <div className="preferences__col">
                        <ul>
                            {this.renderPreferenceDays()}
                        </ul>
                    </div>
                </div>
                <NavLink
                    style={{ top: `${addButtonTop}px`, right: `${addButtonRight}px` }}
                    className="btn-circle preferences__button-create-absences"
                    to={`/${translate('nav.planningPreferences.link')}/${translate('nav.planningPreferences.createPlanningPreference.link')}`}
                >
                    <Icon kind="add" color="white" fontSize={26} />
                </NavLink>
                {loading && <LoadingSpinner />}
            </>
        );
    }
}

PlanningPreferences.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    planningPreferences: PropTypes.array.isRequired,
    selectedDays: PropTypes.array.isRequired,
    timeMode: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    payrollPeriods: state.appReducer.payrollPeriods,
    planningPreferences: state.preferenceReducer.planningPreferences,
    timeMode: state.calendarReducer.timeMode,
    selectedDays: state.calendarReducer.selectedDays,
    loading: state.preferenceReducer.loading,
});

export default withRouter(connect(mapStateToProps)(PlanningPreferences));
