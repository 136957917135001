import React, { FC } from 'react';

import './SaldoItemGroup.scss';

const SaldoItemGroup: FC = ({ children }) => (
    <div className="saldo-item-group">
        { children }
    </div>
)

export default SaldoItemGroup;
