import {
    SHIFT_FAILURE,
    SHIFT_REQUEST,
    SHIFT_SUCCESS,
} from '../actionTypes';

const initialState = {
    shift: null,
    loading: false,
    errors: [],
};

const shiftReducer = (state = initialState, action) => {
    switch (action.type) {
    case SHIFT_REQUEST:
        return { ...state, loading: true };
    case SHIFT_SUCCESS:
        return {
            ...state, loading: false, shift: action.shift,
        };
    case SHIFT_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    default:
        return state;
    }
};

export default shiftReducer;
