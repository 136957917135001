import {
    FEEDBACK_FAILURE,
    FEEDBACK_REQUEST,
    FEEDBACK_SUCCESS,
    FEEDBACK_CATEGORIES_FAILURE,
    FEEDBACK_CATEGORIES_SUCCESS,
    FEEDBACK_SUBMIT,
    FEEDBACK_SUBMIT_SUCCESS,
    FEEDBACK_SUBMIT_FAILURE,
} from '../actionTypes';

const initialState = {
    feedback: null,
    categories: [],
    name: '',
    date: '',
    loading: true,
    sendingData: false,
    dataSubmitSuccess: false,
    dataSubmitFailure: false,
    failure: false,
    errors: [],
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
    case FEEDBACK_REQUEST:
        return { ...state, loading: true };
    case FEEDBACK_SUCCESS:
        return {
            ...state,
            loading: false,
            name: action.name,
            date: action.date,
        };
    case FEEDBACK_CATEGORIES_SUCCESS:
        return {
            ...state,
            loading: false,
            categories: action.data,
        };
    case FEEDBACK_CATEGORIES_FAILURE:
        return {
            ...state, loading: false, errors: action.errors, failure: true,
        };
    case FEEDBACK_FAILURE:
        return {
            ...state, loading: false, errors: action.errors, failure: true,
        };
    case FEEDBACK_SUBMIT:
        return {
            ...state, sendingData: true,
        };
    case FEEDBACK_SUBMIT_SUCCESS:
        return {
            ...state, dataSubmitSuccess: true,
        };
    case FEEDBACK_SUBMIT_FAILURE:
        return {
            ...state, dataSubmitFailure: true,
        };
    default:
        return state;
    }
};

export default feedbackReducer;
