import React, { FC, ReactElement } from 'react';

import { YearNavigator } from '../../compositions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { setYear } from '../../redux/@interface/calendar/calendarReducer';

interface ConnectedYearNavigatorProps {
  onChangeYear?: (year: number) => void;
  className?: string;
}

const ConnectedYearNavigator: FC<ConnectedYearNavigatorProps> = ({ onChangeYear, className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();
    const { year } = useTypedSelector(state => state.calendarReducer);

    const handleIncrementButtonClick = (newYear: number): void => {
        dispatch(setYear(newYear));
        onChangeYear && onChangeYear(newYear);
    };

    return (
        <YearNavigator
            year={year}
            onIncrementButtonClick={handleIncrementButtonClick}
            className={className}
        />
    );
};

export default ConnectedYearNavigator;
