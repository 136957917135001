import React, { FC, useState } from 'react';
import {
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

import { translate } from '../../helpers/translations/translator';
import { UserFormData } from '../../@paco/entities/User/User';
import { validatePincodeRegistrationData } from '../../compositions/RegistrationFlow/helpers/validateFormData';
import { isObjEmpty } from '../../helpers/tools';
import { IconButton } from '../../@paco/compositions';

import './PincodeRegistration.scss';


interface PincodeRegistrationProps {
    values: UserFormData;
    onChange: (formData: Partial<UserFormData>) => void;
    onNextStep: () => void;
    onPreviousStep: () => void;
    className?: string;
    inputClassName?: string;
}

const pincodeLength = 4;

export const PincodeRegistration: FC<PincodeRegistrationProps> = ({
    values,
    onChange,
    onNextStep,
    onPreviousStep,
    className = '',
    inputClassName = '',
}) => {
    const [submitted, setSubmitted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const validationErrors = validatePincodeRegistrationData(values, submitted);

    const onNextStepClick = () => {
        setSubmitted(true);
        if (isObjEmpty(validatePincodeRegistrationData(values, true))) {
            onNextStep();
        }
    }

    const onChangePincode = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            setIsDisabled(false);
        }

        onChange({ pincode: e.target.value })
    };

    const onChangePincodeRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ pincodeRepeat: e.target.value })
    };

    return (
        <div className={`pincode-registration ${className}`}>
            <h4 className="pincode-registration__title">{translate('pages.registration.pincodeTitle')}</h4>
            <div className="pincode-registration__modal-body">
                <p>
                    {translate('pages.registration.pincodeIntro', { employeeNumber: values.employeeNumber })}
                </p>
                <div className="pincode-registration__row">
                    <div className="pincode-registration__col">
                        <div className="pincode-registration__row">
                            <div className="pincode-registration__col">
                                <FormGroup>
                                    <Label>{translate('common.pincode')}</Label>
                                    <Input
                                        invalid={!!validationErrors.pincode}
                                        type="password"
                                        name="pincode"
                                        id="pincode"
                                        maxLength={pincodeLength}
                                        placeholder={translate('pages.registration.enterPincode')}
                                        defaultValue={values.pincode}
                                        onChange={onChangePincode}
                                        className={inputClassName}
                                    />
                                    <FormFeedback>
                                        { validationErrors.pincode }
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="pincode-registration__col">
                                <FormGroup>
                                    <Label>{`${translate('common.confirm')} ${translate('common.pincode')}`}</Label>
                                    <Input
                                        invalid={!!validationErrors.pincodeRepeat}
                                        type="password"
                                        name="pincodeRepeat"
                                        id="pincode-repeat"
                                        maxLength={pincodeLength}
                                        placeholder={translate('pages.registration.repeatPincode')}
                                        defaultValue={values.pincodeRepeat}
                                        onChange={onChangePincodeRepeat}
                                        className={inputClassName}
                                    />
                                    <FormFeedback>
                                        { validationErrors.pincodeRepeat }
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pincode-registration__row">
                    <div className="pincode-registration__button-container">
                        <IconButton
                            icon="chevron-left"
                            text={translate('common.back')}
                            onClick={onPreviousStep}
                            className="pincode-registration__back-button"
                        />
                        <IconButton
                            disabled={isDisabled}
                            icon="check"
                            text={translate('common.save')}
                            onClick={onNextStepClick}
                            className="pincode-registration__save-button"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
