import React, { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import './InputLabel.scss';

interface InputLabelProps {
    text: string;
    className?: string;
    requiredClassName?: string;
}

const InputLabel: FC<PropsWithChildren<InputLabelProps>> = ({
    text,
    className = '',
    children,
}): ReactElement => (
    <div className={`input-label ${className}`}>
        {text}

        {children}
    </div>
);

export default InputLabel;
