import {
    takeLatest, put, call,
} from 'redux-saga/effects';
import {
    SET_LOADING,
    LEAVE_OF_ABSENCE_FAILURE,
    LEAVE_OF_ABSENCE_REQUEST,
    LEAVE_OF_ABSENCE_SUCCESS,
    ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_CANCEL_LEAVE_OF_ABSENCE,
    ABSENCES_SAGA_CREATE_LEAVE_OF_ABSENCE,
} from '../actionTypes';
import {
    getLeaveOfAbsenceByUuid,
    createLeaveOfAbsence,
    deleteLeaveOfAbsence,
} from '../../helpers/api/absencesApi';
import { translate } from '../../helpers/translations/translator';
import { transformLeaveOfAbsenceResource } from '../../services/LeaveOfAbsenceService/transformLeaveOfAbsenceResource';
import { ToastType } from '../../@paco/types/toastTypes';
import { setToast } from '../../@paco/redux/toasts/toastsReducer';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

function* fetchLeaveOfAbsence(action) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: LEAVE_OF_ABSENCE_REQUEST });

    try {
        const includes = ['comments', 'comments.user', 'reviewedByUser'];
        const response = yield call(() => getLeaveOfAbsenceByUuid(includes, action.uuid));
        const leaveOfAbsence = transformLeaveOfAbsenceResource(response.data);

        yield put({
            type: LEAVE_OF_ABSENCE_SUCCESS,
            leaveOfAbsence,
        });
    } catch (error) {
        console.error('[fetchLeaveOfAbsence]', error);
        yield put(
            { type: LEAVE_OF_ABSENCE_FAILURE, errors: [error.response] },
        );
    }

    yield put({ type: SET_LOADING, loading: false });
}

function* postLeaveOfAbsence(action) {
    yield put({ type: SET_LOADING, loading: true });
    try {
        const leaveOfAbsence = {
            start: formatDate(action.startDate, DateFormat.apiDateTime),
            end: formatDate(action.endDate, DateFormat.apiDateTime),
            reason: action.reason,
            type: 'leaveOfAbsences',
        };

        yield call(() => createLeaveOfAbsence(
            leaveOfAbsence,
        ));

        yield put(setToast({ text: translate('pages.absences.leaveOfAbsenceRequest'), type: ToastType.pass }));
        action.history.push(`/${translate('nav.absences.link')}`);
    } catch (error) {
        console.error('[postLeaveOfAbsence]', error);
        yield put(
            { type: LEAVE_OF_ABSENCE_FAILURE, errors: [error.response] },
        );
    }
}

function* cancelLeaveOfAbsence(action) {
    yield put({ type: SET_LOADING, loading: true });

    try {
        yield call(() => deleteLeaveOfAbsence(action.leaveOfAbsence));
        yield put(setToast({ text: translate('pages.absences.leaveOfAbsenceRequestCancel'), type: ToastType.pass }));
        action.history.push(`/${translate('nav.absences.link')}`);
    } catch (error) {
        console.error('[cancelLeaveOfAbsence]', error);
        yield put(
            { type: LEAVE_OF_ABSENCE_FAILURE, errors: [error.response] },
        );
    }
}

export default function* leaveOfAbsenceWatcher() {
    yield takeLatest(ABSENCES_SAGA_GET_LEAVE_OF_ABSENCE, fetchLeaveOfAbsence);
    yield takeLatest(ABSENCES_SAGA_CREATE_LEAVE_OF_ABSENCE, postLeaveOfAbsence);
    yield takeLatest(ABSENCES_SAGA_CANCEL_LEAVE_OF_ABSENCE, cancelLeaveOfAbsence);
}
