import React, {
    FC,
    ReactElement,
    useEffect,
} from 'react';

import { DownloadShiftsIcalForm } from '../../../containers';
import { downloadShiftsIcal } from '../../../redux/shifts/shiftsActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { setIsSuccessful } from '../../../redux/shifts/shiftsReducer';
import { Period } from '../../../entities/Period/Period';

interface ConnectedDownloadShiftsIcalFormProps {
    userId: string;
    onClose: () => void;
    className?: string;
}

const ConnectedDownloadShiftsIcalForm: FC<ConnectedDownloadShiftsIcalFormProps> = ({ userId, onClose, className = '' }): ReactElement => {
    const dispatch = useTypedDispatch();

    const { selectedDays } = useTypedSelector(state => state.calendarReducer);
    const { isSuccessful, isLoading } = useTypedSelector(state => state.pacoShiftsReducer);

    const handleSubmit = (period: Period): void => {
        dispatch(downloadShiftsIcal(userId, period));
    };

    useEffect((): void => {
        if (isSuccessful) {
            onClose();
            dispatch(setIsSuccessful(false));
        }
    }, [isSuccessful]);

    return (
        <DownloadShiftsIcalForm
            isLoading={isLoading}
            startDate={selectedDays[0]}
            endDate={selectedDays[selectedDays.length - 1]}
            onCancelClick={onClose}
            onSubmit={handleSubmit}
            className={className}
        />
    );
};

export default ConnectedDownloadShiftsIcalForm;
