import transformAbsenceHoursResource from '../AbsenceHourService/transformAbsenceHoursResource';
import UpToButExcludingDate from '../../helpers/UpToButExcludingDate';
import { AbsenceResource, AbsenceViewModel } from '../../models/Absence';
import { AbsenceHoursResource } from '../../models/AbsenceHours';

export default function transformAbsenceResource(
    absence: AbsenceResource,
    absenceHours?: AbsenceHoursResource[],
): AbsenceViewModel {
    return {
        start: new Date(absence.start),
        end: absence.end ? (
            new UpToButExcludingDate(absence.end).transformToUpToAndIncludingDate()
        ) : null,
        absenceHours: (absenceHours || [])
            .map((resource) => transformAbsenceHoursResource(resource)),
    }
}
