import trans from '../../../helpers/trans';
import { capitalizeFirstLetter } from '../../../../helpers/tools';
import { formatDate, startOfMonth } from '../../../helpers/date';
import { DateFormat } from '../../../types/dateFormatTypes';
import { TimeModeType } from '../../../types/timeModeTypes';

export const getCalendarNavigationDateInputLabel = (selectedDays: Date[], timeMode?: TimeModeType, dateFormat?: DateFormat): string => {
    if (!selectedDays.length) {
        return trans('compositions.calendarNavigation.selectDate');
    }

    if (selectedDays.length === 1) {
        return capitalizeFirstLetter(formatDate(selectedDays[0], dateFormat || DateFormat.datePickerLabel));
    }

    const endDate = selectedDays[selectedDays.length - 1];
    const startDate = timeMode === TimeModeType.month ? startOfMonth(endDate) : selectedDays[0];
    const year = formatDate(selectedDays[0], DateFormat.year);
    const formattedStartDate = formatDate(startDate, dateFormat || DateFormat.datePickerDateLabel);
    const formattedEndDate = formatDate(endDate, dateFormat || DateFormat.datePickerDateLabel);

    return `${formattedStartDate} - ${formattedEndDate} ${year}`;
};
