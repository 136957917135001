import React, { ChangeEvent } from 'react';

export interface CheckboxProps {
    hideLabel: boolean;
    isChecked: boolean;
    name: string;
    label: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({
    hideLabel,
    isChecked,
    name,
    label,
    onChange,
}: CheckboxProps) => (
    <div className="form-check custom-checkbox">
        <input
            checked={isChecked}
            type="checkbox"
            id={name}
            onChange={onChange}
            className="custom-control-input"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={name} className="custom-control-label">
            {!hideLabel && label}
        </label>
    </div>
);
