import React, { FC, ReactElement, useMemo } from 'react';

import { IconButton } from '../../..';
import trans from '../../../../helpers/trans';
import { Button } from '../../../../components';
import { getCalendarNavigationDateInputLabel } from '../../helpers';
import { TimeModeType } from '../../../../types/timeModeTypes';

import './DateControls.scss';

interface DateControlsProps {
    selectedDays: Date[];
    timeMode: TimeModeType;
    onDayPickerButtonClick: () => void;
    onIncrementButtonClick: (goForward: boolean) => void;
    className?: string;
}

const DateControls: FC<DateControlsProps> = ({
    selectedDays,
    timeMode,
    onDayPickerButtonClick,
    onIncrementButtonClick,
    className = '',
}): ReactElement => {
    const datePickerButtonText = useMemo(() => getCalendarNavigationDateInputLabel(selectedDays, timeMode), [selectedDays]);

    const handleNextButtonClick = () => {
        onIncrementButtonClick(true);
    };

    const handlePreviousButtonClick = () => {
        onIncrementButtonClick(false);
    };

    return (
        <div className={`date-controls ${className}`}>
            <IconButton
                hideLabel
                icon="chevron-left"
                text={trans('common.previous')}
                onClick={handlePreviousButtonClick}
                className="date-controls__prev-button"
            />

            <Button
                text={datePickerButtonText}
                onClick={onDayPickerButtonClick}
                className="date-controls__date-picker-button"
            />

            <IconButton
                hideLabel
                icon="chevron-right"
                text={trans('common.next')}
                onClick={handleNextButtonClick}
                className="date-controls__prev-button"
            />
        </div>
    );
};

export default DateControls;
