import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmployeeBalancesPerPeriod from '../../containers/EmployeeBalancesPerPeriod/EmployeeBalancesPerPeriod';
import { Reducers } from '../../redux/reducers';
import { getPayrollPeriods } from '../../redux/app-ts/appActions';
import { LoadingSpinner } from '../../@paco/components';
import { PayrollPeriod } from '../../models';
import { switchToNextOrPreviousPayrollPeriod } from '../../services/PayrollPeriodServices/PayrollPeriodService';
import { getCurrentPayrollPeriod, getUserWithEmployeeBalances } from '../../redux/absences-ts/absencesActions';


const ConnectedEmployeeBalancesPerPeriod: FC = () => {
    const dispatch = useDispatch();

    const payrollPeriods = useSelector((state: Reducers) => state.appReducer.payrollPeriods);
    const currentPayrollPeriod = useSelector((state: Reducers) => state.absencesReducer.currentPayrollPeriod);
    const { loading, employeeBalanceValues } = useSelector((state: Reducers) => state.absencesReducer);

    const [activePayrollPeriod, setActivePayrollPeriod] = useState<PayrollPeriod | null>(null);

    useEffect(() => {
        if (!activePayrollPeriod) {
            dispatch(getCurrentPayrollPeriod());
            setActivePayrollPeriod(currentPayrollPeriod);
        }
    }, [currentPayrollPeriod, payrollPeriods]);

    useEffect(() => {
        if (!payrollPeriods.length) {
            dispatch(getPayrollPeriods());
        }
    }, []);

    useEffect(() => {
        if (activePayrollPeriod) {
            dispatch(getUserWithEmployeeBalances(activePayrollPeriod));
        }
    }, [activePayrollPeriod]);

    const switchPayrollPeriod = (nextPeriod: boolean) => {
        const payrollPeriod = activePayrollPeriod
            && switchToNextOrPreviousPayrollPeriod(activePayrollPeriod, payrollPeriods, nextPeriod);
        setActivePayrollPeriod(payrollPeriod);
    }

    if (!payrollPeriods.length || !activePayrollPeriod) {
        return <LoadingSpinner />
    }

    return (
        <EmployeeBalancesPerPeriod
            loading={loading}
            activePayrollPeriod={activePayrollPeriod}
            employeeBalanceValues={employeeBalanceValues}
            switchPayrollPeriod={switchPayrollPeriod}
        />
    );
}

export default ConnectedEmployeeBalancesPerPeriod;
