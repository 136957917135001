import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router';

import ShiftExchangeDetailByMe from '../../containers/ShiftExchangeDetailByMe/ShiftExchangeDetailByMe';
import ShiftExchangeDetailForMe from '../../containers/ShiftExchangeDetailForMe/ShiftExchangeDetailForMe';
import { acceptShiftExchange, cancelShiftExchange, getShiftExchange } from '../../redux/exchanges/exchangesActions';
import { Reducers } from '../../redux/reducers';
import { LoadingSpinner } from '../../@paco/components';
import { translate } from '../../helpers/translations/translator';

const ConnectedShiftExchangeDetail: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser, settings } = useSelector((state: Reducers) => state.userReducer);
    const { shiftExchange, loading } = useSelector((state: Reducers) => state.exchangesReducer);
    const location = useRouteMatch<{ exchangeUuid: string }>();
    const { exchangeUuid } = location.params;

    useEffect(() => {
        dispatch(getShiftExchange(exchangeUuid));
    }, [exchangeUuid]);

    const onCancelShiftExchange = () => {
        dispatch(cancelShiftExchange(exchangeUuid, history));
    }

    const onDenyShiftExchange = () => {
        dispatch(cancelShiftExchange(exchangeUuid, history, true));
    }

    const onAcceptShiftExchange = () => {
        dispatch(acceptShiftExchange(exchangeUuid, history));
    }

    if ((loading && !shiftExchange) || !currentUser) {
        return <LoadingSpinner />
    }

    if (!shiftExchange) {
        return (
            <>
                {translate('pages.exchanges.exchangeNotFound')}
            </>
        )
    }

    if (shiftExchange.shiftPlanning1.user.id === currentUser.id) {
        return (
            <ShiftExchangeDetailByMe
                loading={loading}
                settings={settings}
                onCancelShiftExchange={onCancelShiftExchange}
                exchange={shiftExchange}
            />
        );
    }

    return (
        <ShiftExchangeDetailForMe
            loading={loading}
            settings={settings}
            onDenyShiftExchange={onDenyShiftExchange}
            onAcceptShiftExchange={onAcceptShiftExchange}
            exchange={shiftExchange}
        />
    );
}

export default ConnectedShiftExchangeDetail;
