import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';
import { CardColor } from '../../types/cardColor';

import './Card.scss';

interface CardProps {
    color?: CardColor;
    className?: string;
}

const Card: FC<CardProps> = ({ children, color, className = '' }): ReactElement => {
    const cardClassName = classnames('card', {
        'card--has-border': color && color !== CardColor.primary,
        [`card--is-${color}`]: color,
    }, className)

    return (
        <div className={cardClassName}>
            {children}
        </div>
    );
};

export default Card;
