import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import './Logo.scss';

interface LogoProps {
  isBlue?: boolean;
  className?: string;
}

const Logo: FC<LogoProps> = ({ isBlue, className = '' }): ReactElement => {
    const now = new Date();
    const isChristmas = now.getMonth() === 11 && now.getDate() > 7;

    const logoClassName = classNames('logo', {
        'logo--is-christmas': isChristmas,
        'logo--is-blue': isBlue,
    }, className);

    return (
        <div className={logoClassName} />
    );
};

export default Logo;
