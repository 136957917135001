import axios, { AxiosResponse } from 'axios';

import { ApiResponse, ShiftExchangeResource, ShiftExchangeViewModel } from '../../models';
import transformShiftExchangeResource from '../../services/ShiftExchangeService/transformShiftExchangeResource';

export const getShiftExchangesInDateRange = async (
    includes: string[] = [],
): Promise<ShiftExchangeViewModel[]> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/shift-exchanges/open`,
        {
            params: {
                include: includes.join(','),
            },
        },
    ) as unknown as ApiResponse<ShiftExchangeResource[]>;

    return response.data.map((exchange: ShiftExchangeResource) => transformShiftExchangeResource(
        exchange,
        exchange.shiftPlanning1,
        exchange.shiftPlanning2,
    ));
}

export const getShiftExchange = async (
    id: string,
    includes: string[] = [],
): Promise<ShiftExchangeViewModel> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shift-exchanges/${id}`,
        {
            params: {
                include: includes.join(','),
            },
        },
    ) as unknown as ApiResponse<ShiftExchangeResource>;

    return transformShiftExchangeResource(
        response.data,
        response.data.shiftPlanning1,
        response.data.shiftPlanning2,
    );
}

export const deleteShiftExchange = async (id: string): Promise<AxiosResponse> => (
    axios.delete(`${process.env.REACT_APP_API_URL}/me/shift-exchange/${id}`)
);

export const declineShiftExchange = async (id: string): Promise<AxiosResponse> => (
    axios.patch(`${process.env.REACT_APP_API_URL}/me/shift-exchange/decline/${id}`)
);

export const patchApproveShiftExchange = async (id: string): Promise<AxiosResponse> => (
    axios.patch(`${process.env.REACT_APP_API_URL}/me/shift-exchange/approve/${id}`)
);

export const postShiftExchange = async (id: string, id2: string): Promise<AxiosResponse> => {
    const body = {
        type: 'shift_exchanges',
        attributes: {},
        relationships: {
            shiftPlanning1: {
                data: {
                    type: 'shift_plannings',
                    id,
                },
            },
            shiftPlanning2: {
                data: {
                    type: 'shift_plannings',
                    id: id2,
                },
            },
        },
    };

    return (
        axios.post(`${process.env.REACT_APP_API_URL}/shift-exchanges`,
            { data: body })
    );
}
