import React, { FC, useState } from 'react';
import {
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

import { translate } from '../../helpers/translations/translator';
import { PreviousAndNextButton } from '../PreviousAndNextButton/PreviousAndNextButton';
import { UserFormData } from '../../@paco/entities/User/User';
import { isObjEmpty } from '../../helpers/tools';
import { validatePasswordRegistrationData } from '../../compositions/RegistrationFlow/helpers/validateFormData';
import useCapslock from '../../@paco/helpers/hooks/useCapsLock';

import './PasswordRegistration.scss';


interface PasswordRegistrationProps {
    values: UserFormData;
    onChange: (formData: Partial<UserFormData>) => void;
    onPreviousStep: () => void;
    onNextStep: () => void;
    className?: string;
    inputClassName?: string;
}

export const PasswordRegistration: FC<PasswordRegistrationProps> = ({
    values,
    onChange,
    onPreviousStep,
    onNextStep,
    className = '',
    inputClassName = '',
}) => {
    const isCapsLockPressed = useCapslock();

    const [submitted, setSubmitted] = useState(false);
    const validationErrors = validatePasswordRegistrationData(values, submitted, isCapsLockPressed);

    const onNextStepClick = () => {
        setSubmitted(true);
        if (isObjEmpty(validatePasswordRegistrationData(values, true))) {
            onNextStep();
        }
    }

    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ password: e.target.value })
    };

    const onChangePasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ passwordRepeat: e.target.value })
    };

    return (
        <div className={`password-registration ${className}`}>
            <h4 className="password-registration__title">{translate('pages.registration.passwordTitle')}</h4>
            <div className="password-registration__modal-body">
                <p>
                    {translate('pages.registration.passwordIntro')}
                </p>
                <div className="password-registration__row">
                    <div className="password-registration__col">
                        <FormGroup>
                            <Label>{translate('common.password')}</Label>
                            <Input
                                invalid={!!validationErrors.password}
                                type="password"
                                name="password"
                                id="password"
                                placeholder={translate('pages.registration.enterPassword')}
                                defaultValue={values.password}
                                onChange={onChangePassword}
                                className={inputClassName}
                            />
                            <FormFeedback>
                                { validationErrors.password }
                            </FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <div className="password-registration__row">
                    <div className="password-registration__col">
                        <FormGroup>
                            <Label>{`${translate('common.confirm')} ${translate('common.password')}`}</Label>
                            <Input
                                invalid={!!validationErrors.passwordRepeat}
                                type="password"
                                name="passwordRepeat"
                                id="password-repeat"
                                placeholder={translate('pages.registration.repeatPassword')}
                                defaultValue={values.passwordRepeat}
                                onChange={onChangePasswordRepeat}
                                className={inputClassName}
                            />
                            <FormFeedback>
                                { validationErrors.passwordRepeat }
                            </FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <PreviousAndNextButton onPreviousStep={onPreviousStep} onNextStep={onNextStepClick} />
            </div>
        </div>
    )
}
