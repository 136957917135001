import axios from 'axios';

const includes = ['department'];

export const getSettings = () => axios.get(`${process.env.REACT_APP_API_URL}/settings`,
    {
        params: {
            include: includes.join(','),
        },
    });

export const dummy = () => {};
