import {
    LeaveOfAbsenceResource,
    LeaveOfAbsenceStatus,
    LeaveOfAbsenceViewModel,
    LeaveOfAbsenceHoursResource,
} from '../../models';
import UpToButExcludingDate from '../../helpers/UpToButExcludingDate';
import transformLeaveOfAbsenceHoursResource from '../LeaveOfAbsenceHourService/transformLeaveOfAbsenceHoursResource';

function transformLeaveOfAbsenceResourceStatus(status: LeaveOfAbsenceResource['status']): LeaveOfAbsenceStatus {
    if (status === 'denied') {
        return LeaveOfAbsenceStatus.denied;
    }

    if (status === 'approved') {
        return LeaveOfAbsenceStatus.approved;
    }

    return LeaveOfAbsenceStatus.open;
}

export const transformLeaveOfAbsenceResource = (
    leaveOfAbsence: LeaveOfAbsenceResource,
    leaveOfAbsenceHours?: LeaveOfAbsenceHoursResource[],
): LeaveOfAbsenceViewModel => ({
    id: leaveOfAbsence.id,
    declineReason: leaveOfAbsence.declineReason,
    reason: leaveOfAbsence.reason,
    start: new Date(leaveOfAbsence.start),
    end: new UpToButExcludingDate(leaveOfAbsence.end).transformToUpToAndIncludingDate(),
    status: transformLeaveOfAbsenceResourceStatus(leaveOfAbsence.status),
    leaveOfAbsenceHours: (leaveOfAbsenceHours || []).map(transformLeaveOfAbsenceHoursResource),
    createdAt: new Date(leaveOfAbsence.createdAt),
})
