import {
    put,
    takeLatest,
    call,
} from 'redux-saga/effects';
import {
    SET_LOADING,
    TRACK_SAGA_GET_TRACK,
    TRACK_FAILURE,
    TRACK_REQUEST,
    TRACK_SUCCESS,
} from '../actionTypes';

import { getTrack } from '../../helpers/api/tracksApi';

function* fetchTrack(action) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: TRACK_REQUEST });

    try {
        const includes = ['shift', 'shift.department', 'shift.shiftPlannings', 'feedback', 'logs'];

        const response = yield call(() => getTrack(includes, action.trackUuid));
        const track = yield call(() => response.data);
        if (track.logs) {
            track.logs = track.logs.filter(log => log.version !== 1);
        }

        yield put({
            type: TRACK_SUCCESS,
            track,
        });
    } catch (error) {
        yield put({ type: TRACK_FAILURE, errors: [error.response] });
    }
    yield put({ type: SET_LOADING, loading: false });
}

export default function* trackWatcher() {
    yield takeLatest(TRACK_SAGA_GET_TRACK, fetchTrack);
}
