import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../helpers/translations/translator';
import { GENDERS } from '../../constants';
import {
    Button,
    InfoListItem,
    LoadingSpinner,
    Select,
} from '../../@paco/components';

import './ProfileDetailItem.scss';

const ProfileDetailSelect = ({
    title,
    propName,
    options,
    onSave,
    intro,
    loading,
    ...props
}) => {
    const [value, setValue] = useState(null);

    useState(() => {
        setValue(props.value || GENDERS[0].value);
    }, [props.value]);

    const onFormSubmit = (e) => {
        e.preventDefault();
        onSave(value);
    };

    return (
        <form
            className="profile-page-detail"
            onSubmit={onFormSubmit}
        >
            <div className="profile-page-detail__header">
                {title}
            </div>
            { intro && (
                <div className="profile-page-detail__intro">
                    {intro}
                </div>
            )}
            <ul className="profile-page-detail__list-group">
                <InfoListItem
                    label={propName}
                    className="profile-page-detail__item"
                >
                    <Select
                        label={title}
                        options={options}
                        value={value}
                        onChange={setValue}
                        className="profile-page-detail__select"
                    />
                </InfoListItem>
            </ul>
            <div className="profile-page-detail__save-button-wrapper">
                <Button
                    disabled={value === null}
                    text={translate('common.save')}
                    type="submit"
                    className="profile-page-detail__save-button"
                />
            </div>
            {loading && <LoadingSpinner />}
        </form>
    );
};

ProfileDetailSelect.propTypes = {
    title: PropTypes.string.isRequired,
    propName: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    intro: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

ProfileDetailSelect.defaultProps = {
    intro: null,
};

export default ProfileDetailSelect;
