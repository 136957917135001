import { ShiftExchangeStatus } from '../../models';
import { translate } from '../../helpers/translations/translator';
import { CardColor } from '../../@paco/types/cardColor';

export default function getShiftExchangeStatus(
    status: ShiftExchangeStatus,
):{ color: CardColor, text: string } {
    if (status === ShiftExchangeStatus.approvedByEmployee) {
        return { color: CardColor.orange, text: translate('common.available') }
    }

    if (status === ShiftExchangeStatus.approvedByManager) {
        return { color: CardColor.success, text: translate('common.planned') }
    }

    if (status === ShiftExchangeStatus.declined) {
        return { color: CardColor.danger, text: translate('pages.exchanges.denied') }
    }

    return { color: CardColor.secondary, text: translate('pages.exchanges.exchangeShift') }
}
