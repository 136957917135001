import axios from 'axios';
import { refreshAccessToken } from '../helpers/api/authenticationApi';
import { setApiToken, setRefreshToken } from '../redux/authentication/authenticationActions';
import { logout } from '../redux/app/appActions';
import { version } from '../../package.json';
import {
    localStorageRefreshTokenKey,
    localStorageTenantKey,
    localStorageTokenKey,
} from '../@paco/helpers/authorizedFetch';

export default class AuthInterceptor {
    constructor(store) {
        this.store = store;
    }

    setInterceptors = () => {
        const tenant = localStorage.getItem(localStorageTenantKey) || process.env.REACT_APP_TENANT || window.location.hostname;

        axios.interceptors.request.use(
            (requestConfig) => {
                requestConfig.headers.authorization = `Bearer ${localStorage.getItem(localStorageTokenKey)}`;
                requestConfig.headers.Tenant = tenant;
                requestConfig.headers.App = 'employee';
                requestConfig.headers['App-Version'] = version;

                return requestConfig;
            },
            err => Promise.reject(err),
        );

        const { dispatch } = this.store;

        const interceptor = axios.interceptors.response.use(
            response => response,
            (error) => {
                if ((error.response.status !== 401)) {
                    return Promise.reject(error);
                }

                axios.interceptors.response.eject(interceptor);

                const apiRefreshToken = localStorage.getItem(localStorageRefreshTokenKey);
                if (!apiRefreshToken || apiRefreshToken === 'undefined') {
                    return Promise.reject(error);
                }

                return refreshAccessToken(apiRefreshToken)
                    .then((refreshResponse) => {
                        dispatch(setApiToken(refreshResponse.data.token));
                        dispatch(setRefreshToken(refreshResponse.data.refresh_token));

                        error.config.headers['authorization'] = `Bearer ${refreshResponse.data.token}`;
                        return axios(error.config);
                    })
                    .catch((refreshError) => {
                        if ((refreshError.response.status !== 200)) {
                            dispatch(logout());
                            localStorage.setItem('authenticated', 'false');
                        }
                    })
                    .finally(localStorage.getItem('authenticated') === 'true' && this.setInterceptors);
            },
        );
    }
}
