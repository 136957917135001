/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCalendarExport: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 2h-1V0h-2v2H5V0H3v2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h3l-2-2H2V8h14v10h-1l-2 2h3c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2M2 6V4h14v2H2m8 11v-6H8v6H5l4 4 4-4h-3z" fill="#114D61" fillRule="nonzero" />
    </svg>
);

export default IconCalendarExport;
/* eslint-enable max-len */
