import { useState, useEffect } from 'react';

const useCapslock = (): boolean => {
    const [isCapslockActive, setIsCapslockActive] = useState<boolean>(false);

    const handleKeyEvent = (event: KeyboardEvent): void => {
        if (typeof event.getModifierState !== 'function') {
            return;
        }

        setIsCapslockActive(event.getModifierState('CapsLock'));
    };

    useEffect((): () => void => {
        const keyDownEvent = 'keydown';
        const keyUpEvent = 'keyup';

        document.addEventListener(keyDownEvent, handleKeyEvent);
        document.addEventListener(keyUpEvent, handleKeyEvent);

        return () => {
            document.removeEventListener(keyDownEvent, handleKeyEvent);
            document.removeEventListener(keyUpEvent, handleKeyEvent);
        };
    }, []);

    return isCapslockActive;
}

export default useCapslock;
