import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ProfileListItem from '../../components/Profile/ProfileListItem';
import { translate } from '../../helpers/translations/translator';
import { convertSpacesToDash } from '../../helpers/tools';
import { getUserFullName } from '../../helpers/getUserFullName';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

import './Profile.scss';

const Profile = ({ currentUser, history }) => {
    if (!currentUser) {
        return null;
    }

    const {
        employeeNumber,
        email,
        phone,
        person,
        nationality,
    } = currentUser;

    const { identificationType, isCompanyMedic } = person;
    const emergencyContact = person.emergencyContact || {};

    const address = (person.addresses && person.addresses.length)
        ? person.addresses[person.addresses.length - 1] : {};
    const name = getUserFullName(currentUser);
    const birthday = formatDate(new Date(currentUser.birthday), DateFormat.date);
    const gender = translate(`common.${person.gender || 'unknown'}`);

    const onItemClick = (title) => {
        const route = convertSpacesToDash(title);
        history.push(`/${translate('nav.profile.link')}/${route}`);
    };

    return (
        <div className="profile-page">
            <ul>
                <ProfileListItem
                    key="name"
                    title={translate('pages.profile.name')}
                    values={[name]}
                />
                <ProfileListItem
                    key="employee-number"
                    title={translate('common.employeeNumber')}
                    values={[employeeNumber]}
                />
                <ProfileListItem
                    key="birthday"
                    title={translate('pages.profile.birthday')}
                    values={[birthday]}
                />
                <ProfileListItem
                    key="nationality"
                    title={translate('pages.profile.nationality')}
                    values={[nationality]}
                />
                <ProfileListItem
                    key="isCompanyMedic"
                    title={translate('pages.profile.isCompanyMedic')}
                    values={[translate(`pages.profile.${isCompanyMedic}`)]}
                />
                <ProfileListItem
                    key="identificationType"
                    title={translate('pages.profile.identificationType')}
                    values={[translate(`pages.profile.${identificationType}`)]}
                />
                <ProfileListItem
                    key="password"
                    title={translate('pages.profile.passwordAndPincode')}
                    onClick={onItemClick}
                />
                <ProfileListItem
                    key="address"
                    title={translate('pages.profile.address')}
                    values={[
                        address.street,
                        address.houseNumber,
                        address.houseNumberAddition,
                        address.postalCode,
                        address.city,
                    ]}
                    onClick={onItemClick}
                />
                <ProfileListItem
                    key="email"
                    title={translate('pages.profile.email')}
                    values={[email]}
                    onClick={onItemClick}
                />
                <ProfileListItem
                    key="phone"
                    title={translate('pages.profile.phone')}
                    values={[phone]}
                    onClick={onItemClick}
                />
                <ProfileListItem
                    key="emergencyContact"
                    title={translate('pages.profile.emergencyContact')}
                    values={[
                        emergencyContact.fullName,
                        emergencyContact.phone,
                        emergencyContact.relationType,
                    ]}
                    onClick={onItemClick}
                />
                <ProfileListItem
                    key="gender"
                    title={translate('common.gender')}
                    values={[gender]}
                    onClick={onItemClick}
                />
            </ul>
        </div>
    );
};

Profile.propTypes = {
    currentUser: PropTypes.object,
    history: PropTypes.object.isRequired,
};

Profile.defaultProps = {
    currentUser: null,
};

const mapStateToProps = state => ({
    currentUser: state.userReducer.currentUser,
});

export default withRouter(connect(mapStateToProps)(Profile));
