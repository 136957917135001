import React, { FC } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import SaldoItem from '../../components/SaldoItem/SaldoItem';
import { translate } from '../../helpers/translations/translator';
import SaldoItemGroup from '../../components/SaldoItemGroup/SaldoItemGroup';
import { LoadingSpinner } from '../../@paco/components';
import { LoketBalancesViewModel } from '../../models';

import './LoketLeaveBalances.scss';


interface LoketLeaveBalancesProps {
    className?: string;
    loading: boolean;
    loketLeaveBalance: LoketBalancesViewModel | null;
    loketEmployeeNotFound: boolean;
}

interface LeaveSaldo {
    hours: number;
    color: string;
}

const colors = {
    nonStatutoryLeaveHoursTotal: '#158671',
    statutoryLeaveHoursTotal: '#26AE60',
    reservedLeaveHoursTotal: '#FFEB3B',
    timeForTimeTotal: '#3498DB',
    usedLeaveHoursTotal: '#FF9F19',
    neutral: '#F0F0F0',
}

const LoketLeaveBalances: FC<LoketLeaveBalancesProps> = ({
    className = '',
    loading,
    loketLeaveBalance,
    loketEmployeeNotFound,
}) => {
    if (loketEmployeeNotFound) {
        return (
            <div className="loket-leave-balances--loket-employee-not-found">
                {translate('pages.absences.loketEmployeeNotFound')}
            </div>
        )
    }

    if (loading || !loketLeaveBalance) {
        return (
            <LoadingSpinner />
        );
    }

    const leaveSaldi: LeaveSaldo[] = [
        {
            hours: loketLeaveBalance.reservedLeaveHoursTotal,
            color: colors.reservedLeaveHoursTotal,
        },
        {
            hours: loketLeaveBalance.nonStatutoryLeaveHoursTotal,
            color: colors.nonStatutoryLeaveHoursTotal,
        },
        {
            hours: loketLeaveBalance.statutoryLeaveHoursTotal,
            color: colors.statutoryLeaveHoursTotal,
        },
        {
            hours: loketLeaveBalance.timeForTimeTotal,
            color: colors.timeForTimeTotal,
        },
        {
            hours: loketLeaveBalance.usedLeaveHoursTotal,
            color: colors.usedLeaveHoursTotal,
        },
    ];

    const totalLoketHours = loketLeaveBalance.reservedLeaveHoursTotal
        + loketLeaveBalance.nonStatutoryLeaveHoursTotal
        + loketLeaveBalance.statutoryLeaveHoursTotal
        + loketLeaveBalance.timeForTimeTotal
        + loketLeaveBalance.usedLeaveHoursTotal;

    const defaultPie = [{ value: 1, color: colors.neutral }];

    return (
        <div className={`loket-leave-balances ${className}`}>
            <div className="loket-leave-balances__pie-chart-container">
                <PieChart
                    className="loket-leave-balances__pie-chart"
                    lineWidth={40}
                    paddingAngle={totalLoketHours > 0 ? 3 : 0}
                    data={totalLoketHours > 0 ? leaveSaldi.map(saldo => ({
                        value: saldo.hours,
                        color: saldo.color,
                    })) : defaultPie}
                />
            </div>
            <SaldoItem
                isTitle
                title={translate('pages.absences.leaveOfAbsenceBalances.actualLeaveOfAbsenceSaldo')}
                hours={loketLeaveBalance.leaveBalanceTotal}
            />
            <SaldoItem
                title="Bovenwettelijke verlofuren"
                hours={loketLeaveBalance.nonStatutoryLeaveHoursTotal}
                color={colors.nonStatutoryLeaveHoursTotal}
            />
            <SaldoItem
                title="Wettelijke verlofuren"
                hours={loketLeaveBalance.statutoryLeaveHoursTotal}
                color={colors.statutoryLeaveHoursTotal}
            />
            <SaldoItemGroup>
                <SaldoItem
                    title={translate('pages.absences.leaveOfAbsenceBalances.reservedLeaveHours')}
                    hours={loketLeaveBalance.reservedLeaveHoursTotal}
                    color={colors.reservedLeaveHoursTotal}
                >
                    { loketLeaveBalance.reservedLeaveHours.map(balance => (
                        <SaldoItem
                            key={balance.label}
                            title={balance.label}
                            hours={balance.value}
                        />
                    ))}
                </SaldoItem>
            </SaldoItemGroup>
            <SaldoItem
                isTitle
                color={colors.timeForTimeTotal}
                title={translate('pages.absences.leaveOfAbsenceBalances.timeForTimeSaldo')}
                hours={loketLeaveBalance.timeForTimeTotal}
            />
            <SaldoItemGroup>
                <SaldoItem
                    title={translate('pages.absences.leaveOfAbsenceBalances.takenLeave')}
                    hours={loketLeaveBalance.usedLeaveHoursTotal}
                    color={colors.usedLeaveHoursTotal}
                >
                    { loketLeaveBalance.usedLeaveHours.map(balance => (
                        <SaldoItem
                            key={balance.label}
                            title={balance.label}
                            hours={balance.value}
                        />
                    ))}
                </SaldoItem>
            </SaldoItemGroup>
        </div>
    );
}

export default LoketLeaveBalances;
