import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PayrollPeriod } from '../../../entities/PayrollPeriod/PayrollPeriod';
import {
    addDays,
    addHours,
    eachDayOfInterval,
    setHours,
    setMinutes,
    setSeconds,
    startOfWeek,
} from '../../../helpers/date';
import { AsyncReduxState, initialAsyncReduxState } from '../../@config/AsyncReduxState';
import { TimeModeType } from '../../../types/timeModeTypes';

export enum LocalStorageItemKey {
    selectedDays = 'selectedDays',
    timeMode = 'timeMode',
}

export type CalendarState = AsyncReduxState<{
    activePayrollPeriod?: PayrollPeriod;
    selectedDays: Date[];
    selectedMobileDay: Date;
    timeMode: TimeModeType;
    year: number;
}>;

const defaultStartDay = setSeconds(setMinutes(setHours(addHours(new Date(), -4), 4), 0), 0);
const defaultStartOfWeek = startOfWeek(defaultStartDay, { weekStartsOn: 1 });

const initialState: CalendarState = {
    ...initialAsyncReduxState,
    selectedDays: eachDayOfInterval({ start: defaultStartOfWeek, end: addDays(defaultStartOfWeek, 6) }),
    selectedMobileDay: defaultStartDay,
    timeMode: TimeModeType.week,
    year: defaultStartDay.getFullYear(),
};

export const calendarSlice = createSlice({
    name: 'calendarReducer',
    initialState,
    reducers: {
        setActivePayrollPeriod(state, action: PayloadAction<PayrollPeriod | undefined>): CalendarState {
            return {
                ...state,
                activePayrollPeriod: action.payload,
            };
        },
        setSelectedDays(state, action: PayloadAction<Date[]>): CalendarState {
            localStorage.setItem(LocalStorageItemKey.selectedDays, JSON.stringify(action.payload));

            return {
                ...state,
                selectedDays: action.payload,
            };
        },
        setSelectedMobileDay(state, action: PayloadAction<Date>): CalendarState {
            return {
                ...state,
                selectedMobileDay: action.payload,
            };
        },
        setTimeMode(state, action: PayloadAction<TimeModeType>): CalendarState {
            localStorage.setItem(LocalStorageItemKey.timeMode, JSON.stringify(action.payload));

            return {
                ...state,
                timeMode: action.payload,
            };
        },
        setYear(state, action: PayloadAction<number>): CalendarState {
            return {
                ...state,
                year: action.payload,
            };
        },
    },
});

export const {
    setActivePayrollPeriod,
    setSelectedDays,
    setSelectedMobileDay,
    setTimeMode,
    setYear,
} = calendarSlice.actions;

export default calendarSlice.reducer;
