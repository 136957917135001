import React from 'react';
import { Provider } from 'react-redux';
import './style/main.scss';
import './@paco/styles/index.scss';
import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { configureStore } from './redux/store';
import AuthInterceptor from './interceptors/AuthInterceptor';
import './interceptors/JsonApiInterceptor';
import AppInterceptor from './interceptors/AppInterceptor';
import './interceptors/PageSizeInterceptor';
import { setApiToken, setRefreshToken, setTenant } from './redux/authentication/authenticationActions';
import { unregisterAllServiceWorkers } from './helpers/serviceWorker';
import { setIOSVersion } from './helpers/tools';
import { setLegacyLanguage } from './helpers/lang/setLegacyLanguage';
import { getLocale } from './@paco/helpers/trans';
import { localizeMoment } from './helpers/date/DateTimeParser';

import './@paco/styles/global.scss';

const packageJson = require('../package.json');

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://80f4b6f8ea2e4eb3bd5ab3a2fd6b22d2@o62714.ingest.sentry.io/5226639',
        release: `${packageJson.name}@${packageJson.version}`,
        environment: process.env.REACT_APP_APP_ENV,
    });
}

const locale = getLocale();
setLegacyLanguage(locale);
localizeMoment(locale);

setIOSVersion();
unregisterAllServiceWorkers();

const getTokensFromUrl = (store: any) => {
    const { dispatch } = store;

    const url = window.location.search;
    const params = queryString.parse(url);

    params.tenant && dispatch(setTenant(params.tenant));
    const { api_token: apiToken, refresh_token: refreshToken } = params;
    if (apiToken) {
        dispatch(setApiToken(apiToken));
        dispatch(setRefreshToken(refreshToken));
    }
};

require('dotenv').config();

export const appRootId = 'app-root';
const container = document.getElementById(appRootId) as Element;
const root = createRoot(container);

configureStore({}).then(store => {
    getTokensFromUrl(store);
    const authInterceptor = new AuthInterceptor(store);
    const appInterceptor = new AppInterceptor(store);
    authInterceptor.setInterceptors();
    appInterceptor.setInterceptors();

    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
    );
});
