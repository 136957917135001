import { PayslipResource, UserDocumentViewModel, DocumentTypeViewModel } from '../../models';
import { getLoketApiUrl } from '../../helpers/api-ts/loket';
import { generateUuid } from '../../helpers/tools';

export default function transformPayslipResourceToUserDocument(
    payslip: PayslipResource,
): UserDocumentViewModel {
    return {
        id: generateUuid(),
        isLoketFile: true,
        isDataUrl: false,
        createdAt: new Date(payslip.payrollPeriodStart),
        displayName: payslip.fileName,
        documentType: {
            id: generateUuid(),
            name: 'Salary',
            slug: 'salary',
        },
        downloadUrl: `${getLoketApiUrl()}/${payslip.downloadUrl}`,
        fileName: payslip.fileName,
    }
}
