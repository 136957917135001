import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { CardColor } from '../../types/cardColor';

import './Badge.scss';

interface BadgeProps {
    color: CardColor;
    className?: string;
}

const Badge: FC<BadgeProps> = ({ color, className = '', children }): ReactElement => {
    const badgeClassName = classNames('badge', {
        [`badge--${color}`]: color,
    }, className);

    return (
        <div className={badgeClassName}>
            {children}
        </div>
    );
}

export default Badge;
