import { getObjProperty } from './tools';

export const getUserFullName = (user: any): string => {
    if (!user) {
        return '';
    }

    const firstname = user.firstname || getObjProperty(user, 'person.firstname') || '';
    const lastname = user.lastname || getObjProperty(user, 'person.lastname') || '';
    const affix = user.affix || getObjProperty(user, 'person.affix') || '';

    return `${firstname}${affix ? ` ${affix}` : ''} ${lastname}`
};
