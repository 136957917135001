import { Resource } from './Resource';
import { ShiftPlanningResource, ShiftPlanningViewModel } from './ShiftPlanning';

export enum ShiftExchangeStatus {
    open = 'open',
    approvedByEmployee = 'approvedByEmployee',
    approvedByManager = 'approvedByManager',
    declined = 'declined',
}

export interface ShiftExchangeResource extends Resource {
    type: 'shift-exchanges';
    id: string;
    shiftPlanning1: ShiftPlanningResource;
    shiftPlanning2: ShiftPlanningResource;
    status: number;
    createdAt: string;
    updatedAt: string;
}

export interface ShiftExchangeViewModel {
    type: 'shift-exchanges';
    id: string;
    shiftPlanning1: ShiftPlanningViewModel;
    shiftPlanning2: ShiftPlanningViewModel;
    status: ShiftExchangeStatus;
    createdAt: Date;
}
