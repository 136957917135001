import React, { FC } from 'react';

import ExchangeButton from './subcomponents/ExchangeButton/ExchangeButton';
import { translate } from '../../helpers/translations/translator';

import './ExchangeShiftOptions.scss';


interface ExchangeShiftOptionsProps {
    onOfferShiftClick: () => void;
    onChooseEmployeeClick: () => void;
}

const ExchangeShiftOptions: FC<ExchangeShiftOptionsProps> = ({
    onOfferShiftClick,
    onChooseEmployeeClick,
}) => (
    <div className="exchange-shift-options">
        <h1 className="exchange-shift-options__title">
            {translate('pages.exchanges.chooseExchangeMethod')}
        </h1>
        <ExchangeButton
            text={translate('pages.exchanges.offerShift')}
            onClick={onOfferShiftClick}
        />
        <ExchangeButton
            text={translate('pages.exchanges.chooseEmployee')}
            onClick={onChooseEmployeeClick}
        />
    </div>
)

export default ExchangeShiftOptions;
