import React from 'react';
import PropTypes from 'prop-types';
import { getUserFullName } from '../../../helpers/getUserFullName';
import './Comment.scss';

const Comment = ({ comment }) => {
    const userFullName = getUserFullName(comment.owner);

    return (
        <tr className="comment">
            <td>
                {userFullName ? `${userFullName}: ` : ''}
                {comment.body}
            </td>
        </tr>
    );
}

Comment.propTypes = {
    comment: PropTypes.object.isRequired,
};

export default Comment;
