import { all } from 'redux-saga/effects';
import authenticationWatcher from './authentication/authenticationSaga';
import tracksWatcher from './tracks/tracksSaga';
import trackWatcher from './track/trackSaga';
import shiftsWatcher from './shifts/shiftsSaga';
import shiftWatcher from './shift/shiftSaga';
import exchangesWatcher from './exchanges/exchangesSaga';
import appWatcher from './app/appSaga';
import appTsWatcher from './app-ts/appSaga';
import absencesWatcher from './absences/absencesSaga';
import absenceWatcher from './absence/absenceSaga';
import userWatcher from './user/userSaga';
import feedbackWatcher from './feedback/feedbackSaga';
import registrationWatcher from './registration/registrationSaga';
import documentsTsWatcher from './documents-ts/documentsSaga';
import preferenceWatcher from './preferences/preferenceSaga';
import absencesTsWatcher from './absences-ts/absencesSaga';

export default function* rootSaga() {
    yield all([
        appWatcher(),
        appTsWatcher(),
        authenticationWatcher(),
        tracksWatcher(),
        trackWatcher(),
        shiftsWatcher(),
        shiftWatcher(),
        exchangesWatcher(),
        absencesWatcher(),
        absencesTsWatcher(),
        absenceWatcher(),
        userWatcher(),
        feedbackWatcher(),
        registrationWatcher(),
        documentsTsWatcher(),
        preferenceWatcher(),
    ]);
}
