export enum ToastType {
    fail = 'fail',
    neutral = 'neutral',
    pass = 'pass',
    updateApp = 'update-app',
    warning = 'warning',
}

export interface Toast {
    createdOn?: number;
    text: string;
    type: ToastType;
    shouldFadeOutAutomatically?: boolean;
}
