import { UserDocumentViewModel } from '../../models';
import authorizedGetFile from '../../helpers/file/authorizedGetFile';

export default function downloadUserDocument(userDocument: UserDocumentViewModel) {
    const {
        id,
        isDataUrl,
        fileName,
        downloadUrl,
    } = userDocument;

    if (isDataUrl && id) {
        const url = `${process.env.REACT_APP_API_URL}/files/download/${id}`;
        authorizedGetFile(url, fileName);
    } else if (!isDataUrl) {
        authorizedGetFile(downloadUrl, fileName);
    }
}
