import axios from 'axios';
import { ApiResponse } from '../../models/FetchResult';
import { UserResource, UserViewModel } from '../../models/User';
import transformUserResource from '../../services/UserService/transformUserResource';

export const getProfileOfCurrentUser = async (includes: string[] = []): Promise<UserViewModel> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/profile`,
        {
            params: {
                include: includes.join(','),
            },
        },
    ) as unknown as ApiResponse<UserResource>;

    return transformUserResource(response.data);
};
