import { History } from 'history';
import {
    EXCHANGE_SHIFTS_SAGA_ACCEPT_SHIFT_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_CANCEL_SHIFT_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_GET_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGES,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_BY_ME,
    EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_FOR_ME,
    EXCHANGE_SHIFTS_SAGA_SET_EXCHANGE,
    EXCHANGE_SHIFTS_SAGA_SET_TAB,
    EXCHANGE_SHIFTS_SAGA_SUBMIT_SHIFT_EXCHANGE,
} from '../actionTypes';
import {
    AcceptShiftExchangeAction,
    CancelShiftExchangeAction,
    SubmitShiftExchangeAction,
} from '../exchanges-ts/exchangesModels';

export const getExchangesOfferedByMe = () => (
    { type: EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_BY_ME });
export const getExchangesOfferedForMe = () => (
    { type: EXCHANGE_SHIFTS_SAGA_GET_SHIFTS_OFFERED_FOR_ME });
export const getExchange = (exchange: string) => ({ type: EXCHANGE_SHIFTS_SAGA_GET_EXCHANGE, exchange });
export const setExchange = (exchange: string) => ({ type: EXCHANGE_SHIFTS_SAGA_SET_EXCHANGE, exchange });
export const setExchangeTab = (tab: string, history: History) => (
    { type: EXCHANGE_SHIFTS_SAGA_SET_TAB, tab, history }
);
export const getShiftExchanges = () => ({ type: EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGES });
export const getShiftExchange = (exchangeId: string) => ({ type: EXCHANGE_SHIFTS_SAGA_GET_SHIFT_EXCHANGE, exchangeId });
export const cancelShiftExchange = (
    exchangeId: string,
    history: History,
    forMe?: boolean,
): CancelShiftExchangeAction => (
    {
        type: EXCHANGE_SHIFTS_SAGA_CANCEL_SHIFT_EXCHANGE,
        exchangeId,
        history,
        forMe,
    }
);
export const acceptShiftExchange = (
    exchangeId: string,
    history: History,
): AcceptShiftExchangeAction => (
    {
        type: EXCHANGE_SHIFTS_SAGA_ACCEPT_SHIFT_EXCHANGE,
        exchangeId,
        history,
    }
);
export const submitShiftExchange = (
    shiftPlanningId: string,
    shiftPlanning2Id: string,
    history: History,
): SubmitShiftExchangeAction => (
    {
        type: EXCHANGE_SHIFTS_SAGA_SUBMIT_SHIFT_EXCHANGE,
        shiftPlanningId,
        shiftPlanning2Id,
        history,
    }
)
