/* eslint-disable max-len */
import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconChevronLeft: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg className={`icon-component ${className}`} viewBox="0 0 14 14">
        <path d="M5.52,7l5.3,5.74L9.66,14L3.18,7l6.48-7l1.16,1.26l-4.5,4.86L5.52,7z" />
    </svg>
);

export default IconChevronLeft;
/* eslint-enable max-len */
