import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../../redux/app/appActions';
import { translate } from '../../../helpers/translations/translator';
import trans, { getLanguage } from '../../../@paco/helpers/trans';
import { LanguageIcon, Logo } from '../../../@paco/components';
import { CollapsibleNavigation } from '../../../@paco/compositions';
// Import must be direct for the order of styling
import IconButton from '../../../@paco/compositions/@buttons/IconButton/IconButton';

import './Navigation.scss';

const lang = getLanguage();

class Navigation extends Component {
    state = {
        collapsed: true,
        hasLanguageParams: false,
    };

    componentDidMount() {
        const { search } = this.props.location;
        const params = new URLSearchParams(search);

        // If the app gets language from location it means it's embedded in the ValkWorks app,
        // this means we don't have to show the language in the navigation
        if (params.has('language')) {
            this.setState({
                hasLanguageParams: true,
            })
        }
    }

    navItemHeaders = [
        translate('nav.tracks.link'),
        translate('nav.shifts.link'),
        translate('nav.exchanges.link'),
        translate('nav.absences.link'),
        translate('nav.documents.link'),
        translate('nav.planningPreferences.link'),
        translate('nav.profile.link'),
    ];

    navItems = {
        [translate('nav.tracks.link')]: {
            label: translate('nav.tracks.label'),
            link: `/${translate('nav.tracks.link')}/`,
            children: [],
        },
        [translate('nav.shifts.link')]: {
            label: translate('nav.shifts.label'),
            link: `/${translate('nav.shifts.link')}/`,
            children: [
                {
                    label: translate('nav.shifts.myShifts.label'),
                    link: translate('nav.shifts.myShifts.link'),
                },
                {
                    label: translate('nav.shifts.openShifts.label'),
                    link: translate('nav.shifts.openShifts.link'),
                },
            ],
        },
        [translate('nav.exchanges.link')]: {
            label: translate('nav.exchanges.label'),
            link: `/${translate('nav.exchanges.link')}/`,
            children: [
                {
                    label: translate('nav.exchanges.myExchanges.label'),
                    link: translate('nav.exchanges.myExchanges.link'),
                },
                {
                    label: translate('nav.exchanges.offeredExchanges.label'),
                    link: translate('nav.exchanges.offeredExchanges.link'),
                },
            ],
        },
        [translate('nav.absences.link')]: {
            label: translate('nav.absences.label'),
            link: `/${translate('nav.absences.link')}/`,
            children: [
                {
                    label: translate('nav.absences.leaveOfAbsenceOverview.label'),
                    link: translate('nav.absences.leaveOfAbsenceOverview.link'),
                },
                {
                    label: translate('nav.absences.loketHoursPerPeriod.label'),
                    link: translate('nav.absences.loketHoursPerPeriod.link'),
                },
                {
                    label: translate('nav.absences.leaveOfAbsenceRequests.label'),
                    link: translate('nav.absences.leaveOfAbsenceRequests.link'),
                },
                {
                    label: translate('nav.absences.absences.label'),
                    link: translate('nav.absences.absences.link'),
                },
                {
                    label: translate('nav.absences.createAbsences.label'),
                    link: translate('nav.absences.createAbsences.link'),
                },
            ],
        },
        [translate('nav.documents.link')]: {
            label: translate('nav.documents.label'),
            link: `/${translate('nav.documents.link')}/`,
            children: [],
        },
        [translate('nav.planningPreferences.link')]: {
            label: translate('nav.planningPreferences.label'),
            link: `/${translate('nav.planningPreferences.link')}/`,
            children: [],
        },
        [translate('nav.profile.link')]: {
            label: translate('nav.profile.label'),
            link: `/${translate('nav.profile.link')}/`,
            children: [],
        },
        [translate('nav.language.link')]: {
            label: (
                <>
                    <LanguageIcon locale={lang.code} />
                    {trans(`common.language.${lang.code}`)}
                </>
            ),
            link: `/${translate('nav.language.link')}/`,
            children: [],
        },
    };

    toggleNavBar = () => {
        const { collapsed } = this.state;
        this.setState({
            collapsed: !collapsed,
        });
    };

    logout = () => {
        this.props.dispatch(logout());
    };

    onButtonBackClick = () => {
        this.props.history.goBack();
        this.setState({
            collapsed: true,
        });
    }

    render() {
        const navItemHeaders = [
            ...this.navItemHeaders,
            ...(!this.state.hasLanguageParams ? [translate('nav.language.link')] : []),
        ];

        return (
            <div className="navigation">
                <div className="navigation__top">
                    <IconButton
                        hideLabel
                        icon="arrow"
                        text={trans('common.back')}
                        onClick={this.onButtonBackClick}
                        className="navigation__back-button"
                        iconClassName="navigation__back-button-icon"
                    />
                    <NavLink to="/">
                        <Logo isBlue className="navigation__brand" />
                    </NavLink>
                    <IconButton
                        hideLabel
                        icon="hamburger"
                        text="hamburger"
                        onClick={() => this.toggleNavBar()}
                        className="navigation__toggle-button"
                        iconClassName="navigation__toggle-button-icon"
                    />
                </div>
                <CollapsibleNavigation
                    isActive={!this.state.collapsed}
                    navItems={this.navItems}
                    navItemsHeaders={navItemHeaders}
                    onLogoutClick={this.logout}
                    onNavLinkClick={this.toggleNavBar}
                />
            </div>
        );
    }
}

Navigation.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(connect()(Navigation));
