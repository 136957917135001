import {
    all, put, select, takeLatest,
} from 'redux-saga/effects';

import { getAbsencesForMe, getLeaveOfAbsencesForMe } from '../../helpers/api-ts/absencesApi';
import getHoursFromLeaveOfAbsences from '../../services/LeaveOfAbsenceHourService/getHoursFromLeaveOfAbsences';
import getHoursFromAbsences from '../../services/AbsenceService/getHoursFromAbsences';
import {
    ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_FAILURE,
    ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_REQUEST,
    ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_SUCCESS,
    ABSENCES_SAGA_GET_USER_WITH_EMPLOYEE_BALANCES,
    ABSENCES_GET_CURRENT_PAYROLL_PERIOD_FAILURE,
    ABSENCES_GET_CURRENT_PAYROLL_PERIOD_REQUEST,
    ABSENCES_GET_CURRENT_PAYROLL_PERIOD_SUCCESS,
    ABSENCES_SAGA_GET_CURRENT_PAYROLL_PERIOD,
    ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_REQUEST,
    ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_SUCCESS,
    ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_FAILURE,
    ABSENCES_SAGA_GET_EMPLOYEE_LOKET_BALANCES,
} from '../actionTypes';
import { EmployeeBalanceType, PayrollPeriod } from '../../models';
import { LeaveType } from '../../models/LeaveOfAbsenceHours';
import getTotalBillableTimeFromTracks from '../../services/TrackService/getTotalBillableTimeFromTracks';
import { getTracksForMe } from '../../helpers/api-ts/tracksApi';
import { getProfileOfCurrentUser } from '../../helpers/api-ts/userApi';
import { GetEmployeeLoketBalances, GetUserWithEmployeeBalancesAction } from './absencesModel';
import { getLoketLeaveBalance } from '../../helpers/api-ts/loketLeaveBalance';
import { doesDateOverlapWithPeriod } from '../../@paco/entities/Period/PeriodHelpers';
import { Period } from '../../@paco/entities/Period/Period';
import { differenceInDays } from '../../@paco/helpers/date';


export function* getUserWithEmployeeBalances(action: GetUserWithEmployeeBalancesAction): any {
    yield put({ type: ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_REQUEST });

    try {
        const { payrollPeriod } = action;
        const startDate = payrollPeriod.start;
        const endDate = payrollPeriod.end.date;
        const payrollPeriodId = payrollPeriod.id;

        const [
            absences,
            leaveOfAbsences,
            tracks,
            currentUser,
        ] = yield all([
            getAbsencesForMe(
                ['absenceHours', 'absenceHours.payrollPeriod'],
                startDate,
                endDate,
            ),
            getLeaveOfAbsencesForMe(
                ['leaveOfAbsenceHours', 'leaveOfAbsenceHours.payrollPeriod'],
                startDate,
                endDate,
            ),
            getTracksForMe(
                [],
                startDate,
                endDate,
            ),
            getProfileOfCurrentUser(
                [],
            ),
        ]);

        const absenceHours = getHoursFromAbsences(absences, payrollPeriodId);
        const loaHours = getHoursFromLeaveOfAbsences(leaveOfAbsences, payrollPeriodId);

        const vacancyLoaHours = getHoursFromLeaveOfAbsences(leaveOfAbsences, payrollPeriodId, [LeaveType.normal]);
        const specialLoaHours = getHoursFromLeaveOfAbsences(leaveOfAbsences, payrollPeriodId, [LeaveType.special]);
        const tvtLoaHours = getHoursFromLeaveOfAbsences(leaveOfAbsences, payrollPeriodId, [LeaveType.tvt]);

        const workedHours = getTotalBillableTimeFromTracks(tracks);

        const contractHoursForPeriod = currentUser.contractHours * 4;
        const tvtMutationHours = loaHours + workedHours + absenceHours - contractHoursForPeriod;

        const employeeBalanceValues: EmployeeBalanceType = {
            absenceHours,
            specialLoaHours,
            tvtLoaHours,
            tvtMutationHours,
            loaHours,
            vacancyLoaHours,
            workedHours,
        };

        yield put({
            type: ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_SUCCESS,
            employeeBalanceValues,
        });
    } catch (error) {
        yield put({ type: ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_FAILURE, error });
    }
}

export function* getCurrentPayrollPeriod(): any {
    yield put({ type: ABSENCES_GET_CURRENT_PAYROLL_PERIOD_REQUEST });

    try {
        const state = yield select();
        const { payrollPeriods } = state.appReducer;

        const currentDate = new Date();
        const matchingPayrollPeriod = payrollPeriods
            .find((payrollPeriod: PayrollPeriod) => doesDateOverlapWithPeriod(currentDate, { start: payrollPeriod.start, end: payrollPeriod.end.date } as Period));

        const sortedPayrollPeriods = [...payrollPeriods].sort(
            (payrollPeriodA: PayrollPeriod, payrollPeriodB: PayrollPeriod) => {
                if (Math.abs(differenceInDays(payrollPeriodB.start, currentDate))
                    < Math.abs(differenceInDays(payrollPeriodA.start, currentDate))) {
                    return 1;
                }

                return -1;
            },
        );

        yield put({
            type: ABSENCES_GET_CURRENT_PAYROLL_PERIOD_SUCCESS,
            currentPayrollPeriod: matchingPayrollPeriod || sortedPayrollPeriods[0],
        });
    } catch (error) {
        console.error(error);
        yield put({ type: ABSENCES_GET_CURRENT_PAYROLL_PERIOD_FAILURE });
    }
}

export function* getEmployeeLoketBalances(action: GetEmployeeLoketBalances) : any {
    yield put({ type: ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_REQUEST });

    try {
        const { loketEmployeeId } = action;
        const loketLeaveBalance = yield getLoketLeaveBalance(loketEmployeeId);

        yield put({ type: ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_SUCCESS, loketLeaveBalance });
    } catch (error) {
        yield put({ type: ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_FAILURE });
    }
}

export default function* absencesTsWatcher() {
    yield takeLatest(ABSENCES_SAGA_GET_USER_WITH_EMPLOYEE_BALANCES, getUserWithEmployeeBalances);
    yield takeLatest(ABSENCES_SAGA_GET_CURRENT_PAYROLL_PERIOD, getCurrentPayrollPeriod);
    yield takeLatest(ABSENCES_SAGA_GET_EMPLOYEE_LOKET_BALANCES, getEmployeeLoketBalances);
}

