import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';

import {
    getExchangesOfferedByMe,
    getExchangesOfferedForMe,
    getShiftExchanges,
    setExchangeTab,
} from '../../redux/exchanges/exchangesActions';
import ZeroState from '../../components/Helpers/ZeroState';
import ExchangeListItem from '../../components/ExchangeListItem/ExchangeListItem';
import ShiftExchangeListItem from '../../components/ShiftExchangeListItem/ShiftExchangeListItem';
import { MY_EXCHANGES, OFFERED_EXCHANGES } from '../../constants';
import { translate } from '../../helpers/translations/translator';
import { isTabDifferentThanActiveTab } from '../../helpers/tools';
import { InfoListItem, LoadingSpinner } from '../../@paco/components';
import { TabButtons } from '../../@paco/compositions';

import './Exchanges.scss';

class Exchanges extends Component {
    componentDidMount() {
        const { activeTab, tab } = this.props;
        if (!activeTab) {
            this.toggle(tab);
        }

        this.props.dispatch(getExchangesOfferedByMe());
        this.props.dispatch(getExchangesOfferedForMe());
        this.props.dispatch(getShiftExchanges());
    }

    componentDidUpdate(prevProps) {
        const { activeTab, location } = this.props;
        isTabDifferentThanActiveTab(
            location, prevProps.location, activeTab, translate('nav.exchanges.myExchanges.link'),
            MY_EXCHANGES, OFFERED_EXCHANGES, this.toggle,
        );
    }

    toggle = (tab, pushHistory) => {
        const { history } = this.props;

        if (this.props.activeTab !== tab) {
            this.props.dispatch(setExchangeTab(tab, pushHistory && history));
        }
    }

    handleTabButtonClick = (newIndex) => {
        if (newIndex === 0) {
            this.toggle(MY_EXCHANGES, true);

            return;
        }

        this.toggle(OFFERED_EXCHANGES, true);
    }

    getShiftsOfferedByMe = () => {
        const {
            currentUser,
            shiftsOfferedByMe,
            settings,
            shiftExchanges,
        } = this.props;
        const shiftExchangeByMe = shiftExchanges
            .filter(shiftExchange => shiftExchange.shiftPlanning1.user.id === currentUser.id);
        const exchanges = [...shiftsOfferedByMe, ...shiftExchangeByMe];

        if (!exchanges.length) {
            return (
                <ZeroState text={translate('pages.exchanges.noExchangesFound')} />
            );
        }

        return exchanges.map(exchange => {
            if (exchange.type === 'shift-exchanges') {
                return (
                    <ShiftExchangeListItem
                        key={exchange.id}
                        settings={settings}
                        exchange={exchange}
                        onClick={() => this.props.history.push(`/${translate('nav.exchanges.shiftExchange.link')}/${exchange.id}`)}
                        userId={currentUser.id}
                    />
                );
            }

            return (
                <ExchangeListItem
                    byMe
                    settings={settings}
                    key={exchange.id}
                    exchange={exchange}
                    onClick={() => this.props.history.push(`/${translate('nav.exchanges.link')}/${exchange.id}`)}
                />
            )
        });
    };

    getShiftsOfferedForMe = () => {
        const {
            shiftsOfferedForMe,
            shiftExchanges,
            settings,
            currentUser,
        } = this.props;
        const shiftExchangeForMe = shiftExchanges
            .filter(shiftExchange => shiftExchange.shiftPlanning2.user.id === currentUser.id);

        if (!shiftsOfferedForMe.length && !shiftExchangeForMe.length) {
            return (
                <ZeroState text={translate('pages.exchanges.noExchangesFound')} />
            );
        }

        return (
            <>
                { !!shiftExchangeForMe.length && (
                    <InfoListItem label={translate('common.forYou')} />
                )}
                {
                    shiftExchangeForMe.map(exchange => (
                        <ShiftExchangeListItem
                            key={exchange.id}
                            settings={settings}
                            exchange={exchange}
                            onClick={() => this.props.history.push(`/${translate('nav.exchanges.shiftExchange.link')}/${exchange.id}`)}
                            userId={currentUser.id}
                        />
                    ))
                }
                { !!shiftsOfferedForMe.length && (
                    <InfoListItem label={translate('common.vacant')} />
                )}
                {
                    shiftsOfferedForMe.map(exchange => (
                        <ExchangeListItem
                            byMe={false}
                            settings={settings}
                            key={exchange.id}
                            exchange={exchange}
                            onClick={() => this.props.history.push(`/${translate('nav.exchanges.link')}/${exchange.id}`)}
                        />
                    ))
                }
            </>
        );
    };

    render() {
        const { currentUser, loading } = this.props;

        if (!currentUser) {
            return null;
        }

        return (
            <>
                <TabButtons
                    activeIndex={this.props.activeTab === MY_EXCHANGES ? 0 : 1}
                    buttonLabels={[
                        translate('pages.exchanges.exchangesOfferedByMe'),
                        translate('pages.exchanges.exchangesOfferedByOthers'),
                    ]}
                    onButtonClick={this.handleTabButtonClick}
                />
                <TabContent activeTab={this.props.activeTab}>
                    <TabPane tabId={MY_EXCHANGES}>
                        <div className="exchanges__row">
                            <div className="exchanges__col">
                                <ul>
                                    {this.getShiftsOfferedByMe()}
                                </ul>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId={OFFERED_EXCHANGES}>
                        <div className="exchanges__row">
                            <div className="exchanges__col">
                                <ul>
                                    {this.getShiftsOfferedForMe()}
                                </ul>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
                {loading && (
                    <LoadingSpinner />
                )}
            </>
        );
    }
}

Exchanges.propTypes = {
    dispatch: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    tab: PropTypes.string.isRequired,
    shiftsOfferedByMe: PropTypes.array.isRequired,
    shiftsOfferedForMe: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    settings: PropTypes.array,
};

Exchanges.defaultProps = {
    activeTab: null,
    settings: [],
};

const mapStateToProps = state => ({
    shiftsOfferedByMe: state.exchangesReducer.shiftsOfferedByMe,
    shiftsOfferedForMe: state.exchangesReducer.shiftsOfferedForMe,
    shiftExchanges: state.exchangesReducer.shiftExchanges,
    currentUser: state.userReducer.currentUser,
    activeTab: state.exchangesReducer.activeTab,
    settings: state.userReducer.settings,
    errors: state.tracksReducer.errors,
    loading: state.appReducer.loading,
});

export default withRouter(connect(mapStateToProps)(Exchanges));
