import axios from 'axios';
import { setAppError } from '../redux/app/appActions';
import { getObjProperty } from '../helpers/tools';
import { ToastType } from '../@paco/types/toastTypes';
import { setToast } from '../@paco/redux/toasts/toastsReducer';

// After upgrading to Symphony 4.3 the API wouldn't accept any patch calls with a slash
// at the end. This interceptor will fix that
function removeSlashAtEndOfUrl(url) {
    if (url.charAt(url.length - 1) === '/') {
        return url.substr(0, url.length - 1);
    }
    return url;
}

export default class AppInterceptor {
    constructor(store) {
        this.store = store;
    }

    setInterceptors = () => {
        axios.interceptors.request.use((config) => {
            config.url = removeSlashAtEndOfUrl(config.url);
            return config;
        });

        // Error code handling
        axios.interceptors.response.use(
            response => response,
            (error) => {
                const { dispatch } = this.store;

                if (error.response.status === 500) {
                    dispatch(setAppError({
                        code: 500,
                        message: error.response.data.meta,
                    }));
                }

                if (error.response.status === 422) {
                    const errors = getObjProperty(error, 'response.data.errors');
                    const detail = (errors && errors[0]) ? errors[0].detail : '';
                    dispatch(setToast({
                        text: detail,
                        type: ToastType.fail,
                    }));
                }

                return Promise.reject(error);
            },
        );
    }
}
