import {
    APP_LOGOUT, CURRENT_USER_EDIT_FAILURE, CURRENT_USER_EDIT_REQUEST,
    CURRENT_USER_EDIT_SUCCESS,
    CURRENT_USER_FAILURE,
    CURRENT_USER_REQUEST,
    CURRENT_USER_SUCCESS,
} from '../actionTypes';

const initialState = {
    currentUser: undefined,
    error: undefined,
    loading: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
    case CURRENT_USER_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case CURRENT_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            currentUser: action.user,
            permissions: action.permissions,
            settings: action.settings,
        };
    case CURRENT_USER_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.error,
            currentUser: undefined,
            settings: [],
        };
    case CURRENT_USER_EDIT_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case CURRENT_USER_EDIT_SUCCESS:
        return {
            ...state,
            currentUser: action.user,
            loading: false,
        };
    case CURRENT_USER_EDIT_FAILURE:
        return {
            ...state,
            loading: false,
        };
    case APP_LOGOUT:
        return { ...initialState };
    default:
        return state;
    }
};

export default userReducer;
