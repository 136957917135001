import React from 'react';
import PropTypes from 'prop-types';
import './UserColor.scss';

function UserColor({ color, className }) {
    return (
        <div
            className={`user-color ${className}`}
            style={color ? { background: `#${color}` } : {}}
        />
    );
}

UserColor.propTypes = {
    color: PropTypes.string.isRequired,
    className: PropTypes.string,
};

UserColor.defaultProps = {
    className: '',
};

export default UserColor;
