import {
    DOCUMENTS_GET_DOCUMENTS_FOR_ME_SUCCESS,
    DOCUMENTS_FAILURE,
    DOCUMENTS_REQUEST, APP_LOGOUT,
} from '../actionTypes';

const initialState = {
    documents: [],
    loading: false,
    errors: [],
};

const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
    case DOCUMENTS_REQUEST:
        return { ...state, loading: true };
    case DOCUMENTS_GET_DOCUMENTS_FOR_ME_SUCCESS:
        return {
            ...state, loading: false, documents: action.documents,
        };
    case DOCUMENTS_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    case APP_LOGOUT:
        return { ...initialState };
    default:
        return state;
    }
};

export default documentsReducer;
