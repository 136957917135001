import React, { FC, ReactElement } from 'react';
import ListGroupItem from '../ListGroupItem/ListGroupItem';

import './InfoListBlock.scss';

interface InfoListBlockProps {
    label: string;
    className?: string;
}

const InfoListBlock: FC<InfoListBlockProps> = ({ label, className = '', children }): ReactElement => {
    return (
        <ListGroupItem className={`info-list-block ${className}`}>
            <div className="info-list-block__label">{label}</div>
            {children}
        </ListGroupItem>
    );
}

export default InfoListBlock;
