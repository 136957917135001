export const getLoketApi = (hostname: string, apiUrl?: string): string | undefined => {
    if (hostname.indexOf('valkplanner.nl') !== -1) {
        return `${apiUrl}`.replace('*', hostname.split('.')[0]);
    }

    if (hostname.indexOf('cdemo.dev') !== -1) {
        return `${apiUrl}`.replace('*', hostname.split('-')[0]);
    }

    return apiUrl;
}

export const getLoketApiUrl = (): string | undefined => getLoketApi(
    document.location.hostname,
    process.env.REACT_APP_LOKET_API_URL,
);
