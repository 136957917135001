import React, { FC, ReactElement } from 'react';
import { Card } from '../../components';
import { convertDateToDay, convertDateToMonth, convertDateToMonthDay } from '../../../helpers/DateTimeParser';
import { CardColor } from '../../types/cardColor';

import './DateCard.scss';

interface DateCardProps {
    color?: CardColor;
    date: Date;
    className?: string;
}

const DateCard: FC<DateCardProps> = ({ color, date, className = '' }): ReactElement => (
    <Card color={color} className={`date-card ${className}`}>
        <div className="date-card__weekday">{convertDateToDay(date).toLowerCase()}</div>
        <div className="date-card__date">{convertDateToMonthDay(date)}</div>
        <div className="date-card__month">{convertDateToMonth(date)}</div>
    </Card>
);

export default DateCard;
