import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cancelLeaveOfAbsence, getLeaveOfAbsence } from '../../redux/absence/absenceActions';
import { Button, Card, InfoListItem } from '../../@paco/components';
import { addDays, differenceInDays } from '../../@paco/helpers/date';
import { convertDateToDateYearString } from '../../helpers/DateTimeParser';
import LeaveOfAbsence from '../../models/LeaveOfAbsence';
import { translate } from '../../helpers/translations/translator';
import { getUserFullName } from '../../helpers/getUserFullName';
import { ABSENCE_STATUS_DENIED, ABSENCE_STATUS_OPEN } from '../../constants';

import './AbsenceDetail.scss';

class AbsenceDetail extends Component {
    componentWillMount() {
        const { match, dispatch } = this.props;

        if (match.params.absenceUuid) {
            dispatch(getLeaveOfAbsence(match.params.absenceUuid));
        }
    }

    getContent = () => {
        const { leaveOfAbsence, dispatch, history } = this.props;
        const leaveOfAbsenceObject = new LeaveOfAbsence(leaveOfAbsence);

        switch (leaveOfAbsenceObject.getStatus()) {
        case ABSENCE_STATUS_OPEN: {
            return (
                <Button
                    onClick={() => dispatch(cancelLeaveOfAbsence(leaveOfAbsence, history))}
                    className="absence-detail__cancel-button"
                >
                    {translate('pages.absences.revokeRequest')}
                </Button>
            );
        }
        default: {
            return null;
        }
        }
    };

    renderDeclineComments = (leaveOfAbsence, leaveOfAbsenceObject) => {
        if ((!leaveOfAbsence.comments || !leaveOfAbsence.comments.length)
            || leaveOfAbsenceObject.getStatus() !== ABSENCE_STATUS_DENIED) {
            return null;
        }

        return (
            <ul className="absence-detail__list">
                <InfoListItem label={translate('pages.absences.reasonOfRejection')}>
                    { leaveOfAbsence.comments[leaveOfAbsence.comments.length - 1].body }
                </InfoListItem>
            </ul>
        );
    };

    render() {
        const { leaveOfAbsence } = this.props;

        if (!leaveOfAbsence) {
            return (
                <Card className="absence-detail__header" />
            );
        }

        const leaveOfAbsenceObject = new LeaveOfAbsence(leaveOfAbsence);
        const { reviewedByUser } = leaveOfAbsence;
        const daysBetween = differenceInDays(leaveOfAbsence.end.date, leaveOfAbsence.start);

        return leaveOfAbsence ? (
            <>
                <Card
                    color={leaveOfAbsenceObject.getColor()}
                    className="absence-detail__header"
                />
                <ul className="absence-detail__list">
                    <InfoListItem label={translate('pages.absences.requestedOn')}>
                        {convertDateToDateYearString(leaveOfAbsence.createdAt)}
                    </InfoListItem>
                    <InfoListItem label={translate('common.startDate')}>
                        {convertDateToDateYearString(leaveOfAbsence.start)}
                    </InfoListItem>
                    <InfoListItem label={translate('common.endDate')}>
                        {convertDateToDateYearString(addDays(leaveOfAbsence.start, daysBetween))}
                    </InfoListItem>
                </ul>
                <ul className="absence-detail__list">
                    <InfoListItem label={translate('pages.absences.reasonRequest')}>
                        {leaveOfAbsence.reason}
                    </InfoListItem>
                </ul>
                <ul className="absence-detail__list">
                    <InfoListItem label={translate('common.status')}>
                        {leaveOfAbsenceObject.getLabel()}
                    </InfoListItem>
                    { reviewedByUser && (
                        <InfoListItem label={translate('common.reviewedBy')}>
                            {getUserFullName(reviewedByUser)}
                        </InfoListItem>
                    )}
                </ul>
                {this.renderDeclineComments(leaveOfAbsence, leaveOfAbsenceObject)}
                {this.getContent()}
            </>
        ) : <div />;
    }
}

AbsenceDetail.propTypes = {
    leaveOfAbsence: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

AbsenceDetail.defaultProps = {
    leaveOfAbsence: null,
};

function mapStateToProps(state) {
    return {
        leaveOfAbsence: state.absenceReducer.leaveOfAbsence,
    };
}

export default withRouter(connect(mapStateToProps)(AbsenceDetail));
