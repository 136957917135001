import {
    LEAVE_OF_ABSENCES_REQUEST,
    LEAVE_OF_ABSENCES_SUCCESS,
    LEAVE_OF_ABSENCES_FAILURE,
    ABSENCES_REQUEST,
    ABSENCES_SUCCESS,
    ABSENCES_FAILURE,
    ABSENCES_SET_TAB,
    APP_LOGOUT,
    ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_REQUEST,
    ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_SUCCESS,
    ABSENCES_GET_CURRENT_PAYROLL_PERIOD_SUCCESS,
    ABSENCES_GET_CURRENT_PAYROLL_PERIOD_FAILURE,
    ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_SUCCESS,
    ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_REQUEST,
} from '../actionTypes';

const initialState = {
    activeTab: null,
    leaveOfAbsences: [],
    absences: [],
    loading: false,
    errors: [],
    employeeBalanceValues: null,
    employeeLoketLeaveBalance: null,
    currentPayrollPeriod: null,
};

const absencesReducer = (state = initialState, action) => {
    switch (action.type) {
    case LEAVE_OF_ABSENCES_REQUEST:
    case ABSENCES_REQUEST:
    case ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_REQUEST:
    case ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_REQUEST:
        return { ...state, loading: true };
    case LEAVE_OF_ABSENCES_SUCCESS:
        return {
            ...state, loading: false, leaveOfAbsences: action.leaveOfAbsences,
        };
    case ABSENCES_SUCCESS:
        return {
            ...state, loading: false, absences: action.absences,
        };
    case ABSENCES_GET_USER_WITH_EMPLOYEE_BALANCES_SUCCESS:
        return {
            ...state, loading: false, employeeBalanceValues: action.employeeBalanceValues,
        }
    case ABSENCES_GET_CURRENT_PAYROLL_PERIOD_SUCCESS:
        return {
            ...state, currentPayrollPeriod: action.currentPayrollPeriod,
        };
    case ABSENCES_GET_EMPLOYEE_LOKET_BALANCES_SUCCESS:
        return {
            ...state, employeeLoketLeaveBalance: action.loketLeaveBalance, loading: false,
        }
    case ABSENCES_SET_TAB:
        return {
            ...state, activeTab: action.tab,
        };
    case ABSENCES_GET_CURRENT_PAYROLL_PERIOD_FAILURE:
    case LEAVE_OF_ABSENCES_FAILURE:
    case ABSENCES_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    case APP_LOGOUT:
        return { ...initialState };
    default:
        return state;
    }
};

export default absencesReducer;
