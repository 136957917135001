import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';

import ExchangeShiftOptions from '../../containers/ExchangeShiftOptions/ExchangeShiftOptions';
import { offerShift } from '../../redux/shift/shiftActions';
import { Reducers } from '../../redux/reducers';
import ExchangeShiftForm from '../../containers/ExchangeShiftForm/ExchangeShiftForm';
import { ShiftViewModel, ColleagueViewModel, DepartmentResource } from '../../models';
import getShifts, { getShift } from '../../helpers/api-ts/shiftsApi';
import { getColleagues } from '../../helpers/api-ts/colleagues';
import { submitShiftExchange } from '../../redux/exchanges/exchangesActions';
import filterShiftsByDepartments from '../../services/ShiftService/filterShiftsByDepartments';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../@paco/helpers/date';

const ConnectedExchangeShift: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<{ shiftUuid: string }>();
    const { currentUser, settings } = useSelector((state: Reducers) => state.userReducer);
    const { loading: storeLoading } = useSelector((state: Reducers) => state.exchangesReducer);

    const [showExchangeShiftForm, setShowExchangeShiftForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState<ColleagueViewModel[]>([]);
    const [shifts, setShifts] = useState<ShiftViewModel[]>([]);
    const [currentUserShiftPlanning, setCurrentUserShiftPlanning] = useState<string | null>(null);

    const getEmployees = async () => {
        setLoading(true);
        const departments = currentUser.departments.map((department: DepartmentResource) => department.id);
        const data = await getColleagues(['employee'], departments, ['departments']);
        setEmployees(data.filter(colleague => colleague.id !== currentUser.id));
        setLoading(false);
    }

    const getShiftsByUserIdAndDate = async (userId: string, date: Date) => {
        const userDepartments = currentUser.departments.map((department: DepartmentResource) => department.id);
        const startDate = getStartOfWorkDay(date);
        const endDate = getEndOfWorkDay(date);
        setLoading(true);
        const data = await getShifts(
            [userId],
            userDepartments,
            startDate,
            endDate,
            [
                'shiftPlannings',
                'shiftPlannings.user',
                'department',
                'department.group',
            ],
        );

        const filteredShifts = filterShiftsByDepartments(data, userDepartments);
        setShifts(filteredShifts);
        setLoading(false);
    }

    const getCurrentUserShift = async (shiftId: string, userId: string) => {
        const data = await getShift(
            shiftId,
            ['shiftPlannings', 'shiftPlannings.user'],
        );

        const userShiftPlanning = data?.shiftPlannings
            .find(shiftPlanning => shiftPlanning?.user?.id === userId);

        if (userShiftPlanning) {
            setCurrentUserShiftPlanning(userShiftPlanning.id);
        }
    }

    useEffect(() => {
        if (currentUser) {
            getEmployees();
        }
    }, [currentUser]);

    useEffect(() => {
        if (match.params.shiftUuid && currentUser) {
            getCurrentUserShift(match.params.shiftUuid, currentUser.id);
        }
    }, [currentUser, match.params.shiftUuid]);

    const onOfferShiftClick = () => {
        if (location.pathname) {
            const id = location.pathname.split('/')[2];

            dispatch(offerShift(id, history));
        }
    }

    const onChooseEmployeeClick = () => {
        setShowExchangeShiftForm(true);
    }

    const onSelectUserAndDate = (userId: string, date: Date) => {
        getShiftsByUserIdAndDate(userId, date);
    }

    const onSubmitShiftPlanning = (shiftPlanningId: string) => {
        if (currentUserShiftPlanning) {
            dispatch(submitShiftExchange(currentUserShiftPlanning, shiftPlanningId, history));
        }
    }

    if (showExchangeShiftForm) {
        return (
            <ExchangeShiftForm
                loading={loading || storeLoading}
                employees={employees}
                shifts={shifts}
                settings={settings}
                onSubmitShiftPlanning={onSubmitShiftPlanning}
                onSelectUserAndDate={onSelectUserAndDate}
            />
        )
    }

    return (
        <ExchangeShiftOptions
            onOfferShiftClick={onOfferShiftClick}
            onChooseEmployeeClick={onChooseEmployeeClick}
        />
    );
}

export default ConnectedExchangeShift;
