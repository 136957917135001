import { User, UserFormData, UserResource } from './User';
import { generateApiUrl } from '../../helpers/url';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import { FetchResult, FetchResultType } from '../FetchResult';
import trans from '../../helpers/trans';
import { transformToUser, transformUserFormDataToEditUserProfileRequest } from './UserTransformers';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import { JapiDocument } from '../../japi/types/Document';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { RoleResource } from '../Role/Role';
import { transformToRole } from '../Role/RoleTransformers';
import getIncluded from '../../japi/getIncluded';
import { transformToPerson } from '../Person/PersonTransformers';
import { PersonResource } from '../Person/Person';

export const patchUserProfileApiCall = async (formData: UserFormData, userId: string): Promise<FetchResult<User, string>> => {
    const apiData = transformUserFormDataToEditUserProfileRequest(formData, userId);
    const includes = ['person', 'roles', 'departments'];

    try {
        const url = generateApiUrl({
            endpoint: `/users/${userId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const departmentsResource = getMultipleIncluded(doc, doc.data, 'departments') as DepartmentResource[];
        const rolesResource = getMultipleIncluded(doc, doc.data, 'roles') as RoleResource[];
        const personResource = getIncluded(doc, doc.data, 'person') as PersonResource;

        const departments = departmentsResource.map(transformToDepartment);
        const roles = rolesResource.map(transformToRole);
        const person = transformToPerson(personResource);
        const user = transformToUser(
            doc.data,
            person,
            departments,
            roles,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: user,
        };
    } catch (error) {
        console.error('[patchUserProfileApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
