import {
    ABSENCES_SAGA_SET_TAB,
    ABSENCES_SAGA_GET_ABSENCES_ME,
    ABSENCES_SAGA_GET_LEAVE_OF_ABSENCES_ME,
} from '../actionTypes';

const getLeaveOfAbsencesForMe = year => ({ type: ABSENCES_SAGA_GET_LEAVE_OF_ABSENCES_ME, year });
const getAbsencesForMe = year => ({ type: ABSENCES_SAGA_GET_ABSENCES_ME, year });
const setAbsencesTab = (tab, history) => ({ type: ABSENCES_SAGA_SET_TAB, tab, history });

export {
    getLeaveOfAbsencesForMe,
    getAbsencesForMe,
    setAbsencesTab,
};
