import React from 'react';
import PropTypes from 'prop-types';
import { convertDateToDateYearString } from '../../helpers/DateTimeParser';
import { translate } from '../../helpers/translations/translator';
import { getObjProperty } from '../../helpers/tools';
import downloadUserDocument from '../../services/UserDocumentService/downloadUserDocument';
import { ListGroupItem } from '../../@paco/components';

import './DocumentListItem.scss';

function DocumentListItem({ document, onClick, className }) {
    return (
        <ListGroupItem
            onClick={onClick}
            className={className}
        >
            <button onClick={() => downloadUserDocument(document)} type="button" className="document-list-item list-group-item__header text w-100">
                <div className="d-flex">
                    <div className="font-weight-bold">{document.displayName}</div>
                </div>
                <div className="d-flex justify-content-between w-100">
                    <div className="pr-1">
                        {translate(`pages.documents.${getObjProperty(document, 'documentType.slug')}`)}
                    </div>
                    <div className="text-right">
                        {document.createdAt
                            ? convertDateToDateYearString(document.createdAt) : null}
                    </div>
                </div>
            </button>
        </ListGroupItem>
    );
}

DocumentListItem.propTypes = {
    document: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

DocumentListItem.defaultProps = {
    onClick: () => {},
    className: '',
};

export default DocumentListItem;
