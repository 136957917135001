import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../style/Icon/Icon';
import { ListGroupItem } from '../../@paco/components';

import './ProfileListItem.scss';

function ProfileListItem({
    title,
    values,
    onClick,
}) {
    const onListGroupItemClick = () => {
        onClick(title);
    };

    return (
        <ListGroupItem
            onClick={onClick && onListGroupItemClick}
            className="profile-list-item"
        >
            <div className="profile-list-item__header list-group-item__header">
                { title }
            </div>
            { values.length > 0 && (
                <div className="profile-list-item__values">
                    { values.map((value, index) => (
                        <div key={`value-${index + 1}`} className="profile-list-item__value">
                            { value || '' }
                        </div>
                    ))}
                </div>
            )}
            { onClick && (
                <Icon
                    className="profile-list-item__icon"
                    color="orange"
                    fontSize={24}
                    kind="create"
                />
            )}
        </ListGroupItem>
    );
}

ProfileListItem.propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.array,
    onClick: PropTypes.func,
};

ProfileListItem.defaultProps = {
    onClick: null,
    values: [],
};

export default ProfileListItem;
