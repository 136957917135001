import { UserResource } from '../../models';
import { BasicUser } from '../../@paco/entities/User/User';
import { transformToBasicPerson } from '../../@paco/entities/Person/PersonTransformers';

export const transformLegacyBasicUserToBasicUser = (user: UserResource): BasicUser => ({
    id: user.id,
    employeeNumber: user.employeeNumber,
    person: transformToBasicPerson(user.firstname, user.lastname, user.affix || undefined),
    plannedHoursInWeek: user.plannedHoursInWeek || 0,
    plannedConceptHoursInWeek: user.plannedConceptHoursInWeek || 0,
});
