import {
    PREFERENCE_GET_PLANNING_PREFERENCES_REQUEST,
    PREFERENCE_GET_PLANNING_PREFERENCES_SUCCESS,
    PREFERENCE_GET_PLANNING_PREFERENCES_FAILURE,
    PREFERENCE_GET_PLANNING_PREFERENCE_SUCCESS,
    PREFERENCE_GET_PLANNING_PREFERENCE_FAILURE,
    PREFERENCE_FAILURE,
    PREFERENCE_GET_PLANNING_PREFERENCE_REQUEST,
    PREFERENCE_DEL_PLANNING_PREFERENCE_REQUEST,
    PREFERENCE_DEL_PLANNING_PREFERENCE_FAILURE,
    PREFERENCE_CREATE_PLANNING_PREFERENCE_REQUEST,
    PREFERENCE_CREATE_PLANNING_PREFERENCE_FAILURE,
} from '../actionTypes';

const initialState = {
    planningPreferences: [],
    planningPreference: null,
    loading: false,
    errors: null,
};

const preferenceReducer = (state = initialState, action) => {
    switch (action.type) {
    case PREFERENCE_GET_PLANNING_PREFERENCE_REQUEST:
    case PREFERENCE_GET_PLANNING_PREFERENCES_REQUEST:
    case PREFERENCE_DEL_PLANNING_PREFERENCE_REQUEST:
    case PREFERENCE_CREATE_PLANNING_PREFERENCE_REQUEST:
        return { ...state, loading: true };
    case PREFERENCE_GET_PLANNING_PREFERENCES_SUCCESS:
        return { ...state, planningPreferences: action.planningPreferences, loading: false };
    case PREFERENCE_GET_PLANNING_PREFERENCE_SUCCESS:
        return { ...state, planningPreference: action.planningPreference, loading: false };
    case PREFERENCE_DEL_PLANNING_PREFERENCE_FAILURE:
    case PREFERENCE_CREATE_PLANNING_PREFERENCE_FAILURE:
        return { ...state, loading: false };
    case PREFERENCE_GET_PLANNING_PREFERENCES_FAILURE:
        return { ...state, planningPreferences: [], loading: false };
    case PREFERENCE_GET_PLANNING_PREFERENCE_FAILURE:
        return { ...state, planningPreference: null, loading: false };
    case PREFERENCE_FAILURE:
        return {
            ...state, loading: false, error: action.error,
        };
    default:
        return state;
    }
};

export default preferenceReducer;
