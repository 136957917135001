import axios from 'axios';
import Jsona from 'jsona/lib/Jsona';

const jsonApiDataFormatter = new Jsona();

const includes = ['tracks', 'tracks.owner'];

export const getFeedback = feedbackUuid => axios.get(
    `${process.env.REACT_APP_API_URL}/feedback/${feedbackUuid}`,
    {
        params: {
            include: includes.join(','),
        },
    },
);

export const getFeedbackCategories = () => axios.get(
    `${process.env.REACT_APP_API_URL}/feedback-categories/`,
    {
        params: {
            include: [],
        },
    },
);

export const patchFeedback = (id, remark, selectedCategory) => {
    const category = {
        id: selectedCategory.id,
        name: selectedCategory.name,
        type: 'feedback_categories',
    };

    const data = {
        id,
        type: 'feedback',
        remark,
        relationshipNames: ['category'],
        category,
    };

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/feedback/${id}`, jsonApiDataFormatter.serialize({ stuff: data }),
    );
};
