import React, { FC, useMemo } from 'react';

import { ShiftExchangeStatus } from '../../models';
import getShiftExchangeStatus from '../../services/ShiftExchangeService/getShiftExchangeStatus';
import DateCard from '../../@paco/compositions/DateCard/DateCard';


interface ExchangeHeaderProps {
    date: Date;
    status: ShiftExchangeStatus;
    className?: string;
}

const ExchangeHeader: FC<ExchangeHeaderProps> = ({
    date,
    status = ShiftExchangeStatus.open,
    className = '',
}) => {
    const { color } = useMemo(() => getShiftExchangeStatus(status), [status]);

    return (
        <DateCard
            color={color}
            date={date}
            className={`exchange-header ${className}`}
        />
    )
}

export default ExchangeHeader;
