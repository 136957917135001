import {
    EditUserProfileRequest, User, UserFormData, UserResource,
} from './User';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { UserStatus } from '../../types/userStatus';
import { transformToBasicPerson } from '../Person/PersonTransformers';
import { Department } from '../Department/Department';
import { Role } from '../Role/Role';
import { Person } from '../Person/Person';

export const transformUserToUserFormData = (user: User): UserFormData => ({
    affix: user.person.affix || '',
    birthday: user.birthday,
    email: user.email,
    employeeNumber: user.employeeNumber,
    firstname: user.person.firstname,
    lastname: user.person.lastname,
    phone: user.phone,
    password: '',
    passwordRepeat: '',
    pincode: '',
    pincodeRepeat: '',
});

export const transformUserFormDataToEditUserProfileRequest = (formData: UserFormData, id: string): EditUserProfileRequest => ({
    type: 'users',
    id,
    attributes: {
        phone: formData.phone,
        pincode: formData.pincode,
        password: formData.password,
    },
});

export const transformToUser = (
    resource: UserResource,
    person: Person,
    departments: Department[],
    roles: Role[],
): User => {
    const status = getEnumKeyByEnumValue(UserStatus, resource.attributes.status);

    if (!status) {
        console.error(`[transformToUser]: Unknown status enum found named "${resource.attributes.status}".`);
    }

    return {
        id: resource.id,
        birthday: new Date(resource.attributes.birthday),
        employeeNumber: resource.attributes.employeeNumber,
        phone: resource.attributes.phone,
        email: resource.attributes.email,
        nationality: resource.attributes.nationality,
        status: status ? UserStatus[status] : UserStatus.active,
        shouldTrackTime: resource.attributes.shouldTrackTime,
        isPaidBasedOnTrackedTime: resource.attributes.isPaidBasedOnTrackedTime,
        isEligibleForTimeForTime: resource.attributes.isEligibleForTimeForTime,
        contractHours: resource.attributes.contractHours,
        registrationComplete: resource.attributes.registrationComplete,
        isLoketAuthorized: resource.attributes.isLoketAuthorized,
        departments,
        roles,
        person,
    };
};

