import {
    PREFERENCE_SAGA_GET_PLANNING_PREFERENCES,
    PREFERENCE_SAGA_GET_PLANNING_PREFERENCE,
    PREFERENCE_SAGA_CREATE_PLANNING_PREFERENCE,
    PREFERENCE_SAGA_DEL_PLANNING_PREFERENCE,
} from '../actionTypes';

export const getPreferenceDays = () => ({ type: PREFERENCE_SAGA_GET_PLANNING_PREFERENCES });
export const getPlanningPreference = id => ({ type: PREFERENCE_SAGA_GET_PLANNING_PREFERENCE, id });
export const createPlanningPreference = (startDate, endDate, reason, history) => (
    {
        type: PREFERENCE_SAGA_CREATE_PLANNING_PREFERENCE, startDate, endDate, reason, history,
    }
);
export const deletePlanningPreference = (id, history) => (
    { type: PREFERENCE_SAGA_DEL_PLANNING_PREFERENCE, id, history }
);
