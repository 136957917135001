import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { translate } from '../../helpers/translations/translator';
import {
    Button, Card,
    InfoListBlock,
    InfoListItem,
    LoadingSpinner,
} from '../../@paco/components';
import { getPlanningPreference, deletePlanningPreference } from '../../redux/preferences/preferenceActions';
import { getObjProperty } from '../../helpers/tools';
import { convertDateToTime } from '../../helpers/DateTimeParser';
import { formatDate } from '../../@paco/helpers/date';

import './PlanningPreferenceDetail.scss';


class PlanningPreferenceDetail extends Component {
    componentDidMount() {
        const { match, dispatch } = this.props;
        if (match.params.planningPreferenceId) {
            dispatch(getPlanningPreference(match.params.planningPreferenceId));
        }
    }

    dispatchDelete = () => {
        const { dispatch, planningPreference, history } = this.props;
        dispatch(deletePlanningPreference(planningPreference.id, history));
    };

    render() {
        const { planningPreference, loading } = this.props;

        if (!planningPreference) {
            return null;
        }

        const date = getObjProperty(planningPreference, 'start');
        const readableDate = formatDate(new Date(date));
        const comment = getObjProperty(planningPreference, 'comment') || '';
        const time = `${convertDateToTime(planningPreference.start)} - ${convertDateToTime(planningPreference.end)}`;

        return (
            <>
                <Card className="planning-preference-detail__header">
                    {translate('pages.planningPreferences.planningPreference')}
                </Card>
                <ul className="mb-4">
                    <InfoListItem label={translate('common.date')}>
                        {readableDate}
                    </InfoListItem>
                    <InfoListItem label={translate('common.time')}>
                        {time}
                    </InfoListItem>
                    <InfoListBlock label={translate('common.reason')}>
                        <Input
                            disabled
                            className="border-0"
                            value={comment}
                            placeholder={translate('common.description')}
                            type="textarea"
                        />
                    </InfoListBlock>
                </ul>
                <Button
                    disabled={!planningPreference}
                    onClick={() => this.dispatchDelete()}
                    className="planning-preference-detail__delete-button"
                >
                    {translate('common.delete')}
                </Button>
                {loading && <LoadingSpinner />}
            </>
        );
    }
}

PlanningPreferenceDetail.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    planningPreference: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};

PlanningPreferenceDetail.defaultProps = {
    planningPreference: null,
};

const mapStateToProps = state => ({
    planningPreference: state.preferenceReducer.planningPreference,
    loading: state.preferenceReducer.loading,
});

export default withRouter(connect(mapStateToProps)(PlanningPreferenceDetail));
