import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import { translate } from '../../helpers/translations/translator';
import { formatDate } from '../../@paco/helpers/date';
import trans from '../../@paco/helpers/trans';
import { DateInput } from '../../@paco/compositions';
import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { transformDateAndTimesToPeriod } from '../../@paco/entities/Period/PeriodTransformers';
import {
    Button,
    Card,
    InfoListBlock,
    InfoListItem,
    LoadingSpinner,
} from '../../@paco/components';
import { createPlanningPreference } from '../../redux/preferences/preferenceActions';
import { Checkbox } from '../../components/Checkbox/Checkbox';

import './CreatePlanningPreference.scss';

class CreatePlanningPreference extends Component {
    state = {
        selectedStartDate: new Date(),
        isWholeDay: false,
        startTime: '08:00',
        endTime: '18:00',
        reason: undefined,
    };

    handleStartDateChange = (val) => {
        this.setState({ selectedStartDate: val });
    };

    handleStartTimeChange = (e) => {
        this.setState({
            startTime: e.target.value,
        });
    };

    handleEndTimeChange = (e) => {
        this.setState({
            endTime: e.target.value,
        });
    };

    handleReasonChange = (e) => {
        this.setState({ reason: e.currentTarget.value });
    };

    handleWholeDayChange = (e) => {
        this.setState({
            isWholeDay: e.target.checked,
        })
    }

    submit = () => {
        const {
            selectedStartDate,
            isWholeDay,
            startTime,
            endTime,
            reason,
        } = this.state;
        const { dispatch, history } = this.props;

        const correctedStartTime = isWholeDay ? '04:00' : startTime;
        const correctedEndTime = isWholeDay ? '04:00' : endTime;

        const period = transformDateAndTimesToPeriod(selectedStartDate, correctedStartTime, correctedEndTime);
        const startDate = formatDate(period.start, DateFormat.apiDateTime);
        const endDate = formatDate(period.end, DateFormat.apiDateTime)

        dispatch(createPlanningPreference(startDate, endDate, reason, history));
    };

    render() {
        const { loading } = this.props;
        const {
            selectedStartDate,
            isWholeDay,
            startTime,
            endTime,
            reason,
        } = this.state;
        const canSubmit = !!reason;

        return (
            <>
                <Card className="create-planning-preference__header">
                    {translate('pages.planningPreferences.createPlanningPreference')}
                </Card>
                <ul>
                    <InfoListItem label={translate('common.date')}>
                        <DateInput
                            hideLabel
                            label={trans('common.date')}
                            onChange={this.handleStartDateChange}
                            value={selectedStartDate}
                        />
                    </InfoListItem>
                    <InfoListItem label={translate('common.isWholeDay')}>
                        <Checkbox
                            label={translate('common.isWholeDay')}
                            hideLabel
                            name="isWholeDay"
                            isChecked={isWholeDay}
                            onChange={this.handleWholeDayChange}
                        />
                    </InfoListItem>
                    <InfoListItem disabled={isWholeDay} label={translate('common.startTime')}>
                        <FormGroup>
                            <input
                                className="form-control"
                                type="time"
                                value={isWholeDay ? '04:00' : startTime}
                                disabled={isWholeDay}
                                onChange={this.handleStartTimeChange}
                            />
                        </FormGroup>
                    </InfoListItem>
                    <InfoListItem disabled={isWholeDay} label={translate('common.endTime')}>
                        <FormGroup>
                            <input
                                className="form-control"
                                type="time"
                                value={isWholeDay ? '04:00' : endTime}
                                disabled={isWholeDay}
                                onChange={this.handleEndTimeChange}
                            />
                        </FormGroup>
                    </InfoListItem>
                    <InfoListBlock label={translate('common.reason')}>
                        <Input
                            className="border-0"
                            onChange={this.handleReasonChange}
                            placeholder={translate('common.description')}
                            maxLength="125"
                            type="textarea"
                        />
                    </InfoListBlock>
                </ul>
                <Button
                    disabled={!canSubmit}
                    onClick={() => this.submit()}
                    className="create-planning-preference__submit-button"
                >
                    {translate('common.save')}
                </Button>
                {loading && <LoadingSpinner />}
            </>
        );
    }
}

CreatePlanningPreference.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

CreatePlanningPreference.defaultProps = {};

const mapStateToProps = state => ({
    loading: state.preferenceReducer.loading,
});

export default withRouter(connect(mapStateToProps)(CreatePlanningPreference));
