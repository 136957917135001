import React, { FC, useMemo } from 'react';

import { ShiftPlanningViewModel } from '../../models';
import { translate } from '../../helpers/translations/translator';
import { getUserFullName } from '../../helpers/getUserFullName';

import './ShiftPlannedUsers.scss';


interface ShiftPlannedUsersProps {
    peopleLimit: number;
    exchangeUser?: string;
    shiftPlannings: ShiftPlanningViewModel[];
}

const ShiftPlannedUsersList: FC<ShiftPlannedUsersProps> = ({
    shiftPlannings = [],
    exchangeUser,
    peopleLimit,
}) => {
    const plannedShiftPlannings = useMemo(() => shiftPlannings
        .filter(shiftPlanning => shiftPlanning.planned), [shiftPlannings]);

    return (
        <div className="shift-planned-users">
            <div className="shift-planned-users__planned-users-count">
                <div className="shift-planned-users__planned-users-count-text">
                    {translate('pages.exchanges.plannedEmployees')}
                </div>
                <div className="shift-planned-users__planned-users-count-number">
                    { `${plannedShiftPlannings.length}/${peopleLimit}` }
                </div>
            </div>
            { plannedShiftPlannings.map((shiftPlanning) => (
                <div className="shift-planned-users__user" key={shiftPlanning.id}>
                    {
                        `${getUserFullName(shiftPlanning.user)}
                            ${shiftPlanning.user?.id === exchangeUser ? `(${translate('pages.exchanges.exchange')})` : ''}`
                    }
                </div>
            )) }
        </div>
    )
}

export default ShiftPlannedUsersList;
