import { TRACKS_FAILURE, TRACKS_REQUEST, TRACKS_SUCCESS } from '../actionTypes';

const initialState = {
    tracks: [],
    loading: false,
    errors: [],
};

const tracksReducer = (state = initialState, action) => {
    switch (action.type) {
    case TRACKS_REQUEST:
        return { ...state, loading: true };
    case TRACKS_SUCCESS:
        return {
            ...state, loading: false, tracks: action.tracks,
        };
    case TRACKS_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    default:
        return state;
    }
};

export default tracksReducer;
