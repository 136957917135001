import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconPlus: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 14 14" fill="none" className={`icon-component ${className}`}>
        <path fillRule="evenodd" clipRule="evenodd" d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" />
    </svg>
);

export default IconPlus;
