import axios from 'axios';

import { PayrollPeriod, ApiResponse } from '../../models';
import { PayrollPeriodResponseApiParams, transformApiParamsToPayrollPeriod } from '../../services/PayrollPeriodServices/PayrollPeriodService';

export const getPayrollPeriods = async (): Promise<PayrollPeriod[]> => {
    const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/payroll-periods`, {
            params: {
                orderByStart: JSON.stringify({ start: 'ASC' }),
            },
        }) as unknown as ApiResponse<PayrollPeriodResponseApiParams[]>;

    return response.data.map(
        (payrollPeriod: PayrollPeriodResponseApiParams) => transformApiParamsToPayrollPeriod(
            payrollPeriod,
        ),
    );
};
