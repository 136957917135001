import axios from 'axios';
import Jsona from 'jsona/lib/Jsona';

const jsonApiDataFormatter = new Jsona();

axios.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'] === 'application/vnd.api+json') {
            response.data = jsonApiDataFormatter.deserialize(response.data);
        }

        return response;
    },
    error => Promise.reject(error),
);
