import { UserResource, UserViewModel } from '../../models';


export default function transformUserResource(user: UserResource): UserViewModel {
    return {
        affix: user.affix || '',
        birthday: new Date(user.birthday),
        contractHours: user.contractHours,
        email: user.email,
        isEligibleForTimeForTime: false,
        isPaidBasedOnTrackedTime: false,
        employeeNumber: user.employeeNumber,
        firstname: user.firstname,
        id: user.id,
        isLoketAuthorized: user.isLoketAuthorized,
        lastname: user.lastname,
        nationality: user.nationality,
        phone: user.phone,
        plannedHoursInWeek: user.plannedHoursInWeek || 0,
        plannedConceptHoursInWeek: user.plannedConceptHoursInWeek || 0,
        registrationComplete: user.registrationComplete,
        shouldTrackTime: user.shouldTrackTime,
    }
}
