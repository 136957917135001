import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';

import { translate } from '../../helpers/translations/translator';
import { formatDate } from '../../helpers/DateTimeParser';
import { isValid } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

import './FormDatePicker.scss';

registerLocale('nl-NL', nl);

class FormDatePicker extends React.Component {
    onInputChange = (e) => {
        this.props.onChange && this.props.onChange(e.target.value);
    };

    // eslint-disable-next-line class-methods-use-this
    convertDate(date) {
        if (typeof date === 'string') {
            return new Date(date);
        }
        return date;
    }

    render() {
        const {
            selected,
            disabled,
            onChange,
            invalid,
            showTimeSelect,
            feedback,
            showYearDropdown,
            placeholderText,
            minDate,
            maxDate,
            datePickerClassName,
        } = this.props;

        const className = `form-control ${datePickerClassName} ${invalid ? 'invalid' : ''}`;
        const date = this.convertDate(selected);
        const min = minDate ? this.convertDate(minDate) : null;
        const max = maxDate ? this.convertDate(maxDate) : null;
        const dateIsValid = date && isValid(date);
        const inputValue = dateIsValid ? formatDate(date, DateFormat.inputDate) : selected;

        return (
            <span className="form-date-picker">
                <DatePicker
                    className={className}
                    dateFormat="dd-MM-yyyy"
                    disabled={disabled}
                    selected={dateIsValid && date}
                    minDate={dateIsValid && min}
                    maxDate={dateIsValid && max}
                    onChange={onChange && onChange}
                    showTimeSelect={showTimeSelect}
                    placeholderText={placeholderText}
                    popperPlacement="top-end"
                    timeIntervals={15}
                    timeFormat="HH:mm"
                    timeCaption={translate('common.time')}
                    scrollableYearDropdown={showYearDropdown}
                    showYearDropdown={showYearDropdown}
                    locale="nl-NL"
                />
                <input
                    className="form-control form-date-picker-mobile"
                    disabled={disabled}
                    value={inputValue || ''}
                    type="date"
                    onChange={this.onInputChange}
                />
                {(feedback && invalid) && (
                    <FormFeedback className="active">
                        {feedback}
                    </FormFeedback>
                )}
            </span>
        );
    }
}

FormDatePicker.propTypes = {
    selected: PropTypes.any,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    placeholderText: PropTypes.string,
    feedback: PropTypes.any,
    datePickerClassName: PropTypes.string,
};
FormDatePicker.defaultProps = {
    selected: new Date(),
    minDate: null,
    maxDate: null,
    onChange: null,
    invalid: false,
    disabled: false,
    showTimeSelect: false,
    showYearDropdown: false,
    placeholderText: null,
    feedback: null,
    datePickerClassName: '',
};

export default FormDatePicker;
