import React, { FC, useState } from 'react';
import {
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

import { translate } from '../../helpers/translations/translator';
import FormDatePicker from '../FormDatePicker/FormDatePicker';
import { PreviousAndNextButton } from '../PreviousAndNextButton/PreviousAndNextButton';
import { UserFormData } from '../../@paco/entities/User/User';
import { validateVerifyRegistrationData } from '../../compositions/RegistrationFlow/helpers/validateFormData';
import { isObjEmpty } from '../../helpers/tools';

import './VerifyRegistration.scss';


interface VerifyRegistrationProps {
    values: UserFormData;
    onChange: (formData: Partial<UserFormData>) => void;
    onPreviousStep: () => void;
    onNextStep: () => void;
    className?: string;
    inputClassName?: string;
}

export const VerifyRegistration: FC<VerifyRegistrationProps> = ({
    values,
    onChange,
    onPreviousStep,
    onNextStep,
    className = '',
    inputClassName = '',
}) => {
    const [submitted, setSubmitted] = useState(false);
    const validationErrors = validateVerifyRegistrationData(values, submitted);

    const onNextStepClick = () => {
        setSubmitted(true);
        if (isObjEmpty(validateVerifyRegistrationData(values, true))) {
            onNextStep();
        }
    }

    const onChangeFirstname = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ firstname: e.target.value })
    };

    const onChangeAffix = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ affix: e.target.value })
    };

    const onChangeLastname = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ lastname: e.target.value })
    };

    const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ phone: e.target.value })
    };

    const onChangeBirthday = (date: Date) => {
        onChange({ birthday: date })
    };

    return (
        <div className={`verify-registration ${className}`}>
            <h4 className="verify-registration__title">{translate('pages.registration.checkYourData')}</h4>
            <div className="verify-registration__modal-body">
                <p>
                    {translate('pages.registration.checkYourDataIntro1')}
                    <br />
                    {translate('pages.registration.checkYourDataIntro2')}
                </p>
                {values.employeeNumber
            && (
                <div className="verify-registration__row">
                    <div className="verify-registration__col">
                        <FormGroup>
                            <Label>{translate('common.employeeNumber')}</Label>
                            <Input
                                disabled
                                type="number"
                                name="employeeNumber"
                                id="employeeNumber"
                                autoComplete="off"
                                defaultValue={values.employeeNumber}
                                className={inputClassName}
                            />
                        </FormGroup>
                    </div>
                </div>
            )}
                <div className="verify-registration__row">
                    <div className="verify-registration__col">
                        <FormGroup>
                            <Label>{translate('common.firstName')}</Label>
                            <Input
                                type="text"
                                name="firstname"
                                id="firstname"
                                autoComplete="off"
                                invalid={!!validationErrors.firstname}
                                defaultValue={values.firstname}
                                onChange={onChangeFirstname}
                                className={inputClassName}
                            />
                            <FormFeedback>
                                { validationErrors.firstname }
                            </FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <div className="verify-registration__row">
                    <div className="verify-registration__col">
                        <FormGroup>
                            <Label>{translate('common.affix')}</Label>
                            <Input
                                type="text"
                                name="affix"
                                id="affix"
                                autoComplete="off"
                                defaultValue={values.affix}
                                onChange={onChangeAffix}
                                className={inputClassName}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="verify-registration__row">
                    <div className="verify-registration__col">
                        <FormGroup>
                            <Label>{translate('common.lastName')}</Label>
                            <Input
                                type="text"
                                name="lastname"
                                id="lastname"
                                autoComplete="off"
                                invalid={!!validationErrors.lastname}
                                defaultValue={values.lastname}
                                onChange={onChangeLastname}
                                className={inputClassName}
                            />
                            <FormFeedback>
                                { validationErrors.lastname }
                            </FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <div className="verify-registration__row">
                    <div className="verify-registration__col">
                        <FormGroup>
                            <Label>{translate('common.email')}</Label>
                            <Input
                                disabled
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="off"
                                defaultValue={values.email}
                                className={inputClassName}
                            />
                            <FormFeedback>
                                { validationErrors.email }
                            </FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <div className="verify-registration__row">
                    <div className="verify-registration__col">
                        <FormGroup>
                            <Label>{translate('common.dateOfBirth')}</Label>
                            <FormDatePicker
                                showYearDropdown
                                selected={values.birthday}
                                onChange={onChangeBirthday}
                                datePickerClassName={inputClassName}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="verify-registration__row">
                    <div className="verify-registration__col">
                        <FormGroup>
                            <Label>{translate('common.phone')}</Label>
                            <Input
                                invalid={!!validationErrors.phone}
                                type="tel"
                                pattern="^[0-9-+\s()]*$"
                                name="phone"
                                id="phone"
                                defaultValue={values.phone}
                                onChange={onChangePhone}
                                className={inputClassName}
                            />
                            <FormFeedback>
                                { validationErrors.phone }
                            </FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <PreviousAndNextButton onPreviousStep={onPreviousStep} onNextStep={onNextStepClick} />
            </div>
        </div>
    )
}
