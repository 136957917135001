import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';
import { getAppEmbeddedState } from './appHelper';

export type AppState = AsyncReduxState<{
    isEmbedded: boolean;
}>;

const initialState: AppState = {
    ...initialAsyncReduxState,
    isEmbedded: getAppEmbeddedState(),
};

export const AppSlice = createSlice({
    name: 'pacoAppReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): AppState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): AppState {
            return {
                ...state,
                error: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
} = AppSlice.actions;

export default AppSlice.reducer;
