import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { convertDateToTime } from '../../helpers/DateTimeParser';
import getShift, { setShift, unregisterShift } from '../../redux/shift/shiftActions';
import { getObjProperty, getDeviatedTimeShift, shouldShowEndTime } from '../../helpers/tools';
import { transformLegacyBasicUserToBasicUser } from '../../services/UserService/transformLegacyBasicUserToBasicUser';
import { translate } from '../../helpers/translations/translator';
import { Button, InfoListItem } from '../../@paco/components';
import { EmployeesList, DateCard } from '../../@paco/compositions';
import TimeTicker from '../../components/TimeTicker';
import Shift from '../../models/Shift';
import Comment from './Comment/Comment';

import './ShiftsDetail.scss';

class ShiftsDetail extends Component {
    componentWillUnmount() {
        this.props.dispatch(setShift(null));
    }

    componentDidMount() {
        const { match, dispatch } = this.props;

        if (match.params.shiftUuid) {
            dispatch(getShift(match.params.shiftUuid));
        }
    }

    handleUnregisterShift = (shift) => {
        const { dispatch, history } = this.props;

        dispatch(unregisterShift(shift, history));
    }

    renderButtons = () => {
        const { shift } = this.props;
        const shiftObject = new Shift(shift);

        if (!moment(shift.start).isAfter(moment())) {
            return this.getTrackInfo();
        }

        switch (shiftObject.getStatus()) {
        case 'offered':
        case 'open': {
            return (
                <NavLink
                    to={`/${translate('nav.shifts.link')}/${shift.id}/${translate('nav.shifts.register.link')}`}
                    className="shifts-detail__button"
                >
                    {translate('common.subscribe')}
                </NavLink>
            );
        }

        case 'planned': {
            const { match } = this.props;
            const shiftsPath = translate('nav.shifts.link');
            const exchangeShiftPath = translate('nav.shifts.exchange.link');

            return (
                <NavLink
                    to={`/${shiftsPath}/${match.params.shiftUuid}/${exchangeShiftPath}`}
                    className="shifts-detail__button"
                >
                    {translate('pages.shifts.exchangeShift')}
                </NavLink>
            );
        }

        case 'registered': {
            return (
                <Button
                    onClick={() => this.handleUnregisterShift(shift)}
                    className="shifts-detail__button"
                >
                    {translate('common.unsubscribe')}
                </Button>
            );
        }
        default: {
            return null;
        }
        }
    };

    getTrackedTime = (shift) => {
        const content = null;

        if (!shift.tracks[0]) {
            return content;
        }

        if (!shift.tracks[0].billableEnd) {
            return (
                <InfoListItem label={translate('pages.shifts.clockedTime')}>
                    <TimeTicker from={shift.tracks[0].checkIn} />
                </InfoListItem>
            );
        }

        return (
            <InfoListItem label={translate('pages.shifts.clockedTime')}>
                {`${convertDateToTime(shift.tracks[0].billableStart)} -
                ${convertDateToTime(shift.tracks[0].billableEnd)}`}
            </InfoListItem>
        );
    }

    getShiftTime = (shift, settings, showEndTime) => {
        if (shift && shift.end && showEndTime) {
            return `${convertDateToTime(shift.start)} - ${convertDateToTime(shift.end)}`;
        }

        return convertDateToTime(shift.start);
    }

    getTrackInfo = () => {
        const { shift } = this.props;

        const track = (shift.tracks && shift.tracks[0]) ? shift.tracks[0] : {};

        return (
            <>
                {this.getTrackedTime(shift)}

                {track.billableEnd
                && (
                    <>
                        <InfoListItem label={translate('pages.shifts.workedTime')}>
                            {track.totalBillableTime}
                        </InfoListItem>
                        <InfoListItem label={translate('common.break')}>
                            {`${track.pause} ${translate('common.minutes')}`}
                        </InfoListItem>
                        <InfoListItem label={translate('common.meal')}>
                            {track.meal ? translate(`pages.tracks.${track.meal.toLowerCase()}`) : ''}
                        </InfoListItem>
                        <InfoListItem label={translate('common.satisfaction')}>
                            {track.happiness}
                        </InfoListItem>
                    </>
                )
                }
            </>
        );
    };

    render() {
        const { shift, currentUser, settings } = this.props;

        if (!shift) {
            return null;
        }

        const shiftObject = new Shift(shift);
        const { plannedUsers, shiftPlannings } = shift;
        const department = getObjProperty(shift, 'department.name');
        const group = getObjProperty(shift, 'department.group.name');
        const showEndTime = shouldShowEndTime(settings, getObjProperty(shift, 'department.id'));
        const shiftTime = this.getShiftTime(shift, settings, showEndTime);
        const currentUserId = getObjProperty(currentUser, 'id');
        const deviatedTime = getDeviatedTimeShift(shift, currentUserId, showEndTime);
        const userShiftPlannings = shiftPlannings && shiftPlannings
            .find(planning => getObjProperty(planning, 'user.id') === currentUserId);
        const comments = userShiftPlannings ? userShiftPlannings.comments : [];
        const coworkers = (plannedUsers || [])
            .filter(user => user.id !== currentUserId)
            .map(transformLegacyBasicUserToBasicUser);

        return (
            <div className="shifts-detail">
                <DateCard
                    color={shiftObject.getColor()}
                    date={shift.start}
                    className="shifts-detail__header"
                />
                <ul className="shifts-detail__list">
                    <InfoListItem label={translate('common.department')}>
                        {`${group ? `${group} - ` : ''}${department}`}
                    </InfoListItem>
                    {deviatedTime
                    && (
                        <InfoListItem label={translate('pages.shifts.deviatedShiftTime')}>
                            {deviatedTime}
                        </InfoListItem>
                    )}
                    <InfoListItem label={`${deviatedTime ? translate('pages.shifts.originalShiftTime') : translate('common.shiftTime')}`}>
                        {shiftTime}
                    </InfoListItem>
                </ul>
                {!!comments.length && (
                    <ul className="shifts-detail__list">
                        <InfoListItem label={translate('common.note')}>
                            <table>
                                <tbody>
                                    <Comment comment={comments[comments.length - 1]} />
                                </tbody>
                            </table>
                        </InfoListItem>
                    </ul>
                )}
                {coworkers && (
                    <EmployeesList
                        employees={coworkers}
                        className="shifts-detail__list"
                    />
                )}
                {this.renderButtons()}
            </div>
        );
    }
}

ShiftsDetail.propTypes = {
    shift: PropTypes.object,
    currentUser: PropTypes.object,
    settings: PropTypes.array,
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

ShiftsDetail.defaultProps = {
    shift: null,
    settings: [],
    currentUser: null,
};

const mapStateToProps = state => ({
    shift: state.shiftReducer.shift,
    currentUser: state.userReducer.currentUser,
    settings: state.userReducer.settings,
});

export default withRouter(connect(mapStateToProps)(ShiftsDetail));
