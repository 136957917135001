import {
    ShiftPlanningResource,
    ShiftPlanningViewModel,
    ShiftResource,
    UserResource,
} from '../../models';
import UpToButExcludingDate from '../../helpers/UpToButExcludingDate';
import transformUserResource from '../UserService/transformUserResource';
// eslint-disable-next-line import/no-cycle
import transformShiftResource from '../ShiftService/transformShiftResource';

export default function transformShiftPlanningResource(
    shiftPlanning: ShiftPlanningResource,
    user?: UserResource,
    shift?: ShiftResource,
): ShiftPlanningViewModel {
    return {
        id: shiftPlanning.id,
        color: shiftPlanning.color,
        customStartTime: shiftPlanning.customStartTime,
        end: new UpToButExcludingDate(shiftPlanning.end).transformToUpToAndIncludingDate(),
        planned: shiftPlanning.planned,
        start: new Date(shiftPlanning.start),
        user: user ? transformUserResource(user) : undefined,
        shift: shift ? transformShiftResource(
            shift,
            shift.shiftPlannings,
            undefined,
            shift.department,
        ) : undefined,
    }
}
