import axios, { AxiosResponse } from 'axios';
import { ShiftResource, ShiftViewModel } from '../../models';
import transformShiftResource from '../../services/ShiftService/transformShiftResource';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

export default async function getShifts(
    users: string[] = [],
    departments: string[] = [],
    startDate: Date,
    endDate: Date,
    includes: string[] = [],
): Promise<ShiftViewModel[]> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/shifts/open`,
        {
            params: {
                userUuids: users.length > 0 ? JSON.stringify(users) : undefined,
                startDate: formatDate(startDate, DateFormat.apiDateTime),
                endDate: formatDate(endDate, DateFormat.apiDateTime),
                departments: departments.length > 0 ? JSON.stringify(departments) : undefined,
                include: includes.join(','),
            },
        },
    ) as unknown as AxiosResponse<ShiftResource[]>;

    return response.data
        .map((shift) => transformShiftResource(
            shift,
            shift.shiftPlannings,
            shift.tracks,
            shift.department,
        ));
}

export async function getShift(id: string, includes: string[] = []): Promise<ShiftViewModel> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shifts/${id}`,
        {
            params: {
                include: includes.join(','),
            },
        },
    ) as unknown as AxiosResponse<ShiftResource>;

    return transformShiftResource(
        response.data,
        response.data.shiftPlannings,
        response.data.tracks,
        response.data.department,
    );
}
