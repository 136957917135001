import { Period } from './Period';
import { addDays, compareTimeStringsAsc, formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';

export const transformToPeriod = (
    start: Date,
    end: Date,
    hasEnd?: boolean,
): Period => ({
    start,
    end,
    hasEnd,
});

export const transformDateAndTimesToPeriod = (date: Date, startTime: string, endTime: string): Period => {
    const endTimeIsEarlierThanStartTime = compareTimeStringsAsc(startTime, endTime);
    const endDate = endTimeIsEarlierThanStartTime ? addDays(date, 1) : date;

    const start = new Date(`${formatDate(date, DateFormat.inputDate)}T${startTime}:00`);
    const end = new Date(`${formatDate(endDate, DateFormat.inputDate)}T${endTime}:00`);

    return { start, end };
};
