import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import getTracksForMe from '../../redux/tracks/tracksActions';
import TrackListItem from '../../components/Track/TrackListItem';
import ZeroState from '../../components/Helpers/ZeroState';
import { translate } from '../../helpers/translations/translator';
import { ConnectedDateNavigator } from '../../@paco/connectors';

import './Tracks.scss';

class Tracks extends Component {
    componentDidMount() {
        this.props.dispatch(getTracksForMe());
    }

    getTracksForMe = () => {
        this.props.dispatch(getTracksForMe());
    }

    renderTracks = () => {
        const { tracks, loading } = this.props;

        return tracks.length > 0 && !loading ? tracks.map(track => (
            <TrackListItem
                key={track.id}
                track={track}
                onClick={() => this.props.history.push({
                    pathname: `/${translate('nav.tracks.link')}/${track.id}`,
                    state: { track },
                })}
                className="tracks__list-item"
            />
        )) : <ZeroState text={translate('pages.tracks.emptyList')} />;
    };

    render() {
        return (
            <>
                <ConnectedDateNavigator onAfterDateChange={this.getTracksForMe} />

                <ul>
                    {this.renderTracks()}
                </ul>
            </>
        );
    }
}

Tracks.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    tracks: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    tracks: state.tracksReducer.tracks,
    errors: state.tracksReducer.errors,
    loading: state.appReducer.loading,
});

export default withRouter(connect(mapStateToProps)(Tracks));
