import axios from 'axios';

import { DateFormat } from '../../@paco/types/dateFormatTypes';
import { formatDate } from '../../@paco/helpers/date';

export const getPreferencesForMe = (startDate, endDate) => axios.get(
    `${process.env.REACT_APP_API_URL}/me/prefer-to-work-time-slots`, {
        params: {
            startDate: formatDate(startDate, DateFormat.apiDateTime),
            endDate: formatDate(endDate, DateFormat.apiDateTime),
            orderByStart: JSON.stringify({ start: 'ASC' }),
        },
    },
);

export const getPreference = id => axios.get(`${process.env.REACT_APP_API_URL}/me/prefer-to-work-time-slots/${id}`);

export const postPlanningPreference = (startDate, endDate, reason, userId) => {
    const body = {
        type: 'preferToWorkTimeSlot',
        attributes: {
            start: startDate,
            end: endDate,
            comment: reason,
        },
        relationships: {
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
        },
    };
    return axios.post(
        `${process.env.REACT_APP_API_URL}/me/prefer-to-work-time-slots`,
        { data: body },
    );
};

export const delPlanningPreference = id => axios.delete(`${process.env.REACT_APP_API_URL}/me/prefer-to-work-time-slots/${id}`);
