import axios from 'axios';
import {
    ApiResponse,
    PayslipResource,
    UserDocumentViewModel,
} from '../../models';
import { getLoketApiUrl } from './loket';
import transformPayslipResourceToUserDocument
    from '../../services/UserDocumentService/transformPayslipResourceToUserDocument';

export async function getUserPayslips(
    employmentId: string,
    year?: number,
): Promise<UserDocumentViewModel[]> {
    try {
        const response = await axios
            .get(`${getLoketApiUrl()}/payslips/${employmentId}`) as unknown as ApiResponse<PayslipResource[]>;

        return response
            .data
            .map((payslip) => transformPayslipResourceToUserDocument(payslip))
            .filter((payslip) => (year ? payslip.createdAt.getFullYear() === year : true));
    } catch (error) {
        console.error('[getUserPayslips]', error);
        return [];
    }
}
