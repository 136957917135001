import React, { FC, useMemo } from 'react';

import { InfoListItem } from '../../@paco/components';
import { translate } from '../../helpers/translations/translator';
import { ShiftPlanningViewModel, ShiftViewModel } from '../../models';
import getShiftTime from '../../services/ShiftService/getShiftTime';
import getDepartmentName from '../../services/DepartmentService/getDepartmentName';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

import './ExchangeShiftInfoList.scss';

interface ExchangeShiftInfoListProps {
    canViewEndTime: boolean;
    userId: string;
    title?: string;
    shift: ShiftViewModel;
    userName?: string;
    offeredBy?: string;
    shiftPlanning?: ShiftPlanningViewModel;
}

const ExchangeShiftInfoList: FC<ExchangeShiftInfoListProps> = ({
    canViewEndTime,
    title,
    shift,
    userId,
    userName,
    offeredBy,
    ...props
}) => {
    const shiftPlanning = useMemo(() => props.shiftPlanning || shift.shiftPlannings
        .find((planning) => (planning.user && planning.user.id === userId)),
    [shift]);
    const shiftTime = useMemo(() => getShiftTime(shiftPlanning || shift, canViewEndTime),
        [shift, shiftPlanning]);
    const departmentName = useMemo(() => (shift.department
        ? getDepartmentName(shift.department) : shift.department),
    [shift]);

    return (
        <div className="exchange-shift-info-list">
            <ul className="exchange-shift-info-list__list-group">
                { title && (
                    <InfoListItem label={title} />
                )}
                { userName && (
                    <InfoListItem label={translate('common.name')}>
                        {userName}
                    </InfoListItem>
                )}
                <InfoListItem label={translate('common.shiftTime')}>
                    {shiftTime}
                </InfoListItem>
                <InfoListItem label={translate('common.date')}>
                    {formatDate(shift.start, DateFormat.dateLabel)}
                </InfoListItem>
                <InfoListItem label={translate('common.department')}>
                    {departmentName}
                </InfoListItem>
            </ul>
        </div>
    )
}

export default ExchangeShiftInfoList;
