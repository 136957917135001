import UpToButExcludingDate from '../../helpers/UpToButExcludingDate';
import { TrackResource, TrackViewModel } from '../../models/Track';

function transformHourMinuteStringToNumber(value: string): number {
    const [hours, minutes] = value.split(':');

    return parseInt(hours, 0) + (parseInt(minutes, 0) / 60);
}

export default function transformTrackResource(
    track: TrackResource,
): TrackViewModel {
    const totalBillableTime = transformHourMinuteStringToNumber(track.totalBillableTime);

    return {
        totalBillableTime,
        billableStart: new Date(track.billableStart),
        billableEnd: new UpToButExcludingDate(track.billableEnd).transformToUpToAndIncludingDate(),
        checkIn: new Date(track.checkIn),
        checkOut: new UpToButExcludingDate(track.checkOut).transformToUpToAndIncludingDate(),
    };
}
