import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    RefAttributes,
} from 'react';
import classNames from 'classnames';
import { Button } from '../../../../components';
import { ButtonProps } from '../../../../components/@buttons/Button/Button';

import './TimeModeButton.scss';

interface TimeModeButtonProps extends ButtonProps {
    isActive?: boolean;
    className?: string;
}

export type ButtonWithRefProps = TimeModeButtonProps & RefAttributes<HTMLButtonElement>;

const TimeModeButton: ForwardRefExoticComponent<ButtonWithRefProps> = forwardRef(({
    isActive,
    className = '',
    ...buttonProps
}, ref): ReactElement => {
    const buttonClassName = classNames('time-mode-button', {
        'time-mode-button--is-active': isActive,
    }, className);

    return (
        <Button
            {...buttonProps}
            ref={ref}
            className={buttonClassName}
        />
    );
});

export default TimeModeButton;
