import {
    AUTH_SAGA_AUTHENTICATE_USER,
    AUTH_SAGA_SET_API_TOKEN_AND_GET_USER,
    AUTH_SAGA_UPDATE_API_TOKEN,
    AUTH_SAGA_UPDATE_REFRESH_TOKEN,
    AUTH_SAGA_SUBMIT_PASSWORD,
    AUTH_SAGA_FORGOT_PASSWORD,
    AUTH_SAGA_UPDATE_TENANT,
} from '../actionTypes';

export default credentials => ({
    type: AUTH_SAGA_AUTHENTICATE_USER,
    credentials,
});

export const setTenant = tenant => ({
    type: AUTH_SAGA_UPDATE_TENANT,
    tenant,
});

export const setApiToken = apiToken => ({
    type: AUTH_SAGA_UPDATE_API_TOKEN,
    apiToken,
});

export const setRefreshToken = refreshToken => ({
    type: AUTH_SAGA_UPDATE_REFRESH_TOKEN,
    refreshToken,
});

export const setApiTokenAndGetUser = credentials => ({
    type: AUTH_SAGA_SET_API_TOKEN_AND_GET_USER,
    credentials,
});

export const forgotUserPassword = email => ({
    type: AUTH_SAGA_FORGOT_PASSWORD,
    email,
});

export const submitUserPasswordPincode = (password, pincode, loginByQuery, history) => ({
    type: AUTH_SAGA_SUBMIT_PASSWORD,
    password,
    pincode,
    loginByQuery,
    history,
});
