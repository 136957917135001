import React, {
    forwardRef,
    ForwardRefExoticComponent,
    InputHTMLAttributes,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import classNames from 'classnames';

import './Input.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, RefAttributes<HTMLInputElement> {
    error?: string;
}

export type HTMLInputProps = JSX.IntrinsicElements['input'] & InputProps;

const Input: ForwardRefExoticComponent<HTMLInputProps> = forwardRef(({
    type,
    error = '',
    className = '',
    ...inputProps
}, ref: Ref<HTMLInputElement>): ReactElement => {
    const inputClassNames = classNames('input', className, {
        'input--has-error': !!error,
    });

    return (
        <input
            {...inputProps}
            ref={ref}
            type={type || 'text'}
            className={inputClassNames}
        />
    );
});

export default Input;
