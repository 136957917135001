import React, { FC, useMemo } from 'react';

import { ShiftExchangeViewModel } from '../../models';
import { convertDateToDateString, convertDateToDay } from '../../helpers/DateTimeParser';
import getUserShiftPlanning from './helpers/getUserShiftPlanning';
import { translate } from '../../helpers/translations/translator';
import { shouldShowEndTime } from '../../helpers/tools';
import getShiftTime from '../../services/ShiftService/getShiftTime';
import getColleagueShiftPlanning from './helpers/getColleagueShiftPlanning';
import { getUserFullName } from '../../helpers/getUserFullName';
import { ListGroupItem } from '../../@paco/components';
import ShiftExchangeBadge from './subcomponents/ShiftExchangeBadge/ShiftExchangeBadge';

import './ShiftExchangeListItem.scss';


interface ShiftExchangeListItemProps {
    exchange: ShiftExchangeViewModel;
    settings: any;
    userId: string;
    onClick: () => void;
}

const ShiftExchangeListItem: FC<ShiftExchangeListItemProps> = ({
    exchange,
    settings,
    userId,
    onClick,
}) => {
    const shiftPlanning = useMemo(() => getUserShiftPlanning(exchange, userId), [exchange]);
    const colleagueShiftPlanning = useMemo(() => getColleagueShiftPlanning(exchange, userId), [exchange]);
    const day = useMemo(() => (shiftPlanning ? convertDateToDay(shiftPlanning.start) : undefined), [shiftPlanning]);
    const date = useMemo(() => (shiftPlanning ? convertDateToDateString(shiftPlanning.start) : undefined), [shiftPlanning]);
    const department = useMemo(() => ((shiftPlanning?.shift?.department) ? shiftPlanning.shift.department : undefined), [shiftPlanning]);
    const groupName = useMemo(() => (department?.group ? department.group.name : undefined), [department]);
    const showEndTime = useMemo(() => shouldShowEndTime(settings, department?.id), [settings, department]);
    const time = useMemo(() => (shiftPlanning ? getShiftTime(shiftPlanning, showEndTime) : undefined), [shiftPlanning]);

    if (!shiftPlanning) {
        return null;
    }

    return (
        <ListGroupItem
            onClick={onClick}
            className="shift-exchange-list-item"
        >
            <div className="shift-exchange-list-item__header">
                <div className="shift-exchange-list-item__day-and-date">
                    <div className="shift-exchange-list-item__day">{day}</div>
                    <div className="shift-exchange-list-item__date"> {`- ${date}`}</div>
                </div>
                <div>{time}</div>
                { (colleagueShiftPlanning && colleagueShiftPlanning.user) && (
                    <div className="shift-exchange-list-item__exchange-with">
                        <span className="shift-exchange-list-item__exchange-with-text">
                            {`${translate('pages.exchanges.exchangeWith')}: `}
                        </span>
                        {getUserFullName(colleagueShiftPlanning.user)}
                    </div>
                )}
            </div>
            <div className="shift-exchange-list-item__status-and-department">
                <ShiftExchangeBadge status={exchange.status} />
                <div className="small shift-exchange-list-item__department">
                    {`${groupName ? `${groupName} - ` : ''}`}
                    {department ? department.name : ''}
                </div>
            </div>
        </ListGroupItem>
    )
}

export default ShiftExchangeListItem;
