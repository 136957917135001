import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
    isSmall?: boolean;
    className?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    isSmall,
    className = '',
}): ReactElement => {
    const classNames = classnames('loading-spinner', {
        'loading-spinner--is-small': isSmall,
    }, className);

    return (
        <div className={classNames}>
            <div className="loading-spinner__background" />
            <div className="loading-spinner__spinner">
                {/* eslint-disable max-len */}
                {/* eslint-disable react/style-prop-object */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 1334" width="750" height="1334" preserveAspectRatio="xMidYMid slice" style={{ width: '100%', height: '100%' }}>
                    <defs>
                        <clipPath id="__lottie_element_2">
                            <rect width="750" height="1334" x="0" y="0" />
                        </clipPath>
                        <linearGradient
                            id="__lottie_element_15"
                            spreadMethod="pad"
                            gradientUnits="userSpaceOnUse"
                            x1="0"
                            y1="0"
                            x2="87.00800323486328"
                            y2="2.2300000190734863"
                        >
                            <stop offset="0%" stopColor="rgb(42,106,127)" />
                            <stop offset="50%" stopColor="rgb(73,138,160)" />
                            <stop offset="100%" stopColor="rgb(105,170,193)" />
                        </linearGradient>
                        <linearGradient
                            id="__lottie_element_19"
                            spreadMethod="pad"
                            gradientUnits="userSpaceOnUse"
                            x1="-90.29100036621094"
                            y1="-74.40899658203125"
                            x2="-19.41900062561035"
                            y2="86.0780029296875"
                        >
                            <stop offset="0%" stopColor="rgb(105,170,193)" />
                            <stop offset="50%" stopColor="rgb(73,138,160)" />
                            <stop offset="100%" stopColor="rgb(42,106,127)" />
                        </linearGradient>
                        <linearGradient
                            id="__lottie_element_20"
                            spreadMethod="pad"
                            gradientUnits="userSpaceOnUse"
                            x1="0"
                            y1="0"
                            x2="87.00800323486328"
                            y2="2.2300000190734863"
                        >
                            <stop offset="0%" stopColor="rgb(56,75,93)" />
                            <stop offset="50%" stopColor="rgb(82,101,121)" />
                            <stop offset="100%" stopColor="rgb(108,128,149)" />
                        </linearGradient>
                    </defs>
                    <g clipPath="url(#__lottie_element_2)">
                        <g transform="matrix(1.1199545860290527,0.010086470283567905,-0.010086470283567905,1.1199545860290527,529,518)" opacity="1">
                            <g opacity="1" transform="matrix(0.8573364615440369,-0.05995102599263191,0.05995102599263191,0.8573364615440369,-137.9669952392578,132.64999389648438)">
                                <g className="loading-spinner__spinner-rectangle1">
                                    <path
                                        d="M85,-49 C85,-49 85,49 85,49 C85,68.86840057373047 68.86840057373047,85 49,85 C49,85 -49,85 -49,85 C-68.86840057373047,85 -85,68.86840057373047 -85,49 C-85,49 -85,-49 -85,-49 C-85,-68.86840057373047 -68.86840057373047,-85 -49,-85 C-49,-85 49,-85 49,-85 C68.86840057373047,-85 85,-68.86840057373047 85,-49z"
                                        fill="url(#__lottie_element_19)"
                                        fillOpacity="1"
                                    />
                                    <path
                                        stroke="url(#__lottie_element_20)"
                                        strokeLinecap="butt"
                                        strokeLinejoin="miter"
                                        fillOpacity="0"
                                        strokeMiterlimit="4"
                                        strokeOpacity="1"
                                        strokeWidth="12"
                                        d="M0 0"
                                    />
                                </g>
                            </g>
                        </g>
                        <g transform="matrix(0.9583274722099304,0.4529993236064911,-0.4529993236064911,0.9583274722099304,566,601.5)" opacity="1">
                            <g opacity="1" transform="matrix(0.8594300150871277,0,0,0.8594300150871277,-137.9669952392578,132.64999389648438)">
                                <path fill="rgb(255,255,255)" fillOpacity="1" d=" M85,-49 C85,-49 85,49 85,49 C85,68.86840057373047 68.86840057373047,85 49,85 C49,85 -49,85 -49,85 C-68.86840057373047,85 -85,68.86840057373047 -85,49 C-85,49 -85,-49 -85,-49 C-85,-68.86840057373047 -68.86840057373047,-85 -49,-85 C-49,-85 49,-85 49,-85 C68.86840057373047,-85 85,-68.86840057373047 85,-49z" />
                                <path
                                    stroke="url(#__lottie_element_15)"
                                    strokeLinecap="butt"
                                    strokeLinejoin="miter"
                                    fillOpacity="0"
                                    strokeMiterlimit="4"
                                    strokeOpacity="1"
                                    strokeWidth="12"
                                    d=" M85,-49 C85,-49 85,49 85,49 C85,68.86840057373047 68.86840057373047,85 49,85 C49,85 -49,85 -49,85 C-68.86840057373047,85 -85,68.86840057373047 -85,49 C-85,49 -85,-49 -85,-49 C-85,-68.86840057373047 -68.86840057373047,-85 -49,-85 C-49,-85 49,-85 49,-85 C68.86840057373047,-85 85,-68.86840057373047 85,-49z"
                                />
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,0,0)" opacity="1">
                            <g opacity="1" transform="matrix(1,0,0,1,375,667)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M0,-52 C28.7189998626709,-52 52,-28.7189998626709 52,0 C52,28.7189998626709 28.7189998626709,52 0,52 C-28.7189998626709,52 -52,28.7189998626709 -52,0 C-52,-28.7189998626709 -28.7189998626709,-52 0,-52z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,375,667)">
                                <path
                                    fill="rgb(255,255,255)"
                                    fillOpacity="1"
                                    d=" M0,-46.619998931884766 C25.74799919128418,-46.619998931884766 46.62099838256836,-25.746999740600586 46.62099838256836,0.0010000000474974513 C46.62099838256836,25.749000549316406 25.74799919128418,46.62099838256836 0,46.62099838256836 C-25.74799919128418,46.62099838256836 -46.62099838256836,25.749000549316406 -46.62099838256836,0.0010000000474974513 C-46.62099838256836,-25.746999740600586 -25.74799919128418,-46.619998931884766 0,-46.619998931884766z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,375,667)">
                                <path
                                    fill="rgb(255,255,255)"
                                    fillOpacity="1"
                                    d=" M0,-43.034000396728516 C23.767000198364258,-43.034000396728516 43.03499984741211,-23.766000747680664 43.03499984741211,0.0010000000474974513 C43.03499984741211,23.76799964904785 23.767000198364258,43.03499984741211 0,43.03499984741211 C-23.767000198364258,43.03499984741211 -43.03499984741211,23.76799964904785 -43.03499984741211,0.0010000000474974513 C-43.03499984741211,-23.766000747680664 -23.767000198364258,-43.034000396728516 0,-43.034000396728516z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,375,632.0349731445312)">
                                <path
                                    fill="rgb(17,77,96)"
                                    fillOpacity="1"
                                    d=" M0,4.48199987411499 C-0.9900000095367432,4.48199987411499 -1.7929999828338623,3.680000066757202 -1.7929999828338623,2.690000057220459 C-1.7929999828338623,2.690000057220459 -1.7929999828338623,-2.690000057220459 -1.7929999828338623,-2.690000057220459 C-1.7929999828338623,-3.680999994277954 -0.9900000095367432,-4.48199987411499 0,-4.48199987411499 C0.9900000095367432,-4.48199987411499 1.7929999828338623,-3.680999994277954 1.7929999828338623,-2.690000057220459 C1.7929999828338623,-2.690000057220459 1.7929999828338623,2.690000057220459 1.7929999828338623,2.690000057220459 C1.7929999828338623,3.680000066757202 0.9900000095367432,4.48199987411499 0,4.48199987411499z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,375,701.9650268554688)">
                                <path
                                    fill="rgb(17,77,96)"
                                    fillOpacity="1"
                                    d=" M0,4.482999801635742 C-0.9900000095367432,4.482999801635742 -1.7929999828338623,3.680000066757202 -1.7929999828338623,2.690000057220459 C-1.7929999828338623,2.690000057220459 -1.7929999828338623,-2.688999891281128 -1.7929999828338623,-2.688999891281128 C-1.7929999828338623,-3.680000066757202 -0.9900000095367432,-4.482999801635742 0,-4.482999801635742 C0.9900000095367432,-4.482999801635742 1.7929999828338623,-3.680000066757202 1.7929999828338623,-2.688999891281128 C1.7929999828338623,-2.688999891281128 1.7929999828338623,2.690000057220459 1.7929999828338623,2.690000057220459 C1.7929999828338623,3.680000066757202 0.9900000095367432,4.482999801635742 0,4.482999801635742z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,409.96600341796875,667)">
                                <path
                                    fill="rgb(17,77,96)"
                                    fillOpacity="1"
                                    d=" M-4.482999801635742,0 C-4.482999801635742,-0.9900000095367432 -3.680000066757202,-1.7929999828338623 -2.690000057220459,-1.7929999828338623 C-2.690000057220459,-1.7929999828338623 2.688999891281128,-1.7929999828338623 2.688999891281128,-1.7929999828338623 C3.680000066757202,-1.7929999828338623 4.482999801635742,-0.9900000095367432 4.482999801635742,0 C4.482999801635742,0.9900000095367432 3.680000066757202,1.7929999828338623 2.688999891281128,1.7929999828338623 C2.688999891281128,1.7929999828338623 -2.690000057220459,1.7929999828338623 -2.690000057220459,1.7929999828338623 C-3.680000066757202,1.7929999828338623 -4.482999801635742,0.9900000095367432 -4.482999801635742,0z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,340.0350036621094,667)">
                                <path
                                    fill="rgb(17,77,96)"
                                    fillOpacity="1"
                                    d=" M-4.482999801635742,0 C-4.482999801635742,-0.9900000095367432 -3.680000066757202,-1.7929999828338623 -2.690000057220459,-1.7929999828338623 C-2.690000057220459,-1.7929999828338623 2.688999891281128,-1.7929999828338623 2.688999891281128,-1.7929999828338623 C3.680000066757202,-1.7929999828338623 4.482999801635742,-0.9900000095367432 4.482999801635742,0 C4.482999801635742,0.9900000095367432 3.680000066757202,1.7929999828338623 2.688999891281128,1.7929999828338623 C2.688999891281128,1.7929999828338623 -2.690000057220459,1.7929999828338623 -2.690000057220459,1.7929999828338623 C-3.680000066757202,1.7929999828338623 -4.482999801635742,0.9900000095367432 -4.482999801635742,0z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,406.8340148925781,685.3790283203125)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M2.3289999961853027,1.3450000286102295 C1.8339999914169312,2.2019999027252197 0.7369999885559082,2.496000051498413 -0.11999999731779099,2.000999927520752 C-0.11999999731779099,2.000999927520752 -1.6729999780654907,1.1050000190734863 -1.6729999780654907,1.1050000190734863 C-2.5309998989105225,0.6100000143051147 -2.8239998817443848,-0.4869999885559082 -2.3289999961853027,-1.3450000286102295 C-1.8339999914169312,-2.2019999027252197 -0.7369999885559082,-2.496000051498413 0.11999999731779099,-2.000999927520752 C0.11999999731779099,-2.000999927520752 1.6729999780654907,-1.1050000190734863 1.6729999780654907,-1.1050000190734863 C2.5309998989105225,-0.609000027179718 2.8239998817443848,0.4880000054836273 2.3289999961853027,1.3450000286102295z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,343.1669921875,648.6199951171875)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M2.3289999961853027,1.3450000286102295 C1.8339999914169312,2.203000068664551 0.7369999885559082,2.496000051498413 -0.11999999731779099,2.002000093460083 C-0.11999999731779099,2.002000093460083 -1.6729999780654907,1.1050000190734863 -1.6729999780654907,1.1050000190734863 C-2.5299999713897705,0.6100000143051147 -2.8239998817443848,-0.4869999885559082 -2.3289999961853027,-1.343999981880188 C-1.8339999914169312,-2.2019999027252197 -0.7369999885559082,-2.496000051498413 0.11999999731779099,-2.000999927520752 C0.11999999731779099,-2.000999927520752 1.6729999780654907,-1.1039999723434448 1.6729999780654907,-1.1039999723434448 C2.5309998989105225,-0.609000027179718 2.825000047683716,0.4880000054836273 2.3289999961853027,1.3450000286102295z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,343.1659851074219,685.3790283203125)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M-2.3289999961853027,1.3450000286102295 C-1.8339999914169312,2.2019999027252197 -0.7369999885559082,2.496000051498413 0.11999999731779099,2.000999927520752 C0.11999999731779099,2.000999927520752 1.6729999780654907,1.1050000190734863 1.6729999780654907,1.1050000190734863 C2.5309998989105225,0.6100000143051147 2.8239998817443848,-0.4869999885559082 2.3289999961853027,-1.3450000286102295 C1.8339999914169312,-2.2019999027252197 0.7369999885559082,-2.496000051498413 -0.11999999731779099,-2.000999927520752 C-0.11999999731779099,-2.000999927520752 -1.6729999780654907,-1.1050000190734863 -1.6729999780654907,-1.1050000190734863 C-2.5309998989105225,-0.609000027179718 -2.8239998817443848,0.4880000054836273 -2.3289999961853027,1.3450000286102295z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,406.8330078125,648.6199951171875)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M-2.328000068664551,1.3450000286102295 C-1.8329999446868896,2.203000068664551 -0.7369999885559082,2.496000051498413 0.11999999731779099,2.002000093460083 C0.11999999731779099,2.002000093460083 1.6729999780654907,1.1050000190734863 1.6729999780654907,1.1050000190734863 C2.5299999713897705,0.6100000143051147 2.8239998817443848,-0.4869999885559082 2.3289999961853027,-1.343999981880188 C1.8339999914169312,-2.2019999027252197 0.7369999885559082,-2.496000051498413 -0.11999999731779099,-2.000999927520752 C-0.11999999731779099,-2.000999927520752 -1.6729999780654907,-1.1039999723434448 -1.6729999780654907,-1.1039999723434448 C-2.5309998989105225,-0.609000027179718 -2.8239998817443848,0.4880000054836273 -2.328000068664551,1.3450000286102295z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,356.6210021972656,698.833984375)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M-1.3450000286102295,2.3289999961853027 C-2.2019999027252197,1.8339999914169312 -2.496000051498413,0.7369999885559082 -2.000999927520752,-0.11999999731779099 C-2.000999927520752,-0.11999999731779099 -1.1050000190734863,-1.6729999780654907 -1.1050000190734863,-1.6729999780654907 C-0.6100000143051147,-2.5309998989105225 0.4869999885559082,-2.8239998817443848 1.343999981880188,-2.3289999961853027 C2.2019999027252197,-1.8339999914169312 2.496000051498413,-0.7369999885559082 2.000999927520752,0.11999999731779099 C2.000999927520752,0.11999999731779099 1.1039999723434448,1.6729999780654907 1.1039999723434448,1.6729999780654907 C0.609000027179718,2.5309998989105225 -0.4880000054836273,2.8239998817443848 -1.3450000286102295,2.3289999961853027z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,393.3800048828125,635.1669921875)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M-1.3450000286102295,2.328000068664551 C-2.203000068664551,1.8329999446868896 -2.496000051498413,0.7369999885559082 -2.002000093460083,-0.11999999731779099 C-2.002000093460083,-0.11999999731779099 -1.1050000190734863,-1.6729999780654907 -1.1050000190734863,-1.6729999780654907 C-0.6100000143051147,-2.5299999713897705 0.4869999885559082,-2.8239998817443848 1.343999981880188,-2.3289999961853027 C2.2019999027252197,-1.8339999914169312 2.496000051498413,-0.7369999885559082 2.000999927520752,0.11999999731779099 C2.000999927520752,0.11999999731779099 1.1039999723434448,1.6729999780654907 1.1039999723434448,1.6729999780654907 C0.609000027179718,2.5309998989105225 -0.4880000054836273,2.8239998817443848 -1.3450000286102295,2.328000068664551z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,356.6210021972656,635.166015625)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M-1.3450000286102295,-2.3289999961853027 C-2.2019999027252197,-1.8339999914169312 -2.496000051498413,-0.7369999885559082 -2.000999927520752,0.11999999731779099 C-2.000999927520752,0.11999999731779099 -1.1050000190734863,1.6729999780654907 -1.1050000190734863,1.6729999780654907 C-0.6100000143051147,2.5309998989105225 0.4869999885559082,2.8239998817443848 1.343999981880188,2.3289999961853027 C2.2019999027252197,1.8339999914169312 2.496000051498413,0.7369999885559082 2.000999927520752,-0.11999999731779099 C2.000999927520752,-0.11999999731779099 1.1039999723434448,-1.6729999780654907 1.1039999723434448,-1.6729999780654907 C0.609000027179718,-2.5309998989105225 -0.4880000054836273,-2.8239998817443848 -1.3450000286102295,-2.3289999961853027z"
                                />
                            </g>
                            <g opacity="1" transform="matrix(1,0,0,1,393.3800048828125,698.8330078125)">
                                <path
                                    fill="rgb(72,143,167)"
                                    fillOpacity="1"
                                    d=" M-1.3450000286102295,-2.328000068664551 C-2.203000068664551,-1.8329999446868896 -2.496000051498413,-0.7360000014305115 -2.002000093460083,0.12099999934434891 C-2.002000093460083,0.12099999934434891 -1.1050000190734863,1.6740000247955322 -1.1050000190734863,1.6740000247955322 C-0.6100000143051147,2.5309998989105225 0.4869999885559082,2.825000047683716 1.343999981880188,2.3299999237060547 C2.2019999027252197,1.8350000381469727 2.496000051498413,0.7379999756813049 2.000999927520752,-0.11900000274181366 C2.000999927520752,-0.11900000274181366 1.1039999723434448,-1.6720000505447388 1.1039999723434448,-1.6720000505447388 C0.609000027179718,-2.5299999713897705 -0.4880000054836273,-2.8239998817443848 -1.3450000286102295,-2.328000068664551z"
                                />
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,0,0)" opacity="1">
                            <g opacity="1" transform="matrix(1,0,0,1,375,656.5)">
                                <path
                                    fill="rgb(44,61,80)"
                                    fillOpacity="1"
                                    d=" M0,-12.5 C0,-12.5 0,-12.5 0,-12.5 C1.1039999723434448,-12.5 2,-11.604000091552734 2,-10.5 C2,-10.5 2,10.5 2,10.5 C2,11.604000091552734 1.1039999723434448,12.5 0,12.5 C-1.1039999723434448,12.5 -2,11.604000091552734 -2,10.5 C-2,10.5 -2,-10.5 -2,-10.5 C-2,-11.604000091552734 -1.1039999723434448,-12.5 0,-12.5z"
                                />
                            </g>
                        </g>
                        <g transform="matrix(0.9781474471092224,-0.207912415266037,0.207912415266037,0.9781474471092224,-130.48287963867188,92.54278564453125)" opacity="1">
                            <g opacity="1" transform="matrix(1,0,0,1,387.5,667)">
                                <path
                                    className="loading-spinner__spinner-clock"
                                    fill="rgb(44,61,80)"
                                    fillOpacity="1"
                                    d=" M-12.5,-2 C-12.5,-2 12.5,-2 12.5,-2 C13.604000091552734,-2 14.5,-1.1039999723434448 14.5,0 C14.5,1.1039999723434448 13.604000091552734,2 12.5,2 C12.5,2 -12.5,2 -12.5,2 C-13.604000091552734,2 -14.5,1.1039999723434448 -14.5,0 C-14.5,-1.1039999723434448 -13.604000091552734,-2 -12.5,-2z"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default LoadingSpinner;
