import React from 'react';
import PropTypes from 'prop-types';

function ZeroState({ text }) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '10rem',
                padding: '0 1rem 1rem',
            }}
        >
            {text}
        </div>
    );
}

ZeroState.propTypes = {
    text: PropTypes.string.isRequired,
};

export default ZeroState;
