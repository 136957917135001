import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../helpers/translations/translator';
import { convertDateToDateYearCompleteString, convertDateToTime } from '../../../helpers/DateTimeParser';
import TrackLogItem from './TrackLogItem';
import './TrackLog.scss';

function generateTimeLog(start, end, translation) {
    if (!start.oldValue || !end.oldValue) {
        return null;
    }

    const start1 = convertDateToTime(start.oldValue);
    const start2 = convertDateToTime(start.newValue);
    const end1 = convertDateToTime(end.oldValue);
    const end2 = convertDateToTime(end.newValue);
    return translate(translation, {
        start1,
        start2,
        end1,
        end2,
    });
}

function generatePauseLog(pause) {
    if (!pause.oldValue || !pause.newValue) {
        return null;
    }

    return translate('pages.logs.pause', {
        pause1: pause.oldValue,
        pause2: pause.newValue,
    });
}

function generateMealLog(meal) {
    if (!meal.oldValue || !meal.newValue) {
        return null;
    }

    return translate('pages.logs.meal', {
        meal1: translate(`pages.tracks.${meal.oldValue.toLowerCase()}`),
        meal2: translate(`pages.tracks.${meal.newValue.toLowerCase()}`),
    });
}

const TrackLog = ({
    actor,
    loggedAt,
    checkIn,
    checkOut,
    billableStart,
    billableEnd,
    pause,
    meal,
}) => {
    const checkInOutLog = generateTimeLog(checkIn, checkOut, 'pages.logs.checkInOut');
    const billableStartEnd = generateTimeLog(billableStart, billableEnd, 'pages.logs.billableStartEnd');
    const pauseLog = generatePauseLog(pause);
    const mealLog = generateMealLog(meal);
    const dateTimeString = `${convertDateToDateYearCompleteString(loggedAt)} ${convertDateToTime(loggedAt)}`;

    if (!checkInOutLog && !billableStartEnd && !pauseLog && !mealLog) {
        return null;
    }

    return (
        <li className="list-group-item-medium list-group-item track-log">
            <div className="track-log__actor">
                {translate('pages.logs.changedBy', { actor, date: dateTimeString })}
            </div>
            <ul className="track-log__list">
                {checkInOutLog && (
                    <TrackLogItem
                        title={translate('pages.shifts.clockedTime')}
                        text={checkInOutLog}
                    />
                )}
                {billableStartEnd && (
                    <TrackLogItem
                        title={translate('pages.tracks.billableTime')}
                        text={billableStartEnd}
                    />
                )}
                {pauseLog && (
                    <TrackLogItem
                        title={translate('common.break')}
                        text={pauseLog}
                    />
                )}
                {mealLog && (
                    <TrackLogItem
                        title={translate('common.meal')}
                        text={mealLog}
                    />
                )}
            </ul>
        </li>
    );
};

TrackLog.propTypes = {
    actor: PropTypes.string.isRequired,
    loggedAt: PropTypes.string.isRequired,
    checkIn: PropTypes.object,
    checkOut: PropTypes.object,
    billableStart: PropTypes.object,
    billableEnd: PropTypes.object,
    pause: PropTypes.object,
    meal: PropTypes.object,
};

TrackLog.defaultProps = {
    checkIn: {},
    checkOut: {},
    billableStart: {},
    billableEnd: {},
    pause: {},
    meal: {},
};

export default TrackLog;
