import React, { ChangeEvent, FC } from 'react';

import { ErrorLabel, Input, InputLabel } from '../../../components';
import { HTMLInputProps } from '../../../components/@inputs/Input/Input';
import { formatDate, isDateStringValid } from '../../../helpers/date';
import { DateFormat } from '../../../types/dateFormatTypes';

import './DateInput.scss';

export interface DateInputProps extends Omit<HTMLInputProps, 'onChange' | 'value'> {
    label: string;
    value: Date;
    type?: 'date' | 'datetime-local';
    hideLabel?: boolean;
    error?: string;
    onChange: (value: Date) => void;
}

const DateInput: FC<DateInputProps> = ({
    label,
    value,
    hideLabel,
    type = 'date',
    required = false,
    error = '',
    onChange,
    className = '',
    ...inputProps
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (isDateStringValid(event.currentTarget.value)) {
            onChange(new Date(event.currentTarget.value));
        }
    };

    return (
        <label className={`date-input ${className}`}>
            {!hideLabel && <InputLabel text={label} />}

            <Input
                {...inputProps}
                type={type}
                value={formatDate(value, type === 'datetime-local' ? DateFormat.inputDateTime : DateFormat.inputDate)}
                required={required}
                error={error}
                className="date-input__input"
                onChange={handleChange}
            />

            {error && <ErrorLabel text={error} className="date-input__error-label" />}
        </label>
    );
};

export default DateInput;
