import { PersonResource } from '../../models/Person';
import { Person } from '../../@paco/entities/Person/Person';
import { GenderType } from '../../@paco/types/genderTypes';
import { IdentificationType } from '../../@paco/types/identificationTypes';
import { CompanyMedicType } from '../../@paco/types/companyMedicTypes';

export const transformLegacyPersonResourceToPacoPerson = (resource: PersonResource): Person => ({
    id: resource.id,
    firstname: resource.firstname,
    affix: resource.affix || undefined,
    lastname: resource.lastname,
    gender: resource.gender as GenderType,
    birthday: resource.birthday ? new Date(resource.birthday) : undefined,
    hasSundayAllowance: resource.hasSundayAllowance,
    identificationExpirationDate: new Date(resource.identificationExpirationDate),
    identificationType: resource.identificationType as IdentificationType,
    companyMedic: resource.isCompanyMedic || CompanyMedicType.unknown,
    nationality: resource.nationality,
    workdays: resource.workDays,
});
