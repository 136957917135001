import {
    FEEDBACK_SAGA_GET_FEEDBACK,
    FEEDBACK_SAGA_GET_FEEDBACK_CATEGORIES,
    FEEDBACK_SAGA_SUBMIT_FEEDBACK,
} from '../actionTypes';

export const getFeedback = feedbackUuid => ({ type: FEEDBACK_SAGA_GET_FEEDBACK, feedbackUuid });
export const getFeedbackCategories = () => ({ type: FEEDBACK_SAGA_GET_FEEDBACK_CATEGORIES });
export const submitFeedback = (id, remark, category) => (
    {
        type: FEEDBACK_SAGA_SUBMIT_FEEDBACK, id, remark, category,
    });
