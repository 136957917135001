import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import { Button } from '../../components';

import './TabButtons.scss';

interface TabButtonsProps {
    activeIndex?: number;
    buttonLabels: [string, string];
    onButtonClick: (index: number) => void;
    className?: string;
}

const TabButtons: FC<TabButtonsProps> = ({
    activeIndex,
    buttonLabels,
    onButtonClick,
    className = '',
}): ReactElement => {
    const wrapperClassName = classnames('tab-buttons', {
        [`tab-buttons--is-active-${activeIndex}`]: activeIndex !== undefined,
    }, className);

    const handleFirstButtonClick = () => onButtonClick(0);
    const handleSecondButtonClick = () => onButtonClick(1);

    return (
        <div className={wrapperClassName}>
            <Button
                text={buttonLabels[0]}
                onClick={handleFirstButtonClick}
                className="tab-buttons__button"
            />
            <Button
                text={buttonLabels[1]}
                onClick={handleSecondButtonClick}
                className="tab-buttons__button"
            />
        </div>
    );
};

export default TabButtons;
