import { DepartmentResource, DepartmentViewModel, GroupResource } from '../../models';
import transformGroupResource from '../GroupService/transformGroupResource';

export default function transformDepartmentResource(
    department: DepartmentResource,
    group?: GroupResource,
): DepartmentViewModel {
    return {
        id: department.id,
        name: department.name,
        group: group ? transformGroupResource(group) : undefined,
    }
}
