import {
    CURRENT_USER_SAGA_EDIT_EMAIL,
    CURRENT_USER_SAGA_EDIT_PHONE,
    CURRENT_USER_SAGA_GET_CURRENT_USER,
    CURRENT_USER_SAGA_EDIT_EMERGENCY_CONTACT,
    CURRENT_USER_SAGA_EDIT_ADDRESS,
    CURRENT_USER_SAGA_EDIT_GENDER,
} from '../actionTypes';

export const getProfileOfCurrentUser = () => ({ type: CURRENT_USER_SAGA_GET_CURRENT_USER });
export const editUserPhone = (userId, phone, history) => ({
    type: CURRENT_USER_SAGA_EDIT_PHONE, userId, phone, history,
});
export const editUserEmail = (userId, email, history) => ({
    type: CURRENT_USER_SAGA_EDIT_EMAIL, userId, email, history,
});
export const editUserAddress = (
    personId,
    street,
    houseNumber,
    houseNumberAddition,
    postalCode,
    city,
    history,
) => ({
    type: CURRENT_USER_SAGA_EDIT_ADDRESS,
    personId,
    street,
    houseNumber,
    houseNumberAddition,
    postalCode,
    city,
    history,
});
export const editGender = (personId, gender, history) => ({
    type: CURRENT_USER_SAGA_EDIT_GENDER, personId, gender, history,
});
export const editUserEmergencyContact = (
    personId, emergencyContactId, fullName, phone, relationType, history,
) => ({
    type: CURRENT_USER_SAGA_EDIT_EMERGENCY_CONTACT,
    personId,
    emergencyContactId,
    fullName,
    phone,
    relationType,
    history,
});
