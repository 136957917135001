import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
} from 'react';

import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Icon } from '../../../components';
import { IconName } from '../../../components/Icon/Icon';

import './IconNavLink.scss';

interface IconButtonProps extends NavLinkProps {
    icon: IconName;
    iconPos?: 'left' | 'right';
    hideLabel?: boolean;
    text: string;
    className?: string;
    iconClassName?: string;
}

const IconNavLink: ForwardRefExoticComponent<IconButtonProps> = forwardRef(({
    icon,
    iconPos = 'left',
    text,
    hideLabel,
    className = '',
    iconClassName = '',
    ...iconButtonProps
}, ref: Ref<HTMLAnchorElement>): ReactElement => {
    const iconButtonClassNames = classNames('icon-nav-link', {
        [`icon-nav-link--align-${iconPos}`]: iconPos,
        'icon-nav-link--hidden-label': hideLabel,
    }, className);

    return (
        <NavLink
            {...iconButtonProps}
            ref={ref}
            aria-label={hideLabel ? text : undefined}
            className={iconButtonClassNames}
        >
            <Icon name={icon} className={`icon-nav-link__icon ${iconClassName}`} />

            {!hideLabel && text}
        </NavLink>
    );
});

export default IconNavLink;
