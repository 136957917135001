import { translate } from '../helpers/translations/translator';
import { CardColor } from '../@paco/types/cardColor';

export default class Shift {
    shiftTypes = {
        open: { label: translate('common.open'), color: CardColor.secondary },
        absent: { label: translate('common.absence'), color: CardColor.danger },
        registered: { label: translate('common.signedUp'), color: CardColor.orange },
        registeredToOffer: { label: translate('common.signedUp'), color: CardColor.orange },
        planned: { label: translate('common.planned'), color: CardColor.success },
        offeredByMe: { label: translate('common.planned'), icon: 'swap_horiz', color: CardColor.success },
        offered: { label: translate('pages.shifts.shiftToExchange'), color: CardColor.secondary },
        full: { label: translate('common.full'), color: CardColor.orange },
    };

    constructor(shift) {
        this.shift = shift;
    }

    getStatus() {
        return this.shift.statusForCurrentUser;
    }

    getType() {
        const type = this.shiftTypes[this.getStatus()] || {};
        return type;
    }

    getLabel() {
        return this.getType().label;
    }

    getColor() {
        return this.getType().color;
    }

    getIcon() {
        return this.getType().icon ? this.getType().icon : null;
    }

    getRegisteredUsers() {
        if (this.getStatus() === 'offeredByMe') {
            return this.shift.registeredUsers;
        }

        return false;
    }
}
