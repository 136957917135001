import React, {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';
import { useSearchParam } from 'react-use';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RegistrationContainer } from '../../containers';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { getAccessTokenWithRegistrationToken, submitRegistration } from '../../redux/registration/registrationActions';
import { setApiToken, setRefreshToken } from '../../../redux/authentication/authenticationActions';
import { getProfileOfCurrentUser } from '../../../redux/user/userActions';
import { translate } from '../../../helpers/translations/translator';
import { Reducers } from '../../../redux/reducers';
import { UserFormData } from '../../entities/User/User';
import { transformLegacyUserResourceToPacoUser } from '../../../services/UserService/transformLegacyUserToPacoUser';
import { reset } from '../../redux/registration/registrationReducer';
import { ToastType } from '../../types/toastTypes';
import { setToast } from '../../redux/toasts/toastsReducer';

const ConnectedRegistration: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const userId = useSearchParam('uuid');
    const confirmId = useSearchParam('confirm');

    const { currentUser: legacyCurrentUser, loading: isUserLoading } = useSelector((state: Reducers) => state.userReducer);
    const {
        isLoading: isRegistrationLoading,
        isSuccessful,
        accessToken,
        error,
    } = useTypedSelector(state => state.pacoRegistrationReducer);

    const currentUser = useMemo(() => (
        legacyCurrentUser ? transformLegacyUserResourceToPacoUser(legacyCurrentUser) : undefined
    ), [legacyCurrentUser]);
    const isLoading = isUserLoading || isRegistrationLoading;

    const handleSubmitRegistration = (formData: UserFormData): void => {
        if (currentUser) {
            dispatch(submitRegistration(formData, currentUser.id, currentUser.person.id));
        }
    };

    const handleFailedRegistration = (): void => {
        dispatch(setToast({ text: translate('pages.registration.registrationError'), type: ToastType.fail }));
    }

    useEffect((): () => void => () => {
        dispatch(reset());
        dispatch(getProfileOfCurrentUser());
    }, []);

    useEffect((): void => {
        if (userId && confirmId) {
            dispatch(getAccessTokenWithRegistrationToken(userId, confirmId));
        }
    }, [userId, confirmId]);

    useEffect((): void => {
        if (accessToken) {
            dispatch(setApiToken(accessToken.token));
            dispatch(setRefreshToken(accessToken.refresh_token));
            dispatch(getProfileOfCurrentUser());
        }
    }, [accessToken]);

    useEffect((): void => {
        if (error) {
            handleFailedRegistration();
        }
    }, [error]);

    useEffect((): void => {
        if (isSuccessful) {
            dispatch(setToast({
                text: translate('pages.changePasswordPincode.changeSuccess'),
                type: ToastType.pass,
            }));
            history.push('/');
        }
    }, [isSuccessful]);

    return (
        <RegistrationContainer
            isLoading={isLoading}
            currentUser={currentUser}
            onSubmitRegistration={handleSubmitRegistration}
        />
    );
}

export default ConnectedRegistration;
