import { useState } from 'react';
import PropTypes from 'prop-types';
import useInterval from './Helpers/useInterval';
import { getDifferenceInTime } from '../helpers/DateTimeParser';

const TimeTicker = ({ from }) => {
    const [time, setTime] = useState(getDifferenceInTime(new Date(from), undefined, true));

    useInterval(() => {
        setTime(getDifferenceInTime(new Date(from), undefined, true));
    }, 1000);

    return time;
};

TimeTicker.propTypes = {
    from: PropTypes.string.isRequired,
};

export default TimeTicker;
