import axios from 'axios';
import moment from 'moment';
import Jsona from 'jsona/lib/Jsona';

const jsonApiDataFormatter = new Jsona();

export const getAbsencesForMe = (includes = [], year = moment().get('year')) => axios.get(
    `${process.env.REACT_APP_API_URL}/me/absences`,
    {
        params: {
            include: includes.join(','),
            startDate: `${year}-01-01`,
            endDate: `${year}-12-31`,
        },
    },
);

export const getLeaveOfAbsenceByUuid = (includes = [], leaveOfAbsenceUuid) => axios.get(
    `${process.env.REACT_APP_API_URL}/leave-of-absences/${leaveOfAbsenceUuid}`,
    {
        params: {
            include: includes.join(','),
        },
    },
);

export const createLeaveOfAbsence = leaveOfAbsence => axios.post(
    `${process.env.REACT_APP_API_URL}/me/leave-of-absences`, jsonApiDataFormatter.serialize({ stuff: leaveOfAbsence }),
);

export const deleteLeaveOfAbsence = leaveOfAbsence => axios.delete(
    `${process.env.REACT_APP_API_URL}/me/leave-of-absences/${leaveOfAbsence.id}`,
);
