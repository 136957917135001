import {
    put, takeLatest, call, select,
} from 'redux-saga/effects';
import {
    TRACKS_FAILURE,
    TRACKS_SUCCESS,
    TRACKS_SAGA_GET_TRACKS,
    SET_LOADING,
    TRACKS_REQUEST,
} from '../actionTypes';
import { getTracksForMe } from '../../helpers/api/tracksApi';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../@paco/helpers/date';

function* fetchTracks() {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: TRACKS_REQUEST });

    try {
        const includes = ['shift', 'shift.department', 'shift.department.group'];
        const state = yield select();

        const { selectedDays } = state.calendarReducer;
        const startDate = selectedDays[0];
        const endDate = selectedDays[selectedDays.length - 1];

        const response = yield call(() => getTracksForMe(
            includes,
            getStartOfWorkDay(startDate),
            getEndOfWorkDay(endDate),
        ));
        const tracks = yield call(() => response.data);

        yield put({
            type: TRACKS_SUCCESS,
            tracks,
        });
    } catch (error) {
        yield put({ type: TRACKS_FAILURE, errors: [error.response] });
    }

    yield put({ type: SET_LOADING, loading: false });
}

export default function* tracksWatcher() {
    yield takeLatest(TRACKS_SAGA_GET_TRACKS, fetchTracks);
}
