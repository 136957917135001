import { UserFormData } from '../User/User';
import { FetchResult, FetchResultType } from '../FetchResult';
import { generateApiUrl } from '../../helpers/url';
import { authorizedFetch } from '../../helpers/authorizedFetch';
import trans from '../../helpers/trans';
import { transformFormDataToEditPersonRequest, transformToPerson } from './PersonTransformers';
import { JapiDocument } from '../../japi/types/Document';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { Person, PersonResource } from './Person';
import getIncluded from '../../japi/getIncluded';

export const patchPersonApiCall = async (formData: UserFormData, personId: string): Promise<FetchResult<Person, string>> => {
    const apiData = transformFormDataToEditPersonRequest(formData, personId);

    try {
        const url = generateApiUrl({ endpoint: `/people/${personId}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<PersonResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const person = transformToPerson(doc.data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: person,
        };
    } catch (error) {
        console.error('[patchPersonApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
