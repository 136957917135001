import React from 'react';
import PropTypes from 'prop-types';
import './TrackLogItem.scss';

const TrackLogItem = ({ title, text }) => (
    <li className="track-log-item">
        <div className="track-log-item__title">
            { title }
        </div>
        <div className="track-log-item__text">
            { text }
        </div>
    </li>
);

TrackLogItem.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default TrackLogItem;
