import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { translate } from '../../helpers/translations/translator';
import { removeSlashAtEndOfString } from '../../helpers/tools';
import './ToolTip.scss';


function getBody(pathname) {
    const myShiftsPath = `/${translate('nav.shifts.link')}/${translate('nav.shifts.myShifts.link')}`;
    const openShiftsPath = `/${translate('nav.shifts.link')}/${translate('nav.shifts.openShifts.link')}`;
    const absencesPath = `/${translate('nav.absences.link')}/${translate('nav.absences.absences.link')}`;
    const leaveOfAbsencesPath = `/${translate('nav.absences.link')}/${translate('nav.absences.leaveOfAbsences.link')}`;
    const tracksPath = `/${translate('nav.tracks.link')}`;
    const myExchangesPath = `/${translate('nav.exchanges.link')}/${translate('nav.exchanges.myExchanges.link')}`;
    const offeredExchangesPath = `/${translate('nav.exchanges.link')}/${translate('nav.exchanges.offeredExchanges.link')}`;
    const documentsPath = `/${translate('nav.documents.link')}`;
    const preferencePath = `/${translate('nav.planningPreferences.link')}`;
    const profilePath = `/${translate('nav.profile.link')}`;

    switch (removeSlashAtEndOfString(pathname)) {
    case myShiftsPath:
        return translate('pages.tooltips.myShifts');
    case openShiftsPath:
        return translate('pages.tooltips.openShifts');
    case absencesPath:
        return translate('pages.tooltips.absences');
    case leaveOfAbsencesPath:
        return translate('pages.tooltips.leaveOfAbsences');
    case tracksPath:
        return translate('pages.tooltips.tracks');
    case myExchangesPath:
        return translate('pages.tooltips.myExchanges');
    case offeredExchangesPath:
        return translate('pages.tooltips.offeredExchanges');
    case documentsPath:
        return translate('pages.tooltips.documents');
    case preferencePath:
        return translate('pages.tooltips.planningPreferences');
    case profilePath:
        return translate('pages.tooltips.profile');
    default:
        return null;
    }
}

function calculateLeftEmptySpace() {
    return (window.innerWidth > 1024 ? window.innerWidth - 1024 : 0) / 2;
}

class ToolTip extends Component {
    state = {
        left: calculateLeftEmptySpace(),
        // eslint-disable-next-line react/no-unused-state
        pathname: '',
        body: null,
    };

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    formatBodyIntoParagraphs = body => body && body
        .split('\\n')
        .map((i, index) => <p key={`${index + 1}`}>{i}</p>)

    onButtonClick = (e) => {
        // Unlike other browsers, on safari clicking a button won't focus it.
        e.currentTarget.focus();
    }

    onResize = () => {
        this.setState({
            left: calculateLeftEmptySpace(),
        });
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.location.pathname !== state.pathname) {
            return {
                pathname: props.location.pathname,
                body: getBody(props.location.pathname),
            };
        }

        return {
            ...state,
        };
    };

    render() {
        const { body, left } = this.state;

        if (!body) {
            return null;
        }

        return (
            <div className="tooltips" style={{ left: `${left}px` }}>
                <button
                    type="button"
                    onClick={this.onButtonClick}
                    className="btn-circle btn-circle-small btn-circle-dark tooltips__button"
                >
                    ?
                </button>
                <div className="tooltips__text">
                    {this.formatBodyIntoParagraphs(body)}
                </div>
            </div>
        );
    }
}

ToolTip.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    location: PropTypes.object.isRequired,
};

export default withRouter(ToolTip);
