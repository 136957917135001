import React, { FC } from 'react';

import { translate } from '../../helpers/translations/translator';
import { LoadingSpinner } from '../../@paco/components';
import { User } from '../../@paco/entities/User/User';
import { IconButton } from '../../@paco/compositions';

import './WelcomeRegistration.scss';

interface WelcomeRegistrationProps {
    isLoading: boolean;
    currentUser?: User;
    onNextStep: () => void;
    className?: string;
}

export const WelcomeRegistration: FC<WelcomeRegistrationProps> = ({
    isLoading,
    currentUser,
    onNextStep,
    className = '',
}) => {
    const hasError = !isLoading && !currentUser;

    return (
        <div className={`welcome-registration ${className}`}>
            <h4 className="welcome-registration__title">{translate('pages.registration.introTitle')}</h4>
            <div className="welcome-registration__modal-body">
                <p className="welcome-registration__intro">
                    {hasError ? translate('pages.registration.registrationError') : translate('pages.registration.intro')}
                </p>
                {isLoading && <LoadingSpinner className="welcome-registration__loading-spinner" />}
                {currentUser && (
                    <IconButton
                        icon="chevron-right"
                        iconPos="right"
                        text={translate('pages.registration.dataCheck')}
                        onClick={onNextStep}
                        className="welcome-registration__button"
                    />
                )}
            </div>
        </div>
    )
}
