import { Period } from './Period';
import { addSeconds, areIntervalsOverlapping, isBefore } from '../../helpers/date';

// It is technically possible to have a period with a start date that is after the end date.
// This should of course not happen but it is possible. This function is to prevent areIntervalsOverlapping from throwing an error.
const sanitizePeriod = (period: Period): Period => {
    if (isBefore(period.end, period.start)) {
        return {
            start: period.end,
            end: period.start,
        };
    }

    return period;
};

export const doesDateOverlapWithPeriod = (date: Date, period: Period): boolean => {
    const sanitizedPeriod = sanitizePeriod(period);

    return (
        areIntervalsOverlapping(
            {
                start: date,
                end: addSeconds(date, 1),
            },
            {
                start: sanitizedPeriod.start,
                end: sanitizedPeriod.end,
            },
        )
    );
};
