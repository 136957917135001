import { UserFormData } from '../User/User';
import {
    BasicPerson,
    EditPersonRequest,
    Person,
    PersonResource,
} from './Person';
import { WorkdayType } from '../../types/workdayTypes';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { GenderType } from '../../types/genderTypes';
import { IdentificationType } from '../../types/identificationTypes';
import { CompanyMedicType } from '../../types/companyMedicTypes';
import { formatDate } from '../../helpers/date';
import { DateFormat } from '../../types/dateFormatTypes';

export const transformToBasicPerson = (
    firstname: string,
    lastname: string,
    affix = '',
): BasicPerson => ({
    firstname,
    affix,
    lastname,
});

const transformStringToWorkday = (value: string): WorkdayType | undefined => {
    const workday = getEnumKeyByEnumValue(WorkdayType, value);

    if (!workday) {
        console.error(`[transformStringToWorkday]: Unknown enum found named "${value}".`);

        return undefined;
    }

    return WorkdayType[workday];
};

export const transformToPerson = (resource: PersonResource): Person => ({
    id: resource.id,
    firstname: resource.attributes.firstname,
    affix: resource.attributes.affix || undefined,
    lastname: resource.attributes.lastname,
    gender: resource.attributes.gender as GenderType,
    birthday: resource.attributes.birthday ? new Date(resource.attributes.birthday) : undefined,
    hasSundayAllowance: resource.attributes.hasSundayAllowance,
    identificationExpirationDate: new Date(resource.attributes.identificationExpirationDate),
    identificationType: resource.attributes.identificationType as IdentificationType,
    companyMedic: resource.attributes.isCompanyMedic || CompanyMedicType.unknown,
    nationality: resource.attributes.nationality,
    workdays: resource.attributes.workDays
        .map(transformStringToWorkday)
        .filter(Boolean) as WorkdayType[],
});

export const transformFormDataToEditPersonRequest = (formData: UserFormData, personId: string): EditPersonRequest => ({
    type: 'people',
    id: personId,
    attributes: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        affix: formData.affix,
        birthday: formatDate(formData.birthday, DateFormat.apiDate),
    },
});
