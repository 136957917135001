import { CardColor } from '../@paco/types/cardColor';

export const leaveOfAbsenceStatusTypes = {
    open: { label: 'In behandeling', color: CardColor.blue },
    approved: { label: 'Goedgekeurd', color: CardColor.primary },
    denied: { label: 'Afgekeurd', color: CardColor.grey },
};

export default class LeaveOfAbsence {
    constructor(leaveOfAbsence) {
        this.leaveOfAbsence = leaveOfAbsence;
    }

    getLeaveOfAbsence() {
        return this.leaveOfAbsence;
    }

    getStatus() {
        return this.leaveOfAbsence.status;
    }

    getLabel() {
        return leaveOfAbsenceStatusTypes[this.getStatus()].label;
    }

    getColor() {
        return leaveOfAbsenceStatusTypes[this.getStatus()].color;
    }

    getIcon() {
        return leaveOfAbsenceStatusTypes[
            this.getStatus()].icon ? leaveOfAbsenceStatusTypes[this.getStatus()].icon : null;
    }
}
