import React from 'react';
import PropTypes from 'prop-types';

import './icon.scss';

function Icon(props) {
    return (
        <i
            className={
                `
                material-icons
                icon
                ${props.className}
                ${props.inline && 'icon--inline'}
                ${props.color && `text-${props.color}`}
             `}
            style={{
                fontSize: props.fontSize,
            }}
        >
            {props.kind}
        </i>
    );
}

Icon.propTypes = {
    kind: PropTypes.string.isRequired,
    className: PropTypes.string,
    inline: PropTypes.bool,
    color: PropTypes.string,
    fontSize: PropTypes.number,
};

Icon.defaultProps = {
    inline: false,
    className: '',
    color: null,
    fontSize: 24,
};

export default Icon;
