import React, { FC } from 'react';
import ConnectedLoketLeaveBalances
    from '../../connectors/ConnectedLoketLeaveBalances/ConnectedLoketLeaveBalances';


const LoketLeaveBalances: FC = () => (
    <ConnectedLoketLeaveBalances />
);

export default LoketLeaveBalances;
