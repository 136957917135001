import underscore from 'underscore';
import { v4 as uuidv4 } from 'uuid';

import { GENDERS } from '../constants';
import { translate } from './translations/translator';
import { formatDate } from '../@paco/helpers/date';
import { DateFormat } from '../@paco/types/dateFormatTypes';

const getObjProperty = (containerObj, str, defaultValueArg) => {
    const defaultValue = typeof defaultValueArg !== 'undefined' ? defaultValueArg : null;

    try {
        return underscore(str.split('.')).reduce((obj, key) => obj[key], containerObj);
    } catch (e) {
        return defaultValue;
    }
};

const isObjEmpty = (object) => Object.keys(object).length === 0;

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

const isTouch = () => 'ontouchstart' in document.documentElement;

const getDeviatedTimeShift = (shift, userId, showEndTime) => {
    if (!shift || !userId || !shift.shiftPlannings || !shift.shiftPlannings.length) {
        return null;
    }

    const shiftPlanning = shift.shiftPlannings.filter(a => getObjProperty(a, 'user.id') === userId)[0];

    if (!shiftPlanning) {
        return null;
    }
    const deviated = shiftPlanning.start !== shift.start
        || (showEndTime && (shiftPlanning.end !== shift.end));

    if (!deviated) {
        return null;
    }

    if (deviated && showEndTime) {
        return `${formatDate(new Date(shiftPlanning.start), DateFormat.timeOnlyLabel)} - ${formatDate(new Date(shiftPlanning.end), DateFormat.timeOnlyLabel)}`;
    }

    return `${formatDate(new Date(shiftPlanning.start), DateFormat.timeOnlyLabel)}`;
};

const setIOSVersion = () => {
    const match = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    let version;

    if (match !== undefined && match !== null) {
        version = [
            parseInt(match[1], 10),
            parseInt(match[2], 10),
            parseInt(match[3] || 0, 10),
        ];
        window.iOSVersion = parseFloat(version.join('.'));
    }
};

// Checks if tab is not active, then starts a function
const isTabDifferentThanActiveTab = (
    location, prevLocation, activeTab, tab1Translation, tab1, tab2, fn,
) => {
    const splits = location.pathname.split('/');
    const link = splits[splits.length - 1];
    const tab = link === tab1Translation ? tab1 : tab2;
    if (tab !== activeTab) {
        fn(tab);
    }
    return false;
};

const hardRefresh = () => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const name of names) caches.delete(name);
        });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
};

const removeSlashAtEndOfString = (url) => {
    if (url.charAt(url.length - 1) === '/') {
        return url.substr(0, url.length - 1);
    }
    return url;
};

const checkPermission = (permissions, name) => !!permissions.find(
    permission => permission.name === name,
);

const shouldShowEndTime = (settings, departmentId) => {
    const showEndTimeSettings = settings.filter(setting => setting.key === 'EMPLOYEE_APP_SHOW_END_TIMES');
    const departmentSettings = showEndTimeSettings.find(setting => getObjProperty(setting, 'department.id') === departmentId);
    if (departmentSettings) {
        return departmentSettings.value === '1';
    }

    const generalSettings = showEndTimeSettings.find(setting => !setting.department);
    if (generalSettings) {
        return generalSettings.value === '1';
    }

    return true;
};

const convertSpacesToDash = text => text.replace(/\s+/g, '-').toLowerCase();

const generateGenderOptions = currentGender => [
    ...GENDERS
        .filter(gender => !(currentGender && gender.value === 'null'))
        .map(gender => ({
            label: translate(`common.${gender.label}`),
            value: gender.value,
        })),
];

const roundNumberDecimals = (value) => Math.round(value * 100) / 100;

const generateUuid = () => uuidv4();

export {
    getObjProperty,
    capitalizeFirstLetter,
    isTouch,
    getDeviatedTimeShift,
    setIOSVersion,
    isTabDifferentThanActiveTab,
    hardRefresh,
    removeSlashAtEndOfString,
    checkPermission,
    shouldShowEndTime,
    convertSpacesToDash,
    generateGenderOptions,
    roundNumberDecimals,
    generateUuid,
    isObjEmpty,
};
