import React, { FC, ReactElement } from 'react';

import { InactiveUser } from '../../containers';
import { useTypedDispatch } from '../../redux/store';
import { logout } from '../../../redux/app/appActions';

const ConnectedInactiveUser: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const handleLogoutButtonClick = (): void => {
        dispatch(logout());
    };

    return (
        <InactiveUser onLogoutButtonClick={handleLogoutButtonClick} />
    );
};

export default ConnectedInactiveUser;
