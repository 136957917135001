import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';
import { Locale } from '../../helpers/trans';

import './LanguageIcon.scss';

interface LanguageIconProps {
  locale: Locale;
  className?: string;
}

const LanguageIcon: FC<LanguageIconProps> = ({ locale, className = '' }): ReactElement => {
    const spanClassName = classnames('language-icon', {
        [`language-icon--is-${locale}`]: locale,
    }, className);

    return (
        <div className={spanClassName} />
    );
}

export default LanguageIcon;
