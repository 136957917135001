import axios from 'axios';
import { formatDate } from '../../@paco/helpers/date';
import { DateFormat } from '../../@paco/types/dateFormatTypes';

const includes = ['person', 'roles', 'roles.permissions', 'person.emergencyContact', 'person.addresses', 'departments'];

export const getProfileOfCurrentUser = () => axios.get(
    `${process.env.REACT_APP_API_URL}/me/profile`,
    {
        params: {
            include: includes.join(','),
        },
    },
);

export const generateUserBodyData = user => ({
    type: 'users',
    id: user.id,
    attributes: {
        phone: user.phone,
        pincode: user.pincode,
        password: user.password,
    },
});

export const generatePersonBodyData = (user, personId) => ({
    type: 'people',
    id: personId,
    attributes: {
        firstname: user.firstname,
        lastname: user.lastname,
        affix: user.affix,
        birthday: formatDate(user.birthday, DateFormat.apiDate),
    },
});

export const generatePersonGenderData = (personId, gender) => ({
    type: 'people',
    id: personId,
    attributes: {
        gender,
    },
});

export const patchUserProfile = (user) => {
    const body = generateUserBodyData(user);
    return axios.patch(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        { data: body },
        {
            params: {
                include: 'person',
            },
        },
    );
};

export const patchUserPhoneAndEmail = (userId, phone, email) => {
    const body = {
        type: 'users',
        id: userId,
        attributes: {
            ...(phone && { phone }),
            ...(email && { email }),
        },
    };

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/me/user`,
        { data: body },
        {
            params: {
                include: includes.join(','),
            },
        },
    );
};

export const patchPersonGender = (personId, gender) => {
    const body = generatePersonGenderData(personId, gender);
    return axios.patch(`${process.env.REACT_APP_API_URL}/people/${personId}`, { data: body });
};

export const postUserAddress = (
    personId,
    street,
    houseNumber,
    houseNumberAddition,
    postalCode,
    city,
) => {
    const body = {
        type: 'addresses',
        id: null,
        attributes: {
            street,
            houseNumber,
            houseNumberAddition,
            postalCode,
            city,
        },
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/me/address`, { data: body });
};

export const patchPerson = (user, personId) => {
    const body = generatePersonBodyData(user, personId);
    return axios.patch(`${process.env.REACT_APP_API_URL}/people/${personId}`, { data: body });
};

function generateEmergencyBodyData(personId, emergencyContactId, fullName, phone, relationType) {
    return {
        type: 'emergencyContacts',
        id: emergencyContactId,
        attributes: {
            fullName: fullName || '',
            relationType: relationType || '',
            phone: phone || '',
        },
        relationships: {
            person: {
                data: {
                    type: 'people',
                    id: personId,
                },
            },
        },
    };
}

export const patchEmergencyContact = (
    personId, emergencyContactId, fullName, phone, relationType,
) => {
    const body = generateEmergencyBodyData(
        personId, emergencyContactId, fullName, phone, relationType,
    );
    return axios.put(`${process.env.REACT_APP_API_URL}/me/emergency-contacts`, { data: body });
};
