import {
    put,
    takeLatest,
    call,
    select,
} from 'redux-saga/effects';

import {
    SHIFT_FAILURE,
    SHIFT_REQUEST,
    SHIFT_SUCCESS,
    SHIFT_SAGA_CANCEL_OFFER_SHIFT,
    SHIFT_SAGA_GET_SHIFT,
    SHIFT_SAGA_OFFER_SHIFT,
    SHIFT_SAGA_REGISTER_OFFERED_SHIFT,
    SHIFT_SAGA_REGISTER_SHIFT,
    SHIFT_SAGA_SET_SHIFT,
    SHIFT_SAGA_UNREGISTER_OFFERED_SHIFT,
    SHIFT_SAGA_UNREGISTER_SHIFT,
    SET_LOADING,
} from '../actionTypes';
import {
    cancelShiftOffer,
    getShift,
    offerShift,
    registerShift,
    registerShiftOffer,
    unregisterShift,
    unregisterShiftOffer,
} from '../../helpers/api/shiftsApi';
import { postComment } from '../../helpers/api/commentsApi';
import { getObjProperty } from '../../helpers/tools';
import { translate } from '../../helpers/translations/translator';

function* fetchShift(action) {
    yield put({ type: SET_LOADING, loading: true });
    yield put({ type: SHIFT_REQUEST });
    try {
        const includes = [
            'department',
            'department.group',
            'tracks',
            'plannedUsers',
            'shiftPlannings',
            'shiftPlannings.comments',
            'shiftPlannings.user',
            'shiftPlannings.comments.owner',
        ];

        const response = yield call(() => getShift(includes, action.shiftUuid));
        const shift = yield call(() => response.data);

        yield put({
            type: SHIFT_SUCCESS,
            shift,
        });
    } catch (error) {
        yield put({ type: SHIFT_FAILURE, errors: [error.response] });
    }
    yield put({ type: SET_LOADING, loading: false });
}

function* postCommentToShiftPlanning(shiftId, comment) {
    const state = yield select();
    const userId = getObjProperty(state, 'userReducer.currentUser.id');
    const response = yield call(getShift, ['shiftPlannings', 'shiftPlannings.user'], shiftId);
    const shiftPlanningId = response.data.shiftPlannings
        .find(shiftPlanning => shiftPlanning.user.id === userId)
        .id;
    postComment(shiftPlanningId, comment, 'shiftPlanning', 'shiftPlannings');
}

function* postRegisterShift(action) {
    yield put({ type: SET_LOADING, loading: true });

    try {
        const response = yield call(() => registerShift(action.shiftId));
        const openShiftsLink = `/${translate('nav.shifts.link')}/${translate('nav.shifts.openShifts.link')}`;

        if (action.comment) {
            yield* postCommentToShiftPlanning(action.shiftId, action.comment);
        }

        yield put({
            type: SHIFT_SUCCESS,
            shift: response.data,
        });

        if (action.history) {
            action.history.push(openShiftsLink);
        }
    } catch (error) {
        yield put({ type: SHIFT_FAILURE, errors: [error.response] });
    }
}

function* deleteRegisteredShift(action) {
    yield put({ type: SET_LOADING, loading: true });

    try {
        const openShiftsLink = `/${translate('nav.shifts.link')}/${translate('nav.shifts.openShifts.link')}`;
        yield call(() => unregisterShift(action.shift));

        if (action.history) {
            action.history.push(openShiftsLink);
        }
    } catch (error) {
        yield put({ type: SHIFT_FAILURE, errors: [error.response] });
    }
}

function* postOfferShift(action) {
    yield put({ type: SET_LOADING, loading: true });

    try {
        const exchangesLink = `/${translate('nav.exchanges.link')}`;

        yield call(() => offerShift(action.shiftId));

        if (action.history) {
            action.history.push(exchangesLink);
        }
    } catch (error) {
        yield put({ type: SHIFT_FAILURE, errors: [error.response] });
    }
}

function* deleteOfferShift(action) {
    yield put({ type: SET_LOADING, loading: true });

    try {
        const exchangesLink = `/${translate('nav.exchanges.link')}`;

        yield call(() => cancelShiftOffer(action.shift));

        if (action.history) {
            action.history.push(exchangesLink);
        }
    } catch (error) {
        yield put({ type: SHIFT_FAILURE, errors: [error.response] });
    }
}

function* registerOfferedShift(action) {
    yield put({ type: SET_LOADING, loading: true });

    try {
        const exchangesLink = `/${translate('nav.exchanges.link')}`;

        yield call(() => registerShiftOffer(action.exchange));

        if (action.history) {
            action.history.push(exchangesLink);
        }
    } catch (error) {
        yield put({ type: SHIFT_FAILURE, errors: [error.response] });
    }
}

function* unregisterOfferedShift(action) {
    yield put({ type: SET_LOADING, loading: true });

    try {
        const exchangesLink = `/${translate('nav.exchanges.link')}`;

        yield call(() => unregisterShiftOffer(action.exchange));

        if (action.history) {
            action.history.push(exchangesLink);
        }
    } catch (error) {
        yield put({ type: SHIFT_FAILURE, errors: [error.response] });
    }
}

function* setShift(action) {
    yield put({
        type: SHIFT_SUCCESS,
        shift: action.shift,
    });
}

export default function* shiftsWatcher() {
    yield takeLatest(SHIFT_SAGA_GET_SHIFT, fetchShift);
    yield takeLatest(SHIFT_SAGA_REGISTER_SHIFT, postRegisterShift);
    yield takeLatest(SHIFT_SAGA_UNREGISTER_SHIFT, deleteRegisteredShift);
    yield takeLatest(SHIFT_SAGA_OFFER_SHIFT, postOfferShift);
    yield takeLatest(SHIFT_SAGA_CANCEL_OFFER_SHIFT, deleteOfferShift);
    yield takeLatest(SHIFT_SAGA_REGISTER_OFFERED_SHIFT, registerOfferedShift);
    yield takeLatest(SHIFT_SAGA_UNREGISTER_OFFERED_SHIFT, unregisterOfferedShift);
    yield takeLatest(SHIFT_SAGA_SET_SHIFT, setShift);
}
