import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import AbsenceListItem from '../../components/AbsenceListItem/AbsenceListItem';
import { getAbsencesForMe } from '../../redux/absences/absencesActions';
import ZeroState from '../../components/Helpers/ZeroState';
import { translate } from '../../helpers/translations/translator';
import { ConnectedYearNavigator } from '../../@paco/connectors';

import './Absences.scss';

class Absences extends Component {
    componentDidMount() {
        this.getData(this.props.year);
    }

    getData(year) {
        return this.props.dispatch(getAbsencesForMe(year || this.props.year));
    }

    renderAbsences = () => {
        const { absences } = this.props;

        return absences.length > 0 ? absences.map(absence => (
            <AbsenceListItem
                key={absence.id}
                absence={absence}
                className="absences__list-item"
            />
        )) : <ZeroState text={translate('pages.absences.noAbsencesFound')} />;
    };

    onChangeYear = (year) => {
        this.getData(year);
    };

    render() {
        return (
            <>
                <ConnectedYearNavigator onChangeYear={this.onChangeYear} />

                <div className="absences__row">
                    <div className="absences__col">
                        <ul>
                            {this.renderAbsences()}
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

Absences.propTypes = {
    absences: PropTypes.array.isRequired,
    year: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    leaveOfAbsences: state.absencesReducer.leaveOfAbsences,
    absences: state.absencesReducer.absences,
    year: state.calendarReducer.year,
    errors: state.tracksReducer.errors,
});

export default withRouter(connect(mapStateToProps)(Absences));
