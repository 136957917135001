import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconArrow: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
    <svg viewBox="0 0 21 16" className={`icon-component ${className}`}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-22.000000, -42.000000)">
                <polygon points="29.9155152 42 29.2641137 42.6575342 22.6316621 49.3662895 22 50.0047647 22.6316621 50.6337105 29.2641137 57.3424658 29.9155152 58 31.2874062 56.7421084 30.626135 56.0750447 25.5234899 50.919595 42.0525069 50.919595 43 50.919595 43 49.0899345 42.0525069 49.0899345 25.5234899 49.0899345 30.626135 43.9249553 31.2874062 43.2578916" />
            </g>
        </g>
    </svg>
);

export default IconArrow;
