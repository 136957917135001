import React from 'react';
import PropTypes from 'prop-types';
import { convertDateToDateString, convertDateToDay, convertDateToTime } from '../../helpers/DateTimeParser';

import Icon from '../style/Icon/Icon';
import TimeTicker from '../TimeTicker';
import { translate } from '../../helpers/translations/translator';
import { ListGroupItem } from '../../@paco/components';

function getTime(track) {
    if (!track.billableEnd) {
        return <TimeTicker from={track.checkIn} />;
    }

    return `${convertDateToTime(track.billableStart)} - ${convertDateToTime(track.billableEnd)}`;
}

function TrackListItem({ track, onClick, className }) {
    const start = track.billableStart || track.checkIn;
    const end = track.billableEnd || track.checkOut;

    return (
        <ListGroupItem onClick={onClick} className={className}>
            <div className="list-group-item__header">
                <div className="d-flex flex-row text">
                    <div className="font-weight-bold">{`${convertDateToDay(start)} -`}</div>
                    <div className="text text-nowrap ml-1"> {convertDateToDateString(start)}</div>
                </div>
                <div className={`text ${track.shift ? '' : 'text-danger'}`}>
                    {track.shift ? track.shift.department.name : translate('pages.tracks.unplannedShift')}
                </div>
            </div>
            <div className={`text ${end ? '' : 'text-danger'} align-self-center ml-auto`}>
                {getTime(track)}
            </div>
            <div>
                <Icon kind="chevron_right" color="secondary" />
            </div>
        </ListGroupItem>
    );
}

TrackListItem.propTypes = {
    track: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

TrackListItem.defaultProps = {
    onClick: () => {},
    className: '',
};

export default TrackListItem;
