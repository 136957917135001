import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { DateControls, TimeModeButton } from './subcomponents';
import { TimeModeType } from '../../types/timeModeTypes';
import trans from '../../helpers/trans';
import { PayrollPeriod } from '../../entities/PayrollPeriod/PayrollPeriod';
import { DayPicker } from '..';

import './DateNavigator.scss';

interface DateNavigatorProps {
  activeTimeMode: TimeModeType;
  payrollPeriods: PayrollPeriod[];
  selectedDays: Date[];
  timeModeButtons?: TimeModeType[];
  onDayPickerClick: (day: Date) => void;
  onIncrementButtonClick: (goForward: boolean) => void;
  onTimeModeChange: (timeMode: TimeModeType) => void;
  className?: string;
}

const DateNavigator: FC<DateNavigatorProps> = ({
    activeTimeMode,
    payrollPeriods,
    selectedDays,
    timeModeButtons = [TimeModeType.week, TimeModeType.period],
    onDayPickerClick,
    onIncrementButtonClick,
    onTimeModeChange,
    className = '',
}): ReactElement => {
    const [isDayPickerVisible, toggleDayPickerVisible] = useToggle(false);

    const handleDayClick = (day: Date): void => {
        onDayPickerClick(day);
        toggleDayPickerVisible(false)
    }

    const handleDayPickerButtonClick = (): void => {
        toggleDayPickerVisible();
    }

    const handlePeriodButtonClick = (): void => {
        onTimeModeChange(TimeModeType.period);
    };

    const handleWeekButtonClick = (): void => {
        onTimeModeChange(TimeModeType.week);
    };

    const dateNavigatorClassNames = classNames('date-navigator', {
        'date-navigator--has-multiple-time-mode-buttons': timeModeButtons.length > 1,
    }, className);

    return (
        <div className={dateNavigatorClassNames}>
            <div className="date-navigator__time-mode-buttons">
                {timeModeButtons.includes(TimeModeType.week) && (
                    <TimeModeButton
                        isActive={activeTimeMode === TimeModeType.week}
                        text={trans('common.week')}
                        onClick={handleWeekButtonClick}
                        className="date-navigator__time-mode-button"
                    />
                )}

                {timeModeButtons.includes(TimeModeType.month) && (
                    <TimeModeButton
                        isActive={activeTimeMode === TimeModeType.month}
                        text={trans('common.month')}
                        onClick={handlePeriodButtonClick}
                        className="date-navigator__time-mode-button"
                    />
                )}

                {timeModeButtons.includes(TimeModeType.period) && (
                    <TimeModeButton
                        isActive={activeTimeMode === TimeModeType.period}
                        text={trans('common.period')}
                        onClick={handlePeriodButtonClick}
                        className="date-navigator__time-mode-button"
                    />
                )}
            </div>

            <DateControls
                selectedDays={selectedDays}
                timeMode={activeTimeMode}
                onDayPickerButtonClick={handleDayPickerButtonClick}
                onIncrementButtonClick={onIncrementButtonClick}
                className="date-navigator__date-controls"
            />

            {isDayPickerVisible && (
                <DayPicker
                    payrollPeriods={payrollPeriods}
                    selectedDays={selectedDays}
                    timeMode={activeTimeMode}
                    onDayClick={handleDayClick}
                />
            )}
        </div>
    );
};

export default DateNavigator;
