import React, { FC, useMemo } from 'react';

import { ShiftExchangeViewModel } from '../../models';
import ExchangeHeader from '../ExchangeHeader/ExchangeHeader';
import ExchangeShiftInfoList from '../ExchangeShiftInfoList/ExchangeShiftInfoList';
import { getUserFullName } from '../../helpers/getUserFullName';
import { translate } from '../../helpers/translations/translator';
import { Button, LoadingSpinner } from '../../@paco/components';
import { SettingResource } from '../../models/Setting';
import { shouldShowEndTime } from '../../helpers/tools';

import './ShiftExchangeDetail.scss';


interface ShiftExchangeDetailByMeProps {
    loading: boolean;
    settings: SettingResource[];
    exchange: ShiftExchangeViewModel;
    onCancelShiftExchange: () => void;
}

const ShiftExchangeDetailByMe: FC<ShiftExchangeDetailByMeProps> = ({
    loading,
    settings,
    exchange,
    onCancelShiftExchange,
}) => {
    const { shift, user } = exchange.shiftPlanning1;
    const { shift: shift2, user: user2 } = exchange.shiftPlanning2;
    const planning2UserName = useMemo(() => (user2 ? getUserFullName(user2) : ''), [user2]);
    const canViewPlanningEndTime = useMemo(() => shouldShowEndTime(settings, shift?.department?.id), [shift, settings]);
    const canViewPlanning2EndTime = useMemo(() => shouldShowEndTime(settings, shift?.department?.id), [shift, settings]);

    return (
        <>
            { loading && <LoadingSpinner /> }
            <ExchangeHeader status={exchange.status} date={exchange.shiftPlanning1.start} />
            {(shift && user) && (
                <ExchangeShiftInfoList
                    canViewEndTime={canViewPlanningEndTime}
                    userId={user.id}
                    shift={shift}
                    shiftPlanning={exchange.shiftPlanning1}
                />
            )}
            {(shift2 && user2) && (
                <ExchangeShiftInfoList
                    title={translate('pages.exchanges.exchangeWith')}
                    canViewEndTime={canViewPlanning2EndTime}
                    userId={user2.id}
                    userName={planning2UserName}
                    shift={shift2}
                    shiftPlanning={exchange.shiftPlanning2}
                />
            )}
            <div className="shift-exchange-detail__container-button">
                <Button
                    text={translate('pages.absences.revokeRequest')}
                    className="shift-exchange-detail__submit-button"
                    onClick={onCancelShiftExchange}
                />
            </div>
        </>
    );
}

export default ShiftExchangeDetailByMe;
