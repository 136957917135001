import {
    put, takeLatest, call,
} from 'redux-saga/effects';
import {
    REGISTRATION_SAGA_SUBMIT,
    REGISTRATION_SUBMIT_FAILURE,
    REGISTRATION_SUBMIT_REQUEST,
    REGISTRATION_SUBMIT_SUCCESS,
    AUTH_SAGA_UNSET_TOKENS,
} from '../actionTypes';
import { patchUserProfile, patchPerson } from '../../helpers/api/userApi';
import { getCurrentUser } from '../user/userSaga';

export function* submitRegistration(action) {
    yield put({ type: REGISTRATION_SUBMIT_REQUEST });
    try {
        const response = yield call(() => patchUserProfile(action.data));
        const { data } = response;
        yield call(() => patchPerson(action.data, data.person.id));
        yield put({ type: REGISTRATION_SUBMIT_SUCCESS });
        yield getCurrentUser();
    } catch (error) {
        yield put({ type: AUTH_SAGA_UNSET_TOKENS });
        yield put({ type: REGISTRATION_SUBMIT_FAILURE });
    }
}

export default function* registrationWatcher() {
    yield takeLatest(REGISTRATION_SAGA_SUBMIT, submitRegistration);
}
