import React, { FC, useMemo } from 'react';
import { AbsenceResource } from '../../models';
import { translate } from '../../helpers/translations/translator';
import { getAbsenceDateString } from '../../helpers/date/DateTimeParser';
import { ListGroupItem } from '../../@paco/components';

interface AbsenceListItemProps {
    absence: AbsenceResource;
    className?: string;
}

const AbsenceListItem:FC<AbsenceListItemProps> = ({ absence, className = '' }) => {
    const text = useMemo(() => getAbsenceDateString(absence), [absence]);

    return (
        <ListGroupItem className={className}>
            <div className="list-group-item__header text">
                <div className="d-flex">
                    <div className="font-weight-bold">{translate('pages.absences.absence')}</div>
                </div>
                <div className="d-flex">
                    {text}
                </div>
            </div>
        </ListGroupItem>
    )
}

export default AbsenceListItem;
