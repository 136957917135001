import {
    TRACK_FAILURE,
    TRACK_REQUEST,
    TRACK_SUCCESS,
} from '../actionTypes';

const initialState = {
    track: null,
    loading: false,
    errors: [],
};

const trackReducer = (state = initialState, action) => {
    switch (action.type) {
    case TRACK_REQUEST:
        return { ...state, loading: true };
    case TRACK_SUCCESS:
        return {
            ...state, loading: false, track: action.track,
        };
    case TRACK_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    default:
        return state;
    }
};

export default trackReducer;
