import { ColleagueResource, ColleagueViewModel, DepartmentResource } from '../../models';
import { getUserFullName } from '../../helpers/getUserFullName';
import transformDepartmentResource from '../DepartmentService/transformDepartmentResource';

export default function transformColleagueResource(
    colleague: ColleagueResource,
    departments: DepartmentResource[] = [],
): ColleagueViewModel {
    return {
        id: colleague.id,
        affix: colleague.affix,
        employeeNumber: colleague.employeeNumber,
        firstname: colleague.firstname,
        lastname: colleague.lastname,
        fullName: getUserFullName(colleague),
        departments: departments
            .map((department) => transformDepartmentResource(department)),
    }
}
