import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import {
    applyMiddleware,
    createStore,
    StoreEnhancer,
    Store,
    CombinedState,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';
import { Reducers } from '../@paco/redux/reducers';

const sagaMiddleware = createSagaMiddleware();

const createEnhancer = (): StoreEnhancer => (process.env.REACT_APP_APP_ENV !== 'production'
    ? composeWithDevToolsDevelopmentOnly(applyMiddleware(thunk, sagaMiddleware))
    : applyMiddleware(thunk, sagaMiddleware)
);

export const configureStore = async (preloadedState: Record<string, unknown> = {}): Promise<Store<CombinedState<Reducers>>> => {
    const enhancer = await createEnhancer();

    const store = createStore(reducers, preloadedState, enhancer);

    sagaMiddleware.run(rootSaga);

    return store;
};
