import React, { FC, useState } from 'react';
import classnames from 'classnames';

import Icon from '../style/Icon/Icon';

import './SaldoItem.scss';


interface LeaveOfAbsenceSaldoProps {
    title: string;
    hours: number;
    color?: string;
    isTitle?: boolean;
    className?: string;
}

const SaldoItem: FC<LeaveOfAbsenceSaldoProps> = ({
    title,
    hours,
    color,
    isTitle = false,
    children,
    className = '',
}) => {
    const [showChildren, setShowChildren] = useState(false);

    const toggleChildren = () => {
        setShowChildren(!showChildren);
    }

    const hasChildren = Array.isArray(children) ? !!children.length : !!children;
    const classNames = classnames('saldo-item', {
        'saldo-item--is-title': isTitle,
        'saldo-item--has-children': hasChildren,
        'saldo-item--has-no-children': !hasChildren,
        'saldo-item--show-children': hasChildren && showChildren,
    }, className);

    return (
        <div className={classNames}>
            <div className="saldo-item__content">
                { color && <div className="saldo-item__color" style={{ background: color }} /> }
                <div className="saldo-item__title">
                    {title}
                </div>
                <div className="saldo-item__hours-and-button-toggle-container">
                    {hasChildren ? (
                        <div className="saldo-item__button-toggle-container">
                            <button type="button" className="saldo-item__button-toggle" onClick={toggleChildren}>
                                <div className="saldo-item__hours">
                                    {`${hours} uur`}
                                </div>
                                <Icon
                                    className="saldo-item__button-toggle-arrow"
                                    color="primary"
                                    kind="keyboard_arrow_down"
                                    fontSize={24}
                                />
                            </button>
                        </div>
                    ) : (
                        <div className="saldo-item__hours">
                            {`${hours} uur`}
                        </div>
                    )}
                </div>
            </div>
            { children && (
                <div className="saldo-item__children">
                    { children }
                </div>
            )}
        </div>
    )
};

export default SaldoItem;
