import axios from 'axios';
import { ApiResponse, LoketBalancesViewModel, LoketLeaveBalanceResource } from '../../models';
import { getLoketApiUrl } from './loket';
import transformLoketBalancesResource from '../../services/LoketBalances/transformLoketBalancesResource';

export async function getLoketLeaveBalance(employmentId: string): Promise<LoketBalancesViewModel> {
    const response = await axios
        .get(`${getLoketApiUrl()}/leave/balance/${employmentId}`) as unknown as ApiResponse<LoketLeaveBalanceResource[]>;

    return transformLoketBalancesResource(response.data, employmentId);
}
