import { Toast as ToastInterface, ToastType } from '../../../types/toastTypes';

export const toastsWithFadingBehaviour = (toasts: ToastInterface[]): ToastInterface[] => {
    const maxToasts = 3;
    let failedToasts = 0;

    return [...toasts]
        .reverse()
        .map((toast) => {
            if (toast.type === ToastType.fail) {
                const shouldFadeOutAutomatically = failedToasts >= maxToasts;

                failedToasts += 1;

                return {
                    ...toast,
                    shouldFadeOutAutomatically,
                };
            }

            if (toast.shouldFadeOutAutomatically === undefined) {
                return {
                    ...toast,
                    shouldFadeOutAutomatically: true,
                };
            }

            return { ...toast };
        })
        .reverse();
};
