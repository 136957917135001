import React, { FC } from 'react';

import EmployeeSaldoGroup from '../../compositions/EmployeeSaldoGroup/EmployeeSaldoGroup';
import { EmployeeBalanceType, PayrollPeriod } from '../../models';
import { LoadingSpinner } from '../../@paco/components';
import PayrollPeriodNavigator from '../../components/PayrollPeriodNavigator/PayrollPeriodNavigator';

import './EmployeeBalancesPerPeriod.scss';


interface EmployeeBalancesPerPeriodProps {
    loading: boolean;
    activePayrollPeriod: PayrollPeriod;
    className?: string;
    employeeBalanceValues: EmployeeBalanceType;
    switchPayrollPeriod: (nextPeriod: boolean) => void;
}

const EmployeeBalancesPerPeriod: FC<EmployeeBalancesPerPeriodProps> = ({
    loading,
    activePayrollPeriod,
    employeeBalanceValues,
    switchPayrollPeriod,
}) => (
    <div className="employee-balances-per-period">
        {loading && <LoadingSpinner />}
        <PayrollPeriodNavigator
            className="loket-balances-per-period__period-navigator"
            payrollPeriod={activePayrollPeriod}
            switchPayrollPeriod={switchPayrollPeriod}
        />
        {employeeBalanceValues && <EmployeeSaldoGroup {...employeeBalanceValues} />}
    </div>
)

export default EmployeeBalancesPerPeriod;
