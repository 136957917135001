import React, { FC, useMemo } from 'react';

import { Badge } from '../../../../@paco/components';
import { ShiftExchangeStatus } from '../../../../models';
import getShiftExchangeStatus from '../../../../services/ShiftExchangeService/getShiftExchangeStatus';

import './ShiftExchangeBadge.scss';


interface ShiftExchangeBadgeProps {
    status: ShiftExchangeStatus;
}

const ShiftExchangeBadge: FC<ShiftExchangeBadgeProps> = ({ status }) => {
    const { color, text } = useMemo(() => getShiftExchangeStatus(status), [status]);

    return (
        <Badge color={color} className="shift-exchange-badge">
            {text}
        </Badge>
    )
}

export default ShiftExchangeBadge;
