import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { translate } from '../../helpers/translations/translator';
import {
    MY_SHIFTS, OPEN_SHIFTS, MY_EXCHANGES, OFFERED_EXCHANGES,
} from '../../constants';
import Layout from '../Layout/Layout';
import Tracks from '../../pages/Tracks/Tracks';
import TracksDetail from '../../pages/Tracks/TracksDetail';
// eslint-disable-next-line import/no-cycle
import Shifts from '../../pages/Shifts/Shifts';
import Exchanges from '../../pages/Exchanges/Exchanges';
import ShiftsDetail from '../../pages/Shifts/ShiftsDetail';
import ShiftRegister from '../../pages/Shifts/Register/ShiftRegister';
import Absences from '../../pages/Absences/Absences';
import AbsenceDetail from '../../pages/Absences/AbsenceDetail';
import Documents from '../../pages/Documents/Documents';
import ChangePasswordPincode from '../../pages/ChangePasswordPincode/ChangePasswordPincode';
import ApplyLeaveOfAbsence from '../../pages/Absences/Apply/ApplyLeaveOfAbsence';
import ErrorPage from '../../pages/ErrorPage';
import ExchangeDetail from '../../pages/Exchanges/ExchangeDetail';
import PlanningPreferences from '../../pages/PlanningPreferences/PlanningPreferences';
import CreatePlanningPreference from '../../pages/PlanningPreferences/CreatePlanningPreference';
import PlanningPreferenceDetail from '../../pages/PlanningPreferences/PlanningPreferenceDetail';
import Profile from '../../pages/Profile/Profile';
import ProfileDetail from '../../pages/Profile/ProfileDetail';
import LeaveOfAbsenceRequests from '../../pages/Absences/LeaveOfAbsenceRequests';
import LoketLeaveBalances from '../../pages/Absences/LoketLeaveBalances';
import LoketHoursPerPeriod from '../../pages/Absences/LoketHoursPerPeriod';
import ShiftExchangeDetail from '../../pages/Exchanges/ShiftExchangeDetail';
import ExchangeShift from '../../pages/Shifts/ExchangeShift/ExchangeShift';
import { LanguagePage } from '../../@paco/pages';
import { ConnectedToastsContainer } from '../../@paco/connectors';

const PrivateRouteGroup = ({ isAuthenticated, ...rest }) => {
    const shiftsPath = `/${translate('nav.shifts.link')}`;
    const exchangeShiftPath = `/${translate('nav.shifts.exchange.link')}`;
    const myShiftsPath = `/${translate('nav.shifts.link')}/${translate('nav.shifts.myShifts.link')}`;
    const openShiftsPath = `/${translate('nav.shifts.link')}/${translate('nav.shifts.openShifts.link')}`;
    const myExchangesPath = `/${translate('nav.exchanges.link')}/${translate('nav.exchanges.myExchanges.link')}`;
    const offeredExchangesPath = `/${translate('nav.exchanges.link')}/${translate('nav.exchanges.offeredExchanges.link')}`;
    const absencesPath = `/${translate('nav.absences.link')}/${translate('nav.absences.absences.link')}`;
    const leaveOfAbsenceOverviewPath = `/${translate('nav.absences.link')}/${translate('nav.absences.leaveOfAbsenceOverview.link')}`;
    const loketHoursPerPeriodPath = `/${translate('nav.absences.link')}/${translate('nav.absences.loketHoursPerPeriod.link')}`;
    const leaveOfAbsenceRequestsPath = `/${translate('nav.absences.link')}/${translate('nav.absences.leaveOfAbsenceRequests.link')}`;
    const createAbsencesPath = `/${translate('nav.absences.link')}/${translate('nav.absences.createAbsences.link')}`;
    const planningPrefPath = `/${translate('nav.planningPreferences.link')}`;
    const planningPrefDetailPath = `${planningPrefPath}/:planningPreferenceId`;
    const createPlanningPrefPath = `${planningPrefPath}/${translate('nav.planningPreferences.createPlanningPreference.link')}`;
    const tracksPath = `/${translate('nav.tracks.link')}`;
    const profilePath = `/${translate('nav.profile.link')}`;
    const changePassPath = `${profilePath}/${translate('nav.changePasswordPincode.link')}`;
    const profileDetailPath = `${profilePath}/:detailId`;
    const languagePath = `/${translate('nav.language.link')}`;

    return (
        <>
            <Layout>
                <Route
                    {...rest}
                    render={() => (isAuthenticated === true ? (
                        <Switch>

                            { /* Tracks */ }
                            <Route exact path={tracksPath} component={Tracks} />
                            <Route
                                exact
                                path="/"
                                render={() => (
                                    <Redirect
                                        to={tracksPath}
                                    />
                                )}
                            />
                            <Route exact path={`/${translate('nav.tracks.link')}/:trackUuid`} component={TracksDetail} />

                            { /* Shifts */ }
                            <Route
                                exact
                                path={myShiftsPath}
                                render={props => <Shifts {...props} tab={MY_SHIFTS} />}
                            />
                            <Route
                                exact
                                path={openShiftsPath}
                                render={props => <Shifts {...props} tab={OPEN_SHIFTS} />}
                            />
                            <Route
                                exact
                                path={shiftsPath}
                                render={() => (
                                    <Redirect
                                        to={myShiftsPath}
                                    />
                                )}
                            />
                            <Route exact path={`${shiftsPath}/:shiftUuid`} component={ShiftsDetail} />
                            <Route exact path={`${shiftsPath}/:shiftUuid${exchangeShiftPath}`} component={ExchangeShift} />

                            <Route
                                exact
                                path={`${shiftsPath}/:shiftUuid/${translate('nav.shifts.register.link')}`}
                                component={ShiftRegister}
                            />

                            { /* Exchanges */ }
                            <Route
                                exact
                                path={myExchangesPath}
                                render={props => <Exchanges {...props} tab={MY_EXCHANGES} />}
                            />
                            <Route
                                exact
                                path={offeredExchangesPath}
                                render={props => <Exchanges {...props} tab={OFFERED_EXCHANGES} />}
                            />
                            <Route
                                exact
                                path={`/${translate('nav.exchanges.link')}`}
                                render={() => (
                                    <Redirect
                                        to={myExchangesPath}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`/${translate('nav.exchanges.link')}/:exchangeUuid`}
                                component={ExchangeDetail}
                            />
                            <Route
                                exact
                                path={`/${translate('nav.exchanges.shiftExchange.link')}/:exchangeUuid`}
                                component={ShiftExchangeDetail}
                            />

                            { /* Absences */ }
                            <Route
                                exact
                                path={leaveOfAbsenceOverviewPath}
                                render={props => (
                                    <LoketLeaveBalances {...props} />
                                )}
                            />
                            <Route
                                exact
                                path={loketHoursPerPeriodPath}
                                render={props => (
                                    <LoketHoursPerPeriod {...props} />
                                )}
                            />
                            <Route
                                exact
                                path={leaveOfAbsenceRequestsPath}
                                render={props => (
                                    <LeaveOfAbsenceRequests {...props} />
                                )}
                            />
                            <Route
                                exact
                                path={absencesPath}
                                render={props => <Absences {...props} />}
                            />
                            <Route
                                exact
                                path={`/${translate('nav.absences.link')}`}
                                render={() => (
                                    <Redirect
                                        to={leaveOfAbsenceRequestsPath}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={createAbsencesPath}
                                component={ApplyLeaveOfAbsence}
                            />
                            <Route
                                exact
                                path={`/${translate('nav.absences.link')}/:absenceUuid`}
                                component={AbsenceDetail}
                            />

                            { /* Documents */ }
                            <Route exact path={`/${translate('nav.documents.link')}`} component={Documents} />

                            { /* Password & Pincode */ }
                            <Route
                                exact
                                path={changePassPath}
                                component={ChangePasswordPincode}
                            />

                            { /* Planning preference create */ }
                            <Route
                                exact
                                path={createPlanningPrefPath}
                                component={CreatePlanningPreference}
                            />

                            { /* Planning preference */ }
                            <Route
                                exact
                                path={planningPrefDetailPath}
                                component={PlanningPreferenceDetail}
                            />

                            <Route
                                exact
                                path={planningPrefPath}
                                component={PlanningPreferences}
                            />

                            { /* Profile */ }
                            <Route
                                path={profileDetailPath}
                                component={ProfileDetail}
                            />

                            <Route
                                exact
                                path={profilePath}
                                component={Profile}
                            />

                            { /* Language */ }
                            <Route
                                path={languagePath}
                                component={LanguagePage}
                            />

                            { /* Error */ }
                            <Route render={props => <ErrorPage {...props} errorCode={404} />} />
                        </Switch>
                    ) : (
                        <Redirect to={`/${translate('nav.login.link')}`} />
                    ))
                    }
                />
            </Layout>
            <ConnectedToastsContainer />
        </>
    );
};

PrivateRouteGroup.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

export default withRouter(PrivateRouteGroup);
