// eslint-disable-next-line import/no-cycle
import UpToAndIncludingDate from './UpToAndIncludingDate';
import { addSeconds } from '../@paco/helpers/date';

/*
    Up to but excluding date = "tot", alle data vanuit en naar API is "tot!"
    Zie date-types-diagram.png voor meer uitleg
*/

export default class UpToButExcludingDate {
    private dateObject: Date;

    constructor(upToButExcludingDate: string | Date) {
        this.dateObject = typeof upToButExcludingDate === 'string' ? new Date(upToButExcludingDate) : upToButExcludingDate;
    }

    get date(): Date {
        return this.dateObject;
    }

    // Conversie van van "tot" naar "tot en met"
    transformToUpToAndIncludingDate(): UpToAndIncludingDate {
        const date = addSeconds(this.dateObject, -1);
        return new UpToAndIncludingDate(date);
    }
}
