import React, { FC, ReactElement } from 'react';
import { IconNavLink } from '../../../../compositions';
import trans from '../../../../helpers/trans';

import './InactiveUserTopBar.scss';

interface InactiveUserTopBarProps {
  showBackButton: boolean;
  className?: string;
}

const InactiveUserTopBar: FC<InactiveUserTopBarProps> = ({ showBackButton, className = '' }): ReactElement => (
    <div className={`inactive-user-top-bar ${className}`}>
        {showBackButton && (
            <IconNavLink
                hideLabel
                icon="arrow"
                text={trans('common.back')}
                to="/"
                className="inactive-user-top-bar__back-button"
                iconClassName="inactive-user-top-bar__back-button-icon"
            />
        )}
    </div>
);

export default InactiveUserTopBar;
