import React, { FC, useState, useEffect } from 'react';

import { PincodeRegistration } from '../../components/PincodeRegistration/PincodeRegistration';
import { PasswordRegistration } from '../../components/PasswordRegistration/PasswordRegistration';
import { VerifyRegistration } from '../../components/VerifyRegistration/VerifyRegistration';
import { WelcomeRegistration } from '../../components/WelcomeRegistration/WelcomeRegistration';
import { User, UserFormData } from '../../@paco/entities/User/User';
import { transformUserToUserFormData } from '../../@paco/entities/User/UserTransformers';
import { LoadingSpinner } from '../../@paco/components';

import './RegistrationFlow.scss';


export interface RegistrationFlowProps {
    isLoading: boolean;
    currentUser?: User;
    onSubmit: (formData: UserFormData) => void;
}

export const RegistrationFlow: FC<RegistrationFlowProps> = ({
    isLoading,
    currentUser,
    onSubmit,
}) => {
    const [values, setValues] = useState<UserFormData>();

    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        if (currentUser) {
            setValues(transformUserToUserFormData(currentUser));
        }
    }, [currentUser]);

    const onNextStep = () => {
        setCurrentStep(currentStep + 1);

        if (currentStep === 4 && values) {
            onSubmit(values);
        }
    }

    const onPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    }

    const onChange = (formData: Partial<UserFormData>) => {
        if (!values) {
            return;
        }

        setValues({
            ...values,
            ...formData,
        });
    }

    if (!values || currentStep === 1) {
        return (
            <WelcomeRegistration
                isLoading={isLoading}
                currentUser={currentUser}
                onNextStep={onNextStep}
            />
        );
    }

    if (currentStep === 2) {
        return (
            <VerifyRegistration
                values={values}
                onNextStep={onNextStep}
                onPreviousStep={onPreviousStep}
                onChange={onChange}
                className="registration-flow"
                inputClassName="registration-flow__input"
            />
        );
    }

    if (currentStep === 3) {
        return (
            <PasswordRegistration
                values={values}
                onNextStep={onNextStep}
                onPreviousStep={onPreviousStep}
                onChange={onChange}
                className="registration-flow"
                inputClassName="registration-flow__input"
            />
        )
    }

    if (currentStep === 4) {
        return (
            <PincodeRegistration
                values={values}
                onNextStep={onNextStep}
                onPreviousStep={onPreviousStep}
                onChange={onChange}
                className="registration-flow"
                inputClassName="registration-flow__input"
            />
        )
    }

    return (
        <LoadingSpinner className="registration-flow__loader" />
    )
}

