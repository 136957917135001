import {
    AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST,
    AUTH_SET_API_TOKEN_AND_GET_USER_SUCCESS,
    REGISTRATION_SUBMIT_FAILURE,
    REGISTRATION_SUBMIT_REQUEST,
    REGISTRATION_SUBMIT_SUCCESS,
} from '../actionTypes';

const initialState = {
    loading: false,
    success: false,
    error: false,
};

const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
    case AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST:
    case REGISTRATION_SUBMIT_REQUEST:
        return {
            ...state, loading: true,
        };
    case AUTH_SET_API_TOKEN_AND_GET_USER_SUCCESS:
        return {
            ...state, loading: false,
        };
    case REGISTRATION_SUBMIT_SUCCESS:
        return {
            ...state, loading: false, success: true,
        };
    case REGISTRATION_SUBMIT_FAILURE:
        return { ...state, loading: false, error: true };
    default:
        return state;
    }
};

export default registrationReducer;
