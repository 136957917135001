import React, { ReactElement } from 'react';

import { convertDateToDateYearString } from '../../helpers/DateTimeParser';
import Icon from '../style/Icon/Icon';
import './PayrollPeriodNavigator.scss';
import { PayrollPeriod } from '../../models';

interface PayRollPeriodNavigatorProps {
    className?: string;
    payrollPeriod: PayrollPeriod;
    switchPayrollPeriod: (nextPeriod: boolean) => void;
}

export default function PayrollPeriodNavigator({
    className,
    payrollPeriod,
    switchPayrollPeriod,
}: PayRollPeriodNavigatorProps): ReactElement {
    return (
        <div className={`payroll-navigator border-bottom ${className}`}>
            <div className="payroll-navigator__bottom">
                <button className="payroll-navigator__button" type="button" onClick={() => switchPayrollPeriod(false)}>
                    <Icon kind="chevron_left" />
                </button>
                <button
                    type="button"
                    className="mb-0 font-weight-semi-bold clear-button"
                >
                    {`${convertDateToDateYearString(payrollPeriod.start)} - 
                        ${convertDateToDateYearString(payrollPeriod.end.date)}`}
                </button>
                <button className="payroll-navigator__button" type="button" onClick={() => switchPayrollPeriod(true)}>
                    <Icon kind="chevron_right" />
                </button>
            </div>
        </div>
    );
}
