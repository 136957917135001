import React, { FC, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { translate } from '../../helpers/translations/translator';
import { IconButton } from '../../@paco/compositions';
import { ConnectedYearNavigator } from '../../@paco/connectors';
import { setYear } from '../../@paco/redux/@interface/calendar/calendarReducer';
import { useTypedSelector } from '../../@paco/redux/store';
import ZeroState from '../../components/Helpers/ZeroState';
import LeaveOfAbsenceItem from '../../components/LeaveOfAbsenceListItem/LeaveOfAbsenceListItem';
import { getLeaveOfAbsencesForMe } from '../../redux/absences/absencesActions';

import './LeaveOfAbsenceRequests.scss';

function calculateRightEmptySpace(windowWidth: number) {
    return (windowWidth > 1024 ? windowWidth - 1024 : 0) / 2;
}

// TODO: Make ConnectedLeaveOfAbsenceRequests component

const LeaveOfAbsenceRequests: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { width, height } = useWindowSize();

    const { leaveOfAbsences } = useSelector((state: any) => state.absencesReducer);
    const { year } = useTypedSelector(state => state.calendarReducer);

    const addButtonTop = height - 75;
    const addButtonRight = 20 + calculateRightEmptySpace(width);

    useEffect(() => {
        dispatch(getLeaveOfAbsencesForMe(year))
    }, [year]);

    const onAbsenceListItemClick = (id: string) => {
        history.push(`/${translate('nav.absences.link')}/${id}`)
    }

    const onAddLeaveOfAbsenceClick = () => {
        history.push(`/${translate('nav.absences.link')}/${translate('nav.absences.createAbsences.link')}`);
    }

    const onChangeYear = (value: number) => {
        dispatch(setYear(value));
    }

    return (
        <>
            <ConnectedYearNavigator onChangeYear={onChangeYear} />

            <div className="leave-of-absence-requests__row">
                <div className="leave-of-absence-requests__col">
                    <ul>
                        {leaveOfAbsences.length > 0 ? leaveOfAbsences.map((leaveOfAbsence: any) => (
                            <LeaveOfAbsenceItem
                                key={leaveOfAbsence.id}
                                onClick={() => onAbsenceListItemClick(leaveOfAbsence.id)}
                                leaveOfAbsence={leaveOfAbsence}
                                className="leave-of-absence-requests__list-item"
                            />
                        )) : <ZeroState text={translate('pages.absences.noLeaveOfAbsencesFound')} />}
                    </ul>
                </div>
            </div>

            <IconButton
                hideLabel
                icon="plus"
                style={{ top: `${addButtonTop}px`, right: `${addButtonRight}px` }}
                text={translate('common.add')}
                className="leave-of-absence-requests__apply-button"
                onClick={onAddLeaveOfAbsenceClick}
            />
        </>
    );
}

export default LeaveOfAbsenceRequests;
