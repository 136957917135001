import {
    put,
    takeLatest,
    call,
    select,
} from 'redux-saga/effects';
import {
    SET_LOADING,
    SHIFTS_FAILURE,
    SHIFTS_REQUEST,
    SHIFTS_PLANNED_SUCCESS,
    SHIFTS_OPEN_SUCCESS,
    SHIFTS_SAGA_GET_PLANNED_SHIFTS,
    SHIFTS_SAGA_GET_OPEN_SHIFTS, SHIFTS_SAGA_SET_TAB, SHIFTS_SET_TAB,
} from '../actionTypes';
import { getOpenShifts, getPlannedShifts } from '../../helpers/api/shiftsApi';
import { getObjProperty } from '../../helpers/tools';
import { translate } from '../../helpers/translations/translator';
import { MY_SHIFTS } from '../../constants';
import { getEndOfWorkDay, getStartOfWorkDay } from '../../@paco/helpers/date';

export function* fetchPlannedShifts() {
    yield put({ type: SHIFTS_REQUEST });
    yield put({ type: SET_LOADING, loading: true });

    try {
        const includes = ['department', 'department.group', 'tracks', 'shiftPlannings', 'shiftPlannings.user'];

        const state = yield select();

        const { selectedDays } = state.calendarReducer;
        const startDate = selectedDays[0];
        const endDate = selectedDays[selectedDays.length - 1];

        const response = yield call(() => getPlannedShifts(
            includes,
            getStartOfWorkDay(startDate),
            getEndOfWorkDay(endDate),
        ));
        const plannedShifts = getObjProperty(response, 'data') || [];

        yield put({
            type: SHIFTS_PLANNED_SUCCESS,
            plannedShifts,
        });
    } catch (error) {
        yield put({ type: SHIFTS_FAILURE, errors: [error.response] });
    }
    yield put({ type: SET_LOADING, loading: false });
}

export function* fetchOpenShifts() {
    yield put({ type: SHIFTS_REQUEST });

    try {
        const includes = ['department', 'department.group'];

        const state = yield select();

        const { selectedDays } = state.calendarReducer;
        const startDate = selectedDays[0];
        const endDate = selectedDays[selectedDays.length - 1];

        const response = yield call(() => getOpenShifts(
            includes,
            getStartOfWorkDay(startDate),
            getEndOfWorkDay(endDate),
        ));
        const openShifts = (getObjProperty(response, 'data') || [])
            .filter(shift => shift.statusForCurrentUser !== 'full');

        yield put({
            type: SHIFTS_OPEN_SUCCESS,
            openShifts,
        });
    } catch (error) {
        yield put({ type: SHIFTS_FAILURE, errors: [error.response] });
    }
}

export function* setShiftsTab({ tab, history }) {
    const tabLink = tab === MY_SHIFTS ? translate('nav.shifts.myShifts.link') : translate('nav.shifts.openShifts.link');
    const link = `/${translate('nav.shifts.link')}/${tabLink}`;
    history && history.push(link);
    yield put({ type: SHIFTS_SET_TAB, tab });
}

export default function* shiftsWatcher() {
    yield takeLatest(SHIFTS_SAGA_GET_PLANNED_SHIFTS, fetchPlannedShifts);
    yield takeLatest(SHIFTS_SAGA_GET_OPEN_SHIFTS, fetchOpenShifts);
    yield takeLatest(SHIFTS_SAGA_SET_TAB, setShiftsTab);
}
