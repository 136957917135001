import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Documents from '../../containers/Documents/Documents';
import { Reducers } from '../../redux/reducers';
import { getDocumentsForMe } from '../../redux/documents-ts/documentsActions';
import { useTypedDispatch, useTypedSelector } from '../../@paco/redux/store';
import { downloadYearEndStatement, getYearEndStatements } from '../../@paco/redux/yearEndStatement/yearEndStatementActions';
import { setYear } from '../../@paco/redux/@interface/calendar/calendarReducer';

const ConnectedDocuments: FC = () => {
    const typedDispatch = useTypedDispatch();
    const { currentUser, loading: isUserLoading } = useSelector((state: Reducers) => state.userReducer);
    const { year } = useTypedSelector(state => state.calendarReducer);
    const { documents, loading: isDocumentsLoading } = useSelector((state: Reducers) => state.documentsReducer);
    const { yearEndStatements, isLoading: isYearEndStatementsLoading } = useTypedSelector(state => state.yearEndStatementReducer);

    const isLoading = [
        isUserLoading,
        isDocumentsLoading,
        isYearEndStatementsLoading,
    ].some(Boolean);

    useEffect((): void => {
        if (year && currentUser) {
            typedDispatch(getDocumentsForMe(year, currentUser));
            typedDispatch(getYearEndStatements(currentUser.loketEmployeeId));
        }
    }, [year, currentUser]);

    const onChangeYear = (value: number) => {
        typedDispatch(setYear(value));
    }

    const onDownloadYearEndStatementClick = (selectedYear: number): void => {
        const yearEndStatementsToDownload = yearEndStatements.filter(yearEndStatement => yearEndStatement.year === selectedYear);

        yearEndStatementsToDownload.map(givenYearEndStatement => typedDispatch(downloadYearEndStatement(givenYearEndStatement.employment.id, givenYearEndStatement.year)));
    }

    return (
        <Documents
            documents={documents}
            selectedYearEndStatement={yearEndStatements.find(yearEndStatement => yearEndStatement.year === year)}
            onDownloadYearEndStatementClick={onDownloadYearEndStatementClick}
            isLoading={isLoading}
            onChangeYear={onChangeYear}
        />
    );
};

export default ConnectedDocuments;
