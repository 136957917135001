import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ProfileDetailItem from '../../components/Profile/ProfileDetailItem';
import { translate } from '../../helpers/translations/translator';
import {
    editGender,
    editUserAddress,
    editUserEmail,
    editUserEmergencyContact,
    editUserPhone,
} from '../../redux/user/userActions';
import { GENDER } from '../../constants';
import { generateGenderOptions } from '../../helpers/tools';
import ProfileDetailSelect from '../../components/Profile/ProfileDetailSelect';
import { transformToDashCase } from '../../@paco/helpers/text';

import './Profile.scss';

const ProfileDetail = ({
    currentUser,
    match,
    history,
    dispatch,
    loading,
}) => {
    const ADDRESS = translate('pages.profile.address');
    const EMAIL = translate('pages.profile.email');
    const PHONE = translate('pages.profile.phone');
    const EMERGENCY_CONTACT = translate('pages.profile.emergencyContact');
    const GENDER_TRANS = translate('common.gender');

    if (!currentUser) {
        return null;
    }

    const { email, person, phone } = currentUser;
    const emergencyContact = person.emergencyContact || {};

    const address = (person.addresses && person.addresses.length)
        ? person.addresses[person.addresses.length - 1] : {};
    const activeSection = match.params.detailId;

    const onEmailSave = (values) => {
        dispatch(editUserEmail(currentUser.id, values[0], history));
    };

    const onAddressSave = (values) => {
        dispatch(editUserAddress(
            person.id,
            values[0],
            values[1],
            values[2],
            values[3],
            values[4],
            history,
        ));
    };

    const onPhoneSave = (values) => {
        dispatch(editUserPhone(currentUser.id, values[0], history));
    };

    const onEmergencyContactSave = (values) => {
        dispatch(editUserEmergencyContact(
            person.id,
            emergencyContact.id,
            values[0],
            values[1],
            values[2],
            history,
        ));
    };

    const onGenderSave = (value) => {
        dispatch(editGender(person.id, value, history));
    };

    if (activeSection === transformToDashCase(EMAIL)) {
        const subject = translate('pages.profile.email').toLowerCase();
        const intro = translate('pages.profile.notifyHRIntro', { item: subject });
        return (
            <ProfileDetailItem
                loading={loading}
                fields={[
                    {
                        propName: EMAIL,
                        value: email,
                        type: 'email',
                    },
                ]}
                title={EMAIL}
                intro={intro}
                onSave={onEmailSave}
            />
        );
    }

    if (activeSection === transformToDashCase(ADDRESS)) {
        const subject = translate('pages.profile.address').toLowerCase();
        const intro = translate('pages.profile.notifyHRIntro', { item: subject });
        return (
            <ProfileDetailItem
                loading={loading}
                fields={[
                    {
                        propName: translate('pages.profile.street'),
                        value: address.street,
                    },
                    {
                        propName: translate('pages.profile.houseNumber'),
                        value: address.houseNumber,
                    },
                    {
                        propName: translate('pages.profile.houseNumberAddition'),
                        value: address.houseNumberAddition,
                    },
                    {
                        propName: translate('pages.profile.postalCode'),
                        value: address.postalCode,
                    },
                    {
                        propName: translate('pages.profile.city'),
                        value: address.city,
                    },
                ]}
                title={ADDRESS}
                intro={intro}
                onSave={onAddressSave}
            />
        );
    }

    if (activeSection === transformToDashCase(PHONE)) {
        return (
            <ProfileDetailItem
                loading={loading}
                fields={[
                    {
                        propName: PHONE,
                        value: phone,
                        type: 'tel',
                    },
                ]}
                title={PHONE}
                onSave={onPhoneSave}
            />
        );
    }

    if (activeSection === transformToDashCase(EMERGENCY_CONTACT)) {
        return (
            <ProfileDetailItem
                loading={loading}
                fields={[
                    {
                        propName: translate('common.name'),
                        value: emergencyContact.fullName,
                    },
                    {
                        propName: PHONE,
                        value: emergencyContact.phone,
                        type: 'tel',
                    },
                    {
                        propName: translate('pages.profile.relationWithEmergencyPerson'),
                        value: emergencyContact.relationType,
                    },
                ]}
                title={EMERGENCY_CONTACT}
                onSave={onEmergencyContactSave}
            />
        );
    }

    if (activeSection === transformToDashCase(GENDER_TRANS)) {
        return (
            <ProfileDetailSelect
                loading={loading}
                propName={GENDER}
                value={person.gender}
                options={generateGenderOptions(person.gender)}
                title={translate('common.gender')}
                onSave={onGenderSave}
            />
        );
    }

    return null;
};

ProfileDetail.propTypes = {
    currentUser: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

ProfileDetail.defaultProps = {
    currentUser: null,
};

const mapStateToProps = state => ({
    currentUser: state.userReducer.currentUser,
    loading: state.userReducer.loading,
});

export default withRouter(connect(mapStateToProps)(ProfileDetail));
