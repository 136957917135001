import React, { FC, useMemo } from 'react';
import classnames from 'classnames';

import { ShiftExchangeStatus, ShiftExchangeViewModel } from '../../models';
import ExchangeHeader from '../ExchangeHeader/ExchangeHeader';
import ExchangeShiftInfoList from '../ExchangeShiftInfoList/ExchangeShiftInfoList';
import { getUserFullName } from '../../helpers/getUserFullName';
import { translate } from '../../helpers/translations/translator';
import { Button, LoadingSpinner } from '../../@paco/components';
import { shouldShowEndTime } from '../../helpers/tools';
import { SettingResource } from '../../models/Setting';
import ShiftPlannedUsers from '../ShiftPlannedUsers/ShiftPlannedUsers';

import '../ShiftExchangeDetailByMe/ShiftExchangeDetail.scss';


interface ShiftExchangeDetailByMeProps {
    loading: boolean;
    settings: SettingResource[];
    exchange: ShiftExchangeViewModel;
    onDenyShiftExchange: () => void;
    onAcceptShiftExchange: () => void;
}

const ShiftExchangeDetailForMe: FC<ShiftExchangeDetailByMeProps> = ({
    loading,
    settings,
    exchange,
    onDenyShiftExchange,
    onAcceptShiftExchange,
}) => {
    const { shift, user } = exchange.shiftPlanning1;
    const { shift: shift2, user: user2 } = exchange.shiftPlanning2;
    const planningUserName = useMemo(() => (user ? getUserFullName(user) : ''), [user]);
    const planning2UserName = useMemo(() => (user2 ? getUserFullName(user2) : ''), [user2]);
    const canViewPlanningEndTime = useMemo(() => shouldShowEndTime(settings, shift?.department?.id), [shift, settings]);
    const canViewPlanning2EndTime = useMemo(() => shouldShowEndTime(settings, shift?.department?.id), [shift, settings]);
    const classNames = useMemo(() => classnames('shift-exchange-detail--for-me', {
        'shift-exchange-detail--approved-by-employee': exchange.status === ShiftExchangeStatus.approvedByEmployee,
    }), [exchange]);

    return (
        <div className={classNames}>
            {loading && <LoadingSpinner /> }
            <ExchangeHeader status={exchange.status} date={exchange.shiftPlanning1.start} />
            { (shift && user) && (
                <ExchangeShiftInfoList
                    title={translate('pages.exchanges.offeredShift')}
                    canViewEndTime={canViewPlanningEndTime}
                    userId={user.id}
                    userName={planningUserName}
                    shift={shift}
                    shiftPlanning={exchange.shiftPlanning1}
                />
            )}

            { shift && (
                <ShiftPlannedUsers
                    exchangeUser={user?.id}
                    peopleLimit={shift.peopleLimit}
                    shiftPlannings={shift.shiftPlannings}
                />
            )}

            {(shift2 && user2) && (
                <ExchangeShiftInfoList
                    title={translate('pages.exchanges.exchangeForYourShift')}
                    canViewEndTime={canViewPlanning2EndTime}
                    userId={user2.id}
                    userName={planning2UserName}
                    shift={shift2}
                    shiftPlanning={exchange.shiftPlanning2}
                />
            )}
            <div className="shift-exchange-detail__container-button">
                <Button
                    text={translate('pages.exchanges.denyRequest')}
                    color={exchange.status === ShiftExchangeStatus.approvedByEmployee ? 'orange' : 'orange-text'}
                    onClick={onDenyShiftExchange}
                    className="shift-exchange-detail__deny-button"
                />
                <Button
                    text={translate('pages.exchanges.acceptRequest')}
                    onClick={onAcceptShiftExchange}
                    className="shift-exchange-detail__accept-button"
                />
            </div>
        </div>
    );
}

export default ShiftExchangeDetailForMe;
