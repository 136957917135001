import React, { FC, useMemo } from 'react';
import { ColleagueViewModel, UserViewModel } from '../../models';
import getUserSelectOptions from './helpers/getUserSelectOptions'
import FormSelect from '../FormSelect/FormSelect';
import { translate } from '../../helpers/translations/translator';


interface FormUserSelectProps {
    users: (UserViewModel | ColleagueViewModel)[];
    selectedUser: string | null;
    onSelectUser: (userId: string) => void;
}

const FormUserSelect: FC<FormUserSelectProps> = ({
    selectedUser,
    users,
    ...props
}) => {
    const options = useMemo(() => getUserSelectOptions(users), [users]);

    const onSelectUser = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = users.find(user => user.id === event.target.value);

        if (value) {
            props.onSelectUser(value.id);
        }
    }

    return (
        <FormSelect
            options={options}
            selected={selectedUser}
            title={translate('pages.exchanges.chooseAnEmployee')}
            onChange={onSelectUser}
        />
    );
}

export default FormUserSelect;
