import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, FormFeedback, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router';
import connect from 'react-redux/es/connect/connect';
import { compareAsc, getEndOfWorkDay, getStartOfWorkDay } from '../../../@paco/helpers/date';
import { Button, InfoListBlock, InfoListItem } from '../../../@paco/components';
import { formatDate } from '../../../helpers/DateTimeParser';
import { createLeaveOfAbsence } from '../../../redux/absence/absenceActions';
import { translate } from '../../../helpers/translations/translator';
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';

import './ApplyLeaveOfAbsence.scss';

class ApplyLeaveOfAbsence extends Component {
    state = {
        selectedStartDate: formatDate(new Date()),
        selectedEndDate: formatDate(new Date()),
        reason: undefined,
        endDateValid: false,
        canSubmit: true,
    };

    static getDerivedStateFromProps = (props, state) => {
        const { selectedStartDate, selectedEndDate, reason } = state;
        const endDateValid = compareAsc(new Date(selectedEndDate), new Date(selectedStartDate)) !== -1;
        const canSubmit = reason && endDateValid;

        return {
            ...state,
            endDateValid,
            canSubmit,
        };
    }

    handleStartDateChange = (val) => {
        this.setState({ selectedStartDate: val });
    };

    handleEndDateChange = (val) => {
        this.setState({ selectedEndDate: val });
    };

    handleReasonChange = (e) => {
        this.setState({ reason: e.currentTarget.value });
    };

    handleFormSubmit = (e) => {
        e.preventDefault();

        const { selectedStartDate, selectedEndDate, reason } = this.state;
        const { dispatch, history } = this.props;

        dispatch(createLeaveOfAbsence(
            getStartOfWorkDay(new Date(selectedStartDate)),
            getEndOfWorkDay(new Date(selectedEndDate)),
            reason,
            history,
        ));
    };

    render() {
        const {
            selectedStartDate, selectedEndDate, canSubmit, endDateValid,
        } = this.state;

        return (
            <form onSubmit={this.handleFormSubmit}>
                <ul>
                    <InfoListItem label={translate('common.startDate')} className="form-apply-loa__list-item">
                        <FormGroup className="form-apply-loa__form-group">
                            <FormDatePicker
                                onChange={this.handleStartDateChange}
                                selected={selectedStartDate}
                            />
                        </FormGroup>
                    </InfoListItem>
                    <InfoListItem label={translate('common.endDate')} className="form-apply-loa__list-item">
                        <FormGroup className="form-apply-loa__form-group">
                            <FormDatePicker
                                invalid={!endDateValid}
                                onChange={this.handleEndDateChange}
                                selected={selectedEndDate}
                            />
                            <FormFeedback>
                                {translate('pages.absences.endDateHasToBeLaterThanStartDate')}
                            </FormFeedback>
                        </FormGroup>
                    </InfoListItem>
                    <InfoListBlock
                        label={translate('common.reason')}
                        className="form-apply-loa__list-block"
                    >
                        <Input
                            onChange={this.handleReasonChange}
                            placeholder={translate('pages.absences.descriptionOfThisRequest')}
                            type="textarea"
                            className="form-apply-loa__textarea-input"
                        />
                    </InfoListBlock>
                </ul>

                <Button
                    disabled={!canSubmit}
                    type="submit"
                    className="form-apply-loa__submit-button"
                >
                    {translate('common.request')}
                </Button>
            </form>
        );
    }
}

ApplyLeaveOfAbsence.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};
ApplyLeaveOfAbsence.defaultProps = {};

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps)(ApplyLeaveOfAbsence));
