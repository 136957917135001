import { AbsenceHoursResource, AbsenceHoursViewModel } from '../../models/AbsenceHours';

export default function transformAbsenceHoursResource(
    absenceHours: AbsenceHoursResource,
): AbsenceHoursViewModel {
    return {
        hours: absenceHours.hours,
        payrollPeriod: absenceHours.payrollPeriod,
    }
}
