import axios, { AxiosResponse } from 'axios';
import { ColleagueResource, ColleagueViewModel } from '../../models';
import transformColleagueResource from '../../services/ColleagueService/transformColleagueResource';

export const getColleagues = async (
    userRoleSlugs: string[] = [],
    departments: string[] = [],
    includes: string[] = [],
): Promise<ColleagueViewModel[]> => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/me/colleagues`,
        {
            params: {
                userRoles: userRoleSlugs.length > 0 ? JSON.stringify(userRoleSlugs) : undefined,
                departments,
                include: includes.join(','),
                'page[size]': 9999,
            },
        },
    ) as unknown as AxiosResponse<ColleagueResource[]>;

    return response.data.map(user => transformColleagueResource(user, user.departments));
};
