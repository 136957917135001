import { Role, RoleResource } from './Role';
import { getEnumKeyByEnumValue } from '../../helpers/enum';
import { RoleType } from '../../types/roleTypes';

export const transformToRole = (resource: RoleResource): Role => {
    const roleTypeKey = getEnumKeyByEnumValue(RoleType, resource.attributes.slug);

    if (!roleTypeKey) {
        console.error(`[transformToRole]: Unknown slug found named "${resource.attributes.slug}".`);
    }

    return {
        id: resource.id,
        name: resource.attributes.name,
        slug: roleTypeKey ? RoleType[roleTypeKey] : RoleType.employee,
        public: resource.attributes.public,
        assignable: resource.attributes.assignable,
        priority: resource.attributes.priority,
    };
};
