import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileOfCurrentUser } from '../../redux/user/userActions';
import { Reducers } from '../../redux/reducers';
import { getEmployeeLoketBalances } from '../../redux/absences-ts/absencesActions';
import LoketLeaveBalances from '../../containers/LoketLeaveBalances/LoketLeaveBalances';


const ConnectedLoketLeaveBalances: FC = () => {
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state: Reducers) => state.userReducer);
    const { loading, employeeLoketLeaveBalance } = useSelector((state: Reducers) => state.absencesReducer);
    const [loketEmployeeNotFound, setLoketEmployeeNotFound] = useState(false);

    useEffect(() => {
        if (!currentUser) {
            dispatch(getProfileOfCurrentUser());
        }
    }, []);

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        if (!currentUser.loketEmployeeId) {
            setLoketEmployeeNotFound(true);
        } else {
            dispatch(getEmployeeLoketBalances(currentUser.loketEmployeeId));
        }
    }, [currentUser]);

    return (
        <LoketLeaveBalances
            loading={loading}
            loketLeaveBalance={employeeLoketLeaveBalance || null}
            loketEmployeeNotFound={loketEmployeeNotFound}
        />
    );
};

export default ConnectedLoketLeaveBalances;
