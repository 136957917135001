import {
    LEAVE_OF_ABSENCE_FAILURE,
    LEAVE_OF_ABSENCE_REQUEST,
    LEAVE_OF_ABSENCE_SUCCESS,
} from '../actionTypes';

const initialState = {
    leaveOfAbsence: null,
    loading: false,
    errors: [],
};

const absenceReducer = (state = initialState, action) => {
    switch (action.type) {
    case LEAVE_OF_ABSENCE_REQUEST:
        return { ...state, loading: true, leaveOfAbsence: null };
    case LEAVE_OF_ABSENCE_SUCCESS:
        return {
            ...state, loading: false, leaveOfAbsence: action.leaveOfAbsence,
        };
    case LEAVE_OF_ABSENCE_FAILURE:
        return {
            ...state, loading: false, errors: action.errors,
        };
    default:
        return state;
    }
};

export default absenceReducer;
